import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { GridDataResult, SelectAllCheckboxState } from '@progress/kendo-angular-grid';
import { process, State } from '@progress/kendo-data-query';
import { Observable } from 'rxjs';
import { map } from 'rxjs';
import { PermissionsProvider } from '../../../../services/permissions.provider';
import { ScenarioStoreService } from '../../../../services/scenario-store.service';
import { PadBuilderEditService } from './pad-builder-data-edit-service';
import { DialogRef } from '@progress/kendo-angular-dialog';

@Component({
  selector: 'app-active-for-outlook',
  templateUrl: './active-for-outlook.component.html',
  styleUrls: ['./active-for-outlook.component.css']
})
export class ActiveForOutlookComponent implements OnInit {

  public showProgressBar: boolean;
  public canEditCnd: boolean = false;
  public view: Observable<GridDataResult>;
  public gridState: State = {
    sort: [],
    skip: 0
  };
  public mySelection: string[] = [];
  public selectAllState: SelectAllCheckboxState = 'unchecked';
  public padBuilderEditService: PadBuilderEditService;
  public cancelPadBuilderData: any[];
  public formActive: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder,
    private scenarioStoreService: ScenarioStoreService,
    @Inject(PadBuilderEditService) padBuilderEditServiceFactory: any,
    private permissionsProvider: PermissionsProvider,
    private dialog: DialogRef) {
      this.padBuilderEditService = padBuilderEditServiceFactory();
    }

  ngOnInit(): void {
    this.canEditCnd = this.permissionsProvider.canEditCnd;
    this.view = this.padBuilderEditService.pipe(map(data => process(data, this.gridState)));
    this.padBuilderEditService.read();
    this.cancelPadBuilderData = this.padBuilderEditService.originalData;

    this.formActive = this.fb.group({
      padName: [''],
      activeForOutlook: ['']
    });
  }

  public onSelectedKeysChange(e: any) {
    const len = this.mySelection.length;

    if (len === 0) {
      this.selectAllState = 'unchecked';
    }
  }

  public cellCloseHandler(args: any) {
    const { formGroup, dataItem } = args;
    if (!formGroup.valid) {
      args.preventDefault();
    } else if (formGroup.dirty) {
      this.scenarioStoreService.updatePadConfigField(
        args.dataItem.padName,
        "activeForOutlook",
        args.formGroup.controls.activeForOutlook.value
      );
      
      this.padBuilderEditService.assignValues(dataItem, formGroup.value);
      this.padBuilderEditService.update(dataItem);
      // this.view = this.scenarioStoreService.padBuilderData$.pipe(map(data => process(data, this.gridState)));
    }
  }

  public cellClickHandler({ sender, rowIndex, columnIndex, dataItem, isEdited }) {
    if (!isEdited) {
      sender.editCell(rowIndex, columnIndex, this.fb.group({
        activeForOutlook: dataItem.activeForOutlook
      }));
    }
  }

  public onSelectAllChange(checkedState: SelectAllCheckboxState) {
    if (checkedState === 'checked') {
      this.mySelection = this.padBuilderEditService.data.map(item => item.padName);
      if (this.mySelection.length === 0) {
      } else {
        this.selectAllState = 'checked';
      }
    } else {
      this.mySelection = [];
      this.selectAllState = 'unchecked';
    }
  }

  public massiveSetPadBuilderData(value: string){
      this.mySelection.forEach(element => {
        this.scenarioStoreService.updatePadConfigField(
          element,
          "activeForOutlook",
          value
        );
        
      let elementToChange = this.padBuilderEditService.data.find(x => x.padName == element);
      elementToChange.activeForOutlook = value;
      this.padBuilderEditService.assignValues(elementToChange, value);
      this.padBuilderEditService.update(elementToChange);
      });
    this.view = this.padBuilderEditService.pipe(map(data => process(data, this.gridState)));
    this.padBuilderEditService.read();
  }

  public closeActiveForOutlookDialog(action: string) {
    if(action =='Cancel') {
      this.cancelPadBuilderData.forEach(element => {
        this.scenarioStoreService.updatePadConfigField(
          element.padName,
          "activeForOutlook",
          element.activeForOutlook
        );
        
      let elementToChange = this.padBuilderEditService.data.find(x => x.padName == element.padName);
      elementToChange.activeForOutlook = element.activeForOutlook;
      this.padBuilderEditService.assignValues(elementToChange, element.activeForOutlook);
      this.padBuilderEditService.update(elementToChange);
      });
    } else if(action == 'Submit') {
      this.padBuilderEditService.setNewOriginalData();
      this.cancelPadBuilderData = this.padBuilderEditService.originalData;
    }
    this.dialog.close();
  }

  public onCancelAction(action: string): void {
    this.closeActiveForOutlookDialog(action);
  }

  public onClickOk(action: string): void {
    this.closeActiveForOutlookDialog(action);
  }

}
