import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, concat, Observable } from 'rxjs';
import { catchError, take } from 'rxjs';
import { AuthenticationService } from 'src/app/services';
import { HandleError, HttpErrorHandler } from 'src/app/services/http-error-handler.service';
import { IFacilityBod } from 'src/app/_shared/_models/facility.bod.model';
import { IFacilityProjectCostEstimates } from 'src/app/_shared/_models/facility.project.cost.estimates';
import { environment } from 'src/app/../environments/environment';
import { ISftBodStatus } from '../bod/_models/sft-bod-status';
import { PerformanceConfigurationService } from 'src/app/services/performance-configuration-service';
import { Globals } from 'src/app/_shared/Globals';
import { FacilityProjectCostSignaturesService } from '../bod/_services/facility.project.cost.signatures.service';
import { IFacilityProjectCostSignatures } from '../bod/_models/facility.project.cost.signatures';

@Injectable({
  providedIn: 'root',
})
export class FacilityProviderService {
  public baseUrlFacilityBod: string = environment.PacerDomainURL + 'FacilityBod';
  public baseUrlFacilityProjectCostEstimates: string = environment.PacerDomainURL + 'FacilityProjectCostEstimates';
  public facilityBod$: Observable<IFacilityBod>;
  public facilityProjectCostEstimates$: Observable<IFacilityProjectCostEstimates[]>;
  public isReadOnlyFacility$: Observable<boolean>;
  private facilityBodValue$: BehaviorSubject<IFacilityBod>;
  private facilityProjectCostEstimatesValue$: BehaviorSubject<IFacilityProjectCostEstimates[]>;
  private isReadOnlyFacilityValue$: BehaviorSubject<boolean>;
  private handleError: HandleError;
  private signaturesList: IFacilityProjectCostSignatures[] = [];

  constructor(
    public globals: Globals,
    private http: HttpClient,
    private httpErrorHandler: HttpErrorHandler,
    private authenticationService: AuthenticationService,
    private facilityProjectCostSignaturesService: FacilityProjectCostSignaturesService
  ) {
    this.facilityBodValue$ = new BehaviorSubject<IFacilityBod>(null);
    this.facilityBod$ = this.facilityBodValue$.asObservable();
    this.facilityProjectCostEstimatesValue$ = new BehaviorSubject<IFacilityProjectCostEstimates[]>([]);
    this.facilityProjectCostEstimates$ = this.facilityProjectCostEstimatesValue$.asObservable();
    this.isReadOnlyFacilityValue$ = new BehaviorSubject<boolean>(true);
    this.isReadOnlyFacility$ = this.isReadOnlyFacilityValue$.asObservable();
    this.handleError = httpErrorHandler.createHandleError();
  }

  public initialize(id: string) {
    this.getFacilityBodById(id).subscribe((facilityBod: IFacilityBod[]) => {
      if (facilityBod && facilityBod.length > 0) {
        this.facilityBodValue$.next(facilityBod[0]);
        this.facilityProjectCostEstimatesValue$.next(facilityBod[0].facilityProjectCostEstimates);
      }     
    });

    this.facilityProjectCostSignaturesService.facilityProjectCostSignatures$.subscribe(
      (signatures: IFacilityProjectCostSignatures[]) => {
        this.signaturesList = signatures;
        this.setFacilityVisibility();
      }
    );
  }

  public setFacilityBodValue(value: any) {
    this.facilityBodValue$.next(value);
  }

  public cleanObservables(): void {
    this.facilityBodValue$.next(null);
    this.facilityProjectCostEstimatesValue$.next([]);
  }

  private setFacilityVisibility(): void {
    const facilityProjectCostEstimates = this.facilityProjectCostEstimatesValue$.value;
    if (facilityProjectCostEstimates) {
      if (facilityProjectCostEstimates.length > 0) {
        const projectEstimate = facilityProjectCostEstimates[0];
        const createdBy = !projectEstimate.createdBy ? '' : projectEstimate.createdBy.toLowerCase();
        const updatedBy = !projectEstimate.updatedBy ? '' : projectEstimate.updatedBy.toLowerCase();
        const executionPmUserId = !projectEstimate.executionPmUserId
          ? ''
          : projectEstimate.executionPmUserId.toLowerCase();
        const performancePmUserId = !projectEstimate.performancePmUserId
          ? ''
          : projectEstimate.performancePmUserId.toLowerCase();
        const instrumentationElectricalPmUserId = !projectEstimate.instrumentationElectricalPmUserId
          ? ''
          : projectEstimate.instrumentationElectricalPmUserId.toLowerCase();
        if (
          this.authenticationService.getCurrentUser().toLowerCase() === createdBy ||
          this.authenticationService.getCurrentUser().toLowerCase() === updatedBy ||
          this.authenticationService.getCurrentUser().toLowerCase() === executionPmUserId ||
          this.authenticationService.getCurrentUser().toLowerCase() === performancePmUserId ||
          this.authenticationService.getCurrentUser().toLowerCase() === instrumentationElectricalPmUserId
        ) {
          if (this.signaturesList.length > 0) {
            this.isReadOnlyFacilityValue$.next(true);
          } else {
            this.isReadOnlyFacilityValue$.next(false);
          }
        } else {
          this.isReadOnlyFacilityValue$.next(true);
        }
      }
    }
  }

  private getFacilityBodById(id: string): Observable<IFacilityBod[]> {
    return this.http
      .get<IFacilityBod>(`${this.baseUrlFacilityBod}/getFacilityBodById/${id}`)
      .pipe(catchError(this.handleError('getFacilityBodById')));
  }

  private getFacilityEstimatesByFacilityBodId(id: string): Observable<IFacilityProjectCostEstimates[]> {
    return this.http
      .get<IFacilityProjectCostEstimates[]>(
        `${this.baseUrlFacilityProjectCostEstimates}/GetFacilityProjectCostEstimatesByFacilityBodId/${id}`
      )
      .pipe(catchError(this.handleError('getFacilityEstimatesByFacilityBodId')));
  }
}
