import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IEditableInline } from 'src/app/api/ieditable-inline';
import { IPerformanceBodFacilityPad } from 'src/app/api/performance-bod-facility-pad';
import { InlineEditService } from 'src/app/services/inline-edit.service';
import { PerformanceBodDataService } from 'src/app/services/performance-bod-data.service';
import { PerformanceBodCalculationsService } from 'src/app/services/performance-bod-calculations.service';
import { map } from 'rxjs';
import * as Helpers from 'src/app/_shared/helpers';

@Injectable({
  providedIn: 'root',
})
export class InfrastructureFacilityPadsService extends InlineEditService implements IEditableInline {
  public data: IPerformanceBodFacilityPad[] = [];
  public originalData: IPerformanceBodFacilityPad[] = [];
  public createdItems: IPerformanceBodFacilityPad[] = [];
  public updatedItems: IPerformanceBodFacilityPad[] = [];
  public deletedItems: IPerformanceBodFacilityPad[] = [];

  public performanceBodId: string;

  constructor(
    private performanceBodDataService: PerformanceBodDataService,
    private performanceBodCalculationsService: PerformanceBodCalculationsService
  ) {
    super();
  }

  public isNew(item: any): boolean {
    return !item.id;
  }

  public itemIndex(item: any, data: IPerformanceBodFacilityPad[]): number {
    for (let idx = 0; idx < data.length; idx++) {
      if (data[idx].id === item.id) {
        return idx;
      }
    }
    return -1;
  }

  public create(): Observable<any> {
    return this.performanceBodDataService.createPerformanceBodFacilityPad(this.createdItems);
  }

  public retrieve(): Observable<any> {
    const rows: IPerformanceBodFacilityPad[] = [];
    return this.performanceBodDataService.getPerformanceBodFacilityPadsForGrid(this.performanceBodId).pipe(
      map((data: IPerformanceBodFacilityPad[]) => {
        data.forEach(dataRow => {
          const row: IPerformanceBodFacilityPad = {
            id: dataRow.id,
            // performanceBodId: dataRow.performanceBodId,
            performanceBodPackagesId: dataRow.performanceBodPackagesId,
            name: dataRow.name,
            padRfsd:
              dataRow.padRfsd !== null && dataRow.padRfsd !== undefined
                ? Helpers.convertUtcToLocalTime(dataRow.padRfsd)
                : undefined,
            padDimensions: dataRow.padDimensions,
            permitsRequiredBy:
              dataRow.permitsRequiredBy !== null && dataRow.permitsRequiredBy !== undefined
                ? Helpers.convertUtcToLocalTime(dataRow.permitsRequiredBy)
                : undefined,
            padOrientation: dataRow.padOrientation,
            comments: dataRow.comments,
            isDeleted: dataRow.isDeleted,
            createdBy: dataRow.createdBy,
            createdDate: dataRow.createdDate,
            updatedBy: dataRow.updatedBy,
            updatedDate: dataRow.updatedDate,
            sortOrder: dataRow.sortOrder,
            chargeCode: dataRow.chargeCode,
            cndHOEstimate: dataRow.cndHOEstimate,
            afeNumber: dataRow.afeNumber,
            afeAmount: dataRow.afeAmount,
          };
          rows.push(row);
        });
        return rows;
      })
    );
  }

  public update(): Observable<any> {
    return this.performanceBodDataService.updatePerformanceBodFacilityPad(this.updatedItems);
  }

  public delete(): Observable<any> {
    return this.performanceBodDataService.updatePerformanceBodFacilityPad(this.deletedItems);
  }

  public applyLogicFromPads(): void {
    const facilityPadChanges = this.performanceBodCalculationsService.facilityPadCalculations(this.data);
    for (const facilityPad of facilityPadChanges) {
      this.updateItem(facilityPad);
    }
  }
}
