<kendo-dialog title="Warning" *ngIf="openDialog" [minWidth]="250" width="450" (close)="closeKendoPopUp()">
  <p>{{ warningMessage }}</p>
</kendo-dialog>

<kendo-grid #performanceBodSWD [data]="view | async" (cellClick)="cellClickHandler($event)"
  (cellClose)="cellCloseHandler($event)" (cancel)="cancelHandler($event)" (save)="saveHandler($event)"
  (remove)="removeHandler($event)" (add)="addHandler($event)" [navigable]="true" [resizable]="true"
  [sort]="gridState.sort" [sortable]="{ mode: 'multiple' }" (dataStateChange)="onStateChange($event)" filterable="menu"
  [filter]="filter" (filterChange)="handlerFilter($event)" class="standard-facilities-swd-draggable"
  [rowClass]="rowCallback">
  <ng-template kendoGridToolbarTemplate>
    <button kendoButton class="k-button" [disabled]="!performanceBodSaltWaterDisposalService.hasChanges()"
      (click)="cancelChanges(performanceBodSWD)" [primary]="true">
      Cancel
    </button>
    <button [icon]="'plus'" kendoGridAddCommand [primary]="true" [disabled]="!canEditPerformanceBod"></button>
  </ng-template>
  <kendo-grid-column title="" field="" [hidden]="true" width="1">
    {{ rowLoaded() }}
  </kendo-grid-column>
  <kendo-grid-column field="name" title="Facility Name (SWD)" [editable]="true">
    <ng-template kendoGridEditTemplate let-column="column" let-formGroup="formGroup" let-isNew="isNew">
      <input #input elementFocus class="k-textbox" [formControl]="formGroup.get(column.field)" />
      <kendo-popup [anchor]="input" [hidden]="messageDuplicate" popupClass="k-widget k-tooltip k-tooltip-validation">
        The facility Name (SWD) already exist.
      </kendo-popup>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="Well Pad Name" field="performanceBodPadsId" [editable]="true">
    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
      <multicheck-filter [isPrimitive]="false" [field]="column.field" textField="wellPadName" valueField="id"
        [filterService]="filterService" [currentFilter]="filter" [data]="performanceBodPadsList">
      </multicheck-filter>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ getPerformanceBodPads(dataItem?.performanceBodPadsId)?.wellPadName }}
    </ng-template>
    <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-column="column" let-formGroup="formGroup"
      let-isNew="isNew">
      <kendo-dropdownlist [data]="performanceBodPadsList" textField="wellPadName" valueField="id"
        [valuePrimitive]="true" [formControl]="formGroup.get('performanceBodPadsId')"
        (valueChange)="changePackage($event, dataItem, formGroup)">
      </kendo-dropdownlist>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="performanceBodPackagesId" title="Package Name" [editable]="false">
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ getPerformanceBodPackages(dataItem.performanceBodPackagesId)?.name }}
    </ng-template>
    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
      <multicheck-filter [isPrimitive]="false" [field]="column.field" textField="name" valueField="id"
        [filterService]="filterService" [currentFilter]="filter" [data]="performanceBodPackagesList">
      </multicheck-filter>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="chargeCode" title="Dummy Charge code" [editable]="true" width="150"> </kendo-grid-column>
  <kendo-grid-column field="cndHoEstimate" title="C&D/HO Estimate" [editable]="true" editor="numeric" filter="numeric"></kendo-grid-column>
  <kendo-grid-column field="afeNumber" title="AFE No." [editable]="true" width="150"> </kendo-grid-column>
  <kendo-grid-column field="afeAmount"  title="Approved AFE Amount" [editable]="true" editor="numeric" filter="numeric" width="150">
  </kendo-grid-column>
  <!-- <kendo-grid-column title="AFE No." field="afeId" [editable]="true">
    
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ getAfe(dataItem.afeId)?.name }}
    </ng-template>
    <ng-template
      kendoGridEditTemplate
      let-dataItem="dataItem"
      let-column="column"
      let-formGroup="formGroup"
      let-isNew="isNew"
    >
      <kendo-dropdownlist
        [data]="afeList"
        textField="name"
        valueField="id"
        [valuePrimitive]="true"
        [formControl]="formGroup.get('afeId')"
        (valueChange)="changeAfe($event, dataItem, formGroup)"
      > 
      </kendo-dropdownlist>
    </ng-template>
  </kendo-grid-column> -->
    <kendo-grid-column field="capacity" title="Planned Facility Capacity (BWPD)" [editable]="true" editor="numeric"
      filter="numeric">
    </kendo-grid-column>
    <kendo-grid-column field="peakWaterVolumeP70" title="Expected Peak Disposal (BWPD)" [editable]="true"
      editor="numeric" filter="numeric">
    </kendo-grid-column>
    <kendo-grid-column field="rfsdDate" title="RFSD" [editable]="true" editor="date" format="MM/dd/yyyy" width="100"
      [filterable]="filterable" filter="date">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span>{{ dataItem.rfsdDate | date: 'MM/dd/yyyy' }}</span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-column="column" let-formGroup="formGroup">
        <kendo-datepicker [value]="dataItem.rfsdDate" [format]="'MM/dd/yyyy'"
          [formControl]="formGroup.get('rfsdDate')"></kendo-datepicker>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="comments" title="Comments" [editable]="true"> </kendo-grid-column>
    <kendo-grid-command-column title="" width="150px">
      <ng-template kendoGridCellTemplate let-isNew="isNew">
        <button kendoGridRemoveCommand [primary]="true" [icon]="'minus'" [disabled]="!canEditPerformanceBod"></button>
        <button kendoGridSaveCommand [primary]="true">Add</button>
        <button kendoGridCancelCommand [primary]="true">Cancel</button>
      </ng-template>
    </kendo-grid-command-column>
</kendo-grid>