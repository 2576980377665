import {
  HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor,
  HttpRequest
} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {Router} from "@angular/router";
import { catchError } from "rxjs/operators";
import { throwError } from "rxjs";

@Injectable()
export class NotFoundInterceptor implements HttpInterceptor {
  constructor(private router: Router) {

  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error instanceof HttpErrorResponse && error.status === 404) {
            if (request.url.toLowerCase().includes('/InfrastructureBod/GetByKey/'.toLowerCase())) {
              this.router.navigateByUrl('/InfrastructureBod/List/Own', {replaceUrl: true});
            }            
          }
          return throwError(error);
        })
      )
  }
}
