import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs';
import { IEditableInline } from 'src/app/api/ieditable-inline';
import { InfrastructureRecycle } from 'src/app/api/infrastructure-recycle';
import { InlineEditService } from 'src/app/services/inline-edit.service';
import { PerformanceBodDataService } from 'src/app/services/performance-bod-data.service';
import { PerformanceBodStoreService } from 'src/app/services/performance-bod-store.service';
import * as Helpers from 'src/app/_shared/helpers';

@Injectable({
  providedIn: 'root',
})
export class InfrastructureRecycleService extends InlineEditService implements IEditableInline {
  public data: InfrastructureRecycle[] = [];
  public originalData: InfrastructureRecycle[] = [];
  public createdItems: InfrastructureRecycle[] = [];
  public updatedItems: InfrastructureRecycle[] = [];
  public deletedItems: InfrastructureRecycle[] = [];
  public performanceBodId: string;

  constructor(
    private performanceBodDataService: PerformanceBodDataService
  ) {
    super();
  }

  public isNew(item: any): boolean {
    return !item.id;
  }

  public itemIndex(item: any, data: InfrastructureRecycle[]): number {
    for (let idx = 0; idx < data.length; idx++) {
      if (data[idx].id === item.id) {
        return idx;
      }
    }
    return -1;
  }

  public create(): Observable<any> {
    return this.performanceBodDataService.createInfrastructureRecycle(this.createdItems);
  }

  public retrieve(): Observable<InfrastructureRecycle[]> {
    return this.performanceBodDataService.getInfrastructureRecycle(this.performanceBodId).pipe(map(infrastructureData => {
      infrastructureData.forEach(element => {
        if (element.readyForServiceDate !== null && element.readyForServiceDate !== undefined) {
          element.readyForServiceDate = Helpers.convertUtcToLocalTime(element.readyForServiceDate);
        } else {
          element.readyForServiceDate = undefined;
        }
      });
      return infrastructureData;
    }));
  }

  public update(): Observable<any> {
    return this.performanceBodDataService.updateInfrastructureRecycle(this.updatedItems);
  }

  public delete(): Observable<any> {
    return this.performanceBodDataService.updateInfrastructureRecycle(this.deletedItems);
  }

}
