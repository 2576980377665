import { Component, Inject, OnInit, EventEmitter, Output, OnDestroy } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ScenarioStoreService } from 'src/app/services/scenario-store.service';
import { UserPermissionsService } from 'src/app/services/user-permission.service';
import { IUsrPermissions } from 'src/app/api/user-permission';
import { IPerformanceBod } from 'src/app/api/performance-bod';
import { IPerformanceBodPackages } from 'src/app/api/performance-bod-package';
import { IPerformanceBodSignatures } from 'src/app/api/performance-bod-signatures';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from '@progress/kendo-angular-notification';
import { formatDate } from '@angular/common';
import { PerformanceBodDetailService } from './performance-bod-detail.service';
import { PerformanceBodStoreService } from 'src/app/services/performance-bod-store.service';
import { IntlService, setData } from '@progress/kendo-angular-intl';
import { Guid } from 'guid-typescript';
import clonedeep from 'lodash.clonedeep';
import { PermissionsProvider } from 'src/app/services/permissions.provider';
import { IStatusIndicator } from 'src/app/api/status-indicator';
import { IPerformanceBodStatus } from 'src/app/api/performance-bod-status';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ListService } from 'src/app/_shared/_services/list.service';
import { ILocationCatalogModel } from 'src/app/_shared/_models/location-catalog-model';
import { IBasin } from 'src/app/_shared/_models/basin.interface';
import { IGeographicArea } from 'src/app/_shared/_models/geographicarea.interface';
import { IDevArea } from 'src/app/_shared/_models/devarea.interface';
import { EmailService } from 'src/app/_shared/_services/email.service';
import { IEmailModel } from 'src/app/_shared/_models/email.model';
import { PerformanceAFEService } from '../performance-afe/performance-afe.service';
import * as Helpers from 'src/app/_shared/helpers'

interface Item {
  id: number;
  name: string;
  packageDisabled: boolean;
}

interface IUser {
  cai: string;
  fullName: string;
}

@Component({
  selector: 'app-performance-bod-detail',
  styleUrls: ['./performance-bod-detail.component.css'],
  templateUrl: './performance-bod-detail.component.html',
})
export class PerformanceBodDetailComponent implements OnInit {
  public canEditPerformanceBod: boolean = false;
  public performanceBodData: IPerformanceBod;
  public performanceBodPackages: IPerformanceBodPackages[] = [];
  public performanceBodPackage: IPerformanceBodPackages;
  public performanceBodSignatures: IPerformanceBodSignatures;
  public performanceBodStatus: IPerformanceBodStatus;
  public usersCnD: IUsrPermissions[];
  public users: IUser[] = [];
  public data: string[];
  public systemCAIs: string[] = [];
  public developmentAreaAll: IDevArea[];
  public developmentAreaOptions: IDevArea[];
  public developmentAreaOptionsFiltered: IDevArea[];
  public basinAll: IBasin[];
  public basinOptions: IBasin[];
  public geographicAreaAll: IGeographicArea[];
  public geographicAreaOptions: IGeographicArea[];
  public geographicAreaOptionsFiltered: IGeographicArea[];
  public statusIndicators: IStatusIndicator[];
  public isDisabledGeographic = true;
  public isDisabledDevelopment = true;
  public defaultAfeNumber: string;
  public defaultAfeAmount:number;

  public numPackages: Item[] = [
    { id: 1, name: '1', packageDisabled: false },
    { id: 2, name: '2', packageDisabled: false },
    { id: 3, name: '3', packageDisabled: false },
    { id: 4, name: '4', packageDisabled: false },
    { id: 5, name: '5', packageDisabled: false },
  ];

  public selectedItem: Item = this.numPackages[0];
  public developmentArea: UntypedFormControl = new UntypedFormControl(null, Validators.required);
  public geographicArea: UntypedFormControl = new UntypedFormControl(null, Validators.required);
  public basinArea: UntypedFormControl = new UntypedFormControl(null, Validators.required);
  public formNewBOD: UntypedFormGroup;

  @Output() public nameEvent = new EventEmitter<string>();
  @Output() public saveBodCompleted = new EventEmitter<boolean>();

  public performanceBODName = '';

  private userPermissionsService: UserPermissionsService;
  private perfAfeService:PerformanceAFEService;
  constructor(
    private scenarioStoreService: ScenarioStoreService,
    @Inject(UserPermissionsService) userPermissionsFactory: any,
    private spinner: NgxSpinnerService,
    private notificationService: NotificationService,
    private performanceBodDetailService: PerformanceBodDetailService,
    private performanceBodStoreService: PerformanceBodStoreService,
    public intl: IntlService,
    private listService: ListService,
    private permissionsProvider: PermissionsProvider,
    private authenticationService: AuthenticationService,
    private emailService: EmailService,
    @Inject(PerformanceAFEService) afeSvcFactory: any,
  ) {
    this.userPermissionsService = userPermissionsFactory();
    this.perfAfeService = afeSvcFactory();
  }

  public ngOnInit() {
    this.performanceBodStoreService.performanceBodSignatured$.subscribe((isSignature) => {
      this.canEditPerformanceBod = isSignature ? false : this.permissionsProvider.canEditPerformanceBod;
    });
    this.createNewFormGroup(null);
    this.userPermissionsService.getAllUsers().toPromise().then(data => {
      this.usersCnD = data;
      for (const entry of this.usersCnD) {
        this.systemCAIs.push(entry.fullName);
        this.users.push({
          cai: entry.name,
          fullName: entry.fullName,
        });
      }
      this.systemCAIs = this.systemCAIs.filter((el, i, a) => i === a.indexOf(el));
      this.systemCAIs.sort();
      this.data = this.systemCAIs.slice();
    });

    this.listService
      .getLocationCatalogs()
      .toPromise()
      .then((model: ILocationCatalogModel) => {
        this.basinOptions = model.basins;
        this.basinAll = model.basins;
        this.basinOptions = model.basins.filter(b => b.isDeleted === false);

        this.geographicAreaAll = model.geographicAreas;
        this.geographicAreaOptions = model.geographicAreas.filter(d => d.isDeleted === false);
        this.geographicAreaOptionsFiltered = this.geographicAreaOptions;

        this.developmentAreaAll = model.developmentAreas;
        this.developmentAreaOptions = model.developmentAreas.filter(d => d.isDeleted === false);
        this.developmentAreaOptionsFiltered = this.developmentAreaOptions;
        this.performanceBodStoreService.performanceBod$.subscribe((data: IPerformanceBod) => {
          if (data) {
            this.performanceBodData = {
              id: data.id,
              basinId: data.basinId,
              geographicAreaId: data.geographicAreaId,
              developmentAreaId: data.developmentAreaId,
              batch: data.batch,
              numberOfPackages: data.numberOfPackages,
              name: data.name,
              projectNumber: data.projectNumber,
              generalComments: data.generalComments,
              performanceProjectManagerSignature: data.performanceProjectManagerSignature,
              performanceProjectManagerDate: data.performanceProjectManagerDate,
              performanceTeamLeadSignature: data.performanceTeamLeadSignature,
              performanceTeamLeadDate: data.performanceTeamLeadDate,
              performanceReservoirEngineerSignature: data.performanceReservoirEngineerSignature,
              performanceReservoirEngineerDate: data.performanceReservoirEngineerDate,
              performanceProjectManagerCai: data.performanceProjectManagerCai,
              performanceReservoirEngineerCai: data.performanceReservoirEngineerCai,
              performanceTeamLeadCai: data.performanceTeamLeadCai,
              performanceWaterEngineerCai: data.performanceWaterEngineerCai,
              performanceWaterEngineerSignature: data.performanceWaterEngineerSignature,
              performanceWaterEngineerDate: data.performanceWaterEngineerDate,
              createdBy: data.createdBy,
              createdDate: data.createdDate,
              updatedBy: data.updatedBy,
              updatedDate: data.updatedDate,
              isDeleted: data.isDeleted,
              isTemp: data.isTemp,
              unspecifiedPads: false,
              performanceBodAfes: data.performanceBodAfes
            };
    
            const findIt = this.basinOptions.find(b => b.id === this.performanceBodData.basinId);
            if (!findIt) {
              const basin = this.basinAll.find(b => b.id === this.performanceBodData.basinId);
              if (basin) {
                this.basinOptions.unshift(basin);
              }
            }
    
            const findItGeo = this.geographicAreaOptions.find(b => b.id === this.performanceBodData.geographicAreaId);
            if (!findItGeo) {
              const geographicArea = this.geographicAreaAll.find(
                b => b.id === this.performanceBodData.geographicAreaId
              );
              if (geographicArea) {
                this.geographicAreaOptions.unshift(geographicArea);
              }
            }
    
            const findItDev = this.developmentAreaOptions.find(
              b => b.id === this.performanceBodData.developmentAreaId
            );
            if (!findItDev) {
              const developMentArea = this.developmentAreaAll.find(
                b => b.id === this.performanceBodData.developmentAreaId
              );
              if (developMentArea) {
                this.developmentAreaOptions.unshift(developMentArea);
              }
            }
    
            this.geographicAreaOptionsFiltered = this.geographicAreaOptions.filter(
              g => g.basinId === this.performanceBodData.basinId
            );
    
            this.geographicAreaOptionsFiltered =
              this.geographicAreaOptionsFiltered.length > 0
                ? this.geographicAreaOptionsFiltered
                : this.geographicAreaAll.filter(g => g.basinId === this.performanceBodData.basinId);
    
            if (this.geographicAreaOptionsFiltered !== null && this.geographicAreaOptionsFiltered !== undefined) {
              this.isDisabledGeographic = false;
            }
    
            this.developmentAreaOptionsFiltered = this.developmentAreaOptions.filter(
              d => d.geographicAreaId === this.performanceBodData.geographicAreaId
            );
    
            this.developmentAreaOptionsFiltered =
              this.developmentAreaOptionsFiltered.length > 0
                ? this.developmentAreaOptionsFiltered
                : this.developmentAreaAll.filter(
                    d => d.geographicAreaId === this.performanceBodData.geographicAreaId
                  );
    
            if (this.developmentAreaOptionsFiltered !== null && this.developmentAreaOptionsFiltered !== undefined) {
              this.isDisabledDevelopment = false;
            }
          }
          this.performanceBodStoreService.statusIndicator$.subscribe((data: IStatusIndicator[]) => {
            this.statusIndicators = data.filter(d => d.isForPerformance === true);
            this.performanceBodStoreService.performanceBodPackagesData$.subscribe((packages: IPerformanceBodPackages[]) => {
              if (packages) {
                this.performanceBodPackages = packages.sort((a, b) => {
                  return a.sortOrder - b.sortOrder;
                });
        
                this.selectedItem = this.numPackages.find(p => p.id === this.getNumOfPackagesSelectedId());
                this.numPackages.map((item: Item) => {
                  item.packageDisabled = this.performanceBodPackages.length <= item.id;
                });
                this.performanceBodStoreService.performanceBodSignaturesData$.subscribe((signatures: IPerformanceBodSignatures[]) => {
                  if (signatures) {
                    this.performanceBodSignatures = signatures.length > 0 ? signatures[0] : null;
                    this.performanceBodStoreService.performanceBodStatus$.subscribe((status: IPerformanceBodStatus[]) => {
                      if (status) {
                        this.performanceBodStatus = status.length > 0 ? status[0] : null;
                        this.loadBodData();
                      }
                    });        
                  }
                });                                
              }
            });            
          }); 
          this.refreshAfeData();
          // this.basinSelectionChange(this.performanceBodData.basinId);
        });        
      });
      
    this.bindToFormControls();

  }

  public convertToAfeFormat(params: any): any {
    return Helpers.convertToAfeFormat(params);
  }

  public refreshAfeData(){
    if(this.performanceBodData && this.performanceBodData.id){
      this.perfAfeService.retrieveByPerformanceBodId(this.performanceBodData.id).subscribe(data => {
        let defaultAfe = data.find(x => x.isDefault);
        if(defaultAfe){
          this.defaultAfeNumber = defaultAfe.number;
          this.defaultAfeAmount = defaultAfe.amount;
        }
      });
    }
  }

  public bindToFormControls() {
    // this.developmentArea.valueChanges.subscribe(() => this.updateScenarioName());
  }

  public filterChange(filter: any): void {
    this.data = this.systemCAIs.filter(s => s.toLowerCase().indexOf(filter.toLowerCase()) !== -1);
  }

  public valueChange(value: any, input: string): void {
    this.data = this.systemCAIs.filter(s => s.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    if (this.users.filter(d => d.fullName === value)) {
      const i = this.users.find(j => j.fullName === value);
      if (i !== undefined && this.data !== undefined) {
        this.formNewBOD.controls[input].setValue(i.fullName);
        switch (input) {
          case 'performancePMSignature':
            this.performanceBodData.performanceProjectManagerCai = i.cai;
            break;
          case 'performanceTLSignature':
            this.performanceBodData.performanceTeamLeadCai = i.cai;
            break;
          case 'reservoirEngineerSignature':
            this.performanceBodData.performanceReservoirEngineerCai = i.cai;
            break;
          case 'waterEngineerSignature':
            this.performanceBodData.performanceWaterEngineerCai = i.cai;
            break;
          default:
            break;
        }
      } else {
        switch (input) {
          case 'performancePMSignature':
            this.performanceBodData.performanceProjectManagerCai = null;
            break;
          case 'performanceTLSignature':
            this.performanceBodData.performanceTeamLeadCai = null;
            break;
          case 'reservoirEngineerSignature':
            this.performanceBodData.performanceReservoirEngineerCai = null;
            break;
          case 'waterEngineerSignature':
            this.performanceBodData.performanceWaterEngineerCai = null;
            break;
          default:
            break;
        }
        this.formNewBOD.controls[input].setValue('');
      }
    } else {
      switch (input) {
        case 'performancePMSignature':
          this.performanceBodData.performanceProjectManagerCai = null;
          break;
        case 'performanceTLSignature':
          this.performanceBodData.performanceTeamLeadCai = null;
          break;
        case 'reservoirEngineerSignature':
          this.performanceBodData.performanceReservoirEngineerCai = null;
          break;
        case 'waterEngineerSignature':
          this.performanceBodData.performanceWaterEngineerCai = null;
          break;
        default:
          break;
      }
      this.formNewBOD.controls[input].setValue('');
    }
  }

  public loadBodData() {
    if (this.performanceBodData && this.performanceBodPackages) {
      this.formNewBOD = new UntypedFormGroup({
        basinId: new UntypedFormControl(
          { id: this.performanceBodData.basinId, disabled: false },
          { updateOn: 'change', validators: [Validators.required] }
        ),
        batch: new UntypedFormControl(this.performanceBodData.batch, {
          updateOn: 'change',
          validators: [Validators.required, Validators.minLength(1), Validators.maxLength(50)],
        }),
        developmentAreaId: new UntypedFormControl(
          { id: this.performanceBodData.developmentAreaId, disabled: false },
          { updateOn: 'change', validators: [Validators.required] }
        ),
        generalComments: new UntypedFormControl(this.performanceBodData.generalComments, {
          updateOn: 'change',
          validators: [Validators.maxLength(500)],
        }),
        geographicAreaId: new UntypedFormControl(
          { id: this.performanceBodData.geographicAreaId, disabled: false },
          { updateOn: 'change', validators: [Validators.required] }
        ),
        name: new UntypedFormControl(this.performanceBodData.name, { updateOn: 'change', validators: Validators.required }),
        package1Name: new UntypedFormControl(this.getPackageByIndex(0).name, { updateOn: 'change' }),
        package2Name: new UntypedFormControl(this.getPackageByIndex(1).name, { updateOn: 'change' }),
        package3Name: new UntypedFormControl(this.getPackageByIndex(2).name, { updateOn: 'change' }),
        package4Name: new UntypedFormControl(this.getPackageByIndex(3).name, { updateOn: 'change' }),
        package5Name: new UntypedFormControl(this.getPackageByIndex(4).name, { updateOn: 'change' }),
        packageId: new UntypedFormControl({ id: this.getNumOfPackagesSelectedId(), disabled: false }, { updateOn: 'change' }),
        performanceBODName: new UntypedFormControl(this.performanceBodData.name, {
          updateOn: 'change',
          validators: [Validators.required, Validators.minLength(5), Validators.maxLength(50)],
        }),
        performancePMSignature: new UntypedFormControl(this.performanceBodData.performanceProjectManagerSignature, {
          updateOn: 'change',
        }),
        performanceTLSignature: new UntypedFormControl(this.performanceBodData.performanceTeamLeadSignature, {
          updateOn: 'change',
        }),
        projectNumber: new UntypedFormControl(this.performanceBodData.projectNumber, {
          updateOn: 'change',
          validators: [Validators.max(20000), Validators.min(0), Validators.maxLength(5)],
        }),
        reservoirEngineerSignature: new UntypedFormControl(this.performanceBodData.performanceReservoirEngineerSignature, {
          updateOn: 'change',
        }),
        waterEngineerSignature: new UntypedFormControl(this.performanceBodData.performanceWaterEngineerSignature, {
          updateOn: 'change',
        }),
        statusIndicatorId: new UntypedFormControl(
          { id: this.performanceBodStatus ? this.performanceBodStatus.statusIndicatorId : null, disabled: false },
          { updateOn: 'change', validators: [Validators.required] }
        ),
      });

      const value: Item = {
        id:
          this.performanceBodPackages.length === 0
            ? 1
            : this.performanceBodPackages.length > 5
            ? 5
            : this.performanceBodPackages.length,
        name: null,
        packageDisabled: false,
      };
      this.setNumOfPackgesValidators(value, this.formNewBOD);
      this.nameEvent.emit(this.performanceBodData.name);
    }
  }

  public saveFromBOD(): boolean {
    const value: any = this.formNewBOD.value;
    let oldStatusIndicator;

    if (this.validateBod(value)) {
      this.performanceBodData.basinId = value.basinId.id;
      this.performanceBodData.geographicAreaId = value.geographicAreaId.id;
      this.performanceBodData.developmentAreaId = value.developmentAreaId.id;
      this.performanceBodData.batch = value.batch;
      this.performanceBodData.numberOfPackages = value.packageId.id;
      this.performanceBodData.name = value.performanceBODName;
      this.performanceBodData.projectNumber = value.projectNumber;

      if (value.performancePMSignature != null || undefined) {
        this.performanceBodData.performanceProjectManagerSignature = value.performancePMSignature;
      }
      if (value.performanceTLSignature != null || undefined) {
        this.performanceBodData.performanceTeamLeadSignature = value.performanceTLSignature;
      }
      if (value.reservoirEngineerSignature != null || undefined) {
        this.performanceBodData.performanceReservoirEngineerSignature = value.reservoirEngineerSignature;
      }
      if (value.waterEngineerSignature != null || undefined) {
        this.performanceBodData.performanceWaterEngineerSignature = value.waterEngineerSignature;
      }

      this.performanceBodData.generalComments = value.generalComments;
      this.performanceBodData.updatedDate = new Date();
      this.performanceBodData.updatedBy = this.authenticationService.getCurrentUser().toUpperCase();

      const newPackages: IPerformanceBodPackages[] = [];
      for (let i = 1; i <= this.performanceBodData.numberOfPackages; i++) {
        this.clearData();
        this.performanceBodPackage.performanceBodId = this.performanceBodData.id;
        switch (i) {
          case 1:
            this.performanceBodPackage.name = value.package1Name;
            this.performanceBodPackage.sortOrder = 1;
            break;
          case 2:
            this.performanceBodPackage.name = value.package2Name;
            this.performanceBodPackage.sortOrder = 2;
            break;
          case 3:
            this.performanceBodPackage.name = value.package3Name;
            this.performanceBodPackage.sortOrder = 3;
            break;
          case 4:
            this.performanceBodPackage.name = value.package4Name;
            this.performanceBodPackage.sortOrder = 4;
            break;
          case 5:
            this.performanceBodPackage.name = value.package5Name;
            this.performanceBodPackage.sortOrder = 5;
            break;
          default:
            break;
        }

        if (i <= this.performanceBodPackages.length) {
          this.performanceBodPackages[i - 1].name = this.performanceBodPackage.name;
          this.performanceBodPackages[i - 1].updatedBy = this.authenticationService.getCurrentUser().toUpperCase();
          this.performanceBodPackages[i - 1].updatedDate = new Date();
        } else {
          this.performanceBodPackage.createdBy = this.authenticationService.getCurrentUser().toUpperCase();
          this.performanceBodPackage.createdDate = new Date();
          this.performanceBodPackage.updatedBy = null;
          this.performanceBodPackage.updatedDate = null;
          newPackages.push(this.performanceBodPackage);
        }
      }

      // Validate Unique BOD Names, Project ID's and Package Names
      const pbodTmp: IPerformanceBod = clonedeep(this.performanceBodData);
      pbodTmp.performanceBodPackages = clonedeep(this.performanceBodPackages);
      newPackages.forEach(pk => {
        pk.id = Guid.EMPTY;
        pbodTmp.performanceBodPackages.push(pk);
      });

      //check if every one signed to change bod status
      let singedCount = 0;
      if(this.performanceBodSignatures){
        if(this.performanceBodSignatures.performanceProjectManagerCai)
          singedCount += 1;
        if(this.performanceBodSignatures.performanceWaterEngineerCai)
          singedCount += 1;
        if(this.performanceBodSignatures.performanceTeamLeadCai)
          singedCount += 1;
        if(this.performanceBodSignatures.performanceReservoirEngineerCai)
          singedCount += 1;
      }

      let approvedStatusId = this.statusIndicators.find(x => x.name == 'Approved - Ready for Execution')?.id;

      if (this.performanceBodStatus) {
        oldStatusIndicator = this.performanceBodStatus.statusIndicatorId;
        this.performanceBodStatus.statusIndicatorId = singedCount == 4? approvedStatusId :value.statusIndicatorId.id;
        this.performanceBodStatus.updatedBy = value.statusIndicatorId.id;
        this.performanceBodStatus.updatedDate = new Date();
      } else {
        this.performanceBodStatus = {
          createdBy: this.authenticationService.getCurrentUser().toUpperCase(),
          createdDate: new Date(),
          id: null,
          performanceBod: null,
          performanceBodId: this.performanceBodData.id,
          statusIndicator: null,
          statusIndicatorId: value.statusIndicatorId.id,
          updatedBy: this.authenticationService.getCurrentUser().toUpperCase(),
          updatedDate: new Date(),
        };
      }
      this.performanceBodDetailService.validPerformanceBOD(pbodTmp).subscribe(
        (data: any) => {
          this.onValidComplete(data, newPackages);
        },
        (err: any) => {
          this.onValidError(err);
        }
      );

      this.saveBodCompleted.next(true);
      if(oldStatusIndicator != this.performanceBodStatus.statusIndicatorId && 
        this.performanceBodStatus.statusIndicatorId == 
        this.statusIndicators.find(x => x.name == 'Approved - Ready for Execution').id){
          let usersToSend: string[] = [];
          if(this.performanceBodData.performanceProjectManagerCai){
            usersToSend.push(this.performanceBodData.performanceProjectManagerCai);
          } 
          if(this.performanceBodData.performanceTeamLeadCai){
            usersToSend.push(this.performanceBodData.performanceTeamLeadCai);
          } 
          if(this.performanceBodData.performanceWaterEngineerCai){
            usersToSend.push(this.performanceBodData.performanceWaterEngineerCai);
          } 
          if(this.performanceBodData.performanceReservoirEngineerCai){
            usersToSend.push(this.performanceBodData.performanceReservoirEngineerCai);
          } 
           this.authenticationService.getUsersEmails(usersToSend).subscribe(data => {
            if(data !== null && data !== undefined){
              let mail:IEmailModel = {
                message: this.emailService.buildEmailMessageBod(this.performanceBodData.name),
                recipients: data,
                subject: "Performance BOD Project " + this.performanceBodData.name +" approved for execution"
              }
              this.emailService.sendEmail(mail).subscribe();
            }
           });
        }
      return true;
    } else {
      return false;
    }
  }

  public showSaveNotificationMessage() {
    this.notificationService.show({
      animation: { type: 'fade', duration: 400 },
      content: 'Performance BOD sucessfully saved',
      position: { horizontal: 'center', vertical: 'top' },
      type: { style: 'success', icon: true },
      hideAfter: 1400,
    });
  }
  public clearData() {
    this.performanceBodPackage = {
      id: null,
      name: '',
      performanceBodId: null,
      isDeleted: false,
      createdBy: null,
      createdDate: null,
      sortOrder: 0,
      updatedBy: null,
      updatedDate: null,
      wellPads: 0,
      facilityPads: 0,
      flowLines: 0,
      glLines: 0,
      gathLines: 0,
      injLines: 0,
      ponds: 0,
      eds: 0,
      infrastructureComments: null
    };
  }

  public showWarning(value: string) {
    this.notificationService.show({
      content: value,
      position: { horizontal: 'center', vertical: 'top' },
      animation: { type: 'fade', duration: 400 },
      type: { style: 'warning', icon: true },
      hideAfter: 1400,
    });
  }

  public showInfo(value: string) {
    this.notificationService.show({
      content: value,
      position: { horizontal: 'center', vertical: 'top' },
      animation: { type: 'fade', duration: 400 },
      type: { style: 'info', icon: true },
      hideAfter: 1400,
    });
  }

  public getPackageByIndex(index: number): IPerformanceBodPackages {
    const defaultValue: IPerformanceBodPackages = {
      createdBy: null,
      createdDate: null,
      id: null,
      isDeleted: false,
      name: null,
      performanceBodId: null,
      sortOrder: 0,
      updatedBy: null,
      updatedDate: null,
      wellPads: 0,
      facilityPads: 0,
      flowLines: 0,
      glLines: 0,
      gathLines: 0,
      injLines: 0,
      ponds: 0,
      eds: 0,
      infrastructureComments: null
    };

    if (this.performanceBodPackages !== undefined || this.performanceBodPackages != null) {
      if (this.performanceBodPackages.length > 0) {
        return this.performanceBodPackages.length - 1 >= index ? this.performanceBodPackages[index] : defaultValue;
      }
    }

    return defaultValue;
  }

  public createNewFormGroup(data: any) {
    if (data === null) {
      // Create new facility
      this.formNewBOD = new UntypedFormGroup({
        basinId: new UntypedFormControl({ id: null, disabled: false }, Validators.required),
        batch: new UntypedFormControl('', [Validators.minLength(1), Validators.maxLength(50)]),
        developmentAreaId: new UntypedFormControl({ id: null }, Validators.required),
        generalComments: new UntypedFormControl(''),
        geographicAreaId: new UntypedFormControl({ id: null }, Validators.required),
        package1Name: new UntypedFormControl(''),
        package2Name: new UntypedFormControl(''),
        package3Name: new UntypedFormControl(''),
        package4Name: new UntypedFormControl(''),
        package5Name: new UntypedFormControl(''),
        packageId: new UntypedFormControl({ id: null }),
        performanceBODName: new UntypedFormControl('', Validators.required),
        performancePMSignature: new UntypedFormControl(''),
        performanceTLSignature: new UntypedFormControl(''),
        projectNumber: new UntypedFormControl(''),
        reservoirEngineerSignature: new UntypedFormControl(''),
        waterEngineerSignature: new UntypedFormControl(''),
        statusIndicatorId: new UntypedFormControl({ id: null, disabled: false }, Validators.required),
      });
    }
  }

  public getNumOfPackagesSelectedId(): number {
    return this.performanceBodPackages
      ? this.performanceBodPackages.length === 0
        ? 1
        : this.performanceBodPackages.length > 5
        ? 5
        : this.performanceBodPackages.length
      : 1;
  }

  public itemDisabled(itemArgs: { dataItem: any; index: number }) {
    return !itemArgs.dataItem.packageDisabled;
  }

  public handlePackagesChange(value: Item, form: UntypedFormGroup) {
    this.setNumOfPackgesValidators(value, form);
    this.selectedItem = value;
  }

  public setNumOfPackgesValidators(value: Item, form: UntypedFormGroup) {
    for (let i = 1; i <= this.numPackages.length; i++) {
      if (value.id >= i) {
        form.controls['package' + i + 'Name'].setValidators(Validators.required);
      } else {
        form.controls['package' + i + 'Name'].clearValidators();
        form.controls['package' + i + 'Name'].setValue(null);
      }
    }
  }

  public getFormatDate(cai: string, date: Date): string {
    let dateformat = '-';

    if (date) {
      const user = this.users.find(c => c.cai.toLowerCase() === (cai ? cai.toLowerCase() : null));
      const showUser = user === undefined || user == null ? cai : user.fullName;

      dateformat = showUser + ' - Signed on ' + formatDate(date, 'MM/dd/yyyy', 'en-US');
    }

    return dateformat;
  }

  public getInfoCreation(cai: string, date: any): string {
    const user = this.users.find(c => c.cai.toLowerCase() === cai.toLowerCase());
    const showUser = user === undefined || user == null ? cai : user.fullName;
    const returnValue = showUser + ' on ' + this.intl.formatDate(date, 'MM/dd/yyyy hh:mm:ss a');
    return returnValue;
  }

  public validateBod(entity: any): boolean {
    let isValid = true;
    // packages names
    if (entity === null || entity === undefined) {
      this.showWarning('The information of the new Performance BOD is required.');
      isValid = false;
    } else {
      if (
        entity.developmentAreaId === null ||
        entity.developmentAreaId === undefined ||
        entity.developmentAreaId.id === null ||
        entity.developmentAreaId.id === Guid.EMPTY ||
        entity.developmentAreaId.id === undefined
      ) {
        this.showWarning('The Development Area is required.');
        isValid = false;
      }
      if (entity.performanceBODName === null || entity.performanceBODName === '') {
        this.showWarning('The Perfomance BOD name is required.');
        isValid = false;
      } else {
        if (entity.performanceBODName.length < 5) {
          this.showWarning('The Perfomance BOD name requires minimum 5 characters.');
          isValid = false;
        }

        if (entity.performanceBODName.length > 50) {
          this.showWarning('The Perfomance BOD name requires requires maximum 50 characters.');
          isValid = false;
        }
      }

      if (
        entity.geographicAreaId === null ||
        entity.geographicAreaId === undefined ||
        entity.geographicAreaId.id === null ||
        entity.geographicAreaId.id === undefined
      ) {
        this.showWarning('The Geographic Area is required.');
        isValid = false;
      }

      if (
        entity.basinId === null ||
        entity.basinId === undefined ||
        entity.basinId.id === null ||
        entity.basinId.id === undefined
      ) {
        this.showWarning('The Basin is required.');
        isValid = false;
      }

      if (entity.batch === null || entity.batch === '') {
        this.showWarning('The Batch is required.');
        isValid = false;
      } else {
        if (entity.batch.length < 1) {
          this.showWarning('The Batch requires minimum 1 character.');
          isValid = false;
        }

        if (entity.batch.length > 50) {
          this.showWarning('The Batch requires maximum 50 characters.');
          isValid = false;
        }
      }

      if (entity.packageId === null) {
        this.showWarning('The Number of Packages is required.');
        isValid = false;
      }

      if (entity.packageId.id != null || entity.packageId.id !== undefined) {
        for (let j = 1; j <= entity.packageId.id; j++) {
          switch (j) {
            case 1:
              if (entity.package1Name === '' || !entity.package1Name) {
                this.showWarning('The Package Name 1 is required.');
                this.formNewBOD.controls['package1Name'].markAsTouched();
                isValid = false;
              }
              break;
            case 2:
              if (entity.package2Name === '' || !entity.package2Name) {
                this.showWarning('The Package Name 2 is required.');
                this.formNewBOD.controls['package2Name'].markAsTouched();
                isValid = false;
              } else if (isValid && entity.package1Name === entity.package2Name) {
                this.showWarning('Package Names must be unique.');
                this.formNewBOD.controls['package2Name'].markAsTouched();
                isValid = false;
              }
              break;
            case 3:
              if (entity.package3Name === '' || !entity.package3Name) {
                this.showWarning('The Package Name 3 is required.');
                this.formNewBOD.controls['package3Name'].markAsTouched();
                isValid = false;
              } else if (
                isValid &&
                (entity.package3Name === entity.package1Name || entity.package3Name === entity.package2Name)
              ) {
                this.showWarning('Package Names must be unique.');
                this.formNewBOD.controls['package3Name'].markAsTouched();
                isValid = false;
              }
              break;
            case 4:
              if (entity.package4Name === '' || !entity.package4Name) {
                this.showWarning('The Package Name 4 is required.');
                this.formNewBOD.controls['package4Name'].markAsTouched();
                isValid = false;
              } else if (
                isValid &&
                (entity.package4Name === entity.package1Name ||
                  entity.package4Name === entity.package2Name ||
                  entity.package4Name === entity.package3Name)
              ) {
                this.showWarning('Package Names must be unique.');
                this.formNewBOD.controls['package4Name'].markAsTouched();
                isValid = false;
              }
              break;
            case 5:
              if (entity.package5Name === '' || !entity.package5Name) {
                this.showWarning('The Package Name 5 is required.');
                this.formNewBOD.controls['package5Name'].markAsTouched();
                isValid = false;
              } else if (
                isValid &&
                (entity.package5Name === entity.package1Name ||
                  entity.package5Name === entity.package2Name ||
                  entity.package5Name === entity.package3Name ||
                  entity.package5Name === entity.package4Name)
              ) {
                this.showWarning('Package Names must be unique.');
                this.formNewBOD.controls['package5Name'].markAsTouched();
                isValid = false;
              }
              break;
            default:
              isValid = false;
              break;
          }
        }
      }

      if (!this.formNewBOD.valid && isValid) {
        this.showWarning('Please check the required fields.');
        isValid = false;
      }
    }

    if (!isValid) {
      this.spinner.hide();
    }
    return isValid;
  }

  public setLastUpdate(cai: string, updatedDate: Date): void {
    if (this.performanceBodData) {
      this.performanceBodData.updatedBy = cai;
      this.performanceBodData.updatedDate = updatedDate;
      this.performanceBodDetailService.refreshPerformanceBod(this.performanceBodData.id);
      this.performanceBodStoreService.performanceBodSignaturesData$.subscribe(signatures => {
        if (signatures) {
          if (signatures.length > 0) {
            this.performanceBodSignatures = signatures[0];
          }
        }
      });
      this.formNewBOD.controls['statusIndicatorId'].setValue({ id: this.performanceBodStatus.statusIndicatorId });
    }
  }

  public onValidComplete(value: any, newPackages: IPerformanceBodPackages[]) {
    if (value !== null && value !== undefined && value !== '') {
      this.showWarning(value);
    } else {
      // this.spinner.show();
      this.performanceBodDetailService.save(this.performanceBodData, newPackages, this.performanceBodPackages, [
        this.performanceBodStatus,
      ]);
      this.performanceBodDetailService.refreshed.subscribe(
        (done: boolean) => {
          if (done) {
            this.performanceBodDetailService
              .getPerformanceBodPackage()
              .subscribe((packages: IPerformanceBodPackages[]) => {
                this.performanceBodPackages = packages.sort((a, b) => {
                  return a.sortOrder - b.sortOrder;
                });
                this.selectedItem = this.numPackages.find(p => p.id === this.getNumOfPackagesSelectedId());

                this.numPackages.map((item: Item) => {
                  item.packageDisabled = this.performanceBodPackages.length <= item.id;
                });
                // this.spinner.hide();
              });
            // this.showSaveNotificationMessage();
          }

          this.performanceBodDetailService.getPerformanceBodStatus().subscribe((status: IPerformanceBodStatus[]) => {
            this.performanceBodStatus = status.length > 0 ? status[0] : null;
          });
        },
        (error: any) => {
          // this.spinner.hide();
        }
      );
    }
  }

  public basinSelectionChange(value: any): void {
    this.geographicAreaOptionsFiltered = [];
    this.developmentAreaOptionsFiltered = [];
    this.geographicAreaOptionsFiltered = this.geographicAreaOptions.filter(g => g.basinId === value.id);
    this.formNewBOD.controls['geographicAreaId'].reset();
    this.formNewBOD.controls['developmentAreaId'].reset();
    this.isDisabledGeographic = false;
  }

  public geographicAreaSelectionChange(value: any): void {
    this.developmentAreaOptionsFiltered = [];
    this.developmentAreaOptionsFiltered = this.developmentAreaOptions.filter(d => d.geographicAreaId === value.id);
    this.formNewBOD.controls['developmentAreaId'].reset();
    this.isDisabledDevelopment = false;
  }

  public onNameChange(): void {
    this.nameEvent.emit(this.performanceBODName);
  }

  private onValidError(value: any) {
    if (value !== null && value !== undefined && value !== '') {
      this.showWarning(value);
    }
  }
}
