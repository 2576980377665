<kendo-dialog title="Warning" *ngIf="openDialog" [minWidth]="250" [width]="450" (close)="closeKendoPopUp()">
  <p>{{ warningMessage }}</p>
</kendo-dialog>

<kendo-grid #infraWellArtificialLift
            [data]="view | async"
            (cellClick)="cellClickHandler($event)"
            (cellClose)="cellCloseHandler($event)"
            (cancel)="cancelHandler($event)"
            (save)="saveHandler($event)"
            (remove)="removeHandler($event)"
            (add)="addHandler($event)"
            [navigable]="true"
            [resizable]="true"
            [sort]="gridState.sort"
            [sortable]="{ mode: 'multiple' }"
            (dataStateChange)="onStateChange($event)"
            filterable="menu"
            [filter]="filter"
            (filterChange)="handlerFilter($event)"
            class="infrastructure-artificial-lift-draggable"
            [rowClass]="rowCallback">
  <ng-template kendoGridToolbarTemplate>
    <button kendoButton
            class="k-button"
            [disabled]="!infrastructureWellArtificialLiftService.hasChanges()"
            (click)="cancelChanges(infraWellPad)"
            [primary]="true">
      Cancel
    </button>
    <button [icon]="'plus'" kendoGridAddCommand [primary]="true" [disabled]="!canEditPerformanceBod"></button>
  </ng-template>
  <kendo-grid-column title="" field="" [hidden]="true" [width]="1">
    {{ rowLoaded() }}
  </kendo-grid-column>
  <kendo-grid-column title="Well Pad" field="performanceBodPadsId" [editable]="true">
    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
      <multicheck-filter [isPrimitive]="false"
                         [field]="column.field"
                         textField="wellPadName"
                         valueField="id"
                         [filterService]="filterService"
                         [currentFilter]="filter"
                         [data]="performanceBodPadsList">
      </multicheck-filter>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ getPerformanceBodPads(dataItem.performanceBodPadsId)?.wellPadName }}
    </ng-template>
    <ng-template kendoGridEditTemplate
                 let-dataItem="dataItem"
                 let-column="column"
                 let-formGroup="formGroup"
                 let-isNew="isNew">
      <kendo-dropdownlist #ddlPerformancePads
                          elementFocus
                          [data]="performanceBodPadsList"
                          textField="wellPadName"
                          valueField="id"
                          [valuePrimitive]="true"
                          [formControl]="formGroup.get('performanceBodPadsId')"
                          (valueChange)="changePackage($event, dataItem, formGroup)">
      </kendo-dropdownlist>
      <span #labelPopup></span>
      <kendo-popup [anchor]="labelPopup"
                   [hidden]="messageDuplicate"
                   popupClass="k-widget k-tooltip k-tooltip-validation">
        The Pad name is already selected.
      </kendo-popup>
    </ng-template>
    <ng-template kendoDropDownListValueTemplate let-dataItem>
      {{ getPerformanceBodPads(dataItem?.id)?.wellPadName }}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="performanceBodPackagesId" title="Package Name" [editable]="false">
    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
      <multicheck-filter [isPrimitive]="false"
                         [field]="column.field"
                         textField="name"
                         valueField="id"
                         [filterService]="filterService"
                         [currentFilter]="filter"
                         [data]="performanceBodPackagesList">
      </multicheck-filter>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{
        getPerformanceBodPackages(getPerformanceBodPads(dataItem.performanceBodPadsId)?.performanceBodPackagesId)?.name
      }}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="chargeCode" title="Dummy Charge code" [editable]="true" width="150"> </kendo-grid-column>
  <kendo-grid-column field="cndHOEstimate" title="C&D/HO Estimate" [editable]="true" editor="numeric" filter="numeric">
  </kendo-grid-column>
  <kendo-grid-column field="afeNumber"
                     title="AFE No."
                     [editable]="true"
                     width="150">

  </kendo-grid-column>

  <kendo-grid-column field="afeAmount"  
                     title="Approved AFE Amount"
                     [editable]="true" 
                     editor="numeric" 
                     filter="numeric"
                     width="150">
</kendo-grid-column>

  <kendo-grid-column field="wellheadRating" title="Well Head Rating" [editable]="true"> </kendo-grid-column>
  <kendo-grid-column field="artificialLiftType" title="Artificial Lift Type" [editable]="true"> </kendo-grid-column>
  <kendo-grid-column field="glInjectVolume"
                     title="GL Injection Volume"
                     [editable]="true"
                     editor="numeric"
                     filter="numeric">
  </kendo-grid-column>
  <kendo-grid-column field="comments" title="Comments" [editable]="true" [hidden]="false" [width]="150">
  </kendo-grid-column>
  <kendo-grid-command-column title="" [width]="150">
    <ng-template kendoGridCellTemplate let-isNew="isNew">
      <button kendoGridRemoveCommand [primary]="true" [icon]="'minus'" [disabled]="!canEditPerformanceBod"></button>
      <button kendoGridSaveCommand [primary]="true">Add</button>
      <button kendoGridCancelCommand [primary]="true">Cancel</button>
    </ng-template>
  </kendo-grid-command-column>
</kendo-grid>
