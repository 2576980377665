import { Component, EventEmitter, OnInit, Output, ViewChild, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { GridDataResult, RowClassArgs } from '@progress/kendo-angular-grid';
import { NotificationService } from '@progress/kendo-angular-notification';
import { State, process, filterBy, CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { map } from 'rxjs';
import { IPerformanceBodPackages } from 'src/app/api/performance-bod-package';
import { IPerformanceBodWaterDisposal } from 'src/app/api/performance-bod-water-disposal';
import { PerformanceBodStoreService } from 'src/app/services/performance-bod-store.service';
import { PerformanceBodWaterDisposalService } from './infrastructure-water-disposal.service';
import { UtilService } from 'src/app/services/util.service';
import { PermissionsProvider } from 'src/app/services/permissions.provider';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DragAndDropService } from 'src/app/_shared/drag-and-drop.service';

const DRAGGABLE_SELECTOR = '.infrastructure-water-disposal-draggable';

@Component({
  selector: 'app-infrastructure-water-disposal',

  templateUrl: './infrastructure-water-disposal.component.html',
})
export class InfrastructureWaterDisposalComponent implements OnInit {
  public filter: CompositeFilterDescriptor;
  public filterable: boolean = true;
  public view: Observable<GridDataResult>;
  public performanceBodPackagesList: IPerformanceBodPackages[];
  public performanceBodId: string;
  public formGroup: UntypedFormGroup;
  public gridState: State = {
    sort: [],
    skip: 0,
  };
  public canEditPerformanceBod: boolean = false;
  public performanceBodWaterDisposalService: PerformanceBodWaterDisposalService;
  @Output() public performanceBodUpdate = new EventEmitter<string>();
  @Output() public saveBodCompleted = new EventEmitter<boolean>();
  @ViewChild('waterDisposal')
  private infrastructureWaterDisposalGrid: any;
  private dragAndDropService: DragAndDropService;

  constructor(
    private performanceBodStoreService: PerformanceBodStoreService,    
    private formBuilder: UntypedFormBuilder,
    private spinner: NgxSpinnerService,
    private notificationService: NotificationService,
    private utilService: UtilService,
    private permissionsProvider: PermissionsProvider,
    private authenticationService: AuthenticationService,
    @Inject(DragAndDropService) dragAndDropServiceFactory: any,
    @Inject(PerformanceBodWaterDisposalService) performanceBodWaterDisposalServiceFactory: any
  ) {
    this.dragAndDropService = dragAndDropServiceFactory();
    this.performanceBodWaterDisposalService = performanceBodWaterDisposalServiceFactory();
  }

  public ngOnInit(): void {
    this.canEditPerformanceBod = this.permissionsProvider.canEditPerformanceBod;

    this.performanceBodStoreService.performanceBodId$.subscribe(id => {
      this.performanceBodId = id;
      this.performanceBodWaterDisposalService.performanceBodId = id;
      this.performanceBodWaterDisposalService.read();
    });
    this.performanceBodStoreService.performanceBodPackagesData$.subscribe(data => {
      this.performanceBodPackagesList = data;
    });

    this.view = this.performanceBodWaterDisposalService.pipe(
      map(data => {
        this.dragAndDropService.dataSourceRefreshed(data);
        return process(filterBy(data, this.filter), this.gridState);
      })
    );

    this.dragAndDropService.initialize(this.performanceBodWaterDisposalService.data, DRAGGABLE_SELECTOR, () => {
      this.performanceBodWaterDisposalService.markAsSetOrder(this.authenticationService.getCurrentUser());
    });
  }

  public ngAfterViewInit(): void {
    // To Do
  }

  public ngOnDestroy(): void {
    this.dragAndDropService.refreshDragAndDrop();
  }

  public createFormGroup(dataItem: any): UntypedFormGroup {
    return this.formBuilder.group({
      id: new UntypedFormControl(dataItem.id),
      isDeleted: new UntypedFormControl(dataItem.isDeleted, [Validators.required]),
      // performanceBodId: new FormControl(dataItem.performanceBodId),
      performanceBodPackagesId: new UntypedFormControl(dataItem.performanceBodPackagesId, [Validators.required]),
      description: new UntypedFormControl(dataItem.description, [Validators.minLength(0), Validators.maxLength(250)]),
      readyForServiceDate: new UntypedFormControl(dataItem.readyForServiceDate, [Validators.required]),
      designDisposalRatesBwpd: new UntypedFormControl(dataItem.designDisposalRatesBwpd, [
        Validators.min(0),
        Validators.max(1000000),
      ]),
      maxPermitPressurePsig: new UntypedFormControl(dataItem.maxPermitPressurePsig, [
        Validators.min(0),
        Validators.max(1000000),
      ]),
      anticipatedOperatingPressurePsig: new UntypedFormControl(dataItem.anticipatedOperatingPressurePsig, [
        Validators.min(0),
        Validators.max(1000000),
      ]),
      disposalPumpingCapacityBwpd: new UntypedFormControl(dataItem.disposalPumpingCapacityBwpd, [
        Validators.min(0),
        Validators.max(1000000),
      ]),
      comments: new UntypedFormControl(dataItem.comments, [Validators.minLength(0), Validators.maxLength(500)]),
      createdBy: [dataItem.createdBy],
      createdDate: [dataItem.createdDate],
      updatedBy: [dataItem.updatedBy],
      updatedDate: [dataItem.updatedDate],
    });
  }

  public cellClickHandler({ sender, rowIndex, columnIndex, dataItem, isEdited }) {
    if (!isEdited && this.canEditPerformanceBod) {
      if (dataItem.readyForServiceDate !== undefined && columnIndex === 3) {
        if (dataItem.readyForServiceDate !== null) {
          const y = new Date(dataItem.readyForServiceDate.toString()).getFullYear();
          const m = new Date(dataItem.readyForServiceDate.toString()).getMonth();
          const d = new Date(dataItem.readyForServiceDate.toString()).getDate();
          dataItem.readyForServiceDate = new Date(y, m, d);
        }
      }
      sender.editCell(rowIndex, columnIndex, this.createFormGroup(dataItem));
      this.dragAndDropService.onEditingMode();
    }
  }

  public cellCloseHandler(args: any) {
    const { formGroup, dataItem, column } = args;

    let columnField = '';

    if (column.field === 'performanceBodPackagesName') {
      columnField = 'performanceBodPackagesId';
    } else {
      columnField = column.field;
    }

    const checkColumn = formGroup.controls[columnField];

    if (!checkColumn.valid) {
      args.preventDefault();
    } else if (checkColumn.dirty) {
      if (columnField === 'performanceBodPackagesId') {
        dataItem.performanceBodPackagesName = this.getPerformanceBodPackages(formGroup.controls[columnField].value);
      }
    }

    if (!formGroup.valid) {
      // prevent closing the edited cell if there are invalid values.
      args.preventDefault();
    } else if (formGroup.dirty) {
      dataItem.updatedDate = new Date();
      dataItem.updatedBy = this.authenticationService.getCurrentUser();

      // Date is not working in Object.assign(), so need to be do it manually
      formGroup.value.updatedBy = dataItem.updatedBy;
      formGroup.value.updatedDate = dataItem.updatedDate;

      this.performanceBodWaterDisposalService.assignValues(dataItem, formGroup.value);
      this.performanceBodWaterDisposalService.updateItem(dataItem);
    }
    // calls this to add the attribute of current row
    this.dragAndDropService.onEditingClose();
    this.dragAndDropService.refreshDragAndDrop();
  }

  public addHandler({ sender }) {
    const item: IPerformanceBodWaterDisposal = {
      id: Guid.create().toString(),
      description: '',
      performanceBodPackagesId: '',
      performanceBodId: this.performanceBodId,
      readyForServiceDate: null,
      designDisposalRatesBwpd: 0,
      maxPermitPressurePsig: 0,
      anticipatedOperatingPressurePsig: 0,
      disposalPumpingCapacityBwpd: 0,
      comments: '',
      isDeleted: false,
      createdBy: this.authenticationService.getCurrentUser(),
      createdDate: new Date(),
      updatedBy: null,
      updatedDate: null,
    };

    item.createdBy = this.authenticationService.getCurrentUser();
    item.createdDate = new Date();
    sender.addRow(this.createFormGroup(item));
    this.dragAndDropService.onEditingMode();
  }

  public cancelHandler({ sender, rowIndex }) {
    sender.closeRow(rowIndex);
    this.dragAndDropService.onEditingClose();
    this.dragAndDropService.refreshDragAndDrop();
  }

  public saveHandler({ sender, formGroup, rowIndex }) {
    if (formGroup.valid) {
      formGroup.patchValue({
        performanceBodId: this.performanceBodId,
      });
      this.performanceBodWaterDisposalService.createItem(formGroup.value);
      sender.closeRow(rowIndex);
      this.dragAndDropService.onEditingClose();
      this.dragAndDropService.dataSourceRefreshed(this.performanceBodWaterDisposalService.data);
      this.dragAndDropService.refreshDragAndDrop();
      this.performanceBodWaterDisposalService.markAsSetOrder(this.permissionsProvider.cai);
    } else {
      this.utilService.markFormGroupAsTouched(formGroup);
    }
  }

  public removeHandler({ sender, dataItem }) {
    dataItem.isDeleted = true;
    this.performanceBodWaterDisposalService.removeItem(dataItem);

    sender.cancelCell();
  }

  public saveChanges(grid: any): void {
    grid.closeCell();
    grid.cancelCell();
    this.spinner.show();
    // calls this to add the attribute of current row
    this.dragAndDropService.refreshDragAndDrop();
    this.performanceBodWaterDisposalService.saveChanges();
    this.performanceBodUpdate.next(this.authenticationService.getCurrentUser());

    setTimeout(() => {
      this.spinner.hide();
      this.notificationService.show({
        animation: { type: 'fade', duration: 500 },
        content: 'Infrastructure - Water Disposal Information saved',
        position: { horizontal: 'center', vertical: 'top' },
        type: { style: 'success', icon: true },
        hideAfter: 700,
      });
    }, 3000);
  }

  public saveFromBOD(): void {
    this.infrastructureWaterDisposalGrid.closeCell();
    this.infrastructureWaterDisposalGrid.cancelCell();

    if (this.performanceBodWaterDisposalService.hasChanges()) {
      this.spinner.show();
      // calls this to add the attribute of current row
      this.dragAndDropService.refreshDragAndDrop();
      this.performanceBodWaterDisposalService.saveChanges();
      this.performanceBodUpdate.next(this.authenticationService.getCurrentUser());
      const subscription = this.performanceBodWaterDisposalService.subscribe(() => {
        this.saveBodCompleted.next(true);
      });

      subscription.unsubscribe();      
    }
  }

  public cancelChanges(grid: any): void {
    grid.cancelCell();
    this.dragAndDropService.refreshDragAndDrop();
    this.performanceBodWaterDisposalService.cancelChanges();
  }

  public onStateChange(state: State) {
    this.gridState = state;
    this.performanceBodWaterDisposalService.read();

  }

  public getPerformanceBodPackages(id: string): any {
    if (this.performanceBodPackagesList === null || this.performanceBodPackagesList === undefined) {
      return '';
    }
    this.performanceBodPackagesList = this.performanceBodPackagesList.filter(x => x.id !== null && x.id !== undefined);
    return this.performanceBodPackagesList.find(x => x.id === id);
  }

  public handlerFilter(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    if (filter.filters) {
      if (filter.filters.length > 0) {
        this.dragAndDropService.onEditingMode();
      } else {
        this.dragAndDropService.onEditingClose();
        this.dragAndDropService.refreshDragAndDrop();
      }
    }
  }

  public rowCallback(context: RowClassArgs) {
    return {
      dragging: context.dataItem.dragging,
    };
  }

  public rowLoaded(): void {
    // to check until grid have been loeaded.
    this.dragAndDropService.checkIsOnEditMode();
    this.dragAndDropService.checkDraggableElementLoaded();
  }
}
