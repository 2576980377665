import { Component, Input, OnInit } from '@angular/core';
import { pivotTableData } from '../../../api/mock/mockData';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { GroupDescriptor, SortDescriptor, orderBy, groupBy } from '@progress/kendo-data-query';
import { ScenarioStoreService } from '../../../services/scenario-store.service';
import { UntypedFormControl } from '@angular/forms';
import { ScenarioPbSummary } from '../../../api/padbuilder-master';
import { IPricetScenarioSummary } from '../../../api/pricetScenario';
import { PermissionsProvider } from '../../../services/permissions.provider';

@Component({
  selector: 'app-summary',
  templateUrl: 'summary.component.html',
  styleUrls: ['summary.component.css'],
})
export class SummaryComponent implements OnInit {
  public newScenario: boolean;
  public multiple = false;
  public allowUnsort = true;
  public groups: GroupDescriptor[];
  public sort: SortDescriptor[] = [
    {
      field: 'InfrastructureType',
      dir: 'asc',
    },
  ];
  public canEditCnd: boolean = false;

  public pivotTableGridView: GridDataResult;
  public padRigGridView: GridDataResult;
  public pivotTableData: any[] = pivotTableData;
  public padbuilderRigsData: any[];
  public sequenceName: UntypedFormControl = new UntypedFormControl({ value: null, disabled: true });
  public fileName: UntypedFormControl = new UntypedFormControl({ value: null, disabled: true });
  public modificationDate: UntypedFormControl = new UntypedFormControl({
    value: null,
    disabled: true,
  });
  public scenarioName: UntypedFormControl = new UntypedFormControl({ value: null, disabled: true });
  public forecastNotes: UntypedFormControl = new UntypedFormControl({ value: null, disabled: true });
  public rigCount: UntypedFormControl = new UntypedFormControl({ value: null, disabled: true });
  public developmentArea: UntypedFormControl = new UntypedFormControl({
    value: null,
    disabled: true,
  });
  public scenarioType: UntypedFormControl = new UntypedFormControl({ value: null, disabled: true });
  public scenarioNameS: UntypedFormControl = new UntypedFormControl({ value: null, disabled: true });
  public handover: UntypedFormControl = new UntypedFormControl({ value: null, disabled: true });
  public contingency: UntypedFormControl = new UntypedFormControl({ value: '', disabled: true });
  public state: UntypedFormControl = new UntypedFormControl({ value: null, disabled: true });
  public tax: UntypedFormControl = new UntypedFormControl({ value: null, disabled: true });
  public basin: UntypedFormControl = new UntypedFormControl({ value: null, disabled: true });
  public geographicArea: UntypedFormControl = new UntypedFormControl({
    value: null,
    disabled: true,
  });
  public pricetNotes: UntypedFormControl = new UntypedFormControl({ value: null, disabled: false });

  @Input() public alternatePadCosts;
  @Input() public originalInputInfraData;

  constructor(private scenarioStoreService: ScenarioStoreService, private permissionsProvider: PermissionsProvider) {}

  public ngOnInit(): void {
    this.canEditCnd = this.permissionsProvider.canEditCnd;
    this.scenarioStoreService.pricetSummary$.subscribe((value: IPricetScenarioSummary) => {
      this.developmentArea.setValue(value.developmentArea, {
        emitEvent: false,
      });
      this.scenarioNameS.setValue(value.scenarioName, { emitEvent: false });
      this.scenarioType.setValue(value.scenarioType, { emitEvent: false });
      this.handover.setValue(value.handover, { emitEvent: false });
      this.contingency.setValue(value.contingency, { emitEvent: false });
      this.state.setValue(value.state, { emitEvent: false });
      this.tax.setValue(value.tax, { emitEvent: false });
      this.basin.setValue(value.basin, { emitEvent: false });
      this.geographicArea.setValue(value.geographicArea, {
        emitEvent: false,
      });
      this.pricetNotes.setValue(value.scenarioNotes, { emitEvent: false });
    });

    this.scenarioStoreService.newScenario$.subscribe(value => {
      if (value === true) {
        this.newScenario = value;
        this.sequenceName.setValue('');
        this.fileName.setValue('');
        this.modificationDate.setValue('');
        this.forecastNotes.setValue('');
        this.rigCount.setValue(0);
      }
    });

    this.scenarioStoreService.ScenarioPbSummary$.subscribe((data: ScenarioPbSummary) => {
      const lastPBsaveDate = data.lastPbSaveDate ? new Date(data.lastPbSaveDate) : null;
      this.sequenceName.setValue(data.sequenceName, { emitEvent: false });
      this.fileName.setValue(data.fileName, { emitEvent: false });
      this.modificationDate.setValue(lastPBsaveDate, { emitEvent: false });
      // this.scenarioName.setValue(data.name, { emitEvent: false });
      this.forecastNotes.setValue(data.forecastNotes, { emitEvent: false });
      this.rigCount.setValue(data.rigCount, { emitEvent: false });
      this.padbuilderRigsData = data.scenarioRigs;
      this.loadProducts();
    });
    this.pricetNotes.valueChanges.subscribe(() =>
      this.scenarioStoreService.pricetNotesSummaryUpdated(this.pricetNotes.value)
    );
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.loadProducts();
  }

  public groupChange(groups: GroupDescriptor[]): void {
    this.groups = groups;
    this.pivotTableData = groupBy(pivotTableData, groups);
    this.loadProducts();
  }

  private loadProducts(): void {
    this.pivotTableGridView = {
      data: this.newScenario === false ? orderBy(this.pivotTableData, this.sort) : [],
      total: this.newScenario === false ? this.pivotTableData.length : 0,
    };
  }
}
