import { Injectable, EventEmitter, Inject } from '@angular/core';
import { Observable,throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse, HttpUrlEncodingCodec } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs';
import { map } from 'rxjs';
import { IFacilityBod} from '../../_shared/_models/facility.bod.model';
import { IGeographicArea } from '../../_shared/_models/geographicarea.interface';
import { IDevArea } from '../../_shared/_models/devarea.interface';
import { IBasin } from '../../_shared/_models/basin.interface';
import { environment } from '../../../environments/environment';
import { IUnitRateArea } from '../../_shared/_models/unit.rate.area';
import { IFacilityType } from '../../modules/admin/models/facility.type.model';
import { IFacilityStdDesigns } from '../../_shared/_models/facility.std.designs.model';
import { IFacilityItemType } from '../..//modules/admin/models/facility.item.type.model';
import { IFacilityProjectCostEstimates } from '../../_shared/_models/facility.project.cost.estimates';
import { IFacilityPrefBod } from '../_models/facility.preformance.bod.model';

const CREATE_ACTION = 'Create';
const UPDATE_ACTION = 'Update';
const REMOVE_ACTION = 'DeleteByKey';
const LIST_ACTION = 'List';
const BASE_URL = environment.PacerDomainURL;
const BASE_GEOGRAPHIC_DOMAIN = environment.PricetDomainURL;

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class MyFacilitiesService extends BehaviorSubject<any[]> {
  public loader = new EventEmitter<any>();
  public facility: IFacilityBod;
  public showSavePopUpValue$: BehaviorSubject<boolean>;
  public showSavePopUpMessageValue$: BehaviorSubject<string>;
  public alreadySaved$: Observable<boolean>;
  public showSavePopUp$: Observable<boolean>;
  public showSavePopUpMessage$: Observable<string>;
  private alreadySavedValue$: BehaviorSubject<boolean>;
  private data: any[] = [];

  constructor(private http: HttpClient) {
    super([]);
    this.alreadySavedValue$ = new BehaviorSubject<boolean>(false);
    this.alreadySaved$ = this.alreadySavedValue$.asObservable();
    this.showSavePopUpValue$ = new BehaviorSubject<boolean>(false);

    this.showSavePopUpMessageValue$ = new BehaviorSubject<string>(
      'There are unsaved changes to this scenario. Are you certain that you want to navigate away from this page?'
    );

    this.showSavePopUp$ = this.showSavePopUpValue$.asObservable();
    this.showSavePopUpMessage$ = this.showSavePopUpMessageValue$.asObservable();
  }

  public query(facility: any, parentId: string, cai: string): void {
    this.getExpansionsByCAI(cai, parentId).subscribe(data => {
      this.loader.emit(false);
      super.next(data.filter(a => !facility || a.id !== facility.id));
    });

    this.getFacilitiesByParentID(parentId).subscribe(data => {
      this.loader.emit(false);
      super.next(data);
    });
  }

  public expansionsForAll(parentId: string): void {
    this.loader.emit(true);
    this.getFacilitiesByParentID(parentId).subscribe(data => {
      super.next(data);
      this.loader.emit(false);
    });
  }

  public getFacilities(): Observable<IFacilityBod[]> {
    const serviceUrl: string = environment.PacerDomainURL + 'FacilityBod/List';
    return this.http.get<IFacilityBod[]>(serviceUrl, httpOptions).pipe(
      map((data: any[]) => {
        return data;
      })
    );
  }

  public read() {
    this.loader.emit(true);
    if (this.data.length) {
      this.loader.emit(false);
      return super.next(this.data);
    }

    this.fetch()
      .pipe(
        tap(data => {
          this.loader.emit(false);
          this.data = data;
        })
      )
      .subscribe(data => {
        this.loader.emit(false);
        super.next(data);
      });
  }

  public readByCAI(cai: string) {
    this.loader.emit(true);
    if (this.data.length) {
      this.loader.emit(false);
      return super.next(this.data);
    }

    this.getFacilityBodByCAI(cai)
      .pipe(
        tap(data => {
          this.loader.emit(false);
          this.data = data;
        })
      )
      .subscribe(data => {
        this.loader.emit(false);
        super.next(data);
      });
  }

  public remove(data: any, cai: string): void {
    this.delete(REMOVE_ACTION, data).subscribe(() => this.readByCAI(cai), () => this.readByCAI(cai));
    this.reset();
  }

  public removeExpansionAddition(data: any, parentId: any, cai: string): void {
    this.delete(REMOVE_ACTION, data).subscribe(
      () => this.query(data, parentId, cai),
      () => this.query(data, parentId, cai)
    );
    this.reset();
  }

  public removeWithAll(data: any): void {
    this.delete(REMOVE_ACTION, data).subscribe(() => this.read(), () => this.read());
    this.reset();
  }

  public removeWithAllExpansion(data: any): void {
    this.delete(REMOVE_ACTION, data).subscribe(
      () => this.expansionsForAll(data.id),
      () => this.expansionsForAll(data.id)
    );
    this.reset();
  }

  public save(data: IFacilityBod[], isNew?: boolean) {
    const action = isNew ? CREATE_ACTION : UPDATE_ACTION;
    if (!isNew) {
      this.update(action, data).subscribe(dataS => super.next(dataS));
    } else {
      this.pull(action, data).subscribe(dataS => super.next(dataS));
    }
    this.reset();
  }

  public pull(action: string = 'Create', data?: IFacilityBod[]): Observable<any> {
    const body = data;
    return this.http.post(BASE_URL + `FacilityBod/` + `${action}`, body, httpOptions).pipe(map(res => res as any[]));
  }

  public update(action: string = 'Update', data?: IFacilityBod[]): Observable<any[]> {
    const body = data;
    return this.http.post(BASE_URL + `FacilityBod/` + `${action}`, body, httpOptions).pipe(map(res => res as any[]));
  }

  public updateFacilityProjectCostEstimates(
    action: string = 'Update',
    data?: IFacilityProjectCostEstimates[]
  ): Observable<any[]> {
    const body = data;
    return this.http
      .post(BASE_URL + `FacilityProjectCostEstimates/` + `${action}`, body, httpOptions)
      .pipe(map(res => res as any[]));
  }

  public reset() {
    this.data = [];
  }

  public getFacilityTypeById(id: string): Observable<IFacilityType> {
    const serviceUrl: string = environment.PacerDomainURL + 'FacilityType/GetByKey/' + id;
    return this.http.get<IFacilityType>(serviceUrl, httpOptions);
  }

  public getFacilityBodByCAI(cai: string): Observable<any[]> {
    return this.http.get<any[]>(BASE_URL + `FacilityBod/ListExpansionsByCAI/` + `${cai}` + `/${null}`, {
      responseType: 'json',
    });
  }

  public getExpansionsByCAI(cai: string, parentId: string): Observable<any[]> {
    return this.http.get<any[]>(BASE_URL + `FacilityBod/ListExpansionsByCAI/` + `${cai}/` + `${parentId}`, {
      responseType: 'json',
    });
  }

  public listByFacilitiesId(): Observable<any> {
    this.loader.emit(true);
    const serviceUrl: string = environment.PacerDomainURL + 'FacilityBod/GetGraphByKey/' + this.facility.id;

    return this.http.get(serviceUrl, { responseType: 'json' }).pipe(
      map((data: any[]) => {
        this.loader.emit(false);
        return data;
      })
    );
  }

  public getFacilitiesByParentID(parentId: string): Observable<IFacilityBod[]> {
    return this.http.get<IFacilityBod[]>(`${BASE_URL}FacilityBod/ListExpansionsByParentId/` + parentId);
  }

  public getFacilityById(facilityId: string): Observable<IFacilityBod> {
    return this.http.get<IFacilityBod[]>(`${BASE_URL}FacilityBod/getFacilityBodById/` + facilityId).pipe(
      map((bod: IFacilityBod[]) => {
        if (bod && bod.length > 0) {
          return bod[0];
        }
      })
    );
  }

  public getGeographicAreaByBasinId(basinId: string): Observable<IGeographicArea[]> {
    return this.http.get<IGeographicArea[]>(`${BASE_GEOGRAPHIC_DOMAIN}GeographicArea/ListByBasinId/` + basinId);
  }

  public getDevAreasByGeographicAreaId(geographicAreaId: string): Observable<IDevArea[]> {
    return this.http.get<IDevArea[]>(
      `${BASE_GEOGRAPHIC_DOMAIN}DevelopmentArea/ListByGeographicAreaId/` + geographicAreaId
    );
  }

  public getUnitRateAreasByDevelopmentAreaId(devAreaId: string): Observable<IUnitRateArea[]> {
    return this.http.get<IUnitRateArea[]>(`${BASE_URL}UnitRateArea/ListByDevelopmentAreaId/` + devAreaId);
  }

  public getDevAreaById(devAreaId: string): Observable<IDevArea> {
    return this.http.get<IDevArea>(`${BASE_GEOGRAPHIC_DOMAIN}DevelopmentArea/GetGraphByKey/` + devAreaId);
  }

  public getGeographicAreaById(geographicAreaId: string): Observable<IGeographicArea> {
    return this.http.get<IGeographicArea>(`${BASE_GEOGRAPHIC_DOMAIN}GeographicArea/GetByKey/` + geographicAreaId);
  }
  public getBasinAreaById(basinId: string): Observable<IBasin> {
    return this.http.get<IBasin>(`${BASE_GEOGRAPHIC_DOMAIN}Basin/GetByKey/` + basinId);
  }

  public getUnitRateAreaById(pcrUnitRateAreaId: string): Observable<IUnitRateArea> {
    return this.http.get<IUnitRateArea>(`${BASE_URL}UnitRateArea/GetByKey/` + pcrUnitRateAreaId);
  }

  public getFacilityByProjectName(name: string): Observable<IFacilityBod[]> {
    const body = { projectName: name };
    return this.http
      .post(`${BASE_URL}FacilityBod/GetListByProjectName/`, body, httpOptions)
      .pipe(map(res => res as any[]));
  }

  public getFacilityByStandardProjectKey(standardProjectKey: string): Observable<IFacilityBod[]> {
    const body = { standardProjectKey };
    return this.http
      .post(`${BASE_URL}FacilityBod/GetListByStandardProjectKey/`, body, httpOptions)
      .pipe(map(res => res as any[]));
  }

  public getFacilityItemType(): Observable<IFacilityItemType[]> {
    return this.http.get<IFacilityItemType[]>(`${BASE_URL}FacilityItemType/List`);
  }

  public getAllFacilities(): Observable<any> {
    this.loader.emit(true);
    const serviceUrl: string = environment.PacerDomainURL + 'FacilityBod/ListAll/';
    return this.http.get(serviceUrl, { responseType: 'json' }).pipe(
      map((facilityBods: any[]) => {
        this.loader.emit(false);
        return facilityBods;
      })
    );
  }

  public getDesignById(id: string): Observable<IFacilityStdDesigns> {
    return this.http.get<IFacilityStdDesigns>(`${BASE_URL}FacilityStdDesign/GetByKey/` + id);
  }

  public ListExpansionsAdditionsWithoutParent(cai: string): Observable<any[]> {
    this.loader.emit(true);
    const serviceUrl: string = environment.PacerDomainURL + 'FacilityBod/ListExpansionsAdditionsWithoutParent/' + cai;
    return this.http.get(serviceUrl, { responseType: 'json' }).pipe(
      map((data: any[]) => {
        this.loader.emit(false);
        return data;
      })
    );
  }

  public changeAlreadySaved(status: boolean) {
    this.alreadySavedValue$.next(status);
  }

  public valueChangePerformance(value: any, executionPm: any, iePm: any, caiList: any[]): boolean {
    if (caiList.filter(z => z.fullName === value).length > 0) {
      return true;
    } else {
      return false;
      // if (this.performancePm === '') {
      //   this.messagePerformanceExist = true;
      // } else {
      //   if (this.performancePm != null) {
      //     this.messagePerformanceExist = false;
      //   } else {
      //     this.messagePerformanceExist = true;
      //   }
      // }
    }

    // if (executionPm === '') {
    //   executionPm = null;
    // }

    // if (iePm === '') {
    //   iePm = null;
    // }

    // if (value == null && executionPm == null && iePm == null) {
    //   this.messageExecution = true;
    //   this.messageIe = true;
    //   this.messagePerformance = true;
    //   return;
    // }

    // if (value === executionPm && value === iePm && value != null) {
    //   this.messageExecution = false;
    //   this.messageIe = false;
    //   this.messagePerformance = false;
    // } else {
    //   if (value === executionPm && value !== iePm && value != null) {
    //     this.messageExecution = false;
    //     this.messageIe = true;
    //     this.messagePerformance = false;
    //   } else {
    //     if (value !== executionPm && value === iePm && value != null) {
    //       this.messageExecution = true;
    //       this.messageIe = false;
    //       this.messagePerformance = false;
    //     } else {
    //       if (executionPm === iePm && executionPm != null && iePm != null) {
    //         this.messageExecution = false;
    //         this.messageIe = false;
    //         this.messagePerformance = true;
    //       } else {
    //         this.messageExecution = true;
    //         this.messageIe = true;
    //         this.messagePerformance = true;
    //       }
    //     }
    //   }
  }
  private fetch(action: string = 'List', data?: any): Observable<any[]> {
    return this.http
      .get(BASE_URL + `FacilityBod/` + `${action}`, {
        responseType: 'json',
      })
      .pipe(map(res => res as any[]));
  }

  private delete(action: string = 'DeleteByKey', data?: any): Observable<any> {
    return this.http
      .get(BASE_URL + `FacilityBod/` + `${action}/${data.id}`, {
        responseType: 'json',
      })
      .pipe(map(res => res as any[]));
  }

  private handleError(error: HttpErrorResponse) {
    // tslint:disable-next-line: no-console
    console.log('handle error:', error);
    if (error.error instanceof Error) {
      const errMsg = error.error.message;
      return throwError(()=>{new Error(errMsg)});
    }
    return throwError(()=>{new Error(error.message || 'WebAPI server error')}); 
  }

  public updateFacilityPreformanceBod(action: string = 'Update', data?: IFacilityPrefBod[]): Observable<any[]> {
    const body = data;
    return this.http
      .patch(BASE_URL + `FacilityPreformanceBod/` + `${action}`, body, httpOptions)
      .pipe(map(res => res as any[]));
  }
}
