<div class="card-body-bod-rates">
  <kendo-grid
    #performanceBodNotesGrid
    [data]="view | async"
    (cellClick)="cellClickHandler($event)"
    (cellClose)="cellCloseHandler($event)"
    (cancel)="cancelHandler($event)"
    (save)="saveHandler($event)"
    (remove)="removeHandler($event)"
    (add)="addHandler($event)"
    [navigable]="true"
    [resizable]="true"
    [sort]="gridState.sort"
    [sortable]="{ mode: 'multiple' }"
    (dataStateChange)="onStateChange($event)"
    [filterable]="true"
    [filter]="filter"
    (filterChange)="handlerFilter($event)"
  >
    <ng-template kendoGridToolbarTemplate>
      <button
        kendoButton
        class="k-button"
        [disabled]="!summaryNotesEditService.hasChanges()"
        (click)="cancelChanges()"
        [primary]="true"
      >
        Cancel
      </button>
      <button [icon]="'plus'" kendoGridAddCommand [primary]="true" [disabled]="!canEditPerformanceBod"></button>
    </ng-template>

    <kendo-grid-column field="name" title="Notes">
      <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup">
        <input elementFocus name="name" class="k-textbox" [formControl]="formGroup.get('name')" required />
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column>
      <ng-template kendoGridCellTemplate let-dataItem let-formGroup let-rowIndex="rowIndex">
        <div
          kendoTooltip
          style="text-align: center; padding-top: 10px;"
          [titleTemplate]="titleTemplate"
          [tooltipTemplate]="template"
          filter=".attach-tooltip"
        >
          <ng-template #titleTemplate let-anchor>
            <h6>Attachments:</h6>
          </ng-template>
          <ng-template #template let-anchor>
            <ul>
              <li *ngFor="let attachment of dataItem.performanceBodNotesAttachments">
                {{ attachment.name }}
              </li>
            </ul>
          </ng-template>
          <button
            kendoButton
            [primary]="true"
            icon="k-button k-i-attachment"
            [disabled]="!dataItem.name || !canEditPerformanceBod"
            class="attach-tooltip"
            (click)="onOpenAttachmentPopUp(dataItem, formGroup, performanceBodNotesGrid, rowIndex)"
          >
            Attachments
            <mark *ngIf="dataItem.performanceBodNotesAttachments" class="big swing">
              {{
                dataItem.performanceBodNotesAttachments !== undefined || dataItem.performanceBodNotesAttachments != null
                  ? dataItem.performanceBodNotesAttachments.length
                  : 0
              }}
            </mark>
          </button>
        </div>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-command-column title="" width="150px">
      <ng-template kendoGridCellTemplate let-isNew="isNew">
        <button kendoGridRemoveCommand [primary]="true" [icon]="'minus'" [disabled]="!canEditPerformanceBod"></button>
        <button kendoGridSaveCommand [primary]="true">Add</button>
        <button kendoGridCancelCommand [primary]="true">Cancel</button>
      </ng-template>
    </kendo-grid-command-column>
  </kendo-grid>
</div>
<div kendoDialogContainer #attachmentsDialog ></div>