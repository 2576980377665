<div class="card-body">
  <kendo-grid
    #inputFacilityAssignmentGrid
    [inCellTab]="createFormGroup"
    [data]="gridView"
    (cellClick)="cellClickHandler($event)"
    (cellClose)="cellCloseHandler($event)"
    [reorderable]="true"
    [rowClass]="rowCallback()"
    [navigable]="true"
    (columnReorder)="onColumnReorder($event)"
    (edit)="expandCollapseHandler($event)"
    (dataStateChange)="onDataStateChange($event)"
    [skip]="skip"
    (pageChange)="pageChange($event)"
    [resizable]="true"
  >
    <kendo-grid-command-column
      [resizable]="false"
      [reorderable]="false"
      [locked]="true"
      width="20"
      [minResizableWidth]="20"
      class="no-padding"
      field="fieldName"
    >
      <ng-template kendoGridHeaderTemplate let-column let-columnIndex="columnIndex">
        <button
          kendoButton
          look="flat"
          (click)="onPadHeaderClick()"
          *ngIf="isHeaderBtnVisible"
          style="padding: 0px 0px 0px 0px; border: none; background: #f8f9fa;
      margin: 0px 0px 0px -10px;"
        >
          <span [class]="headerCellClassExpandCollapse()"></span>
        </button>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
        <div [class]="'custom-cell-template-cmd non-editable-cell'">
          <button
            kendoGridEditCommand
            [look]="'flat'"
            [hidden]="isHiddenExpandCollapse(dataItem)"
            style="padding: 2px; padding-top: 6px;"
          >
            <span [class]="cellClassExpandCollapse(dataItem)"></span>
          </button>
        </div>
      </ng-template>
    </kendo-grid-command-column>
    <kendo-grid-column
      [reorderable]="false"
      [locked]="padBuilderData.length > 0"
      field="fieldName"
      title=""
      [class]="['no-padding']"
      width="200"
    >
      <ng-template kendoGridHeaderTemplate let-column let-columnIndex="columnIndex">
        Pad
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem let-row="rowIndex">
        <div [class]="cellClass('none', 'fieldName')">
          {{ dataItem.fieldName.displayName }}
        </div>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column-group width="100" [title]="" [reorderable]="false">
      <ng-container *ngFor="let pad of padBuilderData">
        <kendo-grid-column [class]="'no-padding'" [field]="pad.padName" [title]="pad.pbPadName" width="200">
          <ng-template kendoGridHeaderTemplate let-column let-columnIndex="columnIndex">
            <strong>
              {{ column.title }}
            </strong>
            <br />
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem let-row="rowIndex">
            <ng-container [ngSwitch]="dataItem.fieldName.name">
              <div
                *ngSwitchCase="Fields.padRigDownMoveOffDate"
                [class]="cellClass(pad.padName, Fields.padRigDownMoveOffDate)"
              >
                {{ dataItem[pad.padName] | date }}
              </div>
              <div *ngSwitchCase="Fields.popDate" [class]="cellClass(pad.padName, Fields.popDate)">
                {{ dataItem[pad.padName] | date }}
              </div>
              <div *ngSwitchCase="Fields.spudDate" [class]="cellClass(pad.padName, Fields.spudDate)">
                {{ dataItem[pad.padName] | date }}
              </div>
              <div
                *ngSwitchCase="Fields.tragetSatelliteRfsd"
                [class]="cellClass(pad.padName, Fields.tragetSatelliteRfsd)"
              >
                {{ dataItem[pad.padName] | date }}
              </div>
              <div *ngSwitchCase="Fields.tragetCtbRfsd" [class]="cellClass(pad.padName, Fields.tragetCtbRfsd)">
                {{ dataItem[pad.padName] | date }}
              </div>
              <div *ngSwitchCase="Fields.compressionRfsd" [class]="cellClass(pad.padName, Fields.compressionRfsd)">
                {{ dataItem[pad.padName] | date }}
              </div>
              <div *ngSwitchCase="Fields.waterDisposalRfsd" [class]="cellClass(pad.padName, Fields.waterDisposalRfsd)">
                {{ dataItem[pad.padName] | date }}
              </div>
              <div *ngSwitchCase="Fields.fracPondRfsd" [class]="cellClass(pad.padName, Fields.fracPondRfsd)">
                {{ dataItem[pad.padName] | date }}
              </div>
              <div *ngSwitchCase="Fields.padContingency" [class]="cellClass(pad.padName, Fields.padContingency)">
                {{ dataItem[pad.padName] | percent: '1.1-2' }}
              </div>
              <div *ngSwitchDefault [class]="cellClass(pad.padName, dataItem.fieldName.name)">
                {{ dataItem[pad.padName] }}
              </div>
            </ng-container>
          </ng-template>
          <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-column="column" let-formGroup="formGroup">
            <ng-container [ngSwitch]="dataItem.fieldName.name">
              <kendo-dropdownlist
                #dropdownEditor
                *ngSwitchCase="Fields.padStatus"
                [data]="padStatusOptions"
                elementFocus
                [formControl]="formGroup.get(pad.padName)"
                [class]="cellClass(pad.padName, dataItem.fieldName.name, true)"
              >
              </kendo-dropdownlist>
              <kendo-dropdownlist
                #dropdownEditor
                *ngSwitchCase="Fields.activeForOutlook"
                [data]="activeForOutlookOptions"
                elementFocus
                [formControl]="formGroup.get(pad.padName)"
                [class]="cellClass(pad.padName, dataItem.fieldName.name, true)"
              >
              </kendo-dropdownlist>
              <kendo-dropdownlist
                #dropdownEditor
                *ngSwitchCase="Fields.developmentArea"
                [data]="developmentAreaOptionsOnEdit"
                elementFocus
                [formControl]="formGroup.get(pad.padName)"
                [class]="cellClass(pad.padName, dataItem.fieldName.name, true)"
              >
              </kendo-dropdownlist>
              <kendo-dropdownlist
                #dropdownEditor
                *ngSwitchCase="Fields.isPartOfCo2Development"
                [data]="partOfCo2DevelopmentOptions"
                elementFocus
                [formControl]="formGroup.get(pad.padName)"
                [class]="cellClass(pad.padName, dataItem.fieldName.name, true)"
              >
              </kendo-dropdownlist>
              <kendo-dropdownlist
                #dropdownEditor
                *ngSwitchCase="Fields.satelliteExecutionCategory"
                [data]="executionCategoryOptions"
                elementFocus
                [formControl]="formGroup.get(pad.padName)"
                [class]="cellClass(pad.padName, dataItem.fieldName.name, true)"
              >
              </kendo-dropdownlist>
              <kendo-dropdownlist
                #dropdownEditor
                *ngSwitchCase="Fields.ctbExecutionCategory"
                [data]="executionCategoryOptions"
                elementFocus
                [formControl]="formGroup.get(pad.padName)"
                [class]="cellClass(pad.padName, dataItem.fieldName.name, true)"
              >
              </kendo-dropdownlist>
              <kendo-dropdownlist
                #dropdownEditor
                *ngSwitchCase="Fields.compExecutionCategory"
                [data]="executionCategoryOptions"
                elementFocus
                [formControl]="formGroup.get(pad.padName)"
                [class]="cellClass(pad.padName, dataItem.fieldName.name, true)"
              >
              </kendo-dropdownlist>
              <kendo-dropdownlist
                #dropdownEditor
                *ngSwitchCase="Fields.swdExecutionCategory"
                [data]="executionCategoryOptions"
                elementFocus
                [formControl]="formGroup.get(pad.padName)"
                [class]="cellClass(pad.padName, dataItem.fieldName.name, true)"
              >
              </kendo-dropdownlist>
              <kendo-dropdownlist
                #dropdownEditor
                *ngSwitchCase="Fields.pondExecutionCategory"
                [data]="executionCategoryOptions"
                elementFocus
                [formControl]="formGroup.get(pad.padName)"
                [class]="cellClass(pad.padName, dataItem.fieldName.name, true)"
              >
              </kendo-dropdownlist>
              <kendo-datepicker
                *ngSwitchCase="Fields.padRigDownMoveOffDate"
                elementFocus
                [formControl]="formGroup.get(pad.padName)"
                [class]="cellClass(pad.padName, dataItem.fieldName.name, true)"
              >
              </kendo-datepicker>
              <kendo-datepicker
                *ngSwitchCase="Fields.popDate"
                elementFocus
                [formControl]="formGroup.get(pad.padName)"
                [class]="cellClass(pad.padName, dataItem.fieldName.name, true)"
              >
              </kendo-datepicker>
              <kendo-datepicker
                *ngSwitchCase="Fields.spudDate"
                elementFocus
                [formControl]="formGroup.get(pad.padName)"
                [class]="cellClass(pad.padName, dataItem.fieldName.name, true)"
              >
              </kendo-datepicker>
              <kendo-datepicker
                *ngSwitchCase="Fields.compressionRfsd"
                elementFocus
                [formControl]="formGroup.get(pad.padName)"
                [class]="cellClass(pad.padName, dataItem.fieldName.name, true)"
              >
              </kendo-datepicker>
              <kendo-datepicker
                *ngSwitchCase="Fields.waterDisposalRfsd"
                elementFocus
                [formControl]="formGroup.get(pad.padName)"
                [class]="cellClass(pad.padName, dataItem.fieldName.name, true)"
              >
              </kendo-datepicker>
              <kendo-datepicker
                *ngSwitchCase="Fields.fracPondRfsd"
                elementFocus
                [formControl]="formGroup.get(pad.padName)"
                [class]="cellClass(pad.padName, dataItem.fieldName.name, true)"
              >
              </kendo-datepicker>
              <kendo-numerictextbox
                elementFocus
                [format]="formatOptions"
                [decimals]="4"
                [step]="0.01"
                [min]="0.0"
                [max]="100.0"
                [autoCorrect]="true"
                *ngSwitchCase="Fields.padContingency"
                [formControl]="formGroup.get(pad.padName)"
                [class]="cellClass(pad.padName, dataItem.fieldName.name, true)"
              ></kendo-numerictextbox>
              <input
                elementFocus
                *ngSwitchDefault
                [formControl]="formGroup.get(pad.padName)"
                maxlength="30"
                [class]="'input-edit-cell-template ' + cellClass(pad.padName, dataItem.fieldName.name)"
              />
              <kendo-numerictextbox
                elementFocus
                *ngSwitchCase="Fields.cumulativeTestSeparators"
                [formControl]="formGroup.get(pad.padName)"
                [class]="cellClass(pad.padName, dataItem.fieldName.name, true)"
              >
              </kendo-numerictextbox>
              <kendo-numerictextbox
                elementFocus
                *ngSwitchCase="Fields.cumulativeHeaderSlots"
                [formControl]="formGroup.get(pad.padName)"
                [class]="cellClass(pad.padName, dataItem.fieldName.name, true)"
              >
              </kendo-numerictextbox>
            </ng-container>
          </ng-template>
        </kendo-grid-column>
      </ng-container>
    </kendo-grid-column-group>
  </kendo-grid>
  <app-facility-assignment></app-facility-assignment>
</div>
