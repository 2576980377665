import { Component, EventEmitter, OnInit, Output, ViewChild, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs';
import { GridDataResult, RowClassArgs } from '@progress/kendo-angular-grid';
import { State, process, filterBy, CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { IPerformanceBodPipelines } from 'src/app/api/performance-bod-pipelines';
import { PerformanceBodStoreService } from 'src/app/services/performance-bod-store.service';
import { InfrastructurePipelinesService } from './infrastructure-pipelines.service';
import { Guid } from 'guid-typescript';
import { IPerformanceBodPackages } from 'src/app/api/performance-bod-package';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from '@progress/kendo-angular-notification';
import { UtilService } from 'src/app/services/util.service';
import { PermissionsProvider } from 'src/app/services/permissions.provider';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { CataloguesService } from 'src/app/services/catalogues.service';
import { IPipelineMaterialType } from 'src/app/api/pipeline-material-type';
import { IPipelineMaterialSubType } from 'src/app/api/pipeline-material-sub-type';
import { IPipelineType } from 'src/app/api/pipeline-type';
import { DragAndDropService } from 'src/app/_shared/drag-and-drop.service';

const DRAGGABLE_SELECTOR = '.infrastructure-pipelines-draggable';

@Component({
  selector: 'app-infrastructure-pipelines',
  templateUrl: './infrastructure-pipelines.component.html',

})
export class InfrastructurePipelinesComponent implements OnInit {
  public filter: CompositeFilterDescriptor;
  public filterable: boolean = true;
  public view: Observable<GridDataResult>;
  public performanceBodId: string;
  public formGroup: UntypedFormGroup;
  public gridState: State = {
    sort: [],
    skip: 0,
  };
  public canEditPerformanceBod: boolean = false;
  public performanceBodPackagesList: IPerformanceBodPackages[];
  public yesNoList: any[] = [{ text: 'Yes', value: true }, { text: 'No', value: false }];
  public volumeUnitOfMeasureList: Array<{ text: string; value: string }> = [
    { text: 'BWPD', value: 'BWPD' },
    { text: 'BOPD', value: 'BOPD' },
    { text: 'MMSCFD', value: 'MMSCFD' },
    { text: 'BLPD', value: 'BLPD' },
  ];
  public pipelineTypeList: IPipelineType[];
  public pipelineMaterialTypeList: IPipelineMaterialType[];
  public pipelineMaterialSubTypeByMaterialTypeList: IPipelineMaterialSubType[];
  public pipelineMaterialSubTypeByMaterialTypeListToFilter: IPipelineMaterialSubType[];
  public infrastructurePipelinesService: InfrastructurePipelinesService;
  @Output() public performanceBodUpdate = new EventEmitter<string>();
  @Output() public pipelineCreate = new EventEmitter<any>();
  @Output() public saveBodCompleted = new EventEmitter<boolean>();

  @ViewChild('performanceBodPipelinesGrid', { static: true })
  private infrastructurePipelinesGrid: any;
  private pipelineTypelDefaultItem = { id: null, name: 'Select Pipeline Type' };
  private materiaTypelDefaultItem = { id: null, name: 'Select Material Type' };
  private pipelineMaterialSubTypeList: IPipelineMaterialSubType[];
  private dragAndDropService: DragAndDropService;

  constructor(
    private performanceBodStoreService: PerformanceBodStoreService,
    private formBuilder: UntypedFormBuilder,
    private spinner: NgxSpinnerService,
    private notificationService: NotificationService,
    private utilService: UtilService,
    private permissionsProvider: PermissionsProvider,
    private authenticationService: AuthenticationService,
    private cataloguesService: CataloguesService,
    @Inject(DragAndDropService) dragAndDropServiceFactory: any,
    @Inject(InfrastructurePipelinesService) infrastructurePipelinesServiceFactory: any
  ) {
    this.dragAndDropService = dragAndDropServiceFactory();
    this.infrastructurePipelinesService = infrastructurePipelinesServiceFactory();
  }

  public ngOnInit(): void { 
    this.performanceBodStoreService.performanceBodSignatured$.subscribe((isSignature) => {
      this.canEditPerformanceBod = isSignature? false: this.permissionsProvider.canEditPerformanceBod;
    });

    // Get catalogues used
    this.cataloguesService.pipelineTypeData$.subscribe((pipelineTypes: IPipelineType[]) => {
      this.pipelineTypeList = pipelineTypes;
    });

    this.cataloguesService.pipelineMaterialTypeData$.subscribe((pipelineMaterialTypes: IPipelineMaterialType[]) => {
      this.pipelineMaterialTypeList = pipelineMaterialTypes;
    });

    this.cataloguesService.pipelineMaterialSubTypeData$.subscribe(
      (pipelineMaterialSubTypes: IPipelineMaterialSubType[]) => {
        this.pipelineMaterialSubTypeList = pipelineMaterialSubTypes;
        this.pipelineMaterialSubTypeByMaterialTypeList = [];
      }
    );

    // Set the performance bod identification
    this.performanceBodStoreService.performanceBodId$.subscribe(id => {
      this.performanceBodId = id;
      this.infrastructurePipelinesService.performanceBodId = id;
    });

    // Used to load the view grid
    this.view = this.infrastructurePipelinesService.pipe(
      map(data => {
        this.dragAndDropService.dataSourceRefreshed(data);
        return process(filterBy(data, this.filter), this.gridState);
      })
    );

    // Set packages
    this.performanceBodStoreService.performanceBodPackagesData$.subscribe(data => {
      this.performanceBodPackagesList = data;
    });

    // Get async array
    this.infrastructurePipelinesService.read();

    this.dragAndDropService.initialize(this.infrastructurePipelinesService.data, DRAGGABLE_SELECTOR, () => {
      this.infrastructurePipelinesService.markAsSetOrder(this.authenticationService.getCurrentUser());
    });
  }

  public ngAfterViewInit(): void {
    // To Do
  }

  public ngOnDestroy(): void {
    this.dragAndDropService.refreshDragAndDrop();
  }

  public createFormGroup(dataItem: any): UntypedFormGroup {
    return this.formBuilder.group({
      id: new UntypedFormControl(dataItem.id),
      name: new UntypedFormControl(dataItem.name, [Validators.maxLength(50)]),
      isDeleted: new UntypedFormControl(dataItem.isDeleted),
      createdBy: new UntypedFormControl(dataItem.createdBy),
      createdDate: new UntypedFormControl(dataItem.createdDate),
      updatedBy: new UntypedFormControl(dataItem.updatedBy),
      updatedDate: new UntypedFormControl(dataItem.updatedDate),
      from: new UntypedFormControl(dataItem.from, [Validators.minLength(0), Validators.maxLength(250)]),
      to: new UntypedFormControl(dataItem.to, [Validators.minLength(0), Validators.maxLength(250)]),
      designPressure: new UntypedFormControl(dataItem.designPressure),
      quantity: new UntypedFormControl(dataItem.quantity, [Validators.min(0), Validators.max(50)]),
      pipelineRfsd: new UntypedFormControl(dataItem.pipelineRfsd, [Validators.required]),
      permitsRequiredBy: [dataItem.permitsRequiredBy],
      comments: new UntypedFormControl(dataItem.comments, [Validators.minLength(0), Validators.maxLength(500)]),
      performanceBodPackagesId: new UntypedFormControl(dataItem.performanceBodPackagesId, [Validators.required]),
      performanceBodId: new UntypedFormControl(dataItem.performanceBodId),
      chargeCode: new UntypedFormControl(dataItem.chargeCode, [
        Validators.maxLength(13),
        Validators.pattern('^[a-zA-Z0-9]{0,13}$'),
      ]),
      cndHOEstimate: new UntypedFormControl(dataItem.cndHOEstimate, [Validators.min(0), Validators.max(999999999999)]),
      afeNumber: new UntypedFormControl(dataItem.afeNumber, [
        Validators.maxLength(13),
        Validators.pattern('^[a-zA-Z0-9]{0,13}$'),
      ]),  
      afeAmount: new UntypedFormControl(dataItem.afeAmount, [
        Validators.maxLength(8),
        Validators.pattern('^[a-zA-Z0-9]{0,8}$'),
      ]), 
    });
  }

  public cellClickHandler({ sender, rowIndex, columnIndex, dataItem, isEdited }) {
    if (!isEdited && this.canEditPerformanceBod) {
      let formGroup = this.createFormGroup(dataItem);
      sender.editCell(rowIndex, columnIndex, formGroup);
      this.dragAndDropService.onEditingMode();
    }
  }

  public cellCloseHandler(args: any) {
    const { formGroup, dataItem, column } = args;

    let columnField = '';

    if (column.field === 'performanceBodPackagesName') {
      columnField = 'performanceBodPackagesId';
    } else {
      columnField = column.field;
    }

    const checkColumn = formGroup.controls[columnField];

    if (!checkColumn.valid) {
      args.preventDefault();
    } else if (checkColumn.dirty) {
      if (columnField === 'performanceBodPackagesId') {
        dataItem.performanceBodPackagesName = this.getPerformanceBodPackages(formGroup.controls[columnField].value);
      }
    }

    if (!formGroup.valid) {
      // prevent closing the edited cell if there are invalid values.
      args.preventDefault();
    } else if (formGroup.dirty) {
      if (formGroup.value.pipelineMaterialTypeId !== dataItem.pipelineMaterialTypeId) {
        formGroup.value.pipelineMaterialSubTypeId = null;
      }

      dataItem.updatedDate = new Date();
      dataItem.updatedBy = this.authenticationService.getCurrentUser();

      // Date is not working in Object.assign(), so need to be do it manually
      formGroup.value.updatedBy = dataItem.updatedBy;
      formGroup.value.updatedDate = dataItem.updatedDate;

      this.infrastructurePipelinesService.assignValues(dataItem, formGroup.value);
      this.infrastructurePipelinesService.updateItem(dataItem);
    }
    // calls this to add the attribute of current row
    this.dragAndDropService.onEditingClose();
    this.dragAndDropService.refreshDragAndDrop();
  }

  public addHandler({ sender }) {
    const item: IPerformanceBodPipelines = {
      id: null,
      name: 'Default',
      isDeleted: false,
      createdBy: this.authenticationService.getCurrentUser(),
      createdDate: new Date(),
      updatedBy: this.authenticationService.getCurrentUser(),
      updatedDate: new Date(),
      pipelineType: null,
      pipelineMaterialType: null,
      pipelineMaterialSubType: null,
      from: '',
      to: '',
      designPressure: 0,
      quantity: 1,
      pipelineRfsd: undefined,
      permitsRequiredBy: undefined,
      comments: '',
      performanceBodPackagesId: null,
      performanceBodId: this.performanceBodId,
      sortOrder: 0,
      chargeCode: '',
      cndHOEstimate: 0,
      afeNumber:'',
      afeAmount: 0,
    };
    sender.addRow(this.createFormGroup(item));
    this.dragAndDropService.onEditingMode();
  }

  public cancelHandler({ sender, rowIndex }) {
    sender.closeRow(rowIndex);
    this.dragAndDropService.onEditingClose();
    this.dragAndDropService.refreshDragAndDrop();
  }

  public saveHandler({ sender, formGroup, rowIndex }) {
    //Check is need to add validation for size.

    if (formGroup.valid) {
      formGroup.value.id = Guid.create().toString();
      this.infrastructurePipelinesService.createItem(formGroup.value);
      sender.closeRow(rowIndex);
      this.dragAndDropService.onEditingClose();
      this.dragAndDropService.dataSourceRefreshed(this.infrastructurePipelinesService.data);
      this.dragAndDropService.refreshDragAndDrop();
      this.infrastructurePipelinesService.markAsSetOrder(this.permissionsProvider.cai);
    } else {
      this.utilService.markFormGroupAsTouched(formGroup);
    }
  }

  public removeHandler({ sender, dataItem }) {
    dataItem.isDeleted = true;
    this.infrastructurePipelinesService.removeItem(dataItem);
    sender.cancelCell();
  }

  public saveChanges(grid: any): void {
    grid.closeCell();
    grid.cancelCell();
    this.spinner.show();
    // calls this to add the attribute of current row
    this.dragAndDropService.refreshDragAndDrop();
    this.infrastructurePipelinesService.saveChanges();
    this.performanceBodUpdate.next(this.authenticationService.getCurrentUser());

    setTimeout(() => {
      this.spinner.hide();
      this.notificationService.show({
        content: 'Infrastructure - Facility Pipelines saved',
        position: { horizontal: 'center', vertical: 'top' },
        animation: { type: 'fade', duration: 500 },
        type: { style: 'success', icon: true },
        hideAfter: 700,
      });
    }, 3000);
  }

  public saveFromBOD(): void {
    this.infrastructurePipelinesGrid.closeCell();
    this.infrastructurePipelinesGrid.cancelCell();

    if (this.infrastructurePipelinesService.hasChanges()) {
      this.spinner.show();
      // calls this to add the attribute of current row
      this.dragAndDropService.refreshDragAndDrop();
      this.infrastructurePipelinesService.saveChanges();
      this.performanceBodUpdate.next(this.authenticationService.getCurrentUser());
      const subscription = this.infrastructurePipelinesService.subscribe(() => {
        this.saveBodCompleted.next(true);
      });

      subscription.unsubscribe();
    }
  }

  public cancelChanges(grid: any): void {
    grid.cancelCell();
    this.dragAndDropService.refreshDragAndDrop();
    this.infrastructurePipelinesService.cancelChanges();
  }

  public handlerFilter(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    if (filter.filters) {
      if (filter.filters.length > 0) {
        this.dragAndDropService.onEditingMode();
      } else {
        this.dragAndDropService.onEditingClose();
        this.dragAndDropService.refreshDragAndDrop();
      }
    }
  }

  public onStateChange(state: State) {
    this.gridState = state;
    this.infrastructurePipelinesService.read();

    // const viewSubscription = this.view.subscribe(data => {
    //   this.dragAndDropService.dataSourceRefreshed(data.data);
    // });

    // viewSubscription.unsubscribe();
    // this.dragAndDropService.runZoneOnStable();
  }

  public getPerformanceBodPackages(id: string): any {
    if (this.performanceBodPackagesList === null || this.performanceBodPackagesList === undefined) {
      return '';
    }
    this.performanceBodPackagesList = this.performanceBodPackagesList.filter(x => x.id !== null && x.id !== undefined);
    return this.performanceBodPackagesList.find(x => x.id === id);
  }

  public getYesNo(option: boolean): any {
    return this.yesNoList.find(x => x.value === option);
  }

  public saveFromPerformanceBOD(): boolean {
    // calls this to add the attribute of current row
    this.dragAndDropService.refreshDragAndDrop();
    this.infrastructurePipelinesService.saveChanges();
    return this.infrastructurePipelinesService.hasChanges();
  }

  public materialSubTypeAutocompleteFilter(value: any) {
    this.pipelineMaterialSubTypeByMaterialTypeList = this.pipelineMaterialSubTypeByMaterialTypeListToFilter.filter(
      s => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  public rowCallback(context: RowClassArgs) {
    return {
      dragging: context.dataItem.dragging,
    };
  }

  public rowLoaded(): void {
    // to check until grid have been loeaded.
    this.dragAndDropService.checkIsOnEditMode();
    this.dragAndDropService.checkDraggableElementLoaded();
  }

  public changePackage(value, dataItem: IPerformanceBodPipelines, formGroup: UntypedFormGroup) {
    const performanceBodPackages = this.performanceBodPackagesList.find(s => s.id === value);
    if (!dataItem.id) {
      dataItem.performanceBodPackagesId = performanceBodPackages ? performanceBodPackages.id : null;
      this.pipelineCreate.next(dataItem);
      formGroup.patchValue({
        permitsRequiredBy: dataItem.permitsRequiredBy,
      });
      formGroup.patchValue({
        pipelineRfsd: dataItem.pipelineRfsd,
      });
    }
  }

  public checkNeedValidationForSize(formGroup: UntypedFormGroup, addValidation: boolean): void {
    if (addValidation) {
      formGroup.get('pipelineMaterialSubTypeId').addValidators(Validators.required);
    } else {
      formGroup.get('pipelineMaterialSubTypeId').clearValidators();
      formGroup.get('pipelineMaterialSubTypeId').setErrors(null);
    }
  }
}
