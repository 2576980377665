import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { IFacilityType } from '../models/facility.type.model';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
  withCredentials: true,
};

@Injectable()
export class FacilityTypeService {
  constructor(private http: HttpClient) {}

  getFacilityTypes(): Observable<IFacilityType[]> {
    const serviceUrl: string = environment.PacerDomainURL + 'FacilityType/List/';
    return this.http.get(serviceUrl, httpOptions).pipe(
      map((data: any[]) => {
        return data;
      })
    );
  }

  getFacilityTypeById(facilityTypeId: string): Observable<IFacilityType> {
    const serviceUrl: string = environment.PacerDomainURL + 'FacilityType/GetByKey/' + facilityTypeId;
    return this.http.get(serviceUrl, httpOptions).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  public getFacilityTypeByIdUnique(facilityItemTypeId: string): Observable<any> {
    const serviceUrl: string =
      environment.PacerDomainURL + 'FacilityType/getFacilityTypeByIdUnique/' + facilityItemTypeId;
    return this.http.get(serviceUrl, httpOptions).pipe(
      map((data: any) => {
        return data;
      })
    );
  }
}
