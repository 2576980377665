import { AfterViewInit, Directive, ElementRef, ViewChild } from '@angular/core';

@Directive({
  selector: '[elementFocus]'
})
export class ElementFocusDirective implements AfterViewInit {
  constructor(private el: ElementRef) {}

  ngAfterViewInit(): void {
    this.el.nativeElement.focus();
  }

}