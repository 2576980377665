import { Injectable, EventEmitter } from '@angular/core';
import { InlineEditService } from 'src/app/services/inline-edit.service';
import { IEditableInline } from 'src/app/api/ieditable-inline';
import { Observable } from 'rxjs';
import { InlineActions } from 'src/app/api/inline-actions.enum';
import { map, catchError } from 'rxjs';
import { IDasSimple } from '../../../api/das-simple';
import { DasSimpleService } from '../../../services/das-simple.service';

@Injectable({
  providedIn: 'root'
})
export class DasSimpleListEditService extends InlineEditService implements IEditableInline {
  public data: IDasSimple[] = [];
  public originalData: IDasSimple[] = [];
  public createdItems: IDasSimple[] = [];
  public updatedItems: IDasSimple[] = [];
  public deletedItems: IDasSimple[] = [];
  public completed: EventEmitter<boolean>;
  public cai: string;
  constructor(private dasSimpleService: DasSimpleService) {
    super();
  }

  public isNew(item: any): boolean {
    return !item.id;
  }

  public itemIndex(item: any, data: IDasSimple[]): number {
    for (let idx = 0; idx < data.length; idx++) {
      if (data[idx].id === item.id) {
        return idx;
      }
    }
    return -1;
  }

  public create(): Observable<any> {
    throw new Error('Method not implemented.');
  }

  public retrieve(): Observable<any> {
    this.loader = new EventEmitter();
    this.loader.emit(true);
    return this.dasSimpleService
      .getArray(this.cai)
      .pipe(
        map(
          (data: any[]) => {
            this.loader.emit(false);
            this.loader.complete();
            return data;
          },
          catchError(error => {
            this.loader.error(error);
            this.loader.complete();
            return error;
          })
        )
      );
  }

  public update(): Observable<any> {
    throw new Error('Method not implemented.');
  }

  public delete(): Observable<any> {
    return this.dasSimpleService
      .delete(this.deletedItems)
      .pipe((unitOfWork: any) => {
        return unitOfWork;
      });
  }

  public cancelChanges(): void {
    super.cancelChanges();
    this.fetch(InlineActions.Retrieve);
  }
}
