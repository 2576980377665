<kendo-grid #performanceBodPipelinesGrid
            [data]="view | async"
            (cellClick)="cellClickHandler($event)"
            (cellClose)="cellCloseHandler($event)"
            (cancel)="cancelHandler($event)"
            (save)="saveHandler($event)"
            (remove)="removeHandler($event)"
            (add)="addHandler($event)"
            [navigable]="true"
            [resizable]="true"
            [sort]="gridState.sort"
            [sortable]="{ mode: 'multiple' }"
            (dataStateChange)="onStateChange($event)"
            [filter]="filter"
            filterable="menu"
            (filterChange)="handlerFilter($event)"
            class="infrastructure-pipelines-draggable"
            [rowClass]="rowCallback">
  <ng-template kendoGridToolbarTemplate>
    <button kendoButton
            class="k-button"
            [disabled]="!infrastructurePipelinesService.hasChanges()"
            (click)="cancelChanges(performanceBodPipelinesGrid)"
            [primary]="true">
      Cancel
    </button>
    <button [icon]="'plus'" kendoGridAddCommand [primary]="true" [disabled]="!canEditPerformanceBod"></button>
  </ng-template>
  <kendo-grid-column title="" field="" [hidden]="true" [width]="1">
    {{ rowLoaded() }}
  </kendo-grid-column>

  <kendo-grid-column field="name" title="Pipeline/Flowline Name" [editable]="true" [width]="150"> </kendo-grid-column>
  <kendo-grid-column field="performanceBodPackagesId" title="Package Name" [editable]="true" [width]="120">
    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
      <multicheck-filter [isPrimitive]="false"
                         [field]="column.field"
                         textField="name"
                         valueField="id"
                         [filterService]="filterService"
                         [currentFilter]="filter"
                         [data]="performanceBodPackagesList">
      </multicheck-filter>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ getPerformanceBodPackages(dataItem.performanceBodPackagesId)?.name }}
    </ng-template>
    <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-column="column" let-formGroup="formGroup">
      <kendo-dropdownlist popupAnchor
                          [data]="performanceBodPackagesList"
                          textField="name"
                          valueField="id"
                          [valuePrimitive]="true"
                          [formControl]="formGroup.get('performanceBodPackagesId')"
                          (valueChange)="changePackage($event, dataItem, formGroup)">
      </kendo-dropdownlist>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="chargeCode" title="Dummy Charge code" [editable]="true" width="150"> </kendo-grid-column>
  <kendo-grid-column field="cndHOEstimate"
                     title="C&D/HO Estimate"
                     [editable]="true"
                     editor="numeric"
                     filter="numeric"
                     [width]="120">
  </kendo-grid-column>
  <kendo-grid-column field="afeNumber"
                     title="AFE No."
                     [editable]="true"
                     width="150">

  </kendo-grid-column>

  <kendo-grid-column field="afeAmount"
                     title="Approved AFE Amount"
                     [editable]="true"
                     editor="numeric"
                     filter="numeric"
                     width="150">
</kendo-grid-column>

  <kendo-grid-column field="from" title="From (Location)" [editable]="true" [width]="180">
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem.from }}
    </ng-template>
    <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-column="column" let-formGroup="formGroup">
      <input elementFocus type="text" [formControl]="formGroup.get('from')" class="k-textbox" />
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="to" title="To (Location)" [editable]="true" [width]="180">
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem.to }}
    </ng-template>
    <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-column="column" let-formGroup="formGroup">
      <input elementFocus type="text" [formControl]="formGroup.get('to')" class="k-textbox" />
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="designPressure" title="Design Pressure" filter="numeric" [width]="120">
    <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-column="column" let-formGroup="formGroup">
      <kendo-numerictextbox [decimals]="0"
                            [format]="'#'"
                            [value]="designPressure"
                            [formControl]="formGroup.get('designPressure')"
                            [autoCorrect]="false"></kendo-numerictextbox>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="quantity" title="Quantity" filter="numeric" [width]="105">
    <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-column="column" let-formGroup="formGroup">
      <kendo-numerictextbox [decimals]="2"
                            [format]="n2"
                            [value]="quantity"
                            [formControl]="formGroup.get('quantity')"
                            [autoCorrect]="true"></kendo-numerictextbox>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="pipelineRfsd"
                     title="RFSD"
                     [editable]="true"
                     editor="date"
                     [width]="100"
                     format="MM/dd/yyyy"
                     [filterable]="filterable"
                     filter="date">
    <ng-template kendoGridCellTemplate let-dataItem>
      <span>{{ dataItem.pipelineRfsd | date: 'MM/dd/yyyy' }}</span>
    </ng-template>
    <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-column="column" let-formGroup="formGroup">
      <kendo-datepicker [value]="dataItem.pipelineRfsd"
                        [format]="'MM/dd/yyyy'"
                        [formControl]="formGroup.get('pipelineRfsd')">
        >
      </kendo-datepicker>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="permitsRequiredBy"
                     title="Permits Required By"
                     [editable]="true"
                     editor="date"
                     [width]="130"
                     format="MM/dd/yyyy"
                     [filterable]="filterable"
                     filter="date">
    <ng-template kendoGridCellTemplate let-dataItem>
      <span>{{ dataItem.permitsRequiredBy | date: 'MM/dd/yyyy' }}</span>
    </ng-template>
    <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-column="column" let-formGroup="formGroup">
      <kendo-datepicker [value]="dataItem.permitsRequiredBy"
                        [format]="'MM/dd/yyyy'"
                        [formControl]="formGroup.get('permitsRequiredBy')"></kendo-datepicker>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="comments" title="Comments" [editable]="true" [width]="150"> </kendo-grid-column>
  <kendo-grid-command-column title="" [width]="130">
    <ng-template kendoGridCellTemplate let-isNew="isNew">
      <button kendoGridRemoveCommand [primary]="true" [icon]="'minus'" [disabled]="!canEditPerformanceBod"></button>
      <button kendoGridSaveCommand [primary]="true">Add</button>
      <button kendoGridCancelCommand [primary]="true">Cancel</button>
    </ng-template>
  </kendo-grid-command-column>
</kendo-grid>
