import { IPerformanceBodWSWaterDisposal } from 'src/app/api/performance-bod-ws-water-disposal';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs';
import { IEditableInline } from 'src/app/api/ieditable-inline';
import { InlineEditService } from 'src/app/services/inline-edit.service';
import { PerformanceBodDataService } from 'src/app/services/performance-bod-data.service';
import * as Helpers from 'src/app/_shared/helpers';

@Injectable({
  providedIn: 'root',
})
export class WaterScopeWaterDisposalService extends InlineEditService implements IEditableInline {
  public data: IPerformanceBodWSWaterDisposal[] = [];
  public originalData: IPerformanceBodWSWaterDisposal[] = [];
  public createdItems: IPerformanceBodWSWaterDisposal[] = [];
  public updatedItems: IPerformanceBodWSWaterDisposal[] = [];
  public deletedItems: IPerformanceBodWSWaterDisposal[] = [];
  public performanceBodId: string;

  constructor(private performanceBodDataService: PerformanceBodDataService) {
    super();
  }

  public isNew(item: any): boolean {
    return !item.id;
  }

  public itemIndex(item: any, data: IPerformanceBodWSWaterDisposal[]): number {
    for (let idx = 0; idx < data.length; idx++) {
      if (data[idx].id === item.id) {
        return idx;
      }
    }
    return -1;
  }

  public create(): Observable<any> {
    return this.performanceBodDataService.createPerformanceBodWSWaterDisposal(this.createdItems);
  }

  public retrieve(): Observable<any> {
    return this.performanceBodDataService.getPerformanceBodWSWaterDisposal(this.performanceBodId).pipe(
      map(data => {
        data.forEach(element => {
          if (element.contractStartDate !== null && element.contractStartDate !== undefined) {
            element.contractStartDate = Helpers.convertUtcToLocalTime(element.contractStartDate);
          } else {
            element.contractStartDate = undefined;
          }
          if (element.contractEndDate !== null && element.contractEndDate !== undefined) {
            element.contractEndDate = Helpers.convertUtcToLocalTime(element.contractEndDate);
          } else {
            element.contractEndDate = undefined;
          }
        });
        return data;
      })
    );
  }

  public update(): Observable<any> {
    return this.performanceBodDataService.updatePerformanceBodWSWaterDisposal(this.updatedItems);
  }

  public delete(): Observable<any> {
    return this.performanceBodDataService.updatePerformanceBodWSWaterDisposal(this.deletedItems);
  }

  public validateRangeNumberValue(value: number): boolean {
    if (value >= 0 && value <= 1000000) {
      return true;
    } else {
      return false;
    }
  }
}
