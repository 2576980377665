import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import uniqBy from 'lodash.uniqby';
import { ScenarioStoreService } from '../../../services/scenario-store.service';
import { PadContingencyAssumptions } from '../../../api/padContingencyAssumptions';
import { ContingencyModel } from './contingency-model';
import { PermissionsProvider } from '../../../services/permissions.provider';

@Component({
  selector: 'app-contingency',
  templateUrl: './contingency.component.html',
  styleUrls: ['./contingency.component.css'],
})
export class ContingencyComponent implements OnInit {
  public canEditCnd: boolean = false;
  // list of padcontingencies retrived from api
  pcAssumptions: PadContingencyAssumptions[] = [];
  // Array grouped by answers  to fill the dropdownlists
  listEstimateDescription: string[] = [];
  listNumberOfDevWells: string[] = [];
  listEstimateTimeFrame: string[] = [];
  // Temp result contingency
  lstResult: PadContingencyAssumptions[] = [];
  // value of selected answers
  estimateDescription = '';
  numberOfDevWells = '';
  estimateTimeFrame = '';
  valuePercentage: number;
  notes: UntypedFormControl = new UntypedFormControl('');
  contingencyData: ContingencyModel;

  constructor(private scenarioStoreService: ScenarioStoreService, private permissionsProvider: PermissionsProvider) {}

  ngOnInit() {
    this.canEditCnd = this.permissionsProvider.canEditCnd;
    this.scenarioStoreService.contingencyOptions$.subscribe((data: PadContingencyAssumptions[]) => {
      this.pcAssumptions = data;
      this.listEstimateDescription = uniqBy(this.pcAssumptions, 'estimateDescription').map(c => c.estimateDescription);
      this.listNumberOfDevWells = uniqBy(this.pcAssumptions, 'numberOfDevWells').map(c => c.numberOfDevWells);
      this.listEstimateTimeFrame = uniqBy(this.pcAssumptions, 'estimateTimeFrame').map(c => c.estimateTimeFrame);
    });
    this.scenarioStoreService.contingency$.subscribe(data => {
      this.contingencyData = data;
      this.notes.setValue(data.notes, { emitEvent: false });
    });
    this.notes.valueChanges.subscribe(value => {
      this.contingencyData.notes = value;
      this.setValuePercentage();
    });
  }

  handleChangeEstimate(value) {
    this.contingencyData.estimateDescription = value;
    this.setValuePercentage();
  }

  handleChangeNumWells(value) {
    this.contingencyData.numberOfDevWells = value;
    this.setValuePercentage();
  }

  handleChangeTimeFrame(value) {
    this.contingencyData.estimateTimeFrame = value;
    this.setValuePercentage();
  }

  setValuePercentage() {
    if (
      this.contingencyData.estimateDescription !== '' &&
      this.contingencyData.numberOfDevWells !== '' &&
      this.contingencyData.estimateTimeFrame !== ''
    ) {
      const pcResult = this.pcAssumptions.find(
        pc =>
          pc.estimateDescription === this.contingencyData.estimateDescription &&
          pc.numberOfDevWells === this.contingencyData.numberOfDevWells &&
          pc.estimateTimeFrame === this.contingencyData.estimateTimeFrame
      );
      if (pcResult !== undefined) {
        this.contingencyData.valuePercentage = pcResult.contingencyPercentage;
      } else {
        this.contingencyData.valuePercentage = 0.0;
      }
    } else {
      this.contingencyData.valuePercentage = 0.0;
    }
    this.scenarioStoreService.calculatedContingencyChanged(this.contingencyData);
  }
}
