import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { IFitCcBaseColumn } from '../../models/fit-cc-ibase-column';
import { FitCcInputTypes } from '../fit-cc-input/models/fit-cc-input-types.enum';

@Component({
  selector: 'fit-cc-form-control',
  templateUrl: './fit-cc-form-control.component.html',
  styleUrls: ['./fit-cc-form-control.component.scss'],
})
export class FitCcFormControlComponent implements OnInit {
  @Input() column: IFitCcBaseColumn;
  @Input() columns: IFitCcBaseColumn[];
  @Input() form: UntypedFormGroup;
  @Input() isNew: boolean;

  types = FitCcInputTypes;

  disabledControl: boolean = false;

  constructor() {}
  get isValid() {
    return this.form.controls[this.column.field].valid;
  }

  get isDirty() {
    return this.form.controls[this.column.field].dirty;
  }

  get isTouched() {
    return this.form.controls[this.column.field].touched;
  }

  ngOnInit(): void {
    this.disabledControl = false;

    if (this.column.disabledEdit && !this.isNew) {
      this.disabledControl = true;
    }

    if (this.column.disabledNew && this.isNew) {
      this.disabledControl = true;
    }
  }

  handledropDownChange(dpValue, form) {
    let item: any;
    if (this.column.type == 'dropdown') {
      item = this.column.options.find(x => x.value === dpValue);

      if (this.column.dependencyColumns && item != null) {
        this.column.dependencyColumns.forEach(element => {
          form.controls[element].setValue(undefined);
          let dependencyCol: IFitCcBaseColumn = this.columns.find(x => x.field === element);
          if (dependencyCol && dependencyCol.type == 'dropdown') {
            let options = dependencyCol.optionstemp;
            dependencyCol.options = options ? options.filter(x => x.dependencyField == dpValue) : dependencyCol.options;
          }
        });
      }
    }

    if (this.column.nameColumnRelated != undefined && item != null) {
      let column = this.column.nameColumnRelated;
      form.controls[column].setValue(item.text);
    }

    if (this.column.setOtherField) {
      this.column.setOtherField.forEach(element => {
        form.controls[element].setValue(item.setOtherField);
      });
    }
  }
}
