import { Injectable, Renderer2, NgZone } from '@angular/core';
import { Subscription, fromEvent } from 'rxjs';
import { tap, take } from 'rxjs';
const tableRow = (node: { tagName: string }) => {
  return node.tagName.toLowerCase() === 'tr';
};

const closest = (node, predicate) => {
  while (node && !predicate(node)) {
    node = node.parentNode;
  }

  return node;
};

@Injectable()
export class DragAndDropService {
  private isLoadComplete = false;
  private currentSubscription: Subscription;
  private draggableDataSource: any[] = [];
  private callBackDragAndDropEnd: any;
  private draggableSelector: string;
  private isStopped = false;
  private rowClick: boolean;
  constructor(private renderer: Renderer2, private zone: NgZone) {
    // nothing to do here
  }

  public initialize(dataSource: any[], selector: string, callBackDragEnd: () => any): void {
    this.draggableDataSource = dataSource;
    this.draggableSelector = selector;
    this.callBackDragAndDropEnd = callBackDragEnd;
  }

  public dataSourceRefreshed(dataSource: any[]): void {
    this.draggableDataSource = dataSource;
  }

  public handleDragAndDrop(): Subscription {
    if (this.isStopped) {
      return;
    }
    const sub = new Subscription(() => {
      // empty
    });

    const editRow = Array.from(document.querySelectorAll(this.draggableSelector + ' tbody .k-grid-edit-row'));
    if (editRow.length > 0) {
      this.isLoadComplete = false;
      return;
    }

    let draggedItemIndex: number;

    const tableRows = Array.from(document.querySelectorAll(this.draggableSelector + ' tbody tr'));
    tableRows.forEach((item: any) => {
      this.renderer.setAttribute(item, 'draggable', 'true');
      const dragStart = fromEvent<DragEvent>(item, 'dragstart');
      const dragOver = fromEvent(item, 'dragover');
      const dragEnd = fromEvent(item, 'dragend');
      sub.add(
        dragStart
          .pipe(
            tap(({ dataTransfer }) => {
              try {
                const dragImgEl = document.createElement('span');
                dragImgEl.setAttribute(
                  'style',
                  'position: absolute: display: block; top: 0; left; 0;' + 'width: 0; height: 0;'
                );
                document.body.appendChild(dragImgEl);
                dataTransfer.setDragImage(dragImgEl, 0, 0);
              } catch (error) {
                // IE doesn't support setDragImage
              }

              try {
                // Firefox won't drag without setting data
                dataTransfer.setData('application/json', '');
              } catch (error) {
                // IE doesn't support MIME types in setData
              }
            })
          )
          .subscribe(({ target }) => {
            const row: HTMLTableRowElement = target as HTMLTableRowElement;
            draggedItemIndex = row.rowIndex;
            const dataItem = this.draggableDataSource[draggedItemIndex];
            dataItem.dragging = true;
          })
      );

      sub.add(
        dragOver.subscribe((e: any) => {
          e.preventDefault();
          const dataItem = this.draggableDataSource.splice(draggedItemIndex, 1)[0];
          const dropIndex = closest(e.target, tableRow).rowIndex;
          const dropItem = this.draggableDataSource[dropIndex];

          draggedItemIndex = dropIndex;
          this.zone.run(() => {
            this.draggableDataSource.splice(dropIndex, 0, dataItem);
          });
        })
      );

      sub.add(
        dragEnd.subscribe((e: any) => {
          e.preventDefault();
          const dataItem = this.draggableDataSource[draggedItemIndex];
          this.callBackDragAndDropEnd();
          dataItem.dragging = false;
        })
      );
    });

    return sub;
  }

  public refreshDragAndDrop(): void {
    if (this.currentSubscription) {
      this.currentSubscription.unsubscribe();
    }

    this.isLoadComplete = false;
  }

  public startDragAndDrop(): void {
    this.isStopped = false;
  }
  public stopDragAndDrop(): void {
    this.isStopped = true;
  }

  public checkDraggableElementLoaded(): void {
    if (!this.isLoadComplete) {
      const draggableElement = document.querySelectorAll(this.draggableSelector + ' tbody tr');
      if (this.draggableDataSource.length > 0 && draggableElement.length > 0) {
        if (this.draggableDataSource.length === draggableElement.length) {
          this.isLoadComplete = true;
          this.currentSubscription = this.handleDragAndDrop();
        }
      }
    }
  }

  public runZoneOnStable(): void {
    this.zone.onStable.pipe(take(1)).subscribe(() => {
      this.isLoadComplete = true;
      this.currentSubscription = this.handleDragAndDrop();
    });
  }

  public onEditingMode(): void {
    this.rowClick = true;
  }

  public onEditingClose(): void {
    this.rowClick = false;
  }

  public checkIsOnEditMode(): void {
    if (this.rowClick) {
      this.rowClick = false;
      this.refreshDragAndDrop();
    }
  }
}
