import { Observable, empty, of } from 'rxjs';
import { Injectable, EventEmitter } from '@angular/core';
import { IEditableInline } from 'src/app/api/ieditable-inline';
import { IPerformanceBodWellArtificialLift } from 'src/app/api/performance-bod-well-artificial-lift';
import { InlineEditService } from 'src/app/services/inline-edit.service';
import { PerformanceBodDataService } from 'src/app/services/performance-bod-data.service';
import { PerformanceBodStoreService } from 'src/app/services/performance-bod-store.service';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InfrastructureWellArtificialLiftService extends InlineEditService implements IEditableInline {
  public data: IPerformanceBodWellArtificialLift[] = [];
  public originalData: IPerformanceBodWellArtificialLift[] = [];
  public createdItems: IPerformanceBodWellArtificialLift[] = [];
  public updatedItems: IPerformanceBodWellArtificialLift[] = [];
  public deletedItems: IPerformanceBodWellArtificialLift[] = [];

  public performanceBodId: string;
  public completed: EventEmitter<boolean>;

  constructor(
    private performanceBodDataService: PerformanceBodDataService,
    private performanceBodStoreService: PerformanceBodStoreService
  ) {
    super();
    this.performanceBodStoreService.performanceBodId$.subscribe(id => {
      this.performanceBodId = id;
    });
  }

  public isNew(item: any): boolean {
    return !item.id;
  }

  public itemIndex(item: any, data: IPerformanceBodWellArtificialLift[]): number {
    for (let idx = 0; idx < data.length; idx++) {
      if (data[idx].id === item.id) {
        return idx;
      }
    }
    return -1;
  }

  public create(): Observable<any> {
    return this.performanceBodDataService.createPerformanceBodWellArtificialLift(this.createdItems);
  }

  public retrieve(): Observable<any> {
    return this.performanceBodDataService.getPerformanceBodWellArtificialLift(this.performanceBodId).pipe(
      map(wellArtificialLift => {
        return wellArtificialLift;
      })
    );
  }

  public update(): Observable<any> {
    return this.performanceBodDataService.updatePerformanceBodWellArtificialLift(this.updatedItems);
  }

  public delete(): Observable<any> {
    return this.performanceBodDataService.updatePerformanceBodWellArtificialLift(this.deletedItems);
  }
}
