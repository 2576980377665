import { Observable } from 'rxjs';
import { Component, OnInit, Inject, Input, ViewContainerRef, ViewChild } from '@angular/core';
import { UpperCasePipe } from '@angular/common';
import { State as stateList } from 'src/app/api/state';
import { GeographicArea } from 'src/app/api/geographicArea';
import { Basin } from 'src/app/api/basin';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { ScenarioStoreService } from 'src/app/services/scenario-store.service';
import {
  State,
  process,
  CompositeFilterDescriptor,
  filterBy,
  distinct,
  SortDescriptor,
  FilterDescriptor,
} from '@progress/kendo-data-query';

import { map } from 'rxjs';
import { Subscription } from 'rxjs';

import { FitCcBaseModel } from '../../models/fit-cc-base-model';
import { FitCcBaseService } from '../fit-cc-base.service';

import { FitCcBaseColumn } from '../../models/fit-cc-base-column';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
@Component({
  selector: 'fit-cc-grid',
  templateUrl: './fit-cc-grid.component.html',
  styleUrls: ['./fit-cc-grid.component.css'],
})
export class FitCcGridComponent implements OnInit {
  @Input() columns: any[] = FitCcBaseColumn;
  @Input() gridService: FitCcBaseService<FitCcBaseModel>;
  @Input() editType = 'popup';
  @Input() deleteAction = false;
  @Input() filterable = true;
  @Input() persist = false;
  @Input() canAddNewRecord = true;
  @Input() selectedLogTable: string;
  public stateOptions: stateList[];
  public geographicAreaOptions: GeographicArea[];
  public basinOptions: Basin[];
  private originalColumns: any[];

  public view: Observable<any[] | GridDataResult>;
  public basicState: State = {
    sort: new Array<SortDescriptor>(),
    skip: 0,
    take: 10,
    filter: undefined,
  };

  public editDataItem: FitCcBaseModel;
  public itemSelected: FitCcBaseModel;
  public isNew: boolean;
  public baseService: FitCcBaseService<FitCcBaseModel>;
  private editForm: any;
  formGroup: any;
  public filter: CompositeFilterDescriptor;

  @ViewChild('dialogContainer', { read: ViewContainerRef })
  public dialogContainerRef: ViewContainerRef;

  constructor(private formBuilder: UntypedFormBuilder, private scenarioStoreService: ScenarioStoreService) {}

  public ngOnInit(): void {
    this.baseService = this.gridService;
  }

  public loadGrid(pgridService: FitCcBaseService<FitCcBaseModel>, pcolumns: any[]): void {
    this.createFormGroup = this.createFormGroup.bind(this);
    this.baseService = pgridService;
    this.columns = pcolumns;
    this.baseService.read();
    this.view = this.baseService;

    this.originalColumns = this.columns;
    if (this.baseService.loadPersistedSettings()) {
      this.baseService.persistedSettings = true;
      this.columns = this.baseService.loadPersistedSettings();
    }
  }
  public onStateChange(state: State) {
    this.baseService.gridState = state;
    this.baseService.read();
    if (this.persist) {
      let s = Object.assign({}, state);
      this.baseService.persistSettings(this.columns, s);
    }
  }

  public add() {
    if (!this.baseService.hasChanges()) {
      this.editDataItem = new FitCcBaseModel();
      this.itemSelected = new FitCcBaseModel();
      this.isNew = true;
    } else {
      this.baseService.showDialog(this.dialogContainerRef, true);
    }
  }

  public editHandler({ dataItem }) {
    this.editDataItem = dataItem;
    this.itemSelected = dataItem;
    this.isNew = false;
  }

  public cancelHandler() {
    this.editDataItem = undefined;
    this.itemSelected = undefined;
  }

  public saveHandler(item) {
    let itemEdited: any;
    itemEdited = Object.assign(this.itemSelected, item);
    this.scenarioStoreService.stateOptions$.subscribe(data => {
      this.stateOptions = data;
    });
    this.scenarioStoreService.geographicArea$.subscribe(data => {
      this.geographicAreaOptions = data;
    });
    this.scenarioStoreService.basinOptions$.subscribe(data => {
      this.basinOptions = data;
    });

    this.baseService.save(itemEdited, this.isNew);
    if (itemEdited.stateId != undefined) {
      const state = this.stateOptions.find(s => s.id === itemEdited.stateId);
      itemEdited.state.name = state.name;
    }
    if (itemEdited.geographicAreaId != undefined) {
      const geographicArea = this.geographicAreaOptions.find(ga => ga.id === itemEdited.geographicAreaId);
      itemEdited.geographicArea.name = geographicArea.name;
    }
    if (itemEdited.basinId != undefined) {
      const basin = this.basinOptions.find(s => s.id === itemEdited.basinId);
      itemEdited.basin.name = basin.name;
    }

    this.editDataItem = undefined;
    this.itemSelected = undefined;
  }

  public saveChanges() {
    this.baseService.saveChanges();
  }

  public cancelChanges() {
    this.baseService.cancelChanges();
  }

  public removeHandler({ dataItem }) {
    if (this.baseService.hasChanges()) {
      this.baseService.showDialog(this.dialogContainerRef, true);
    } else {
      this.baseService.showDialog(this.dialogContainerRef, false, dataItem);
    }
  }

  public createFormGroup({ dataItem }): UntypedFormGroup {
    if (dataItem && this.editType === 'inline') {
      this.editForm = new UntypedFormGroup({});

      this.columns.forEach(column => {
        if (dataItem && column.type === 'date') {
          const date = dataItem[column.field] ? new Date(dataItem[column.field]) : null;
          dataItem[column.field] = date;
        }
        if (column.required) {
          this.editForm.addControl(column.field, new UntypedFormControl(dataItem[column.field] || '', [Validators.required]));
        } else {
          this.editForm.addControl(column.field, new UntypedFormControl(dataItem[column.field]));
        }
      });

      return this.editForm;
    }
    return null;
  }

  public cellClickHandler({ sender, rowIndex, columnIndex, dataItem, isEdited }) {
    if (this.editType === 'inline') {
      if (!isEdited) {
        this.formGroup = this.createFormGroup(dataItem);
        sender.editCell(rowIndex, columnIndex, this.formGroup);
      }
    }
  }

  public cellCloseHandler(args: any) {
    if (this.editType === 'inline') {
      const { formGroup, dataItem } = args;

      if (!formGroup.valid) {
        // prevent closing the edited cell if there are invalid values.
        args.preventDefault();
      } else if (formGroup.dirty) {
        const itemToUpdate = Object.assign({}, dataItem);
        Object.assign(itemToUpdate, formGroup.value);
        this.baseService.update(itemToUpdate);
      }
    }
  }

  filterableHandler = () => (this.filterable ? 'menu' : false);

  public getDropdownDisplay = (array: any[], item: any, field: any) => {
    return typeof item === 'object'
      ? array.find(x => x.value === item[field]).text
      : array.find(x => x.value === item).text;
  };

  public filterChange(filter: CompositeFilterDescriptor): void {
    this.baseService.replaceFilterOperator(filter);
    this.baseService.filterChange(filter);
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.baseService.sortChange(sort);
  }

  public activeSort(): boolean {
    return !!this.baseService.getCurrentState().sort.find(x => x.dir !== undefined);
  }

  public activeFilter(field?: string): boolean {
    let result =
      !!this.baseService.getCurrentState().filter && this.baseService.getCurrentState().filter.filters.length > 0;

    if (result && field) {
      const col = this.getFilteredColumns();

      if (col) {
        result = result && col.find(x => x.field === field);
      }
    }
    return result;
  }

  public getFilteredColumns(): any[] {
    let result = [];

    if (this.baseService.getCurrentState().filter) {
      this.baseService.getCurrentState().filter.filters.forEach((f: CompositeFilterDescriptor) => {
        f.filters.forEach((fd: FilterDescriptor) => {
          const c = this.columns.find(x => x.field === fd.field);
          if (c) {
            result.push(c);
          }
        });
      });
    }

    return result;
  }

  public getSortDescription() {
    let desc = [];
    if (this.baseService.getCurrentState().sort) {
      this.baseService.getCurrentState().sort.forEach(s => {
        const c = this.columns.find(x => x.field === s.field);
        if (s.dir && c) {
          desc.push(`${c.title} ${s.dir.toLocaleUpperCase()}`);
        }
      });
    }
    return desc;
  }

  public getFilterDescription() {
    let desc = [];
    let col = this.getFilteredColumns();
    col.forEach(x => {
      if (!desc.find(i => i === `${x.title}`)) {
        desc.push(`${x.title}`);
      }
    });
    return desc;
  }

  public onResize(e: any): void {
    e.forEach(item => {
      this.columns.find(col => col.field === item.column.field).width = item.newWidth;
    });

    if (this.persist) {
      this.baseService.persistSettings(this.columns);
    }
  }

  public onVisibilityChange(e: any): void {
    e.columns.forEach(column => {
      let field = this.columns.find(col => col.field === column.field);
      field.hidden = column.hidden;
      field.hiddenInGrid = column.hidden;
    });
    if (this.persist) {
      this.baseService.persistSettings(this.columns);
    }
  }

  public clearPersist() {
    this.columns = this.originalColumns;
    this.baseService.gridState = this.basicState;
    this.baseService.persistedSettings = false;
    localStorage.removeItem(this.baseService.token);
    this.baseService.publish();
  }

  public pageChange(event: PageChangeEvent): void {
    this.baseService.pageChange(event);
  }

  public getString(item: any, col: any) {
    if (item[col.field] === null) {
      return null;
    }
    if (typeof item[col.field] === 'object') {
      return this.getString(item[col.field], col.inner);
    }

    if (typeof item[col.field] === 'string' || typeof item[col.field] === 'number') {
      return item[col.field];
    }
  }
}
