import { Component, EventEmitter, OnInit, Output, ViewChild, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs';
import { GridDataResult, RowClassArgs } from '@progress/kendo-angular-grid';
import { State, process, CompositeFilterDescriptor, filterBy } from '@progress/kendo-data-query';
import { IPerformanceBodFacilityPad } from 'src/app/api/performance-bod-facility-pad';
import { PerformanceBodStoreService } from 'src/app/services/performance-bod-store.service';
import { InfrastructureFacilityPadsService } from './infrastructure-facility-pads.service';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from '@progress/kendo-angular-notification';
import { IPerformanceBodPackages } from 'src/app/api/performance-bod-package';
import { UtilService } from 'src/app/services/util.service';
import { PermissionsProvider } from 'src/app/services/permissions.provider';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DragAndDropService } from 'src/app/_shared/drag-and-drop.service';

const DRAGGABLE_SELECTOR = '.infrastructure-facility-pads-draggable';

@Component({
  selector: 'app-infrastructure-facility-pads',
  templateUrl: './infrastructure-facility-pads.component.html',

})
export class InfrastructureFacilityPadsComponent implements OnInit {
  public view: Observable<GridDataResult>;
  public performanceBodId: string;
  public formGroup: UntypedFormGroup;
  public messageDuplicate = true;
  public gridState: State = {
    sort: [],
    skip: 0,
  };
  public filter: CompositeFilterDescriptor;
  public filterable: boolean = true;
  public canEditPerformanceBod: boolean = false;
  public performanceBodPackagesList: IPerformanceBodPackages[];
  public orientationList: string[] = [];
  @Output() public performanceBodUpdate = new EventEmitter<string>();
  @Output() public facilityPadCreate = new EventEmitter<any>();
  @Output() public saveBodCompleted = new EventEmitter<boolean>();

  @ViewChild('performanceBodFacilityPadGrid')
  public infrastructureFacilityPadGrid: any;
  public infrastructureFacilityPadsService: InfrastructureFacilityPadsService;
  private dragAndDropService: DragAndDropService;

  constructor(
    private performanceBodStoreService: PerformanceBodStoreService,
    private formBuilder: UntypedFormBuilder,
    private spinner: NgxSpinnerService,
    private notificationService: NotificationService,
    private utilService: UtilService,
    private permissionsProvider: PermissionsProvider,
    private authenticationService: AuthenticationService,
    @Inject(DragAndDropService) dragAndDropServiceFactory: any,
    @Inject(InfrastructureFacilityPadsService) infrastructureFacilityPadsServiceFactory: any
  ) {
    this.dragAndDropService = dragAndDropServiceFactory();
    this.infrastructureFacilityPadsService = infrastructureFacilityPadsServiceFactory();
  }

  public ngOnInit(): void {    
    this.performanceBodStoreService.performanceBodSignatured$.subscribe((isSignature) => {
      this.canEditPerformanceBod = isSignature? false: this.permissionsProvider.canEditPerformanceBod;
    });
    // Set the performance bod identification
    this.performanceBodStoreService.performanceBodId$.subscribe(id => {
      this.performanceBodId = id;
      this.infrastructureFacilityPadsService.performanceBodId = id;
      // Get async array
      this.infrastructureFacilityPadsService.read();
    });

    // Used to load the view grid
    this.view = this.infrastructureFacilityPadsService.pipe(
      map(data => {
        this.dragAndDropService.dataSourceRefreshed(data);
        return process(filterBy(data, this.filter), this.gridState);
      })
    );

    // Set packages
    this.performanceBodStoreService.performanceBodPackagesData$.subscribe(data => {
      this.performanceBodPackagesList = data;
    });

    // Set orientation
    this.performanceBodStoreService.orientationData$.subscribe(data => {
      this.orientationList = data;
    });

    this.dragAndDropService.initialize(this.infrastructureFacilityPadsService.data, DRAGGABLE_SELECTOR, () => {
      this.infrastructureFacilityPadsService.markAsSetOrder(this.authenticationService.getCurrentUser());
    });
  }

  public ngAfterViewInit(): void {
    // To Do
  }

  public ngOnDestroy(): void {
    this.dragAndDropService.refreshDragAndDrop();
  }

  public createFormGroup(dataItem: any): UntypedFormGroup {
    return this.formBuilder.group({
      id: new UntypedFormControl(dataItem.id),
      performanceBodId: new UntypedFormControl(dataItem.performanceBodId),
      performanceBodPackagesId: new UntypedFormControl(dataItem.performanceBodPackagesId, [Validators.required]),
      name: new UntypedFormControl(dataItem.name, [Validators.required, Validators.minLength(1), Validators.maxLength(100)]),
      padRfsd: new UntypedFormControl(dataItem.padRfsd, [Validators.required]),
      padDimensions: new UntypedFormControl(dataItem.padDimensions, [Validators.minLength(0), Validators.maxLength(100)]),
      permitsRequiredBy: [dataItem.permitsRequiredBy],
      padOrientation: new UntypedFormControl(dataItem.padOrientation),
      comments: new UntypedFormControl(dataItem.comments, [Validators.maxLength(500)]),
      isDeleted: new UntypedFormControl(dataItem.isDeleted),
      createdBy: new UntypedFormControl(dataItem.createdBy),
      createdDate: new UntypedFormControl(dataItem.createdDate),
      updatedBy: new UntypedFormControl(dataItem.updatedBy),
      updatedDate: new UntypedFormControl(dataItem.updatedDate),
      chargeCode: new UntypedFormControl(dataItem.chargeCode, [
        Validators.maxLength(13),
        Validators.pattern('^[a-zA-Z0-9]{0,13}$'),
      ]),
      cndHOEstimate: new UntypedFormControl(dataItem.cndHOEstimate, [Validators.min(0), Validators.max(999999999999)]),
      afeNumber: new UntypedFormControl(dataItem.afeNumber, [
        Validators.maxLength(13),
        Validators.pattern('^[a-zA-Z0-9]{0,13}$'),
      ]), 
      afeAmount: new UntypedFormControl(dataItem.afeAmount, [
        Validators.maxLength(8),
        Validators.pattern('^[a-zA-Z0-9]{0,8}$'),
      ]), 
    });
  }

  public cellClickHandler({ sender, rowIndex, columnIndex, dataItem, isEdited }) {
    if (!isEdited && this.canEditPerformanceBod) {
      if (dataItem.bodLockdownDate !== undefined && columnIndex === 5) {
        if (dataItem.bodLockdownDate !== null) {
          const y = new Date(dataItem.bodLockdownDate.toString()).getFullYear();
          const m = new Date(dataItem.bodLockdownDate.toString()).getMonth();
          const d = new Date(dataItem.bodLockdownDate.toString()).getDate();
          dataItem.bodLockdownDate = new Date(y, m, d);
        }
      }

      sender.editCell(rowIndex, columnIndex, this.createFormGroup(dataItem));
      this.dragAndDropService.onEditingMode();
    }
  }

  public cellCloseHandler(args: any) {
    const { formGroup, dataItem, column } = args;

    let columnField = '';

    if (column.field === 'performanceBodPackagesName') {
      columnField = 'performanceBodPackagesId';
    } else {
      columnField = column.field;
    }

    const checkColumn = formGroup.controls[columnField];

    if (!checkColumn.valid) {
      args.preventDefault();
    } else if (checkColumn.dirty) {
      if (columnField === 'performanceBodPackagesId') {
        dataItem.performanceBodPadsName = this.getPerformanceBodPackages(formGroup.controls[columnField].value);
      }
    }

    if (!formGroup.valid) {
      // prevent closing the edited cell if there are invalid values.
      args.preventDefault();
    } else if (formGroup.dirty) {
      if (formGroup.valid && args.sender.data.total > 0) {
        for (let i = 0; i <= args.sender.data.total - 1; i++) {
          if (
            args.sender.data.data[i].name === formGroup.value.name.trim() &&
            args.sender.data.data[i].performanceBodPackagesId === formGroup.value.performanceBodPackagesId &&
            args.sender.data.data[i].id !== formGroup.value.id
          ) {
            this.messageDuplicate = false;
            args.preventDefault();
            return;
          } else {
            this.messageDuplicate = true;
          }
        }
      }

      if (this.messageDuplicate) {
        dataItem.updatedDate = new Date();
        dataItem.updatedBy = this.authenticationService.getCurrentUser();

        // Date is not working in Object.assign(), so need to be do it manually
        formGroup.value.updatedBy = dataItem.updatedBy;
        formGroup.value.updatedDate = dataItem.updatedDate;
        formGroup.value.name = formGroup.value.name.trim();
        this.infrastructureFacilityPadsService.assignValues(dataItem, formGroup.value);
        this.infrastructureFacilityPadsService.updateItem(dataItem);
      }
    }

    // calls this to add the attribute of current row
    this.dragAndDropService.onEditingClose();
    this.dragAndDropService.refreshDragAndDrop();
  }

  public addHandler({ sender }) {
    const item: IPerformanceBodFacilityPad = {
      id: null,
      // performanceBodId: this.performanceBodId,
      performanceBodPackagesId: null,
      name: '',
      padRfsd: null,
      padDimensions: null,
      permitsRequiredBy: null,
      padOrientation: '',
      comments: '',
      isDeleted: false,
      createdBy: this.authenticationService.getCurrentUser(),
      createdDate: new Date(),
      updatedBy: this.authenticationService.getCurrentUser(),
      updatedDate: new Date(),
      sortOrder: 0,
      chargeCode: '',
      cndHOEstimate: 0,
      afeNumber:'',
      afeAmount: 0,
    };

    // item.performanceBodId = this.performanceBodId;
    // item.createdBy = this.scenarioStoreService.getCurrUser();
    // item.createdDate = this.scenarioStoreService.getDateUTC(new Date());
    // item.updatedBy = this.scenarioStoreService.getCurrUser();
    // item.updatedDate = this.scenarioStoreService.getDateUTC(new Date());

    sender.addRow(this.createFormGroup(item));
    this.dragAndDropService.onEditingMode();
  }

  public cancelHandler({ sender, rowIndex }) {
    this.messageDuplicate = true;
    sender.closeRow(rowIndex);
    this.dragAndDropService.onEditingClose();
    this.dragAndDropService.refreshDragAndDrop();
  }

  public saveHandler({ sender, formGroup, rowIndex }) {
    if (formGroup.valid && sender.data.total > 0) {
      for (let i = 0; i <= sender.data.total - 1; i++) {
        if (
          sender.data.data[i].name === formGroup.value.name.trim() &&
          sender.data.data[i].performanceBodPackagesId === formGroup.value.performanceBodPackagesId &&
          sender.data.data[i].id !== formGroup.value.id
        ) {
          this.messageDuplicate = false;
          return;
        } else {
          this.messageDuplicate = true;
        }
      }
    }

    if (this.messageDuplicate) {
      if (formGroup.valid) {
        /*formGroup.patchValue({
          performanceBodId: this.performanceBodId,
        });*/
        formGroup.value.name = formGroup.value.name.trim();
        formGroup.value.id = Guid.create().toString();
        this.infrastructureFacilityPadsService.createItem(formGroup.value);
        sender.closeRow(rowIndex);
        this.dragAndDropService.onEditingClose();
        this.dragAndDropService.dataSourceRefreshed(this.infrastructureFacilityPadsService.data);
        this.dragAndDropService.refreshDragAndDrop();
        this.infrastructureFacilityPadsService.markAsSetOrder(this.permissionsProvider.cai);
      } else {
        this.utilService.markFormGroupAsTouched(formGroup);
      }
    }
  }

  public removeHandler({ sender, dataItem }) {
    // dataItem.isDeleted = true;

    this.infrastructureFacilityPadsService.removeItem(dataItem);
    sender.cancelCell();
    // this.isLoading = false;
  }

  public saveChanges(grid: any): void {
    grid.closeCell();
    grid.cancelCell();
    this.spinner.show();
    // calls this to add the attribute of current row
    this.dragAndDropService.refreshDragAndDrop();
    this.infrastructureFacilityPadsService.saveChanges();
    this.performanceBodUpdate.next(this.authenticationService.getCurrentUser());

    setTimeout(() => {
      this.spinner.hide();
      this.notificationService.show({
        content: 'Infrastructure - Facility Pads saved',
        position: { horizontal: 'center', vertical: 'top' },
        animation: { type: 'fade', duration: 500 },
        type: { style: 'success', icon: true },
        hideAfter: 700,
      });
    }, 3000);
  }

  public saveFromBOD(): void {
    this.infrastructureFacilityPadGrid.closeCell();
    this.infrastructureFacilityPadGrid.cancelCell();

    if (this.infrastructureFacilityPadsService.hasChanges()) {
      // calls this to add the attribute of current row
      this.dragAndDropService.refreshDragAndDrop();
      this.infrastructureFacilityPadsService.saveChanges();
      this.performanceBodUpdate.next(this.authenticationService.getCurrentUser());
      const subscription = this.infrastructureFacilityPadsService.subscribe(() => {
        this.saveBodCompleted.next(true);
      });

      subscription.unsubscribe();
    }
  }

  public cancelChanges(grid: any): void {
    this.messageDuplicate = true;
    grid.cancelCell();
    this.dragAndDropService.refreshDragAndDrop();
    this.infrastructureFacilityPadsService.cancelChanges();
  }

  public handlerFilter(filter: CompositeFilterDescriptor): void {
    if (filter.filters) {
      if (filter.filters.length > 0) {
        this.dragAndDropService.onEditingMode();
      } else {
        this.dragAndDropService.onEditingClose();
        this.dragAndDropService.refreshDragAndDrop();
      }
    }
    this.filter = filter;
  }

  public onStateChange(state: State) {
    this.gridState = state;
    this.infrastructureFacilityPadsService.read();

    // const viewSubscription = this.view.subscribe(data => {
    //   this.dragAndDropService.dataSourceRefreshed(data.data);
    // });

    // viewSubscription.unsubscribe();
    // this.dragAndDropService.runZoneOnStable();
  }

  public getPerformanceBodPackages(id: string): any {
    if (this.performanceBodPackagesList === null || this.performanceBodPackagesList === undefined) {
      return '';
    }
    this.performanceBodPackagesList = this.performanceBodPackagesList.filter(x => x.id !== null && x.id !== undefined);
    return this.performanceBodPackagesList.find(x => x.id === id);
  }

  public saveFromPerformanceBOD(): boolean {
    this.infrastructureFacilityPadsService.saveChanges();
    return this.infrastructureFacilityPadsService.hasChanges();
  }

  public rowCallback(context: RowClassArgs) {
    return {
      dragging: context.dataItem.dragging,
    };
  }

  public rowLoaded(): void {
    // to check until grid have been loeaded.
    this.dragAndDropService.checkIsOnEditMode();
    this.dragAndDropService.checkDraggableElementLoaded();
  }

  public changePackage(value, dataItem: IPerformanceBodFacilityPad, formGroup: UntypedFormGroup) {
    const performanceBodPackages = this.performanceBodPackagesList.find(s => s.id === value);

    if (!dataItem.id) {
      dataItem.performanceBodPackagesId = performanceBodPackages ? performanceBodPackages.id : null;
      this.facilityPadCreate.next(dataItem);
      formGroup.patchValue({
        permitsRequiredBy: dataItem.permitsRequiredBy,
      });
      formGroup.patchValue({
        padRfsd: dataItem.padRfsd,
      });
    }
  }
}
