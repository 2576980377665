<kendo-splitter orientation="vertical">  <!--style="height: 787px;"-->
  <kendo-splitter-pane [collapsible]="true" size="55%">
    <kendo-splitter>
      <kendo-splitter-pane [collapsible]="true" size="50%">
        <div class="row no-gutters">
          <div class="col-sm-12">
            <div class="card-body">
              <p class="card-title">PRICET Scenario Summary</p>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">
                  Development Area
                </label>
                <div class="col-sm-8">
                  <input [formControl]="developmentArea" type="text" class="k-textbox form-control" />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">
                  Scenario Type
                </label>
                <div class="col-sm-8">
                  <input [formControl]="scenarioType" type="text" class="k-textbox form-control" />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">
                  Scenario Name
                </label>
                <div class="col-sm-8">
                  <textarea [formControl]="scenarioNameS"
                            type="text"
                            class="form-control"
                            rows="5"
                            style="height:50px; overflow:hidden;"></textarea>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">
                  Handover #
                </label>
                <div class="col-sm-3">
                  <input [formControl]="handover" type="text" class="k-textbox form-control" />
                </div>
                <label class="col-sm-2 col-form-label">
                  Contingency
                </label>
                <div class="col-sm-3">
                  <input [formControl]="contingency" type="text" class="k-textbox form-control" />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">
                  State
                </label>
                <div class="col-sm-3">
                  <input [formControl]="state" type="text" class="k-textbox form-control" />
                </div>
                <label class="col-sm-2 col-form-label">
                  Tax
                </label>
                <div class="col-sm-3">
                  <input [formControl]="tax" type="text" class="k-textbox form-control" />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">
                  Basin
                </label>
                <div class="col-sm-8">
                  <input [formControl]="basin" type="text" class="k-textbox form-control" />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">
                  Geographic Area
                </label>
                <div class="col-sm-8">
                  <input [formControl]="geographicArea" type="text" class="k-textbox form-control" />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">
                  PRICET Notes
                </label>
                <div class="col-sm-8">
                  <textarea [formControl]="pricetNotes"
                            class="form-control"
                            rows="3"
                            [readOnly]="!canEditCnd"></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
      </kendo-splitter-pane>
      <kendo-splitter-pane>
        <div class="row no-gutters">
          <div class="col-sm-12">
            <div class="card-body pad-smry">
              <p class="card-title">PadBuilder Summary</p>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">
                  Sequence Name
                </label>
                <div class="col-sm-8">
                  <input [formControl]="sequenceName" type="text" class="k-textbox form-control" />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">
                  PadBuilder Filename
                </label>
                <div class="col-sm-8">
                  <input [formControl]="fileName" type="text" class="k-textbox form-control" />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">
                  Last PB Save Date - SMART
                </label>
                <div class="col-sm-8">
                  <kendo-dateinput [format]="'dd-MMM-yyyy HH:mm:ss z'"
                                   [formControl]="modificationDate"
                                   class="form-control"></kendo-dateinput>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">
                  PadBuilder Forecast Notes
                </label>
                <div class="col-sm-8">
                  <textarea [formControl]="forecastNotes" type="text" class="form-control" rows="4"></textarea>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">
                  PadBuilder Rig Count
                </label>
                <div class="col-sm-8">
                  <input [formControl]="rigCount" type="text" class="k-textbox form-control" />
                </div>
              </div>
              <kendo-grid [data]="padbuilderRigsData">
                <kendo-grid-column title="Rig #">
                  <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                    {{ rowIndex + 1 }}
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="pbRigName" title="PB Rig Name">
                  <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                    {{ dataItem.pbRigName }}
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="startDate" title="Start Date">
                  <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                    {{ dataItem.startDate | date: 'MM/dd/yyyy' }}
                  </ng-template>
                </kendo-grid-column>
              </kendo-grid>
            </div>
          </div>
        </div>
      </kendo-splitter-pane>
    </kendo-splitter>
  </kendo-splitter-pane>
  <kendo-splitter-pane class="cd-smry-scroll-hide">
    <div class="card-body">
      <app-padbypad-summary [originalInputInfraData]="originalInputInfraData" [alternatePadCosts]="alternatePadCosts">
        >
      </app-padbypad-summary>
    </div>
  </kendo-splitter-pane>
</kendo-splitter>
