<kendo-grid
  #infrastructureRecycle
  [data]="view | async"
  (cellClick)="cellClickHandler($event)"
  (cellClose)="cellCloseHandler($event)"
  (cancel)="cancelHandler($event)"
  (save)="saveHandler($event)"
  (remove)="removeHandler($event)"
  (add)="addHandler($event)"
  [navigable]="true"
  [resizable]="true"
  [sort]="gridState.sort"
  [sortable]="{ mode: 'multiple' }"
  (dataStateChange)="onStateChange($event)"
  filterable="menu"
  [filter]="filter"
  (filterChange)="handlerFilter($event)"
  class="infrastructure-recycle-draggable"
  [rowClass]="rowCallback"
>
  <ng-template kendoGridToolbarTemplate>
    <button
      kendoButton
      class="k-button"
      [disabled]="!infrastructureRecycleService.hasChanges()"
      (click)="cancelChanges(infrastructureRecycle)"
      [primary]="true"
    >
      Cancel
    </button>
    <button [icon]="'plus'" kendoGridAddCommand [primary]="true" [disabled]="!canEditPerformanceBod"></button>
  </ng-template>
  <kendo-grid-column title="" field="" [hidden]="true" [width]="1">
    {{ rowLoaded() }}
  </kendo-grid-column>
  <kendo-grid-column field="performanceBodPackagesId" title="Package Name" [editable]="true">
    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
      <multicheck-filter
        [isPrimitive]="false"
        [field]="column.field"
        textField="name"
        valueField="id"
        [filterService]="filterService"
        [currentFilter]="filter"
        [data]="performanceBodPackagesList"
      >
      </multicheck-filter>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ getPerformanceBodPackages(dataItem.performanceBodPackagesId)?.name }}
    </ng-template>
    <ng-template
      kendoGridEditTemplate
      let-dataItem="dataItem"
      let-column="column"
      let-formGroup="formGroup"
      let-isNew="isNew"
    >
      <kendo-dropdownlist
        [data]="performanceBodPackagesList"
        [defaultItem]="{ id: null, name: 'Select Item...' }"
        textField="name"
        valueField="id"
        [valuePrimitive]="true"
        [formControl]="formGroup.get('performanceBodPackagesId')"
      >
      </kendo-dropdownlist>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="description" title="Recycle Description" [editable]="true">
    <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-column="column" let-formGroup="formGroup">
      <input #input elementFocus type="text" [formControl]="formGroup.get(column.field)" class="k-textbox" />
      <kendo-popup [anchor]="input" [hidden]="messageDuplicate" popupClass="k-widget k-tooltip k-tooltip-validation">
        The Recycle Description already exist.
      </kendo-popup>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="readyForServiceDate"
    title="Recycle RFSD"
    [editable]="true"
    editor="date"
    format="MM/dd/yyyy"
    [filterable]="filterable"
    filter="date"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
      <span>{{ dataItem.readyForServiceDate | date: 'MM/dd/yyyy' }}</span>
    </ng-template>
    <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-column="column" let-formGroup="formGroup">
      <kendo-datepicker
        [value]="dataItem.readyForServiceDate"
        [format]="'MM/dd/yyyy'"
        [formControl]="formGroup.get('readyForServiceDate')"
      ></kendo-datepicker>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="rotfFixRecycle" title="ROTF/Fixed Recycle" [editable]="true"></kendo-grid-column>
  <kendo-grid-column
    field="throughputMbwpd"
    title="Throughput (MBWPD)"
    [editable]="true"
    editor="numeric"
    filter="numeric"
  ></kendo-grid-column>
  <kendo-grid-column field="isPadRequired" title="Pad Required?" [editable]="true" [width]="120">
    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
      <multicheck-filter
        [isPrimitive]="false"
        [field]="column.field"
        textField="name"
        valueField="id"
        [filterService]="filterService"
        [currentFilter]="filter"
        [data]="selectOption"
      >
      </multicheck-filter>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ getSelectOption(dataItem.isPadRequired)?.name }}
    </ng-template>
    <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-column="column" let-formGroup="formGroup">
      <kendo-dropdownlist
        popupAnchor
        [data]="selectOption"
        textField="name"
        valueField="id"
        [valuePrimitive]="true"
        [formControl]="formGroup.get('isPadRequired')"
      ></kendo-dropdownlist>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="location" title="Location" [editable]="true"></kendo-grid-column>
  <kendo-grid-column field="maxTds" title="Max TDS" [editable]="true"></kendo-grid-column>
  <kendo-grid-column field="blendRadio" title="Blend Ratio" [editable]="true"></kendo-grid-column>
  <kendo-grid-column field="comments" title="Comments" [editable]="true" [width]="150"> </kendo-grid-column>
  <kendo-grid-command-column title="" [width]="150">
    <ng-template kendoGridCellTemplate let-isNew="isNew">
      <button kendoGridRemoveCommand [primary]="true" [icon]="'minus'" [disabled]="!canEditPerformanceBod"></button>
      <button kendoGridSaveCommand [primary]="true">Add</button>
      <button kendoGridCancelCommand [primary]="true">Cancel</button>
    </ng-template>
  </kendo-grid-command-column>
</kendo-grid>
