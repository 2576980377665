import { Injectable, InjectionToken, DebugElement, ViewContainerRef } from '@angular/core';
import { Observable,throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

import { FitCcBaseModel } from '../models/fit-cc-base-model';

import { FitCcBaseSerializer } from '../models/fit-cc-base-serializer';

import { catchError, map, tap } from 'rxjs';

import { NotificationService } from '@progress/kendo-angular-notification';
import { State, process, CompositeFilterDescriptor, DataResult, SortDescriptor } from '@progress/kendo-data-query';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { DialogService, DialogRef } from '@progress/kendo-angular-dialog';
import { IFitCcBaseColumn } from '../models/fit-cc-ibase-column';

const LIST_ACTION = '/list';
const CREATE_ACTION = '/create';
const UPDATE_ACTION = '/update';
const REMOVE_ACTION = '/delete';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Credentials': 'true',
    'Access-Control-Allow-Methods': 'GET, HEAD, OPTIONS, POST, PUT',
  }),
};

@Injectable({
  providedIn: 'root',
})
export class FitCcBaseService<T extends FitCcBaseModel> extends BehaviorSubject<any[] | GridDataResult> {
  public gridState: State = {
    sort: new Array<SortDescriptor>(),
    skip: 0,
    take: 10,
    filter: undefined,
  };
  constructor(
    public http: HttpClient,
    private notificationService: NotificationService,
    private dialogService: DialogService
  ) {
    super([]);
  }

  public data: any[] = [];
  public url: string;
  public endpoint: string;
  public serializer: FitCcBaseSerializer;

  public endpointList = LIST_ACTION;
  public endpointCreate = CREATE_ACTION;
  public endpointUpdate = UPDATE_ACTION;
  public endpointRemove = REMOVE_ACTION;

  private originalData: any[] = [];
  private createdItems: any[] = [];
  private updatedItems: any[] = [];
  private deletedItems: any[] = [];

  dialogConfirmationActions = [{ text: 'No' }, { text: 'Yes', primary: true }];
  dialogInformativeAction = [{ text: 'Ok' }];

  public token = 'DEF_TOKEN';
  public persistedSettings: boolean;

  public read() {
    const action = this.endpointList;
    if (this.data.length) {
      this.publish();
    } else {
      this.fetch(action)
        .pipe(
          tap(data => {
            this.data = data;
          })
        )
        .subscribe(data => {
          this.publish();
        });
    }
  }

  public save(data: any, isNew?: boolean) {
    if (data) {
      const action = isNew ? this.endpointCreate : this.endpointUpdate;
      this.reset();
      this.fetch(action, data, isNew).subscribe(() => {
        if (isNew) {
          this.data = [];
          this.read();
        }
        this.showNotification('Data has been saved successfully', 'success');
      });
    }
  }

  public remove(data: any) {
    this.reset();
    this.fetch(this.endpointRemove, data, false).subscribe(() => {
      this.data = this.data.filter(i => i.id !== data.id);
      this.read();
      this.showNotification('Deleted successfully', 'success', 'right');
    });
  }

  public reset() {
    this.updatedItems = [];
    this.originalData = [];
  }

  private fetch(action: string = '', data?: any, isNew?: boolean): Observable<FitCcBaseModel[]> {
    const serviceUrl: string = this.url + this.endpoint + action;
    // List Method
    if (action === this.endpointList) {
      return this.http.get(serviceUrl, { responseType: 'json' }).pipe(
        map((data: FitCcBaseModel[]) => {
          data = this.serializer.fromJson(data);
          return data;
        }),
        catchError(this.handleError)
      );
    } else {
      return this.http.post<any[]>(serviceUrl, [this.serializer.toJson(data, isNew)], httpOptions);
    }
  }

  public list(action: string = '', data?: any): Observable<any[]> {
    const serviceUrl: string = this.url + this.endpoint + this.endpointList;

    return this.http.get<FitCcBaseModel[]>(serviceUrl).pipe(catchError(this.handleError));
  }

  public listToDropDown(dependencyField?: string): Observable<any[]> {
    const serviceUrl: string = this.url + this.endpoint + this.endpointList;
    return this.http.get(serviceUrl, { responseType: 'json' }).pipe(
      map((data: FitCcBaseModel[]) => {
        return data.map(d => {
          let dep = dependencyField ? d[dependencyField] : '';

          return { value: d.id, text: d.name, dependencyField: dep };
        });
      }),
      catchError(this.handleError)
    );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof Error) {
      const errMsg = error.error.message;
      return throwError(()=>{new Error(errMsg)});
    }
    
    return throwError(()=>{new Error(error.message || 'WebAPI server error')}); 
  }

  public showNotification(
    msg,
    typeMsg: 'none' | 'success' | 'warning' | 'error' | 'info',
    pos: 'center' | 'left' | 'right' = 'center'
  ) {
    this.notificationService.show({
      content: msg,
      hideAfter: 3000,
      position: { horizontal: pos, vertical: 'top' },
      animation: { type: 'fade', duration: 100 },
      type: { style: typeMsg, icon: true },
      closable: typeMsg === 'error' ? true : false,
    });
  }

  public update(item: any): void {
    const currentItem = this.data.find(i => i.id === item.id);

    if (currentItem && !this.isNew(item)) {
      let backupItem = this.originalData.find(i => i.id === item.id);
      if (!backupItem) {
        backupItem = Object.assign({}, currentItem);
        this.originalData.push(backupItem);
      }

      this.trackUpdatedItem(item);

      Object.assign(currentItem, item);
    } else {
      const index = this.createdItems.indexOf(item);
      this.createdItems.splice(index, 1, item);
    }
  }

  private trackUpdatedItem(item: any) {
    const itemTracked = this.updatedItems.find(i => i.id === item.id);

    if (!itemTracked) {
      this.updatedItems.push(item);
    } else {
      const originalItem = this.originalData.find(i => i.id === item.id);
      if (originalItem) {
        JSON.stringify(originalItem) === JSON.stringify(item)
          ? this.updatedItems.splice(this.updatedItems.indexOf(itemTracked), 1)
          : this.updatedItems.splice(this.updatedItems.indexOf(itemTracked), 1, item);
      }
    }
  }

  public getCurrentState() {
    return this.gridState;
  }

  public isNew(item): boolean {
    return !item.id;
  }

  private itemIndex = (item: any, data: any[]): number => {
    for (let idx = 0; idx < data.length; idx++) {
      if (data[idx].id === item.id) {
        return idx;
      }
    }
    return -1;
  };

  public hasChanges(): boolean {
    return Boolean(this.updatedItems.length);
  }

  public checkUpdated(item: any, field: string, updatedItemsArray: any[], originalItemsArray: any[]): boolean {
    let index;
    let indexO;
    let updatedItem;
    let originalItem;

    if (!this.isNew(item)) {
      index = this.itemIndex(item, updatedItemsArray);
      indexO = this.itemIndex(item, originalItemsArray);

      if (index !== -1) {
        updatedItem = updatedItemsArray[index][field];
        originalItem = originalItemsArray[indexO][field];

        if (updatedItem === undefined || updatedItem === null) {
          return false;
        }

        if (Array.isArray(originalItem) && Array.isArray(updatedItem)) {
          let result = false;
          updatedItem.forEach(o =>
            originalItem.forEach(u => {
              if (originalItem.indexOf(o) === updatedItem.indexOf(u)) {
                if (o !== u) {
                  result = true;
                }
              } else if (updatedItem.length !== originalItem.length) {
                result = true;
              }
            })
          );

          return result;
        } else {
          if (
            Object.prototype.toString.call(updatedItem) === '[object Date]' &&
            Object.prototype.toString.call(originalItem) === '[object Date]'
          ) {
            if (updatedItem.getTime() !== originalItem.getTime()) {
              return true;
            }
          } else if (updatedItem !== originalItem) {
            return true;
          }
        }
      }
    } else {
      return false;
    }
  }

  private updateItems(data: any) {
    const d = this.serializer.fromJson(data);
    this.save(d, false);
  }

  public saveChanges() {
    if (!this.hasChanges()) {
      return;
    }

    if (this.updatedItems.length) {
      this.updateItems(this.updatedItems);
    }
  }

  public cancelChanges() {
    this.originalData.forEach(originalItem => {
      const foundItem = this.data.find(i => i.id === originalItem.id);

      if (foundItem) {
        Object.assign(foundItem, originalItem);
      } else {
        this.data.push(originalItem);
      }
    });

    this.reset();
  }

  public fetchCurrentData(): FitCcBaseModel[] {
    return this.data;
  }

  public getOriginalData = () => this.originalData;
  public getUpdatedItems = () => this.updatedItems;

  public filterChange(filter: CompositeFilterDescriptor): void {
    this.gridState.filter = filter;
    this.publish();
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.replaceFilterHandler();
    this.gridState.sort = sort;
    this.publish();
  }

  public pageChange(event: PageChangeEvent): void {
    this.replaceFilterHandler();
    this.gridState.skip = event.skip;
    this.publish();
  }

  public publish() {
    const dr: DataResult = process(this.data, this.getCurrentState());

    super.next(dr);
  }

  public clearSort() {
    this.gridState.sort = [];
    this.publish();
  }

  public clearFilter() {
    this.gridState.filter = undefined;
    this.publish();
  }



  public checkIfDropdown(col: any) {
    let sd = this.gridState.sort.find(s => s.field === col.field);

    if (sd) {
      return col.type === 'dropdown';
    }
  }

  public dialogHandler(container: ViewContainerRef, title: string, content: string, actions: Array<any>): DialogRef {
    return this.dialogService.open({
      appendTo: container,
      title: title,
      content: content,
      actions: actions,
      width: 450,
      minWidth: 250,
    });
  }

  public showDialog(container: ViewContainerRef, changes: boolean, dataItem?: any) {
    let dialog: DialogRef;
    if (changes) {
      dialog = this.dialogHandler(
        container,
        'Information',
        'Please save or cancel your changes before doing this action.',
        this.dialogInformativeAction
      );
    } else {
      dialog = this.dialogHandler(
        container,
        'Please Confirm',
        'NOTE: You are about to delete ' +
          (dataItem.name ? dataItem.name : 'this record') +
          ', would you like to proceed?',
        this.dialogConfirmationActions
      );

      dialog.result.subscribe(result => {
        if (result['text'] == 'Yes') {
          this.remove(dataItem);
        }
      });
    }
  }

  public persistSettings(columns: IFitCcBaseColumn[], state?: State) {
    let gridConfig = {
      columns: columns,
      state: state ? state : this.gridState,
    };

    let settings = Object.assign({}, gridConfig);

    this.setPersistedSettings(this.token, settings);
  }

  public loadPersistedSettings(): any[] {
    const gridConfig: any = this.getPersistedSettings(this.token);

    if (gridConfig !== null && gridConfig !== undefined) {
      let settings = Object.assign({}, gridConfig);
      if (settings.state.filter !== undefined) {
        settings.columns.forEach(c => {
          if (c.type === 'date') {
            this.mapDateFilter(settings.state.filter, c.field);
          }
        });
      }
      this.gridState = settings.state;
      return settings.columns;
    }
  }

  private mapDateFilter = (descriptor: any, columnField: any) => {
    const filters = descriptor.filters || [];

    filters.forEach(filter => {
      if (filter.filters) {
        this.mapDateFilter(filter, columnField);
      } else if (filter.field === columnField && filter.value) {
        filter.value = new Date(filter.value);
      }
    });
  };

  private getPersistedSettings<T>(token: string): T {
    let settings: any = localStorage.getItem(token);
    let settingsObject: any;
    if (settings) {
      settingsObject = Object.assign({}, JSON.parse(settings));
      if (settingsObject && settingsObject.state.filter) {
        this.replaceFilterOperator(settingsObject.state.filter);
      }
    }
    return settingsObject;
  }

  private setPersistedSettings<T>(token: string, config): void {
    let settings = Object.assign({}, config);
    if (settings.state.filter !== undefined) {
      this.replaceFilterOperator(settings.state.filter);
    }
    localStorage.setItem(token, JSON.stringify(settings));
  }

  public replaceFilterOperator(obj) {
    if (obj.filters) {
      this.replaceFilterOperator(obj.filters);
    }
    if (Array.isArray(obj)) {
      for (const f of obj) {
        if (f.filters) {
          this.replaceFilterOperator(f.filters);
        } else if (f.operator) {
          this.replaceFilterOperator(f);
        }
      }
    }
    if (obj.operator && typeof obj.operator === 'function') {
      obj.operator = obj.operator.toString();
    } else if (obj.operator && typeof obj.operator === 'string' && obj.operator.includes('function')) {
      //obj.operator = eval('(' + obj.operator + ')');
      return;
    }
  }

  public filterOperatorHandler = (colType: any) => {
    if (colType === 'multiselect') {
      return (field, value) => {
        for (let f of field) {
          if (typeof f === 'string') {
            f = f.toLocaleLowerCase();
          }
          if (f === value) {
            return true;
          }
        }
        return false;
      };
    } else {
      return 'eq';
    }
  };

  public replaceFilterHandler() {
    if (this.gridState.filter) {
      this.replaceFilterOperator(this.gridState.filter);
    }
  }

  public showSuccessNotification(message: string) {
    this.notificationService.show({
      content: `${message}`,
      hideAfter: 10000,
      animation: { type: 'slide', duration: 600 },
      position: { horizontal: 'right', vertical: 'top' },
      type: { style: 'success', icon: true },
    });
  }

  public showWarningNotification(message: string) {
    this.notificationService.show({
      content: `${message}`,
      animation: { type: 'slide', duration: 600 },
      position: { horizontal: 'right', vertical: 'top' },
      type: { style: 'warning', icon: true },
      closable: true,
    });
  }

  public showErrorNotification(message: string) {
    this.notificationService.show({
      content: `${message}`,
      animation: { type: 'slide', duration: 600 },
      position: { horizontal: 'right', vertical: 'top' },
      type: { style: 'error', icon: true },
      closable: true,
    });
  }
}
