import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Validators, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { IMasterDataLog } from 'src/app/_shared/_models/master-data-logs.model';
import { FitCcBaseColumn } from '../../models/fit-cc-base-column';
import { UtilService } from 'src/app/services/util.service';
import { AuthenticationService } from 'src/app/services';
import { MasterDataLogsService } from 'src/app/_shared/_services/master-data-logs-.service';
@Component({
  selector: 'fit-cc-popup-form',
  templateUrl: './fit-cc-popup-form.component.html',
  styleUrls: ['./fit-cc-popup-form.component.scss'],
})
export class FitCcPopupFormComponent {
  public active = false;
  public editForm: UntypedFormGroup = new UntypedFormGroup({});
  private logsToCreate: IMasterDataLog[] = [];
  public previousValue:string;
  private devlopmentAreaNameMap: Map<string, string> = new Map()
    .set('name', 'Name')
    .set('stateId', 'State Id')
    .set('geographicAreaId', 'Geographic Area Id')
    .set('developmentAreaAbbreviation', 'Development Area Abbreviation')
    .set('geographicArea', 'Geographic Area')
    .set('unitRateArea1', 'Unit Rate Area1')
    .set('mudiDevArea1', 'Mudi Dev Area1')
    .set('effectiveDate', 'Effective Date');

  private stateMap: Map<string, string> = new Map()
    .set('name', 'Name')
    .set('stateAbbreviation', 'State Abbreviation')
    .set('taxRate', 'Tax Rate');

  private basinMap: Map<string, string> = new Map().set('name', 'Name').set('basinAbbreviation', 'Basin Abbreviation');

  private geographicAreaMap: Map<string, string> = new Map()
    .set('name', 'Name')
    .set('stateAbbreviation', 'Geographic Area Abbreviation')
    .set('basinId', 'Basin Name');

  private landDamagesPipelineMap: Map<string, string> = new Map()
    .set('nominalPipeSize', 'Nominal Pipe Size')
    .set('landDamagesCostPerRod', 'Land Damages Cost per Rod')
    .set('landDamagesCostPerFoot', 'Land Damages Cost Per Foot')
    .set('comments', 'Comments');

  private landDamagesMap: Map<string, string> = new Map()
    .set('scope', 'Scope')
    .set('unitCost', 'Unit Cost')
    .set('unitOfMeasureId', 'Unit Of Measure')
    .set('comments', 'Comments');

  private compressorCapacityAssumptionsMap: Map<string, string> = new Map()
    .set('compressorType', 'Compressor Type')
    .set('suctionPressure', 'Suction Pressure')
    .set('fuelGas', 'Fuel Gas')
    .set('compressorGasRateCapacity', 'Compressor Gas Rate Capacity');

  private padContingencyAssumptionsMap: Map<string, string> = new Map()
    .set('estimateDescription', 'Estimate Description')
    .set('numberOfDevWells', 'Number of Wells')
    .set('estimateTimeFrame', 'Estimate Time Frame')
    .set('contingencyPercentage', 'Contingency Percentage');

  private facilityItemYearlyCostMap: Map<string, string> = new Map()
    .set('year', 'Year')
    .set('costPunit', 'Cost Per Uom')
    .set('itemNotes', 'Item Notes');

  private facilityItemsMap: Map<string, string> = new Map()
    .set('name', 'Item Name')
    .set('infrastructureClassId', 'Infrastructure Type')
    .set('locationId', 'Location Name')
    .set('infraestructureScopeCategoryId', 'Scope Category')
    .set('rfsdTimingId', 'Ready For Service Date Timing')
    .set('kindId', 'Kind Name');

  constructor(
    private utilService: UtilService,
    private authenticationService: AuthenticationService,
    private masterDataLogsService: MasterDataLogsService
  ) {}

  @Input() columnsForm: any[] = FitCcBaseColumn;
  @Input() public isNew = false;
  @Input() dialogTitle: string;
  @Input() selectedLogTable: string;

  @Input() public set model(item) {
    //define Form by columns (master-data selected)
    const jsonData = JSON.stringify(item);
    localStorage.setItem('tableData', jsonData);

    this.editForm = new UntypedFormGroup({});
    this.columnsForm.forEach(column => {
      if (item && column.type == 'date') {
        var date = item[column.field] ? new Date(item[column.field]) : null;
        item[column.field] = date;
      }
      if (column.required)
        this.editForm.addControl(column.field, new UntypedFormControl(column.field || '', [Validators.required]));
      else this.editForm.addControl(column.field, new UntypedFormControl());
    });

    this.editForm.reset(item);
    this.active = item !== undefined;
  }

  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Output() save: EventEmitter<any> = new EventEmitter();

  public onSave(e): void {
    var getTableData = JSON.parse(localStorage.getItem('tableData'));
    if (getTableData.id == undefined) {
      if(this.selectedLogTable=='LandDamagesPipeline')
      {
        this.previousValue=this.editForm.controls['nominalPipeSize'].value;
      } else if(this.selectedLogTable=='LandDamages')
      {
        this.previousValue=this.editForm.controls['scope'].value;
      }
      else if(this.selectedLogTable=='ComCapacityAssuptions')
      {
        this.previousValue=this.editForm.controls['compressorType'].value;
      }
      else if(this.selectedLogTable=='PadContingency')
      {
        this.previousValue=this.editForm.controls['estimateDescription'].value;
      }
      else if(this.selectedLogTable=='FacilityItemYearlyCost')
      {
        this.previousValue=this.editForm.controls['year'].value;
      }
      else if(this.selectedLogTable=='FacilityItems')
      {
        this.previousValue=this.editForm.controls['name'].value;
      }else{
        this.previousValue=this.editForm.controls['name'].value;
      }

      this.logsToCreate.push({
        id: null,
        tableType: this.selectedLogTable,
        operation: 'Created',
        fieldName: 'Name',
        previousValue: null,
        currentValue:  this.previousValue,
        createdBy: null,
        createdDate: null,
        updatedDate: new Date(),
        updatedBy: this.authenticationService.getCurrentUser().toUpperCase(),
        rowId: getTableData.id,
        controlName: null,
      });
    } else {
      if (!this.editForm.valid) {
        // prevent closing the edited cell if there are invalid values.
        e.preventDefault();
      } else if (this.editForm.dirty) {
        let fieldsThatChanged: any[] = [];
        if (this.selectedLogTable == 'DevelopmentArea') {
          fieldsThatChanged = this.utilService.getFormGroupDirtyFieldsNamesAndValues(
            this.editForm,
            this.devlopmentAreaNameMap
          );
        } else if (this.selectedLogTable == 'State') {
          fieldsThatChanged = this.utilService.getFormGroupDirtyFieldsNamesAndValues(
            this.editForm, 
            this.stateMap);
        } else if (this.selectedLogTable == 'Basin') {
          fieldsThatChanged = this.utilService.getFormGroupDirtyFieldsNamesAndValues(
            this.editForm, 
            this.basinMap);
        } else if (this.selectedLogTable == 'GeographicArea') {
          fieldsThatChanged = this.utilService.getFormGroupDirtyFieldsNamesAndValues(
            this.editForm,
            this.geographicAreaMap
          );
        } else if (this.selectedLogTable == 'LandDamagesPipeline') {
          fieldsThatChanged = this.utilService.getFormGroupDirtyFieldsNamesAndValues(
            this.editForm,
            this.landDamagesPipelineMap
          );
        } else if (this.selectedLogTable == 'LandDamages') {
          fieldsThatChanged = this.utilService.getFormGroupDirtyFieldsNamesAndValues(
            this.editForm,
            this.landDamagesMap
          );
        } else if (this.selectedLogTable == 'ComCapacityAssuptions') {
          fieldsThatChanged = this.utilService.getFormGroupDirtyFieldsNamesAndValues(
            this.editForm,
            this.compressorCapacityAssumptionsMap
          );
        }else if (this.selectedLogTable == 'PadContingency') {
          fieldsThatChanged = this.utilService.getFormGroupDirtyFieldsNamesAndValues(
            this.editForm,
            this.padContingencyAssumptionsMap
          );
        } else if (this.selectedLogTable == 'FacilityItemYearlyCost') {
          fieldsThatChanged = this.utilService.getFormGroupDirtyFieldsNamesAndValues(
            this.editForm,
            this.facilityItemYearlyCostMap
          );
        } else if (this.selectedLogTable == 'FacilityItems') {
          fieldsThatChanged = this.utilService.getFormGroupDirtyFieldsNamesAndValues(
            this.editForm,
            this.facilityItemsMap
          );
        }

        fieldsThatChanged.forEach(element => {
          let updateValue = false;
          this.logsToCreate.find((log, i) => {
            if (log.rowId === getTableData.id && log.controlName === element.controlName) {
              switch (element.controlName) {
                default:
                  log.currentValue = element.value;
                  break;
              }
              updateValue = true;
              return true;
            }
          });
          if (!updateValue) {
            let previousValue = null;
            let currentValue = null;
            switch (element.controlName) {
              default:
                currentValue = element.value;
                previousValue = getTableData[element.controlName];
                break;
            }
            this.logsToCreate.push({
              id: null,
              tableType: this.selectedLogTable,
              operation: 'Updated',
              fieldName: element.name,
              previousValue: previousValue,
              currentValue: currentValue,
              createdBy: null,
              createdDate: null,
              updatedDate: new Date(),
              updatedBy: this.authenticationService.getCurrentUser().toUpperCase(),
              rowId: getTableData.id,
              controlName: element.controlName,
            });
          }
        });
      }
    }

    this.masterDataLogsService.createLogs(this.logsToCreate).subscribe(() => {
      this.logsToCreate = [];
    });
    e.preventDefault();

    this.save.emit(this.editForm.value);
    this.active = false;
  }

  public onCancel(e): void {
    e.preventDefault();
    this.closeForm();
  }

  public closeForm(): void {
    this.active = false;
    this.cancel.emit(this.editForm.value);
  }
}
