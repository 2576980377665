import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, map } from 'rxjs';
import { HandleError, HttpErrorHandler } from 'src/app/services/http-error-handler.service';
import { PerformanceConfigurationService } from 'src/app/services/performance-configuration-service';
import { Globals } from 'src/app/_shared/Globals';
import { environment } from '../../../../environments/environment';
import { IPerformanceBodStatus } from '../models/performance-bod-status';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class PerformanceBodStatusService {
  private baseUrlPerformanceBodStatus: string = environment.PacerDomainURL + 'PerformanceBodStatus';
  public performanceBodStatus$: Observable<IPerformanceBodStatus[]>;
  public changeStatus$: Observable<boolean>;IPerformanceBodStatus
  private performanceBodSValue: BehaviorSubject<IPerformanceBodStatus[]>;
  private handleError: HandleError;
  private performancBodId: string;
  private changeStatusValue$: BehaviorSubject<boolean>;

  constructor(
    public globals: Globals,
    private http: HttpClient,
    private httpErrorHandler: HttpErrorHandler,
    private performanceConfigurationService: PerformanceConfigurationService
  ) {
    this.performanceBodSValue = new BehaviorSubject<IPerformanceBodStatus[]>([]);
    this.changeStatusValue$ = new BehaviorSubject<boolean>(false);
    this.performanceBodStatus$ = this.performanceBodSValue.asObservable();
    this.changeStatus$ = this.changeStatusValue$.asObservable();
    this.handleError = httpErrorHandler.createHandleError();
  }

  public initialize(performancBodId: string) {
    this.performancBodId = performancBodId;
    this.getPerformanceBodStatus(this.performancBodId).subscribe(
      (performanceBodStatus: IPerformanceBodStatus[]) => {
        this.performanceBodSValue.next(performanceBodStatus);
      },
      () => {
        this.performanceBodSValue.error('Error loading data: could not be able to load Performance bod status.');
      }
    );
  }

  public updateStatus(bodStatus: IPerformanceBodStatus[]): void {
    this.create(bodStatus).subscribe((performanceBodStatus: IPerformanceBodStatus[]) => {
      this.initialize(this.performancBodId);
      this.nextChangeStatus(true);
    });
  }

  public setPerformanceBodSValue(value: any) {
    this.performanceBodSValue.next(value);
  }

  public nextChangeStatus(change: boolean): void {
    this.changeStatusValue$.next(change);
  }

  public cleanObservables(): void {
    this.performanceBodSValue.next([]);
  }

  public getPerformanceBodStatus(performanceBodId: string): Observable<IPerformanceBodStatus[]> {
    return this.http
      .get<IPerformanceBodStatus[]>(`${this.baseUrlPerformanceBodStatus}/ListByPerformanceBodId/${performanceBodId}`)
      .pipe();
  }

  private create(created: IPerformanceBodStatus[]): Observable<any> {
    const toCreate = created.map((item: IPerformanceBodStatus) => {
      return {
        infrastructureBodId: item.performanceBodId,
        statusIndicatorId: item.statusIndicatorId,
        createdBy: item.createdBy,
        createdDate: item.createdDate,
        updatedBy: item.updatedBy,
        updatedDate: item.updatedDate,
      };
    });
    return this.http.post<IPerformanceBodStatus[]>(`${this.baseUrlPerformanceBodStatus}/Create`, toCreate, httpOptions).pipe(
      map((returnValue: any) => {
        if (returnValue.exceptions && returnValue.exceptions.length > 0) {
          //this.performanceBodSValue$.error('Error : An error occurred while creating the entries.');
        }
        return returnValue;
      }),
      catchError(this.handleError('Create'))
    );
  }
}
