<div class="card-body-bod-pck">
  <p class="card-title">Package Information</p>
  <kendo-grid
    #performanceBodPadsGrid
    [data]="view | async"
    (dataStateChange)="onStateChange($event)"
    (cellClick)="cellClickHandler($event)"
    (cellClose)="cellCloseHandler($event)"
    (cancel)="cancelHandler($event)"
    (save)="saveHandler($event)"
    (remove)="removeHandler($event)"
    (add)="addHandler($event)"
    [navigable]="true"
    [resizable]="true"
    [sort]="gridState.sort"
    [sortable]="{ mode: 'multiple' }"
    class="package-information-draggable"
    [rowClass]="rowCallback"
    [filter]="filter"
    filterable="menu"
    (filterChange)="handlerFilter($event)"
  >
    <ng-template kendoGridToolbarTemplate>
      <!--Save button with validation for duplicates and blank well pad names-->
      <app-error-list
        [PerformanceBodId]="performanceBodId"
        (SavingData)="saveChanges(performanceBodPadsGrid)"
        [disabled]="!summaryPackageInformationEditService.hasChanges() || isEditing"
        [duplicatesFound]="duplicatesFound"
        [duplicateError]="'Duplicate Well Pad Name.'"
        [blanksFound]="blanksFound"
        [buttonText]="'Save'"
      >
      </app-error-list>

      <button
        kendoButton
        class="k-button"
        [disabled]="!summaryPackageInformationEditService.hasChanges()"
        (click)="cancelChanges(performanceBodPadsGrid)"
        [primary]="true"
      >
        Cancel
      </button>
      <button [icon]="'plus'" kendoGridAddCommand [primary]="true" [disabled]="!canEditPerformanceBod"></button>
    </ng-template>
    <kendo-grid-column title="" field="" [hidden]="true" width="1">
      {{ rowLoaded() }}
    </kendo-grid-column>
    <kendo-grid-column field="performanceBodPackagesId" title="Package Name" [editable]="true" width="120">
      <ng-template
        kendoGridFilterMenuTemplate
        let-column="column"
        let-filter="filter"
        let-filterService="filterService"
      >
        <multicheck-filter
          [isPrimitive]="false"
          [field]="column.field"
          textField="name"
          valueField="id"
          [filterService]="filterService"
          [currentFilter]="filter"
          [data]="performanceBodPackages"
        >
        </multicheck-filter>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ getPerformanceBodPackages(dataItem.performanceBodPackagesId)?.name }}
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-column="column" let-formGroup="formGroup">
        <kendo-dropdownlist
          popupAnchor
          [data]="performanceBodPackages"
          textField="name"
          valueField="id"
          [valuePrimitive]="true"
          [formControl]="formGroup.get('performanceBodPackagesId')"
        >
        </kendo-dropdownlist>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="wellPadName" title="Well Pad Name" [editable]="true" width="180">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.wellPadName }}
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-column="column" let-formGroup="formGroup">
        <input
          elementFocus
          type="text"
          maxlength="100"
          [formControl]="formGroup.get('wellPadName')"
          class="k-textbox"
        />
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="spudDate"
      title="SPUD Date"
      [editable]="true"
      editor="date"
      format="MM/dd/yyyy"
      width="100"
      [filterable]="filterable"
      filter="date"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <span>{{ dataItem.spudDate | date: 'MM/dd/yyyy' }}</span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-column="column" let-formGroup="formGroup">
        <kendo-datepicker
          [value]="dataItem.spudDate"
          [format]="'MM/dd/yyyy'"
          [formControl]="formGroup.get('spudDate')"
        ></kendo-datepicker>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="popDate"
      title="POP Date"
      [editable]="true"
      editor="date"
      format="MM/dd/yyyy"
      width="100"
      [filterable]="filterable"
      filter="date"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <span>{{ dataItem.popDate | date: 'MM/dd/yyyy' }}</span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-column="column" let-formGroup="formGroup">
        <kendo-datepicker
          [value]="dataItem.popDate"
          [format]="'MM/dd/yyyy'"
          [formControl]="formGroup.get('popDate')"
        ></kendo-datepicker>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="numberOfWells" title="# of Wells" [editable]="true" width="70" filter="numeric">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.numberOfWells }}
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-column="column" let-formGroup="formGroup">
        <kendo-numerictextbox [format]="'n0'" [min]="0" [max]="50" [formControl]="formGroup.get('numberOfWells')">
        </kendo-numerictextbox>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="reservoirName" title="Reservoir(s) Name(s)" [editable]="true" width="160">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.reservoirName }}
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-column="column" let-formGroup="formGroup">
        <input
          elementFocus
          type="text"
          maxlength="100"
          [formControl]="formGroup.get('reservoirName')"
          class="k-textbox"
        />
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="comments" title="Comments" [editable]="true" width="150"> </kendo-grid-column>
    <kendo-grid-command-column title="" width="130">
      <ng-template kendoGridCellTemplate let-isNew="isNew">
        <button kendoGridRemoveCommand [primary]="true" [icon]="'minus'" [disabled]="!canEditPerformanceBod"></button>
        <button kendoGridSaveCommand [primary]="true">Add</button>
        <button kendoGridCancelCommand [primary]="true">Cancel</button>
      </ng-template>
    </kendo-grid-command-column>
  </kendo-grid>
</div>
