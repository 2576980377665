import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, map } from 'rxjs';
import { HandleError, HttpErrorHandler } from 'src/app/services/http-error-handler.service';
import { environment } from 'src/environments/environment';
import { ISftBodStatus } from '../bod/_models/sft-bod-status';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class SftBodStatusService {
  public baseUrlSftBodStatus: string = environment.PacerDomainURL + 'SftBodStatus';
  public sftBodStatus$: Observable<ISftBodStatus[]>;
  public changeStatus$: Observable<boolean>;
  private sftBodStatusValue$: BehaviorSubject<ISftBodStatus[]>;
  private changeStatusValue$: BehaviorSubject<boolean>;
  private handleError: HandleError;
  private facilityBodId: string;
  constructor(private http: HttpClient, private httpErrorHandler: HttpErrorHandler) {
    this.sftBodStatusValue$ = new BehaviorSubject<ISftBodStatus[]>([]);
    this.changeStatusValue$ = new BehaviorSubject<boolean>(false);
    this.sftBodStatus$ = this.sftBodStatusValue$.asObservable();
    this.changeStatus$ = this.changeStatusValue$.asObservable();
    this.handleError = httpErrorHandler.createHandleError();
  }

  public initialize(facilityBodId: string) {
    this.facilityBodId = facilityBodId;
    this.getSftBodStatusByFacilityBoId(this.facilityBodId).subscribe(
      (sftBodStatus: ISftBodStatus[]) => {
        this.sftBodStatusValue$.next(sftBodStatus);
      },
      () => {
        this.sftBodStatusValue$.error('Error loading data: could not be able to load Sft bod status.');
      }
    );
  }

  public updateStatus(bodStatus: ISftBodStatus[]): void {
    this.create(bodStatus).subscribe((sftBodStatus: ISftBodStatus[]) => {
      this.initialize(this.facilityBodId);
      this.nextChangeStatus(true);
    });
  }

  public setFacilityBodValue(value: any) {
    this.sftBodStatusValue$.next(value);
  }

  public nextChangeStatus(change: boolean): void {
    this.changeStatusValue$.next(change);
  }

  public cleanObservables(): void {
    this.sftBodStatusValue$.next([]);
  }

  private getSftBodStatusByFacilityBoId(facilityBodId: string): Observable<ISftBodStatus[]> {
    return this.http
      .get<ISftBodStatus[]>(`${this.baseUrlSftBodStatus}/ListByFacilityBodId/${facilityBodId}`)
      .pipe(catchError(this.handleError('getFacilityBodById')));
  }

  private create(created: ISftBodStatus[]): Observable<ISftBodStatus[]> {
    const toCreate = created.map((item: ISftBodStatus) => {
      return {
        facilityBodId: item.facilityBodId,
        statusIndicatorId: item.statusIndicatorId,
        createdBy: item.createdBy,
        createdDate: item.createdDate,
        updatedBy: item.updatedBy,
        updatedDate: item.updatedDate,
      };
    });
    return this.http.post<ISftBodStatus[]>(`${this.baseUrlSftBodStatus}/Create`, toCreate, httpOptions).pipe(
      map((returnValue: any) => {
        if (returnValue.exceptions && returnValue.exceptions.length > 0) {
          this.sftBodStatusValue$.error('Error : An error occurred while creating the entries.');
        }
      }),
      catchError(this.handleError('Create'))
    );
  }
}
