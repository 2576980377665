import { Component, EventEmitter, OnInit, Output, ViewChild, Inject, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { GridDataResult, RowClassArgs } from '@progress/kendo-angular-grid';
import { NotificationService } from '@progress/kendo-angular-notification';
import { process, State, filterBy, CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { map } from 'rxjs';
import { IPerformanceBodPads } from 'src/app/api/performance-bod-pads';
import { PerformanceBodStoreService } from 'src/app/services/performance-bod-store.service';
import { IPerformanceBodCentralTankBattery } from 'src/app/api/performance-bod-central-tank-battery';
import { IPerformanceBodCompressor } from 'src/app/api/performance-bod-compressor';
import { IPerformanceBodPackages } from 'src/app/api/performance-bod-package';
import { IPerformanceBodSaltWaterDisposal } from 'src/app/api/performance-bod-salt-water-disposal';
import { StandardFacilitiesSummaryService } from './standard-facilities-summary.service';
import { PermissionsProvider } from 'src/app/services/permissions.provider';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DragAndDropService } from 'src/app/_shared/drag-and-drop.service';
import { IPerformanceBodSummary } from 'src/app/api/performance-bod-summary';
import { ListService } from 'src/app/_shared/_services/list.service';
import { IFacilityType } from 'src/app/modules/infrastructure/models/facility-type';
import * as Helpers from 'src/app/_shared/helpers';

const DRAGGABLE_SELECTOR = '.standard-facilities-summary-draggable';

@Component({
  selector: 'app-standard-facilities-summary',
  templateUrl: './standard-facilities-summary.component.html',
  styleUrls: ['./standard-facilities-summary.component.css'],
})
export class StandardFacilitiesSummaryComponent implements OnInit, OnDestroy {
  public filter: CompositeFilterDescriptor;
  public filterable: boolean = true;
  public ctbCsSWDList: Array<{ name: string; id: string }> = [
    { name: 'CTB - Train', id: 'a50366f1-7a72-44b0-899e-f5492a1ad688' },
    { name: 'CS - Compressors', id: '14220339-da61-4dc9-8b4c-0b7c35e78d5c' },
    { name: 'SWD - H-Pumps', id: 'f7c75fb0-2e86-40d6-9248-7f451309c17f' },
  ];
  public designYearList: number[] = [];
  public wellPadName: string = 'Pending To Add Well Pad Name field';
  public view: Observable<GridDataResult>;
  public performanceBodPackagesList: IPerformanceBodPackages[];
  public performanceBodCTBList: IPerformanceBodCentralTankBattery[];
  public performanceBodCSList: IPerformanceBodCompressor[];
  public performanceBodSWDList: IPerformanceBodSaltWaterDisposal[];
  public filterPerformanceBodCTBList: IPerformanceBodCentralTankBattery[];
  public filterPerformanceBodCSList: IPerformanceBodCompressor[];
  public filterPerformanceBodSWDList: IPerformanceBodSaltWaterDisposal[];
  public performanceBodId: string;
  public formGroup: UntypedFormGroup;
  public gridState: State = {
    sort: [],
    skip: 0,
  };
  public performanceBodPadsList: IPerformanceBodPads[];
  public canEditPerformanceBod: boolean = false;
  @Output() public performanceBodUpdate = new EventEmitter<string>();
  @Output() public standardFacilityUpdate = new EventEmitter<any>();
  @Output() public saveBodCompleted = new EventEmitter<boolean>();
  public facilityStageList = [{ id: 1, name: 'New' }, { id: 2, name: 'Existing' }];
  public facilityTypeList: IFacilityType[] = [];
  public afeMask = 'AAAAA-AAAAA-AAA';
  public SourCorrosiveList = [
    { text: 'Yes', value: true },
    { text: 'No', value: false }
  ];
  @ViewChild('performanceBodSummary')
  private summaryGrid: any;
  private dragAndDropService: DragAndDropService;

  constructor(
    private performanceBodStoreService: PerformanceBodStoreService,
    public standardFacilitiesSummaryService: StandardFacilitiesSummaryService,
    private spinner: NgxSpinnerService,
    private notificationService: NotificationService,
    private formBuilder: UntypedFormBuilder,
    private permissionsProvider: PermissionsProvider,
    private authenticationService: AuthenticationService,
    private listService: ListService,
    @Inject(DragAndDropService) dragAndDropServiceFactory: any
  ) {
    this.dragAndDropService = dragAndDropServiceFactory();
  }

  public ngOnInit() {
    this.canEditPerformanceBod = this.permissionsProvider.canEditPerformanceBod;

    this.performanceBodStoreService.performanceBodId$.subscribe(id => {
      this.performanceBodId = id;
      this.standardFacilitiesSummaryService.performanceBodId = id;
      this.standardFacilitiesSummaryService.read();      
    });

    this.performanceBodStoreService.performanceBodPackagesData$.subscribe(data => {
      this.performanceBodPackagesList = data;
    });

    this.performanceBodStoreService.performanceBodPadsData$.subscribe(data => {
      this.performanceBodPadsList = data;
    });

    this.performanceBodStoreService.performanceBodCentralTankBattery$.subscribe(data => {
      this.performanceBodCTBList = data;
    });

    this.performanceBodStoreService.performanceBodCompressor$.subscribe(data => {
      this.performanceBodCSList = data;
    });

    this.performanceBodStoreService.performanceBodSaltWaterDisposal$.subscribe(data => {
      this.performanceBodSWDList = data;
    });

    this.listService.getFacilityTypes().subscribe((facilityTypes: IFacilityType[]) => {
      this.facilityTypeList = facilityTypes;
    });

    for (let i = 2016; i <= 2050; i++) {
      this.designYearList.push(i);
    }

    this.view = this.standardFacilitiesSummaryService.pipe(
      map(data => {
        this.dragAndDropService.dataSourceRefreshed(data);
        return process(filterBy(data, this.filter), this.gridState);
      })
    );


    this.dragAndDropService.initialize(this.standardFacilitiesSummaryService.data, DRAGGABLE_SELECTOR, () => {
      this.standardFacilitiesSummaryService.markAsSetOrder(this.authenticationService.getCurrentUser());
    });
  }

  public ngAfterViewInit(): void {
    // To Do
  }

  public ngOnDestroy(): void {
    this.dragAndDropService.refreshDragAndDrop();
    this.standardFacilitiesSummaryService.reset();
  }

  public createFormGroup(dataItem: any): UntypedFormGroup {
    return this.formBuilder.group({
      id: new UntypedFormControl(dataItem.id),
      name: new UntypedFormControl(dataItem.name, [Validators.minLength(0), Validators.maxLength(100)]),
      trainsCompressorPumpsId: new UntypedFormControl(dataItem.trainsCompressorPumpsId),
      performanceBodPadsId: new UntypedFormControl(dataItem.performanceBodPadsId, [Validators.required]),
      performanceBodPackagesId: new UntypedFormControl(dataItem.performanceBodPackagesId),
      comments: new UntypedFormControl(dataItem.comments, [Validators.minLength(1), Validators.maxLength(500)]),
      numberOfTrains: new UntypedFormControl(dataItem.numberOfTrains, [
        Validators.min(0),
        Validators.max(25),
      ]),
      numberOfCompressors: new UntypedFormControl(dataItem.numberOfCompressors, [
        Validators.min(0),
        Validators.max(25),
      ]),
      numberOfPumps: new UntypedFormControl(dataItem.numberOfPumps, [
        Validators.min(0),
        Validators.max(25),
      ]),
      numberOf3608: new UntypedFormControl(dataItem.numberOf3608),
      numberOf3516: new UntypedFormControl(dataItem.numberOf3516),
      numberOf3606: new UntypedFormControl(dataItem.numberOf3606),
      productionRatesOil: new UntypedFormControl(dataItem.productionRatesOil),
      productionRatesWater: new UntypedFormControl(dataItem.productionRatesWater),
      productionRatesGas: new UntypedFormControl(dataItem.productionRatesGas),
      facilityCapacityOil: new UntypedFormControl(dataItem.facilityCapacityOil),
      facilityCapacityWater: new UntypedFormControl(dataItem.facilityCapacityWater),
      facilityCapacityGas: new UntypedFormControl(dataItem.facilityCapacityGas),
      spGravityOil: new UntypedFormControl(dataItem.spGravityOil),
      spGravityWater: new UntypedFormControl(dataItem.spGravityWater),
      spGravityGas: new UntypedFormControl(dataItem.spGravityGas),
      numberAfe: new UntypedFormControl(dataItem.numberAfe, [Validators.minLength(13)]),
      sourCorrosive: new UntypedFormControl(dataItem.sourCorrosive),
      latitude: new UntypedFormControl(dataItem.latitude),
      longitude: new UntypedFormControl(dataItem.longitude),
      facilityTypeId: new UntypedFormControl(dataItem.facilityTypeId),
      pisAssociatedQuestion: new UntypedFormControl(dataItem.pisAssociatedQuestion),
      stdDesignQuestion: new UntypedFormControl(dataItem.stdDesignQuestion),
      gasRequiredQuestion: new UntypedFormControl(dataItem.gasRequiredQuestion),
      hdpeQuestion: new UntypedFormControl(dataItem.hdpeQuestion),
      fiberOpticQuestion: new UntypedFormControl(dataItem.fiberOpticQuestion),
      blmProjectQuestion: new UntypedFormControl(dataItem.blmProjectQuestion),
      facilityLocationQuestion: new UntypedFormControl(dataItem.facilityLocationQuestion),
      sftDesignYear: new UntypedFormControl(dataItem.sftDesignYear, [Validators.min(2016), Validators.max(2050)]),
      isNew: new UntypedFormControl(dataItem.isNew),
      readyForServiceDate: new UntypedFormControl(dataItem.readyForServiceDate),
      bodLockdownDate: new UntypedFormControl(dataItem.bodLockdownDate),
      isDeleted: new UntypedFormControl(dataItem.isDeleted),
      createdBy: [dataItem.createdBy],
      createdDate: [dataItem.createdDate],
      updatedBy: [dataItem.updatedBy],
      updatedDate: [dataItem.updatedDate],
    });
  }

  public cellClickHandler({ sender, rowIndex, columnIndex, dataItem, isEdited }) {
    if (!isEdited && this.canEditPerformanceBod) {
      if (dataItem.readyForServiceDate !== undefined && columnIndex === 4) {
        if (dataItem.readyForServiceDate !== null) {
          const y = new Date(dataItem.readyForServiceDate.toString()).getFullYear();
          const m = new Date(dataItem.readyForServiceDate.toString()).getMonth();
          const d = new Date(dataItem.readyForServiceDate.toString()).getDate();
          dataItem.readyForServiceDate = new Date(y, m, d);
        }
      }

      if (dataItem.bodLockdownDate !== undefined && columnIndex === 5) {
        if (dataItem.bodLockdownDate !== null) {
          const y = new Date(dataItem.bodLockdownDate.toString()).getFullYear();
          const m = new Date(dataItem.bodLockdownDate.toString()).getMonth();
          const d = new Date(dataItem.bodLockdownDate.toString()).getDate();
          dataItem.bodLockdownDate = new Date(y, m, d);
        }
      }
      sender.editCell(rowIndex, columnIndex, this.createFormGroup(dataItem));
      this.dragAndDropService.onEditingMode();
    }
  }

  public cellCloseHandler(args: any) {
    const { formGroup, dataItem, column } = args;

    let columnField = '';

    if (column.field === 'performanceBodPadsName') {
      columnField = 'performanceBodPadsId';
    } else if (column.field === 'sourCorrosiveText') {
      columnField = 'sourCorrosive';
    } else {
      columnField = column.field;
    }

    const checkColumn = formGroup.controls[columnField];

    if (!checkColumn.valid) {
      args.preventDefault();
    } else if (checkColumn.dirty) {
      if (columnField === 'performanceBodPadsId') {
        dataItem.performanceBodPadsName = this.getPerformanceBodPads(formGroup.controls[columnField].value);
      }
      if (columnField === 'sourCorrosive') {
        dataItem.sourCorrosiveText = formGroup.controls['sourCorrosive'].value ? 'Yes' : 'No';
      }   
    }

    if (!formGroup.valid) {
      // prevent closing the edited cell if there are invalid values.
      args.preventDefault();
    } else if (formGroup.dirty) {
      dataItem.updatedDate = new Date();
      dataItem.updatedBy = this.authenticationService.getCurrentUser();

      // Date is not working in Object.assign(), so need to be do it manually
      formGroup.value.updatedBy = dataItem.updatedBy;
      formGroup.value.updatedDate = dataItem.updatedDate;

      this.standardFacilitiesSummaryService.assignValues(dataItem, formGroup.value);
      this.standardFacilitiesSummaryService.updateItem(dataItem);
    }
    // calls this to add the attribute of current row
    this.dragAndDropService.onEditingClose();
    this.dragAndDropService.refreshDragAndDrop();
  }

  public cancelHandler({ sender, rowIndex }) {
    sender.closeRow(rowIndex);
    this.dragAndDropService.onEditingClose();
    this.dragAndDropService.refreshDragAndDrop();
  }

  public saveHandler({ sender, formGroup, rowIndex }) {
    if (formGroup.valid) {
      formGroup.patchValue({
        performanceBodId: this.performanceBodId,
      });
      this.standardFacilitiesSummaryService.createItem(formGroup.value);
      sender.closeRow(rowIndex);
      this.dragAndDropService.onEditingClose();
      this.dragAndDropService.dataSourceRefreshed(this.standardFacilitiesSummaryService.data);
      this.dragAndDropService.refreshDragAndDrop();
      this.standardFacilitiesSummaryService.markAsSetOrder(this.permissionsProvider.cai);
    }
  }

  public saveChanges(grid: any): void {
    grid.closeCell();
    grid.cancelCell();
    this.spinner.show();

    setTimeout(() => {
      this.spinner.hide();
      this.standardFacilitiesSummaryService.cancelChanges();
      this.notificationService.show({
        content: 'Standard Facilities - Summary Information saved',
        position: { horizontal: 'center', vertical: 'top' },
        animation: { type: 'fade', duration: 500 },
        type: { style: 'success', icon: true },
        hideAfter: 700,
      });
    }, 3000);
    // calls this to add the attribute of current row
    this.dragAndDropService.refreshDragAndDrop();
    this.standardFacilitiesSummaryService.saveChanges();
    this.performanceBodUpdate.next(this.authenticationService.getCurrentUser());
  }

  public saveFromBOD(): void {
    this.summaryGrid.closeCell();
    this.summaryGrid.cancelCell();

    if (this.standardFacilitiesSummaryService.hasChanges()) {
      this.spinner.show();
      // calls this to add the attribute of current row
      this.dragAndDropService.refreshDragAndDrop();
      this.standardFacilitiesSummaryService.saveChanges();
      this.performanceBodUpdate.next(this.authenticationService.getCurrentUser());
      const subscription = this.standardFacilitiesSummaryService.subscribe(() => {
        this.saveBodCompleted.next(true);
      });

      subscription.unsubscribe();
    }
  }

  public cancelChanges(grid: any): void {
    grid.cancelCell();
    this.dragAndDropService.refreshDragAndDrop();
    this.standardFacilitiesSummaryService.cancelChanges();
    this.standardFacilitiesSummaryService.cancelChanges();
  }

  public onStateChange(state: State) {
    this.gridState = state;
    this.standardFacilitiesSummaryService.read();


  }

  public getPerformanceBodPackages(id: string): any {
    if (this.performanceBodPackagesList === null || this.performanceBodPackagesList === undefined) {
      return '';
    }
    return this.performanceBodPackagesList.find(x => x.id === id);
  }

  public getPerformanceBodFacilityName(id: string): any {
    if (
      (this.performanceBodCTBList === null || this.performanceBodCTBList === undefined) &&
      (this.performanceBodCSList === null || this.performanceBodCSList === undefined) &&
      (this.performanceBodSWDList === null || this.performanceBodSWDList === undefined)
    ) {
      return '';
    }
    this.filterPerformanceBodCTBList = this.performanceBodCTBList.filter(x => x.id === id);
    this.filterPerformanceBodCSList = this.performanceBodCSList.filter(x => x.id === id);
    this.filterPerformanceBodSWDList = this.performanceBodSWDList.filter(x => x.id === id);

    if (this.filterPerformanceBodCTBList.length === 1) {
      return this.filterPerformanceBodCTBList.find(x => x.id === id);
    }

    if (this.filterPerformanceBodCSList.length === 1) {
      return this.filterPerformanceBodCSList.find(x => x.id === id);
    }

    if (this.filterPerformanceBodSWDList.length === 1) {
      return this.filterPerformanceBodSWDList.find(x => x.id === id);
    }
  }

  public getCtbCsSwD(id: string): any {
    if (this.ctbCsSWDList === null || this.ctbCsSWDList === undefined) {
      return '';
    }
    return this.ctbCsSWDList.find(x => x.id === id);
  }

  public getFacilityType(id: string): any {
    if (this.ctbCsSWDList === null || this.ctbCsSWDList === undefined) {
      return '';
    }
    return this.ctbCsSWDList.find(x => x.id === id);
  }

  public getPerformanceBodPads(id: string): any {
    if (this.performanceBodPadsList === null || this.performanceBodPadsList === undefined) {
      return '';
    } else {
      return this.performanceBodPadsList.find(x => x.id === id);
    }
  }

  public getIfStdFacilitIsNew(isNew?: number): any {
    if (isNew === null || isNew === undefined) {
      return '';
    } else {
      if (this.facilityStageList) {
        return this.facilityStageList.find(f => f.id === isNew).name;
      }
    }

    return '';
  }

  public handlerFilter(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    if (filter.filters) {
      if (filter.filters.length > 0) {
        this.dragAndDropService.onEditingMode();
      } else {
        this.dragAndDropService.onEditingClose();
        this.dragAndDropService.refreshDragAndDrop();
      }
    }
  }

  public rowCallback(context: RowClassArgs) {
    return {
      dragging: context.dataItem.dragging,
    };
  }

  public rowLoaded(): void {
    // to check until grid have been loeaded.
    this.dragAndDropService.checkIsOnEditMode();
    this.dragAndDropService.checkDraggableElementLoaded();

  }

  public onChangeFacilityStage(value, dataItem: IPerformanceBodSummary, formGroup: UntypedFormGroup) {
    dataItem.isNew = value;
    this.standardFacilityUpdate.next(dataItem);
    formGroup.patchValue({
      readyForServiceDate: dataItem.readyForServiceDate,
    });
    formGroup.patchValue({
      bodLockdownDate: dataItem.bodLockdownDate,
    });        
  }

  public convertToAfeFormat(text: any) {
    return Helpers.convertToAfeFormat(text);
  }
}
