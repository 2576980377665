import { Component, Inject, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { NotificationService } from '@progress/kendo-angular-notification';
import { process, State, orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { map } from 'rxjs';
import { ScenarioStoreService } from '../../../services/scenario-store.service';
import { EditPadsService } from './edit-pads.service';

@Component({
  selector: 'app-edit-pads',
  templateUrl: './edit-pads.component.html',

})
export class EditPadsComponent implements OnInit {
  @Input() padId?: string;
  @Input() scenarioId: string;
  public multiple = false;
  public allowUnsort = true;
  public view: Observable<GridDataResult>;
  public editPadsService: EditPadsService;
  public sort: SortDescriptor[] = [];
  public gridState: State = {
    filter: {
      logic: 'and',
      filters: [],
    }
  };
  public isDuplicatedName = false;

  constructor(@Inject(EditPadsService) editPadsServiceFactory: any,
  private fb: UntypedFormBuilder) { 
    this.editPadsService = editPadsServiceFactory();
  }

  ngOnInit() {    
    this.view = this.editPadsService
    .pipe(map(data => {
      if (this.padId) {
        data = data.filter(d => d.id === this.padId);
      }      
      return process(orderBy(data, this.sort), this.gridState);
    }));
    this.editPadsService.read();
  }

  public cellClickHandler({ sender, rowIndex, columnIndex, dataItem, isEdited }) {
    this.isDuplicatedName = false;
    if (!isEdited) {
      sender.editCell(rowIndex, columnIndex, this.fb.group({
        name: dataItem.name,
        id: dataItem.id,
        isSelected: dataItem.isSelected,
      }));
    }
  }

  public cellCloseHandler(args: any) {
    const { formGroup, dataItem } = args;
    if (!formGroup.valid) {
      args.preventDefault();
    } else if (formGroup.dirty) {
      let result = [];
      if (this.padId) {
        result = this.editPadsService.data
        .filter(d => d.name.toLowerCase().trim() === formGroup.value.name.toLowerCase().trim()
          && d.id !== formGroup.value.id);
      } else {
        result = args.sender.data.data
        .filter(d => d.name.toLowerCase().trim() === formGroup.value.name.toLowerCase().trim()
          && d.id !== formGroup.value.id);
      }
      
      if (result.length > 0) {
        this.isDuplicatedName = true;
        args.preventDefault();
        return;
      } else {
        this.isDuplicatedName = false;
        this.editPadsService.assignValues(dataItem, formGroup.value);
        this.editPadsService.update(dataItem);
      }
    }
  }

  public onDataStateChange(state: any): void {
    this.gridState = state;
    this.editPadsService.read();
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.editPadsService.read();
  }

  public removeHandler({ sender, dataItem }) {
    this.editPadsService.remove(dataItem);
    sender.cancelCell();
  }

  public cancelChanges(grid: any): void {
    grid.cancelCell();
    this.editPadsService.cancelChanges();
  }
}
