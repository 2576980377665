import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, zip, concat, EMPTY } from 'rxjs';
import { PerformanceBodDataService } from 'src/app/services/performance-bod-data.service';
import { PerformanceBodStoreService } from 'src/app/services/performance-bod-store.service';
import { IPerformanceBodNotes } from 'src/app/api/performance-bod-notes';
import { take } from 'rxjs';
import { IFileInfo } from 'src/app/api/fileInfo';

const itemIndex = (item: any, data: any[]): number => {
  for (let idx = 0; idx < data.length; idx++) {
    if (data[idx].id === item.id) {
      return idx;
    }
  }
  return -1;
};

const cloneData = (data: any[]) => data.map(item => Object.assign({}, item));

@Injectable({
  providedIn: 'root',
})
export class SummaryNotesEditService extends BehaviorSubject<any[]> {

  public deletedAttachments: IFileInfo[] = [];
  public createdItems: IPerformanceBodNotes[] = [];
  public performanceBodId: string;
  private data: IPerformanceBodNotes[] = [];
  private deletedItems: IPerformanceBodNotes[] = [];
  private originalData: IPerformanceBodNotes[] = [];
  private updatedItems: IPerformanceBodNotes[] = [];
  
  constructor(
    private performanceBodDataService: PerformanceBodDataService,
    private performanceBodStoreService: PerformanceBodStoreService
  ) {
    super([]);
  }

  public read() {
    if (this.data !== undefined) {
      if (this.data.length) {
        return super.next(this.data);
      }
    }
    // for Lines with API Call, check kendo
    // Lines with observable
    
    // for Lines with API Call, check kendo
    // Line with observable    
    this.performanceBodStoreService.performanceBodNotesData$.subscribe((performanceBodNotes:IPerformanceBodNotes[]) => {
      this.data = performanceBodNotes;
      this.originalData = cloneData(performanceBodNotes);
      super.next(performanceBodNotes);
    });
    this.performanceBodStoreService.retrieveNotesByPerformanceBodId(this.performanceBodId)
  }

  public create(item: any): void {
    this.createdItems.push(item);
    this.data.unshift(item);

    super.next(this.data);
  }

  public update(item: any): void {
    if (!this.isNew(item)) {
      const index = itemIndex(item, this.updatedItems);
      if (index !== -1) {
        this.updatedItems.splice(index, 1, item);
      } else {
        this.updatedItems.push(item);
      }
    } else {
      const index = this.createdItems.indexOf(item);
      this.createdItems.splice(index, 1, item);
    }
  }

  public remove(item: any): void {
    if (item.isDeleted !== undefined) {
      item.isDeleted = true;
    }

    let index = itemIndex(item, this.data);
    this.data.splice(index, 1);

    index = itemIndex(item, this.createdItems);
    if (index >= 0) {
      this.createdItems.splice(index, 1);
    } else {
      this.deletedItems.push(item);
    }

    index = itemIndex(item, this.updatedItems);
    if (index >= 0) {
      this.updatedItems.splice(index, 1);
    }

    super.next(this.data);
  }

  public isNew(item: any): boolean {
    return !item.id;
  }

  public hasChanges(): boolean {
    return Boolean(this.deletedItems.length || this.updatedItems.length || this.createdItems.length);
  }

  public saveChanges(): void {
    if (!this.hasChanges()) {
      return;
    }

    const completed = [];
    if (this.deletedItems.length) {
      completed.push(this.performanceBodDataService.updatePerformanceBodNotes(this.deletedItems));
    }

    if (this.updatedItems.length) {
      completed.push(this.performanceBodDataService.updatePerformanceBodNotes(this.updatedItems));
    }

    if (this.createdItems.length) {
      const toCreate = this.createdItems.map((item:IPerformanceBodNotes) => {
        return {
          createdBy: item.createdBy,
          createdDate: item.createdDate,
          description: item.description,
          isDeleted: item.isDeleted,
          name: item.name,
          performanceBodId: item.performanceBodId,
          updatedBy: item.updatedBy,
          updatedDate: item.updatedDate
        };
      });
      completed.push(this.performanceBodDataService.createPerformanceBodNotes(toCreate));
    }

    this.reset();
    zip(...completed).subscribe(() => this.read());
  }

  public saveChangesNotified(): void {
    const creates = this.createdItems.length > 0 ? 
    this.performanceBodDataService.createPerformanceBodNotes(this.createdItems).pipe(take(1)) : EMPTY;
    const updates = this.updatedItems.length > 0 ?
    this.performanceBodDataService.updatePerformanceBodNotes(this.updatedItems).pipe(take(1)) : EMPTY;
    const deletes = this.deletedItems.length > 0 ?
    this.performanceBodDataService.updatePerformanceBodNotes(this.updatedItems).pipe(take(1)) : EMPTY;

    concat(creates, updates, deletes)
      .toPromise()
      .then(() => {
        /********* */
        this.reset();
        this.performanceBodStoreService.retrieveNotesByPerformanceBodId(this.performanceBodId);
        // this.read();
      });
  } 
  public downloadFile(folderName: string, subFolderName: string, fileName: string): void {
    this.performanceBodDataService.downloadFile(folderName, subFolderName, fileName)
  }


  public removeAttachments(fileInfo: IFileInfo[]): Observable<any> {
    return this.performanceBodDataService.removeAttachments(fileInfo);
  }

  public cancelChanges(): void {
    this.reset();
    this.read();
  }

  public assignValues(target: any, source: any): void {
    Object.assign(target, source);
  }

  private reset() {
    this.data = [];
    this.deletedItems = [];
    this.updatedItems = [];
    this.createdItems = [];
  } 
}
