import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

import { tap } from 'rxjs';
import { map } from 'rxjs';

import { environment } from 'src/environments/environment';
import { IUsrPermissions } from '../api/user-permission';

const CREATE_ACTION = 'Create';
const UPDATE_ACTION = 'Update';
const REMOVE_ACTION = 'DeleteByKey';
const LIST_ACTION = 'List';
const BASE_URL = environment.SecurityDomainURL + 'UserPermission/';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Credentials': 'true',
    'Access-Control-Allow-Methods': 'GET, HEAD, OPTIONS, POST, PUT',
  }),
};

@Injectable({
  providedIn: 'root',
})
export class UserPermissionsService extends BehaviorSubject<any[]> {
  public User: IUsrPermissions;
  private data: any[] = [];
  private dataPacer: any[] = [];
  constructor(private http: HttpClient) {
    super([]);
    httpOptions.headers.append('Access-Control-Allow-Credentials', 'true');
    httpOptions.headers.append('Access-Control-Allow-Methods', 'GET, HEAD, OPTIONS, POST, PUT');
  }

  public read() {
    if (this.data.length) {
      return super.next(this.data);
    }

    this.fetch()
      .pipe(
        tap(data => {
          this.data = data;
        })
      )
      .subscribe(data => {
        super.next(data);
      });
  }


  public remove(data: any): void {
    this.delete(REMOVE_ACTION, data).subscribe(() => this.read(), () => this.read());

    this.reset();
  }

  public resetItem(dataItem: any) {
    if (!dataItem) {
      return;
    }

    // find orignal data item
    const originalDataItem = this.data.find(item => item.Id === dataItem.Id);

    // revert changes
    Object.assign(originalDataItem, dataItem);

    super.next(this.data);
  }

  public save(data: IUsrPermissions, isNew?: boolean) {
    const action = isNew ? CREATE_ACTION : UPDATE_ACTION;
    if (!isNew) {
      this.update(action, data).subscribe(() => this.read(), () => this.read());
    } else {
      this.pull(action, data).subscribe(() => this.read(), () => this.read());
    }
    this.reset();
  }

  public getAllUsers(): Observable<IUsrPermissions[]> {
    return this.http.get<IUsrPermissions[]>(`${BASE_URL}List`);
  }

  public getUserPermissionsByCAI(cai: string): Observable<IUsrPermissions[]> {
    return this.http.get<IUsrPermissions[]>(`${BASE_URL}/ListByName/` + cai);
  }
  
  private fetch(action: string = 'List', data?: any): Observable<any[]> {
    return this.http.get(BASE_URL + `${action}`, { responseType: 'json' }).pipe(map(res => res as any[]));
  }

  private pull(action: string = 'Create', data?: IUsrPermissions): Observable<any> {
    const body = JSON.stringify([data]);
    return this.http.post(BASE_URL + `${action}`, body, httpOptions).pipe(map(res => res as any[]));
  }

  private delete(action: string = 'DeleteByKey', data?: any): Observable<any> {
    return this.http.get(BASE_URL + `${action}/${data.id}`, { responseType: 'json' }).pipe(map(res => res as any[]));
  }

  private update(action: string = 'Update', data?: any): Observable<any[]> {
    const body = JSON.stringify([data]);
    return this.http.post(BASE_URL + `${action}`, body, httpOptions).pipe(map(res => res as any[]));
  }

  private reset() {
    this.data = [];
    this.dataPacer = [];
  }
}
