import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { process, State, CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import {
  Workbook,
  WorkbookSheetColumn,
  WorkbookSheetRow,
  WorkbookSheetRowCell,
  WorkbookSheet,
  ExcelExportData,
} from '@progress/kendo-angular-excel-export';
import { saveAs } from '@progress/kendo-file-saver';
import { IFitCcWorkbook } from '../../models/fit-cc-iworkbook';
import { IFitCcWorkbookSheet } from '../../models/fit-cc-iworkbook-sheet';
import { GridComponent } from '@progress/kendo-angular-grid';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { IFitCcWorkbookTable } from '../../models/fit-cc-iworkbook-table';
import { IFitCcBaseColumn } from '../../models/fit-cc-ibase-column';
import { FitCcUtilities } from '../../models/fit-cc-utilities';
import { DatePipe } from '@angular/common';
import { EnumFitCcExcelExtension } from '../../models/fit-cc-iaudit-history';
import { FitCcInputTypes } from '../fit-cc-input/models/fit-cc-input-types.enum';

@Component({
  selector: 'fit-cc-export-to-excel',
  templateUrl: './fit-cc-export-to-excel.component.html',
  styleUrls: ['./fit-cc-export-to-excel.component.scss'],
})
export class FitCcExportToExcelComponent implements OnInit {
  public opened: boolean = false;
  public loading: boolean = false;
  private dateString: string;

  @Input() fileName: string = 'Excel';
  @Input() fileExtension: EnumFitCcExcelExtension = EnumFitCcExcelExtension.xlsx;
  @Input() buttonName: string = 'Export to Excel';

  @Input() originalColumns: IFitCcBaseColumn[] = [];
  @Input() data: any[];
  @Input() grid: GridComponent;
  @Input() exportFromGrid: boolean = false;

  @Output() exportGridData: EventEmitter<any> = new EventEmitter();

  form = new UntypedFormGroup({
    data: new UntypedFormControl('allData'),
    columns: new UntypedFormControl('allColumns'),
  });

  constructor(private util: FitCcUtilities, private datepipe: DatePipe) {
    this.dateString = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
  }

  ngOnInit() {}

  public openDialogForDataGrid() {
    if (this.exportFromGrid) {
      if (this.grid !== undefined) this.opened = true;
      else this.util.showErrorNotification('Error opening dialog');
    }
  }

  public closeDialogForDataGrid() {
    this.opened = false;
    this.loading = false;
  }

  public exportDataGrid() {
    this.loading = true;
    let columnsGrid = [];

    //getting data
    const currentData = this.data;
    let dataGrid = currentData;

    if (this.form.value.data == 'filteredData') {
      if (this.grid.filter == null) {
        this.util.showWarningNotification('Any filter has been applied yet');
      } else {
        try {
          const result: ExcelExportData = {
            data: process(currentData, { filter: this.grid.filter, sort: this.grid.sort }).data,
          };
          dataGrid = result.data;
        } catch (e) {
          this.util.showErrorNotification('Error: filters are not valid');
        }
      }
    }
    if (this.form.value.columns == 'selectedColumns') {
      this.grid.columns.forEach((column: any) => {
        if (column.isVisible) {
          let field = this.originalColumns.find(col => col.field === column.field && !column.hiddenInGrid);
          if (field != undefined) columnsGrid.push(field);
        }
      });
    } else {
      this.originalColumns.forEach((column: any) => {
        if (column.hiddenInGrid == undefined || !column.hiddenInGrid) columnsGrid.push(column);
      });
    }

    //creating worksheet
    let dataToExport = <IFitCcWorkbook>{};
    let sheet = <IFitCcWorkbookSheet>{};
    let table = <IFitCcWorkbookTable>{};
    sheet.name = this.fileName;
    sheet.tables = [];
    table.columns = columnsGrid;
    table.data = dataGrid;
    sheet.tables.push(table);

    dataToExport.sheets = [];
    dataToExport.sheets.push(sheet);

    this.createWorkbook(dataToExport);
    this.closeDialogForDataGrid();
  }

  public exportTabsExcel(data: any[]) {}

  public createWorkbook(data: IFitCcWorkbook) {
    // create sheets / tabs
    const sheets: WorkbookSheet[] = [];
    data.sheets.forEach((element: IFitCcWorkbookSheet) => {
      const sheet = this.createWorkbookSheet(element);
      sheets.push(sheet);
    });

    //assign sheet to workBook
    const workbook = new Workbook({
      sheets: sheets,
    });
    //save  file
    const fileNameCompleted = data.filename
      ? `${data.filename}.${this.fileExtension}`
      : `${this.fileName}_${this.dateString}.${this.fileExtension}`;
    workbook.toDataURL().then(dataUrl => {
      saveAs(dataUrl, fileNameCompleted);
    });
  }

  public createWorkbookSheet(data: IFitCcWorkbookSheet): WorkbookSheet {
    const sheet: WorkbookSheet = {};

    //asign Name to sheet
    sheet.name = data.name;

    //the tables that belong to this sheet
    const tablesToLoad = data.tables;
    const tables: WorkbookSheetRow[] = [];
    let amountCol = 0;
    tablesToLoad.forEach(element => {
      const table = this.createTable(element.data, element.columns);
      table.forEach(element => {
        tables.push(element);
      });

      tables.push({});
      tables.push({});
      tables.push({});

      //tables = table;
      amountCol = amountCol >= table.length ? amountCol : table.length;
    });

    // set columns in autoWith
    sheet.columns = [];
    while (amountCol >= 0) {
      const col: WorkbookSheetColumn = {};
      col.autoWidth = true;
      sheet.columns.push(col);
      amountCol--;
    }

    sheet.rows = tables;
    return sheet;
  }

  //create table / rows
  public createTable(data: any[], columnsSheet: any[]): WorkbookSheetRow[] {
    let table: WorkbookSheetRow[] = [];

    // take the columns of the table
    const columns = columnsSheet;

    // the rest is data
    const rows = data;

    //create the table according the columns and and the data
    table = this.createRow(columns, rows);

    return table;
  }

  public createRow(columns: any[], rows: any): WorkbookSheetRow[] {
    const table: WorkbookSheetRow[] = [];

    //create header, the dataRow is empty because take the header name from columns
    const headerRow: WorkbookSheetRow = {};
    headerRow.cells = this.createCell(columns, [], true, true);
    table.push(headerRow);

    if (rows != null)
      rows.forEach(dataRow => {
        //create the row, assign background color according the number of the row
        const row: WorkbookSheetRow = {};
        const drawCell = table.length % 2 !== 0 ? true : false;
        row.cells = this.createCell(columns, dataRow, false, drawCell);

        table.push(row);
      });

    return table;
  }

  public createCell(columns, dataRow: any[], isHeader: boolean, drawCell: boolean): WorkbookSheetRowCell[] {
    const cells: WorkbookSheetRowCell[] = [];
    const cellColor = isHeader ? '#808080' : '#f5f5f5';

    columns.forEach(element => {
      const cell: WorkbookSheetRowCell = {};
      let value = dataRow[element.field];
      if (value != null && element.type == 'multiselect') {
        value = value.join(', ');
      }
      if ((element.type == 'date' || element.type == FitCcInputTypes.DATETIME) && value) {
        let format = element.format ? element.format : 'MM/dd/yyyy';
        value = this.datepipe.transform(value, format);
      }
      if (element.type == 'boolean' && !isHeader) value = value ? 'Yes' : 'No';
      cell.value = isHeader ? element.title : value;
      cell.background = drawCell ? cellColor : null;

      cells.push(cell);
    });

    return cells;
  }
}
