import { Component, EventEmitter, Inject, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { State, process, CompositeFilterDescriptor, filterBy } from '@progress/kendo-data-query';
import { PerformanceBodStoreService } from 'src/app/services/performance-bod-store.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from '@progress/kendo-angular-notification';
import { SummaryNotesEditService } from './summary-notes-edit.service';
import { IPerformanceBodNotes } from 'src/app/api/performance-bod-notes';
import { DialogGridActions } from 'src/app/api/enum';
import { DialogService } from '@progress/kendo-angular-dialog';
import { SummaryNotesAttachmentComponent } from '../summary-notes-attachment/summary-notes-attachment.component';
import { FileInfo } from 'src/app/api/fileInfo';
import { PermissionsProvider } from 'src/app/services/permissions.provider';
import { AuthenticationService } from 'src/app/services';

@Component({
  selector: 'app-summary-notes',
  templateUrl: './summary-notes.component.html',
  styleUrls: ['./summary-notes.component.css'],
})
export class SummaryNotesComponent implements OnInit {
  @ViewChild('performanceBodNotesGrid', { static: true })
  public performanceBodNotesGrid: any;
  @ViewChild('attachmentsDialog', { static: true, read: ViewContainerRef })
  public containerRef: ViewContainerRef;
  public view: Observable<GridDataResult>;
  public performanceBodId: string;
  public formGroup: UntypedFormGroup;
  public gridState: State = {
    sort: [],
    skip: 0,
  };
  public filter: CompositeFilterDescriptor;
  public canEditPerformanceBod: boolean = false;
  public performanceBodNotes: IPerformanceBodNotes[];
  public summaryNotesEditService: SummaryNotesEditService;
  public changes: any = {};
  @Output() public performanceBodUpdate = new EventEmitter<string>();
  @Output() public saveBodCompleted = new EventEmitter<boolean>();

  constructor(
    private performanceBodStoreService: PerformanceBodStoreService,
    private formBuilder: UntypedFormBuilder,
    private spinner: NgxSpinnerService,
    private notificationService: NotificationService,
    private dialogService: DialogService,
    private permissionsProvider: PermissionsProvider,
    private authenticationService: AuthenticationService,
    @Inject(SummaryNotesEditService) summaryNotesEditServiceFactory: any
  ) {
    this.summaryNotesEditService = summaryNotesEditServiceFactory();
  }

  public ngOnInit() {    
    this.performanceBodStoreService.performanceBodSignatured$.subscribe((isSignature) => {
      this.canEditPerformanceBod = isSignature? false: this.permissionsProvider.canEditPerformanceBod;
    });

    // Set the performance bod identification
    this.performanceBodStoreService.performanceBodId$.subscribe(id => {
      this.performanceBodId = id;
      this.summaryNotesEditService.performanceBodId = id;
      // Get async array
      this.summaryNotesEditService.read();
    });

    this.view = this.summaryNotesEditService.pipe(
      map(data => {
        return process(filterBy(data, this.filter), this.gridState);
      })
    );
  }

  public createFormGroup(dataItem: any): UntypedFormGroup {
    return this.formBuilder.group({
      description: [
        dataItem.description != null && dataItem.description !== undefined
          ? dataItem.description
          : dataItem.dataItem != null
          ? dataItem.dataItem.description
          : null,
      ],
      id:
        dataItem.id != null && dataItem.id !== undefined
          ? dataItem.id
          : dataItem.dataItem != null
          ? dataItem.dataItem.id
          : null,
      performanceBodId:
        dataItem.performanceBodId != null && dataItem.performanceBodId !== undefined
          ? dataItem.performanceBodId
          : dataItem.dataItem != null
          ? dataItem.dataItem.performanceBodId
          : null,
      isDeleted: [
        dataItem.isDeleted != null && dataItem.isDeleted !== undefined
          ? dataItem.isDeleted
          : dataItem.dataItem != null
          ? dataItem.dataItem.isDeleted
          : null,
      ],
      name: [
        dataItem.name != null && dataItem.name !== undefined
          ? dataItem.name
          : dataItem.dataItem != null
          ? dataItem.dataItem.name
          : null,
        [Validators.required],
      ],
      performanceBodNotesAttachments: [dataItem.performanceBodNotesAttachments],
      createdBy:
        dataItem.createdBy != null && dataItem.createdBy !== undefined
          ? dataItem.createdBy
          : dataItem.dataItem != null
          ? dataItem.dataItem.createdBy
          : null,
      createdDate:
        dataItem.createdDate != null && dataItem.createdDate !== undefined
          ? dataItem.createdDate
          : dataItem.dataItem != null
          ? dataItem.dataItem.createdDate
          : null,
      updatedBy:
        dataItem.updatedBy != null && dataItem.updatedBy !== undefined
          ? dataItem.updatedBy
          : dataItem.dataItem != null
          ? dataItem.dataItem.updatedBy
          : null,
      updatedDate:
        dataItem.updatedDate != null && dataItem.updatedDate !== undefined
          ? dataItem.updatedDate
          : dataItem.dataItem != null
          ? dataItem.dataItem.updatedDate
          : null,
    });
  }

  public cellClickHandler({ sender, rowIndex, columnIndex, dataItem, isEdited }) {
    if (!isEdited && this.canEditPerformanceBod) {
      sender.editCell(rowIndex, columnIndex, this.createFormGroup(dataItem));
    }
  }

  public cellCloseHandler(args: any) {
    const { formGroup, dataItem } = args;

    if (!formGroup.valid) {
      // prevent closing the edited cell if there are invalid values.
      args.preventDefault();
    } else if (formGroup.dirty) {
      dataItem.updatedDate = new Date();
      dataItem.updatedBy = this.authenticationService.getCurrentUser();

      // Date is not working in Object.assign(), so need to be do it manually
      formGroup.value.updatedBy = dataItem.updatedBy;
      formGroup.value.updatedDate = dataItem.updatedDate;

      this.summaryNotesEditService.assignValues(dataItem, formGroup.value);
      this.summaryNotesEditService.update(dataItem);
    }
  }

  public addHandler({ sender }) {
    const item: IPerformanceBodNotes = {
      id: null,
      name: null,
      description: null,
      performanceBodNotesAttachments: [],
      performanceBod: null,
      performanceBodId: this.performanceBodId,
      isDeleted: false,
      createdBy: this.authenticationService.getCurrentUser(),
      createdDate: new Date(),
      updatedBy: this.authenticationService.getCurrentUser(),
      updatedDate: new Date(),
    };

    sender.addRow(this.createFormGroup(item));
  }

  public cancelHandler({ sender, rowIndex }) {
    sender.closeRow(rowIndex);
  }

  public saveHandler({ sender, formGroup, rowIndex }) {
    if (formGroup.valid) {
      this.summaryNotesEditService.create(formGroup.value);
      sender.closeRow(rowIndex);
    }
  }

  public removeHandler({ sender, dataItem }) {
    this.summaryNotesEditService.remove(dataItem);
    if (dataItem.performanceBodNotesAttachments) {
      dataItem.performanceBodNotesAttachments.forEach((attachment: IPerformanceBodNotes) => {
        const fileInfo = new FileInfo();
        fileInfo.fileName = attachment.name;
        fileInfo.folderName = 'PerformanceBodNotes';
        fileInfo.subFolderName = this.performanceBodId;
        this.summaryNotesEditService.deletedAttachments.push(fileInfo);
      });
    }
    sender.cancelCell();
  }

  public saveChanges(grid: any): void {
    grid.closeCell();
    grid.cancelCell();
    this.spinner.show();
    this.summaryNotesEditService.saveChanges();
    this.performanceBodUpdate.next(this.authenticationService.getCurrentUser());
  }

  public onOpenAttachmentPopUp(dataItem: IPerformanceBodNotes, formGroup: UntypedFormGroup, grid: any, rowIndex: any): void {
    // if (dataItem.id) {
    //   this.performanceBodStoreService.retrieveNotesAttachmentByPerformanceBodNotesId(dataItem.id);
    // }
    this.performanceBodStoreService.retrieveNotesAttachmentByPerformanceBodNotesId(dataItem.id);
    const dialogRef = this.dialogService.open({
      appendTo: this.containerRef,
      content: SummaryNotesAttachmentComponent,
      title: 'Attachments for Note',
      width: 700,
    });

    dialogRef.result.subscribe((r: any) => {
      // The meaning of this switch was handle the logic of the returned value, consider change or removed by fixed code
      switch (r.dialogGridAction as DialogGridActions) {
        case DialogGridActions.NewAtParent:
          dataItem.performanceBodNotesAttachments = r.attachments;
          this.cancelHandler({ sender: grid, rowIndex });
          // Removed
          this.removeInCaseWasCreated(dataItem, rowIndex);
          this.summaryNotesEditService.saveChangesNotified();
          break;
        case DialogGridActions.EditedBeforeCancel:
          this.cancelHandler({ sender: grid, rowIndex });
          // Removed
          this.removeInCaseWasCreated(dataItem, rowIndex);
          this.summaryNotesEditService.saveChangesNotified();
          break;
        case DialogGridActions.Saved:
          this.cancelHandler({ sender: grid, rowIndex });
          // Removed
          this.removeInCaseWasCreated(dataItem, rowIndex);
          this.summaryNotesEditService.saveChangesNotified();
          break;
        case DialogGridActions.NotEdited:
          // do nothing
          break;
      }

      if ((r.dialogGridAction as DialogGridActions) !== DialogGridActions.NotEdited) {
        this.performanceBodUpdate.next(this.authenticationService.getCurrentUser());
      }
    });

    const dialogInfo = dialogRef.content.instance;
    dialogInfo.performanceBodNotes = dataItem;
    dialogInfo.performanceBodId = this.performanceBodId;
  }

  public removeInCaseWasCreated(dataItem: any, rowIndex: number): void {
    const index = this.summaryNotesEditService.createdItems.indexOf(dataItem, 0);
    if (index > -1) {
      this.summaryNotesEditService.createdItems.splice(rowIndex, 1);
    }
  }

  public saveFromBOD(): void {
    this.performanceBodNotesGrid.closeCell();
    this.performanceBodNotesGrid.cancelCell();

    if (this.summaryNotesEditService.hasChanges()) {
      this.summaryNotesEditService.saveChanges();
      this.performanceBodUpdate.next(this.authenticationService.getCurrentUser());

      // Remove garbage files in case exists
      if (this.summaryNotesEditService.deletedAttachments.length > 0) {
        this.summaryNotesEditService.removeAttachments(this.summaryNotesEditService.deletedAttachments).subscribe(r => {
          this.summaryNotesEditService.deletedAttachments = [];
        });
      }

      const subscription = this.summaryNotesEditService.subscribe(() => {
        this.saveBodCompleted.next(true);
      });

      subscription.unsubscribe();
    }
  }

  public cancelChanges(): void {
    this.performanceBodNotesGrid.cancelCell();

    this.summaryNotesEditService.cancelChanges();
  }

  public onStateChange(state: State) {
    this.gridState = state;
    this.summaryNotesEditService.read();
  }

  public handlerFilter(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
  }
}
