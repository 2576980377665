import { Component, EventEmitter, OnInit, Output, ViewChild, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { GridDataResult, GridComponent, RowClassArgs } from '@progress/kendo-angular-grid';
import { NotificationService } from '@progress/kendo-angular-notification';
import { process, State, filterBy, CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { map } from 'rxjs';
import { IPerformanceBodPads } from 'src/app/api/performance-bod-pads';
import { IPerformanceBodCentralTankBattery } from 'src/app/api/performance-bod-central-tank-battery';
import { IPerformanceBodPackages } from 'src/app/api/performance-bod-package';
import { IPerformanceBodSummary } from 'src/app/api/performance-bod-summary';
import { StandardFacilitiesSummaryService } from 'src/app/modules/performance-bod/standard-facilities-summary/standard-facilities-summary.service';
import { PerformanceBodStoreService } from 'src/app/services/performance-bod-store.service';
import { PerformanceBodCentralTankBatteryService } from './standard-facilities-central-tank-battery.service';
import { UtilService } from 'src/app/services/util.service';
import { PermissionsProvider } from 'src/app/services/permissions.provider';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DragAndDropService } from 'src/app/_shared/drag-and-drop.service';
import { PerformanceConfigurationService } from 'src/app/services/performance-configuration-service';
import { Globals } from 'src/app/_shared/Globals';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { PerformanceAFEService } from '../performance-afe/performance-afe.service';

const DRAGGABLE_SELECTOR = '.standard-facilities-ctb-draggable';

@Component({
  selector: 'app-standard-facilities-central-tank-battery',
  templateUrl: './standard-facilities-central-tank-battery.component.html',

})
export class StandardFacilitiesCentralTankBatteryComponent implements OnInit {
  public filter: CompositeFilterDescriptor;
  public filterable: boolean = true;
  public warningMessage: string =
    'Please provide a Well Pad Name on the Package Information Section (Performance BOD Summary Tab) before adding a new record';
  public rowIndex: any = -1;
  public countWellPadName: number = 0;
  public openDialog: boolean = false;
  public messageDuplicate: boolean = true;
  public wellPadName: string = 'Pending To Add Well Pad Name field';
  public view: Observable<GridDataResult>;
  public performanceBodPackagesList: IPerformanceBodPackages[];
  public performanceStdFacilitiesList: IPerformanceBodSummary[];
  public performanceBodPadsList: IPerformanceBodPads[];
  public performanceBodId: string;
  public padNames: string[] = [];
  public formGroup: UntypedFormGroup;
  public gridState: State = {
    sort: [],
    skip: 0,
  };
  public canEditPerformanceBod: boolean = false;
  public performanceBodCentralTankBatteryService: PerformanceBodCentralTankBatteryService;
  @Output() public performanceBodUpdate = new EventEmitter<string>();
  @Output() public saveBodCompleted = new EventEmitter<boolean>();
  @ViewChild('performanceBodCTB') public performanceBodCTBGrid: GridComponent;
  // public performanceBodCTBGrid: any;
  private dragAndDropService: DragAndDropService;
  public perfAfeSvc: PerformanceAFEService;
  public afeList: any[];
  constructor(
    public globals: Globals,
    private performanceBodStoreService: PerformanceBodStoreService,
    private formBuilder: UntypedFormBuilder,
    private standardFacilitiesSummaryService: StandardFacilitiesSummaryService,
    private utilService: UtilService,
    private permissionsProvider: PermissionsProvider,
    private authenticationService: AuthenticationService,
    private performanceConfigurationService: PerformanceConfigurationService,
    @Inject(DragAndDropService) dragAndDropServiceFactory: any,
    @Inject(PerformanceBodCentralTankBatteryService) performanceBodCentralTankBatteryServiceFactory: any,
    private route: ActivatedRoute,
    @Inject(PerformanceAFEService) performanceAFEServiceFactory: any,
  ) {
    this.dragAndDropService = dragAndDropServiceFactory();
    this.performanceBodCentralTankBatteryService = performanceBodCentralTankBatteryServiceFactory();
    this.perfAfeSvc = performanceAFEServiceFactory();
  }
  public ngOnInit() {
    this.performanceBodStoreService.performanceBodSignatured$.subscribe((isSignature) => {
      this.canEditPerformanceBod = isSignature ? false : this.permissionsProvider.canEditPerformanceBod;
    });

    this.performanceBodStoreService.performanceBodPackagesData$.subscribe(data => {
      this.performanceBodPackagesList = data;
    });

    this.performanceBodStoreService.performanceBodPadsData$.subscribe(data => {
      this.performanceBodPadsList = data.filter(row => row.wellPadName !== '');
    });

    this.performanceBodStoreService.performanceBodId$.subscribe(id => {
      this.performanceBodId = id;
      this.perfAfeSvc.performanceBodId = id;
      this.performanceBodCentralTankBatteryService.performanceBodId = id;
      this.performanceBodCentralTankBatteryService.read();
    });

    this.performanceBodStoreService.performanceBodSummary$.subscribe(data => {
      this.performanceStdFacilitiesList = data;
    });

    this.view = this.performanceBodCentralTankBatteryService.pipe(
      map(data => {
        this.dragAndDropService.dataSourceRefreshed(data);
        return process(filterBy(data, this.filter), this.gridState);
      })
    );

    this.dragAndDropService.initialize(this.performanceBodCentralTankBatteryService.data, DRAGGABLE_SELECTOR, () => {
      this.performanceBodCentralTankBatteryService.markAsSetOrder(this.authenticationService.getCurrentUser());
    });
  }

  public ngAfterViewInit(): void {
    // To Do
  }

  public ngOnDestroy(): void {
    this.dragAndDropService.refreshDragAndDrop();
  }

  public createFormGroup(dataItem: any): UntypedFormGroup {
    return this.formBuilder.group({
      id: new UntypedFormControl(dataItem.id),
      isDeleted: new UntypedFormControl(dataItem.isDeleted),
      sortOrder: new UntypedFormControl(dataItem.sortOrder),
      name: new UntypedFormControl(dataItem.name, [Validators.required, Validators.minLength(1), Validators.maxLength(100)]),
      performanceBodPadsId: new UntypedFormControl(dataItem.performanceBodPadsId, [Validators.required]),
      performanceBodPackagesId: new UntypedFormControl(dataItem.performanceBodPackagesId),
      chargeCode: new UntypedFormControl(dataItem.chargeCode, [
        Validators.maxLength(13),
        Validators.pattern('^[a-zA-Z0-9]{0,13}$'),
      ]),
      cndHOEstimate: new UntypedFormControl(dataItem.cndHOEstimate, [Validators.min(0), Validators.max(999999999999)]),
      oilBopd: new UntypedFormControl(dataItem.oilBopd, [Validators.required, Validators.min(0), Validators.max(1000000)]),
      waterBwpd: new UntypedFormControl(dataItem.waterBwpd, [Validators.required, Validators.min(0), Validators.max(1000000)]),
      lpGasMmscfd: new UntypedFormControl(dataItem.lpGasMmscfd, [
        Validators.required,
        Validators.min(0),
        Validators.max(1000000),
      ]),
      oilBopdP70: new UntypedFormControl(dataItem.oilBopdP70, [
        Validators.required,
        Validators.min(0),
        Validators.max(1000000),
      ]),
      waterBwpdP70: new UntypedFormControl(dataItem.waterBwpdP70, [
        Validators.required,
        Validators.min(0),
        Validators.max(1000000),
      ]),
     
      hpGasMmscfdP70: new UntypedFormControl(dataItem.hpGasMmscfdP70, [
        Validators.required,
        Validators.min(0),
        Validators.max(1000000),
      ]),

      rfsdDate: new UntypedFormControl(dataItem.rfsdDate),
      comments: new UntypedFormControl(dataItem.comments, [Validators.maxLength(500)]),
      createdBy: new UntypedFormControl(dataItem.createdBy),
      createdDate: new UntypedFormControl(dataItem.createdDate),
      updatedBy: new UntypedFormControl(dataItem.updatedBy),
      updatedDate: new UntypedFormControl(dataItem.updatedDate),
      afeNumber: new UntypedFormControl(dataItem.afeNumber, [
        Validators.maxLength(13),
        Validators.pattern('^[a-zA-Z0-9]{0,13}$'),
      ]),  
      afeAmount: new UntypedFormControl(dataItem.afeAmount, [
        Validators.maxLength(8),
        Validators.pattern('^[a-zA-Z0-9]{0,8}$'),
      ]),  
           
    });
  }

  public cellClickHandler({ sender, rowIndex, columnIndex, dataItem, isEdited }) {
    if (!isEdited && this.canEditPerformanceBod) {
      if (dataItem.rfsdDate !== undefined && columnIndex === 11) {
        if (dataItem.rfsdDate !== null) {
          const y = new Date(dataItem.rfsdDate.toString()).getFullYear();
          const m = new Date(dataItem.rfsdDate.toString()).getMonth();
          const d = new Date(dataItem.rfsdDate.toString()).getDate();
          dataItem.rfsdDate = new Date(y, m, d);
        }
      }
      sender.editCell(rowIndex, columnIndex, this.createFormGroup(dataItem));
      this.dragAndDropService.onEditingMode();
    }
  }

  public cellCloseHandler(args: any) {
    const { formGroup, dataItem, column } = args;

    let columnField = '';

    if (column.field === 'performanceBodPackagesName') {
      columnField = 'performanceBodPackagesId';
    }
    if (column.field === 'performanceBodPadsName') {
      columnField = 'performanceBodPadsId';
    } else {
      columnField = column.field;
    }

    const checkColumn = formGroup.controls[columnField];

    if (!checkColumn.valid) {
      args.preventDefault();
    } else if (checkColumn.dirty) {
      if (columnField === 'performanceBodPackagesId') {
        dataItem.performanceBodPackagesName = this.getPerformanceBodPackages(formGroup.controls[columnField].value);
      }
      if (columnField === 'performanceBodPadsId') {
        dataItem.performanceBodPadsName = this.getPerformanceBodPads(formGroup.controls[columnField].value);
      }
    }

    if (!formGroup.valid) {
      // prevent closing the edited cell if there are invalid values.
      args.preventDefault();
    } else if (formGroup.dirty) {
      if (formGroup.valid && args.sender.data.total > 0) {
        for (let i = 0; i <= args.sender.data.total - 1; i++) {
          if (
            args.sender.data.data[i].name === formGroup.value.name.trim() &&
            args.sender.data.data[i].performanceBodPadsId === formGroup.value.performanceBodPadsId &&
            args.sender.data.data[i].id !== formGroup.value.id
          ) {
            this.messageDuplicate = false;
            args.preventDefault();
            return;
          } else {
            this.messageDuplicate = true;
          }
        }
      }
      if (this.messageDuplicate === true) {
        dataItem.updatedDate = new Date();
        dataItem.updatedBy = this.authenticationService.getCurrentUser();

        // Date is not working in Object.assign(), so need to be do it manually
        dataItem.updatedDate = new Date();
        dataItem.updatedBy = this.authenticationService.getCurrentUser();

        // Date is not working in Object.assign(), so need to be do it manually
        formGroup.value.updatedBy = dataItem.updatedBy;
        formGroup.value.updatedDate = dataItem.updatedDate;

        this.performanceBodCentralTankBatteryService.assignValues(dataItem, formGroup.value);
        this.performanceBodCentralTankBatteryService.updateItem(dataItem);
      }
    }
    // calls this to add the attribute of current row
    this.dragAndDropService.onEditingClose();
    this.dragAndDropService.refreshDragAndDrop();
  }

  public addHandler({ sender, rowIndex }) {
    const item: IPerformanceBodCentralTankBattery = {
      id: Guid.create().toString(),
      name: '',
      performanceBodPadsId: '',
      performanceBodPackagesId: '',
      chargeCode: '',
      cndHOEstimate: 0,
      oilBopd: 0,
      waterBwpd: 0,
      lpGasMmscfd: 0,
      oilBopdP70: 0,
      waterBwpdP70: 0,
      hpGasMmscfdP70: 0,
      rfsdDate: null,
      comments: '',
      isDeleted: false,
      createdBy: this.authenticationService.getCurrentUser(),
      createdDate: new Date(),
      updatedBy: null,
      updatedDate: null,
      sortOrder: 0,
      afeNumber: '',
      afeAmount: 0,
    };
    this.dragAndDropService.onEditingMode();

    if (this.performanceBodPadsList.length >= 0) {
      for (const pad of this.performanceBodPadsList) {
        if (pad.wellPadName === '') {
          this.countWellPadName = this.countWellPadName + 1;
        }
      }
      if (this.countWellPadName === this.performanceBodPadsList.length) {
        this.openDialog = true;
        this.cancelHandler({ sender, rowIndex });
        this.countWellPadName = 0;
        return;
      } else {
        item.createdBy = this.authenticationService.getCurrentUser();
        item.createdDate = new Date();
        sender.addRow(this.createFormGroup(item));
      }
    }
  }

  public cancelHandler({ sender, rowIndex }) {
    this.messageDuplicate = true;
    sender.closeRow(rowIndex);
    this.dragAndDropService.onEditingClose();
    this.dragAndDropService.refreshDragAndDrop();
  }

  public saveHandler({ sender, formGroup, rowIndex }) {
    if (formGroup.valid && sender.data.total > 0) {
      for (let i = 0; i <= sender.data.total - 1; i++) {
        if (
          sender.data.data[i].name === formGroup.value.name.trim() &&
          sender.data.data[i].performanceBodPadsId === formGroup.value.performanceBodPadsId &&
          sender.data.data[i].id !== formGroup.value.id
        ) {
          this.messageDuplicate = false;
          return;
        } else {
          this.messageDuplicate = true;
        }
      }
    }

    if (this.messageDuplicate === true) {
      if (formGroup.valid) {
        if (this.performanceBodPadsList.length >= 0) {
          for (const pad of this.performanceBodPadsList) {
            if (pad.wellPadName === '') {
              this.countWellPadName = this.countWellPadName + 1;
            }
          }
          if (this.countWellPadName === this.performanceBodPadsList.length) {
            this.openDialog = true;
            this.countWellPadName = 0;
            return;
          } else {
            formGroup.value.name = formGroup.value.name.trim();
            this.performanceBodCentralTankBatteryService.createItem(formGroup.value);
            sender.closeRow(rowIndex);
            this.dragAndDropService.onEditingClose();
            this.dragAndDropService.dataSourceRefreshed(this.performanceBodCentralTankBatteryService.data);
            this.dragAndDropService.refreshDragAndDrop();
            this.performanceBodCentralTankBatteryService.markAsSetOrder(this.permissionsProvider.cai);
          }
        }
      } else {
        this.utilService.markFormGroupAsTouched(formGroup);
      }
    }
  }

  public removeHandler({ sender, dataItem }) {
    dataItem.isDeleted = true;
    this.performanceBodCentralTankBatteryService.removeItem(dataItem);

    sender.cancelCell();
  }

  public saveFromBOD(countNotification: number): number {
    if (this.performanceBodCTBGrid.isEditing()) {
      this.performanceBodCTBGrid.closeCell();
    }

    const centralTankBateryId = this.performanceConfigurationService.appConfigs.find(
      config => config.code === this.globals.APP_CONFIG_FACILITY_TYPE_CTB_ID
    );

    if (this.performanceBodCentralTankBatteryService.hasChanges()) {
      countNotification = countNotification + 1;
      this.performanceBodCentralTankBatteryService.createdItems.forEach(element => {
        const item: IPerformanceBodSummary = {
          id: Guid.create().toString(),
          trainsCompressorPumpsId: 'a50366f1-7a72-44b0-899e-f5492a1ad688',
          performanceBodPadsId: element.performanceBodPadsId,
          performanceBodPadsName: null,
          comments: null,
          numberOfTrains: null,
          numberOfCompressors: null,
          numberOfPumps: null,
          numberOf3608: null,
          numberOf3516: null,
          numberOf3606: null,
          productionRatesOil: null,
          productionRatesWater: null,
          productionRatesGas: null,
          facilityCapacityOil: null,
          facilityCapacityWater: null,
          facilityCapacityGas: null,
          spGravityOil: null,
          spGravityWater: null,
          spGravityGas: null,
          numberAfe: null,
          sourCorrosive: false,
          sourCorrosiveText: 'No',
          latitude: null,
          longitude: null,
          facilityTypeId: centralTankBateryId.attributeId,
          facilityTypeName: null,
          pisAssociatedQuestion: null,
          stdDesignQuestion: null,
          gasRequiredQuestion: null,
          hdpeQuestion: null,
          fiberOpticQuestion: null,
          blmProjectQuestion: null,
          facilityLocationQuestion: null,
          sftDesignYear: null,
          readyForServiceDate: null,
          bodLockdownDate: null,
          isDeleted: false,
          createdBy: this.authenticationService.getCurrentUser(),
          createdDate: new Date(),
          updatedBy: null,
          updatedDate: null,
          performanceBodCtbSwdCs: element.id,
          sortOrder: 0,
         // afeId: element.afeId
        };
        this.standardFacilitiesSummaryService.createdItems.push(item);
      });

      this.performanceBodCentralTankBatteryService.deletedItems.forEach(element => {
        const findIt = this.standardFacilitiesSummaryService.originalData.find(
          x => x.performanceBodCtbSwdCs === element.id
        );

        if (findIt) {
          findIt.performanceBodPadsId = element.performanceBodPadsId;
          findIt.isDeleted = true;
          findIt.updatedBy = this.authenticationService.getCurrentUser();
          findIt.updatedDate = new Date();
         // findIt.afeId = element.afeId;
          this.standardFacilitiesSummaryService.deletedItems.push(findIt);

          // Remove from updated in case it need does
          const deletedIndex = this.standardFacilitiesSummaryService.updatedItems.findIndex(x => x.id === findIt.id);
          if (deletedIndex > -1) {
            this.standardFacilitiesSummaryService.updatedItems.splice(deletedIndex, 1);
          }
        }
      });

      this.performanceBodCentralTankBatteryService.updatedItems.forEach(element => {
        const findIt = this.standardFacilitiesSummaryService.originalData.find(
          x => x.performanceBodCtbSwdCs === element.id
        );

        // First review if is not un the delete list yet.
        const willBeDeleted = this.standardFacilitiesSummaryService.deletedItems.find(
          x => x.performanceBodPadsId === findIt.performanceBodCtbSwdCs
        );
        if (!willBeDeleted) {
          if (findIt) {
            const toBeUpdated = this.standardFacilitiesSummaryService.updatedItems.find(
              x => x.performanceBodCtbSwdCs === findIt.performanceBodCtbSwdCs
            );
            if (toBeUpdated) {
              toBeUpdated.performanceBodPadsId = element.performanceBodPadsId;
              toBeUpdated.updatedBy = this.authenticationService.getCurrentUser();
              toBeUpdated.updatedDate = new Date();
            //  toBeUpdated.afeId = element.afeId;
            } else {
              const item: IPerformanceBodSummary = {
                id: findIt.id,
                trainsCompressorPumpsId: findIt.trainsCompressorPumpsId,
                performanceBodPadsId: element.performanceBodPadsId,
                performanceBodPadsName: findIt.performanceBodPadsName,
                comments: findIt.comments,
                numberOfTrains: findIt.numberOfTrains,
                numberOfCompressors: null,
                numberOfPumps: null,
                numberOf3608: null,
                numberOf3516: null,
                numberOf3606: null,
                productionRatesOil: null,
                productionRatesWater: null,
                productionRatesGas: null,
                facilityCapacityOil: null,
                facilityCapacityWater: null,
                facilityCapacityGas: null,
                spGravityOil: null,
                spGravityWater: null,
                spGravityGas: null,
                numberAfe: null,
                sourCorrosive: false,
                sourCorrosiveText: 'No',
                latitude: null,
                longitude: null,
                facilityTypeId: centralTankBateryId.attributeId,
                facilityTypeName: null,
                pisAssociatedQuestion: null,
                stdDesignQuestion: null,
                gasRequiredQuestion: null,
                hdpeQuestion: null,
                fiberOpticQuestion: null,
                blmProjectQuestion: null,
                facilityLocationQuestion: null,
                sftDesignYear: findIt.sftDesignYear,
                readyForServiceDate: findIt.readyForServiceDate,
                bodLockdownDate: findIt.bodLockdownDate,
                isDeleted: false,
                createdBy: findIt.createdBy,
                createdDate: findIt.createdDate,
                updatedBy: this.authenticationService.getCurrentUser(),
                updatedDate: new Date(),
                performanceBodCtbSwdCs: findIt.performanceBodCtbSwdCs,
                sortOrder: 0,
                //afeId: findIt.afeId
              };
              this.standardFacilitiesSummaryService.updatedItems.push(item);
            }
          }
        }
      });
      // calls this to add the attribute of current row
      this.dragAndDropService.refreshDragAndDrop();
      this.performanceBodCentralTankBatteryService.saveChanges();
      this.performanceBodUpdate.next(this.authenticationService.getCurrentUser());
      const subscription = this.performanceBodCentralTankBatteryService.subscribe(() => {
        this.saveBodCompleted.next(true);
      });

      subscription.unsubscribe();
      if (
        this.standardFacilitiesSummaryService.createdItems.length > 0 ||
        this.standardFacilitiesSummaryService.deletedItems.length > 0 ||
        this.standardFacilitiesSummaryService.updatedItems.length > 0
      ) {
        // calls this to add the attribute of current row
        this.standardFacilitiesSummaryService.markAsSetOrder(this.permissionsProvider.cai);
        this.standardFacilitiesSummaryService.saveChanges();
        this.standardFacilitiesSummaryService.cancelChanges();
      }

      setTimeout(() => {
        this.performanceBodCentralTankBatteryService.cancelChanges();
        this.standardFacilitiesSummaryService.cancelChanges();
      }, 3000);
    }
    return countNotification;
  }

  public cancelChanges(grid: any): void {
    this.messageDuplicate = true;
    grid.cancelCell();
    this.dragAndDropService.refreshDragAndDrop();
    this.performanceBodCentralTankBatteryService.cancelChanges();
  }

  public onStateChange(state: State) {
    this.gridState = state;
    this.performanceBodCentralTankBatteryService.read();

    // const viewSubscription = this.view.subscribe(data => {
    //   this.dragAndDropService.dataSourceRefreshed(data.data);
    // });

    // viewSubscription.unsubscribe();
    // this.dragAndDropService.runZoneOnStable();
  }

  public getPerformanceBodPackages(id: string): any {
    if (this.performanceBodPackagesList === null || this.performanceBodPackagesList === undefined) {
      return '';
    }
    return this.performanceBodPackagesList.find(x => x.id === id);
  }

  public changePackage(value, dataItem, formGroup: UntypedFormGroup) {
    let performanceBodPad: IPerformanceBodPads[];
    let performanceBodPackagesId: string;
    performanceBodPad = this.performanceBodPadsList.filter(s => s.id === value);
    performanceBodPad.forEach(element => {
      performanceBodPackagesId = element.performanceBodPackagesId;
    });

    dataItem.performanceBodPackagesId = performanceBodPackagesId;
    formGroup.patchValue({
      performanceBodPackagesId,
    });
  }

  public getPerformanceBodPads(id: string): any {
    if (this.performanceBodPadsList === null || this.performanceBodPadsList === undefined) {
      return '';
    } else {
      return this.performanceBodPadsList.find(x => x.id === id);
    }
  }

  public closeKendoPopUp() {
    this.openDialog = false;
  }

  public handlerFilter(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    if (filter.filters) {
      if (filter.filters.length > 0) {
        this.dragAndDropService.onEditingMode();
      } else {
        this.dragAndDropService.onEditingClose();
        this.dragAndDropService.refreshDragAndDrop();
      }
    }
  }

  public rowCallback(context: RowClassArgs) {
    return {
      dragging: context.dataItem.dragging,
    };
  }

  public rowLoaded(): void {
    // to check until grid have been loeaded.
    this.dragAndDropService.checkIsOnEditMode();
    this.dragAndDropService.checkDraggableElementLoaded();
  }

  public getAfe(id: string): any {
    if (this.afeList === null || this.afeList === undefined) {
      return '';
    }
    return this.afeList.find(x => x.id === id);
  }

  public changeAfe(value, dataItem, formGroup: UntypedFormGroup) {
    let afeArray: any[];
    let afeId: string;
    afeArray = this.afeList.filter(s => s.id === value);
    afeArray.forEach(element => {
      afeId = element.id;
    });

    dataItem.afeId = afeId;
    formGroup.patchValue({
      afeId,
    });
  }

  public getAfeList() {
    this.perfAfeSvc.performanceBodId = this.performanceBodId;
    this.perfAfeSvc.retrieve().subscribe((data) => {
      if (data.length > 0) {
        this.afeList = data;
      }
    });
  }
}
