import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpErrorHandler, HandleError } from './http-error-handler.service';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { IPipelineMaterialType } from '../api/pipeline-material-type';
import { IPipelineMaterialSubType } from '../api/pipeline-material-sub-type';
import { catchError } from 'rxjs';
import { IPipelineType } from '../api/pipeline-type';

@Injectable({
  providedIn: 'root',
})
export class CataloguesService {
  public pipelineMaterialTypeData$: Observable<IPipelineMaterialType[]>;
  public pipelineMaterialSubTypeData$: Observable<IPipelineMaterialSubType[]>;
  public pipelineTypeData$: Observable<IPipelineType[]>;
  private pipelineMaterialTypeDataValue$: BehaviorSubject<IPipelineMaterialType[]>;
  private piplelineMaterialSubTypeDataValue$: BehaviorSubject<IPipelineMaterialSubType[]>;
  private pipelineTypeDataValue$: BehaviorSubject<IPipelineType[]>;
  private baseUrlPipelineType: string = environment.PacerDomainURL + 'PipelineType';
  private baseUrlPipelineMaterialType: string = environment.PacerDomainURL + 'PipelineMaterialType';
  private baseUrlPipelineMaterialSubType: string = environment.PacerDomainURL + 'PipelineMaterialSubType';

  private handleError: HandleError;
  constructor(private http: HttpClient, httpErrorHandler: HttpErrorHandler) {
    this.pipelineTypeDataValue$ = new BehaviorSubject(null);
    this.pipelineMaterialTypeDataValue$ = new BehaviorSubject(null);
    this.piplelineMaterialSubTypeDataValue$ = new BehaviorSubject(null);
    this.pipelineTypeData$ = this.pipelineTypeDataValue$.asObservable();
    this.pipelineMaterialTypeData$ = this.pipelineMaterialTypeDataValue$.asObservable();
    this.pipelineMaterialSubTypeData$ = this.piplelineMaterialSubTypeDataValue$.asObservable();
    this.handleError = httpErrorHandler.createHandleError();
  }

  public load(): void {
    this.getPipelineTypeCatalogue();
    this.getPipelineMaterialTypeCatalogue();
    this.getPipelineMaterialSubTypeCatalogue();
  }

  public getPipelineTypeCatalogue() {
    this.getPipelineType().subscribe(
      (pipelineTypes: IPipelineType[]) => {
        this.pipelineTypeDataValue$.next(pipelineTypes);
      },
      (error: any) => {
        // ToDo
      }
    );
  }

  public getPipelineMaterialTypeCatalogue() {
    this.getPipelineMaterialType().subscribe(
      (pipelineMaterialTypes: IPipelineMaterialType[]) => {
        this.pipelineMaterialTypeDataValue$.next(pipelineMaterialTypes);
      },
      (error: any) => {
        // ToDo
      }
    );
  }

  public getPipelineMaterialSubTypeCatalogue() {
    this.getPipelineMaterialSubType().subscribe(
      (pipelineMaterialSubTypes: IPipelineMaterialSubType[]) => {
        this.piplelineMaterialSubTypeDataValue$.next(pipelineMaterialSubTypes);
      },
      (error: any) => {
        // ToDo
      }
    );
  }

  private getPipelineType(): Observable<IPipelineType[]> {
    return this.http.get<IPipelineType[]>(`${this.baseUrlPipelineType}/List/`).pipe(
      catchError(error => {
        this.handleError('getPipelineType');
        return throwError(error);
      })
    );
  }

  private getPipelineMaterialType(): Observable<IPipelineMaterialType[]> {
    return this.http.get<IPipelineMaterialType[]>(`${this.baseUrlPipelineMaterialType}/List/`).pipe(
      catchError(error => {
        this.handleError('getPipelineMaterialType');
        return throwError(error);
      })
    );
  }

  private getPipelineMaterialSubType(): Observable<IPipelineMaterialSubType[]> {
    return this.http.get<IPipelineMaterialSubType[]>(`${this.baseUrlPipelineMaterialSubType}/List/`).pipe(
      catchError(error => {
        this.handleError('getPipelineMaterialSubType');
        return throwError(error);
      })
    );
  }
}
