import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, zip } from 'rxjs';
import { catchError, map } from 'rxjs';
import 'rxjs';
import { environment } from '../../../../environments/environment';
import { HandleError, HttpErrorHandler } from '../../../services/http-error-handler.service';
import { PAndEProjectBodStatusService } from './../_services/p-and-e-project-bod-status.service';
import { Globals } from '../../../_shared/Globals';
import * as Helpers from '../../../_shared/helpers';
import { IPAndEProjectCostSignatures } from '../_models/pAndE-project-cost-signatures';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class PAndESignaturesService {
  public pAndEProjectCostSignatures$: Observable<IPAndEProjectCostSignatures[]>;
  private pAndEProjectCostSignaturesValue$: BehaviorSubject<IPAndEProjectCostSignatures[]>;
  private handleError: HandleError;
  private pAndEProjectBodId: string;

  constructor(
    private http: HttpClient,
    private httpErrorHandler: HttpErrorHandler,
    public globals: Globals,
    private pAndEProjectBodStatusService: PAndEProjectBodStatusService
  ) {
    this.pAndEProjectCostSignaturesValue$ = new BehaviorSubject<IPAndEProjectCostSignatures[]>([]);
    this.pAndEProjectCostSignatures$ = this.pAndEProjectCostSignaturesValue$.asObservable();
    this.handleError = httpErrorHandler.createHandleError();
  }

  public initialize(pAndEProjectBodId: string) {
    this.pAndEProjectBodId = pAndEProjectBodId;
    this.getSignatureByPAndEProjectBodId(this.pAndEProjectBodId).subscribe(
      (pAndEProjectCostSignatures: IPAndEProjectCostSignatures[]) => {
        pAndEProjectCostSignatures.forEach((signature: IPAndEProjectCostSignatures) => {
          signature.createdDate = Helpers.convertUtcToLocalTime2(signature.createdDate);
          signature.updatedDate = Helpers.convertUtcToLocalTime2(signature.updatedDate);
          signature.signDate = Helpers.convertUtcToLocalTime2(signature.signDate);
        });
        this.pAndEProjectCostSignaturesValue$.next(pAndEProjectCostSignatures);
        this.pAndEProjectBodStatusService.nextChangeStatus(true);
      },
      () => {
        this.pAndEProjectCostSignaturesValue$.error('Error loading data: could not be able to load Sft bod status.');
      }
    );
  }

  public cleanObservables(): void {
    this.pAndEProjectCostSignaturesValue$.next([]);
  }

  public next(): void {
    this.pAndEProjectCostSignaturesValue$.next(this.pAndEProjectCostSignaturesValue$.getValue());
  }

  public setValueToSignatures(signatures: IPAndEProjectCostSignatures[]): void {
    this.pAndEProjectCostSignaturesValue$.next(signatures);
  }

  public save(itemToSave: IPAndEProjectCostSignatures[]): Observable<any> {
    return this.create(itemToSave);
  }

  public remove(): Observable<any> {
    return this.delete();
  }

  public checkProjectValueDriverData(): Observable<boolean> {
    const serviceUrl: string =
    environment.PacerDomainURL + 'PAndEProjectValueDriver/ListByPAndEProjectBodId/' + this.pAndEProjectBodId;

    return this.http.get<any[]>(serviceUrl, httpOptions).pipe(
      map((data: any[]) => {
        return data.length > 0 ? true:false;
      })
    );
  }

  private getSignatureByPAndEProjectBodId(pAndEProjectBodId: string): Observable<IPAndEProjectCostSignatures[]> {
    const serviceUrl: string =
      environment.PacerDomainURL + 'PAndEProjectCostSignatures/ListByPAndEProjectBodId/' + pAndEProjectBodId;

    return this.http.get<IPAndEProjectCostSignatures[]>(serviceUrl, httpOptions).pipe(
      map((data: IPAndEProjectCostSignatures[]) => {
        return data;
      })
    );
  }

  private create(createdItem: IPAndEProjectCostSignatures[]): Observable<any> {
    const serviceUrl: string = environment.PacerDomainURL + 'PAndEProjectCostSignatures/CreateWithReturn/';

    const toCreate = createdItem.map((item: IPAndEProjectCostSignatures) => {
      return {
        pAndEProjectBodId: item.pAndEProjectBodId,
        signer: item.signer,
        signDate: item.signDate,
        signerRole: item.signerRole,
        createdBy: item.createdBy,
        updatedBy: item.updatedBy,
        createdDate: item.createdDate,
        updatedDate: item.updatedDate,
      };
    });

    const requestBody = { entities: toCreate, pAndEProjectBodId: this.pAndEProjectBodId };
    return this.http.post(serviceUrl, JSON.stringify(requestBody), httpOptions).pipe(
      map((pAndEProjectCostSignatures: IPAndEProjectCostSignatures[]) => {
        pAndEProjectCostSignatures.forEach((signature: IPAndEProjectCostSignatures) => {
          signature.createdDate = Helpers.convertUtcToLocalTime2(signature.createdDate);
          signature.updatedDate = Helpers.convertUtcToLocalTime2(signature.updatedDate);
          signature.signDate = Helpers.convertUtcToLocalTime2(signature.signDate);
        });
        this.pAndEProjectCostSignaturesValue$.next(pAndEProjectCostSignatures);
        this.pAndEProjectBodStatusService.nextChangeStatus(true);

        return this.pAndEProjectCostSignaturesValue$.value;
      }, catchError(error => {
        this.pAndEProjectCostSignaturesValue$.error('Error loading data: could not be able to load P&E bod status.');
        return error;
      }))
    );
  }

  private delete(): Observable<any> {
    const serviceUrl: string = environment.PacerDomainURL + 'PAndEProjectCostSignatures/DeleteWithReturn/';
    const deletedItems = this.pAndEProjectCostSignaturesValue$.getValue();
    deletedItems.forEach((item: IPAndEProjectCostSignatures) => {
      item.isDeleted = true;
    });

    const requestBody = { entities: deletedItems, pAndEProjectBodId: this.pAndEProjectBodId };
    return this.http.post(serviceUrl, JSON.stringify(requestBody), httpOptions).pipe(
      map((pAndEProjectCostSignatures: IPAndEProjectCostSignatures[]) => {
        pAndEProjectCostSignatures.forEach((signature: IPAndEProjectCostSignatures) => {
          signature.createdDate = Helpers.convertUtcToLocalTime2(signature.createdDate);
          signature.updatedDate = Helpers.convertUtcToLocalTime2(signature.updatedDate);
          signature.signDate = Helpers.convertUtcToLocalTime2(signature.signDate);
        });
        this.pAndEProjectCostSignaturesValue$.next(pAndEProjectCostSignatures);
        this.pAndEProjectBodStatusService.nextChangeStatus(true);

        return this.pAndEProjectCostSignaturesValue$.value;
      }, catchError(error => {
        this.pAndEProjectCostSignaturesValue$.error('Error loading data: could not be able to load P&E bod status.');
        return error;
      }))
    );
  }

}
