<kendo-dialog title="Warning" *ngIf="openDialog" [minWidth]="250" width="450" (close)="closeKendoPopUp()">
  <p>{{ warningMessage }}</p>
</kendo-dialog>

<div class="container-fluid">
  <div class="row mt-1">
    <div class="col">
      <div style="display: initial;font-size: 2rem;">
        {{ performanceBodName }}
      </div>
      <div style="display: initial;"><span [class]="lockClass()"></span>&nbsp;</div>
    </div>
    <div class="col">
      <div class="float-end">
        <button kendoButton [primary]="true" class="k-button" (click)="saveAll()" [disabled]="!canEditPerformanceBod">
          Save Changes
        </button>
      </div>
      <div class="float-end" style="padding-right: 5px;">
        <button kendoButton icon="file-excel" [primary]="true" (click)="exportToExcel()">Export to Excel</button>
      </div>

      <div class="float-end" style="padding-right: 5px;">
        <button kendoButton [icon]="'close'" class="k-button" (click)="closeBod()" [disabled]="isDisableForCloseBod">Close BOD
        </button>
      </div>
      <div class="float-end" style="padding-right: 5px;">
        <button kendoButton [icon]="'folder-open'" [disabled]="isDisableForReOpenBod" class="k-button" (click)="reOpenBod()">Re-Open BOD
        </button>
      </div>
    </div>
  </div>

  <div class="row mt-1">
    <div class="col-sm">
      <div class="float-right">
        <app-status-indicator></app-status-indicator>
      </div>
    </div>
  </div>
</div>

<div>
  <div id="performancebod">
    <kendo-tabstrip #tabStrip [keepTabContent]="true" [tabPosition]="position" (tabSelect)="onTabSelect($event)">
      <kendo-tabstrip-tab [title]="'Performance BOD Summary'" [selected]="true" [disabled]="false">
        <ng-template kendoTabContent>
          <kendo-splitter style="height: 100%; margin-top: 30px" orientation="vertical">
            <kendo-splitter-pane [collapsible]="true" size="47%">
              <kendo-splitter>
                <kendo-splitter-pane [collapsible]="true" size="50%">
                  <div class="card-body">
                    <app-performance-bod-detail
                      (nameEvent)="nameEventHandler($event)"
                      (saveBodCompleted)="onSaveBodCompleted($event)"
                    ></app-performance-bod-detail>
                  </div>
                </kendo-splitter-pane>
                <kendo-splitter-pane>
                  <div class="card-body">
                    <app-summary-package-information
                      (performanceBodUpdate)="onPerformanceBodUpdate($event)"
                      (spudDateUpdate)="onSpudDateUpdate($event)"
                      (popDateUpdate)="onPopDateUpdate($event)"
                      (numberOfWellsUpdate)="onNumberOfWellsUpdate($event)"
                      (packageInformationUpdate)="onPackageInformationUpdate($event)"
                    >
                    </app-summary-package-information>
                  </div>
                </kendo-splitter-pane>
              </kendo-splitter>
            </kendo-splitter-pane>
            <kendo-splitter-pane>
              <kendo-panelbar [keepItemContent]="true">
                <kendo-panelbar-item title="Expected Peak Production Rates (note if development or operationalized)"
                                     expanded="true">
                  <ng-template kendoPanelBarContent>
                    <div class="card-body-a">
                      <app-summary-peak-production-rates (performanceBodUpdate)="onPerformanceBodUpdate($event)"
                                                         (saveBodCompleted)="onSaveBodCompleted($event)"></app-summary-peak-production-rates>
                    </div>
                  </ng-template>
                </kendo-panelbar-item>
                <kendo-panelbar-item title="Notes & Attachments" expanded="true">
                  <ng-template kendoPanelBarContent>
                    <div class="card-body-a">
                      <app-summary-notes (performanceBodUpdate)="onPerformanceBodUpdate($event)"
                                         (saveBodCompleted)="onSaveBodCompleted($event)"></app-summary-notes>
                    </div>
                  </ng-template>
                </kendo-panelbar-item>
                <!--Removed AFE details and get AFE number from standard facilities page UI itself.-->
                <kendo-panelbar-item title="Signatures" expanded="true">
                  <ng-template kendoPanelBarContent>
                    <div class="card-body-a">
                      <app-summary-signatures (performanceBodUpdate)="onPerformanceBodUpdate($event)"
                                              (performanceBodSign)="saveAll($event)"></app-summary-signatures>
                    </div>
                  </ng-template>
                </kendo-panelbar-item>
              </kendo-panelbar>
            </kendo-splitter-pane>
          </kendo-splitter>
        </ng-template>
      </kendo-tabstrip-tab>
      <kendo-tabstrip-tab [title]="'Standard Facilities'" [selected]="false">
        <ng-template kendoTabContent>
          <div class="panelbar-wrapper">
            <br />
            <br />
            <kendo-panelbar [keepItemContent]="true">
              <!-- Obsolete table-->
              <kendo-panelbar-item
                title="Standard Facilities - Summary"
                expanded="true"
                [selected]="true"
                hidden="true"
              >
                <ng-template kendoPanelBarContent>
                  <app-standard-facilities-summary
                    (performanceBodUpdate)="onPerformanceBodUpdate($event)"
                    (standardFacilityUpdate)="onStandardFacilityUpdate($event)"
                    (saveBodCompleted)="onSaveBodCompleted($event)"
                  ></app-standard-facilities-summary>
                </ng-template>
              </kendo-panelbar-item>
              <kendo-panelbar-item title="Standard Facilities - Central Tank Battery" expanded="true">
                <ng-template kendoPanelBarContent>
                  <app-standard-facilities-central-tank-battery
                    (performanceBodUpdate)="onPerformanceBodUpdate($event)"
                    (saveBodCompleted)="onSaveBodCompleted($event)"
                  ></app-standard-facilities-central-tank-battery>
                </ng-template>
              </kendo-panelbar-item>
              <kendo-panelbar-item title="Standard Facilities - Compressor Station" expanded="true">
                <ng-template kendoPanelBarContent>
                  <app-standard-facilities-compressor
                    (performanceBodUpdate)="onPerformanceBodUpdate($event)"
                    (saveBodCompleted)="onSaveBodCompleted($event)"
                  ></app-standard-facilities-compressor>
                </ng-template>
              </kendo-panelbar-item>
              <kendo-panelbar-item title="Standard Facilities - Salt Water Disposal" expanded="true">
                <ng-template kendoPanelBarContent>
                  <app-standard-facilities-salt-water-disposal
                    (performanceBodUpdate)="onPerformanceBodUpdate($event)"
                    (saveBodCompleted)="onSaveBodCompleted($event)"
                  ></app-standard-facilities-salt-water-disposal>
                </ng-template>
              </kendo-panelbar-item>
            </kendo-panelbar>
          </div>
        </ng-template>
      </kendo-tabstrip-tab>
      <kendo-tabstrip-tab [title]="'Infrastructure'" [selected]="false">
        <ng-template kendoTabContent>
          <div class="panelbar-wrapper">
            <br />
            <br />
            <kendo-panelbar [keepItemContent]="true">
              <kendo-panelbar-item title="Infrastructure - Well Pad" expanded="true" [selected]="true">
                <ng-template kendoPanelBarContent>
                  <app-infrastructure-well-pad
                    (performanceBodUpdate)="onPerformanceBodUpdate($event)"
                    (numberOfWellsUpdate)="onWellCountsUpdate($event)"
                    (wellPadPermitsRequiredByUpdate)="onWellPadPermitsRequiredByUpdate($event)"
                    (wellPadCreate)="onWellPadCreate($event)"
                    (saveBodCompleted)="onSaveBodCompleted($event)"
                  >
                  </app-infrastructure-well-pad>
                </ng-template>
              </kendo-panelbar-item>
              <kendo-panelbar-item title="Infrastructure - Facility Pads" expanded="true">
                <ng-template kendoPanelBarContent>
                  <app-infrastructure-facility-pads
                    (performanceBodUpdate)="onPerformanceBodUpdate($event)"
                    (facilityPadCreate)="onFacilityPadCreate($event)"
                    (saveBodCompleted)="onSaveBodCompleted($event)"
                  >
                  </app-infrastructure-facility-pads>
                </ng-template>
              </kendo-panelbar-item>
              <kendo-panelbar-item title="Infrastructure - Pipelines" expanded="true">
                <ng-template kendoPanelBarContent>
                  <app-infrastructure-pipelines
                    (performanceBodUpdate)="onPerformanceBodUpdate($event)"
                    (pipelineCreate)="onPipelineCreate($event)"
                    (saveBodCompleted)="onSaveBodCompleted($event)"
                  ></app-infrastructure-pipelines>
                </ng-template>
              </kendo-panelbar-item>
              <kendo-panelbar-item title="Infrastructure - Well Hook Up & Artifical Lift" expanded="true">
                <ng-template kendoPanelBarContent>
                  <app-infrastructure-well-artificial-lift
                    (performanceBodUpdate)="onPerformanceBodUpdate($event)"
                    (saveBodCompleted)="onSaveBodCompleted($event)"
                  ></app-infrastructure-well-artificial-lift>
                </ng-template>
              </kendo-panelbar-item>
              <!-- Obsolete table-->
              <kendo-panelbar-item title="Infrastructure - EDS / I&E" expanded="true" hidden="true">
                <ng-template kendoPanelBarContent>
                  <app-infrastructure-eds-ie
                    (performanceBodUpdate)="onPerformanceBodUpdate($event)"
                    (saveBodCompleted)="onSaveBodCompleted($event)"
                  ></app-infrastructure-eds-ie>
                </ng-template>
              </kendo-panelbar-item>
              <kendo-panelbar-item title="Infrastructure - Frac Ponds" expanded="true">
                <ng-template kendoPanelBarContent>
                  <app-infrastructure-frac-pond
                    (performanceBodUpdate)="onPerformanceBodUpdate($event)"
                    (saveBodCompleted)="onSaveBodCompleted($event)"
                  ></app-infrastructure-frac-pond>
                </ng-template>
              </kendo-panelbar-item>
              <!-- Obsolete table-->
              <kendo-panelbar-item title="Infrastructure - Source Water" expanded="true" hidden="true">
                <ng-template kendoPanelBarContent>
                  <app-infrastructure-source-water
                    (performanceBodUpdate)="onPerformanceBodUpdate($event)"
                    (saveBodCompleted)="onSaveBodCompleted($event)"
                  ></app-infrastructure-source-water>
                </ng-template>
              </kendo-panelbar-item>
              <!-- Obsolete table-->
              <kendo-panelbar-item title="Infrastructure - Recycle" expanded="true" hidden="true">
                <ng-template kendoPanelBarContent>
                  <app-infrastructure-recycle
                    (performanceBodUpdate)="onPerformanceBodUpdate($event)"
                    (saveBodCompleted)="onSaveBodCompleted($event)"
                  ></app-infrastructure-recycle>
                </ng-template>
              </kendo-panelbar-item>
              <!-- Obsolete table-->
              <kendo-panelbar-item title="Infrastructure - Water Disposal" expanded="true" hidden="true">
                <ng-template kendoPanelBarContent>
                  <app-infrastructure-water-disposal
                    (performanceBodUpdate)="onPerformanceBodUpdate($event)"
                    (saveBodCompleted)="onSaveBodCompleted($event)"
                  ></app-infrastructure-water-disposal>
                </ng-template>
              </kendo-panelbar-item>
              <!-- Obsolete table-->
              <kendo-panelbar-item title="Infrastructure - Regulatory / Permitting" expanded="true" hidden="true">
                <ng-template kendoPanelBarContent>
                  <app-infrastructure-regulatory-permitting
                    (performanceBodUpdate)="onPerformanceBodUpdate($event)"
                    (regulatoryPermittingCreate)="onRegulatoryPermittingCreate($event)"
                    (saveBodCompleted)="onSaveBodCompleted($event)"
                  ></app-infrastructure-regulatory-permitting>
                </ng-template>
              </kendo-panelbar-item>
              <!-- Obsolete table-->
              <kendo-panelbar-item title="Infrastructure - Commercial" expanded="true" hidden="true">
                <ng-template kendoPanelBarContent>
                  <app-infrastructure-commercial
                    (performanceBodUpdate)="onPerformanceBodUpdate($event)"
                    (commercialCreate)="onCommercialCreate($event)"
                    (saveBodCompleted)="onSaveBodCompleted($event)"
                  ></app-infrastructure-commercial>
                </ng-template>
              </kendo-panelbar-item>
              <kendo-panelbar-item title="Infrastructure - Road Maintenance" expanded="true">
                <ng-template kendoPanelBarContent>
                  <app-infrastructure-road-maintenance
                    (performanceBodUpdate)="onPerformanceBodUpdate($event)"
                    (saveBodCompleted)="onSaveBodCompleted($event)"
                  ></app-infrastructure-road-maintenance>
                </ng-template>
              </kendo-panelbar-item>
            </kendo-panelbar>
          </div>
        </ng-template>
      </kendo-tabstrip-tab>
      <kendo-tabstrip-tab [title]="'P&E Scope'" [selected]="false">
        <ng-template kendoTabContent>
          <div class="panelbar-wrapper">
            <br />
            <br />
            <kendo-panelbar [keepItemContent]="true">
              <kendo-panelbar-item title="P&E Scope - Existing Facilities" expanded="true" [selected]="true">
                <ng-template kendoPanelBarContent>
                  <app-scope-existing-facilities
                    (performanceBodUpdate)="onPerformanceBodUpdate($event)"
                    (existinFacilityCreate)="onExistinFacilityCreate($event)"
                    (saveBodCompleted)="onSaveBodCompleted($event)"
                  ></app-scope-existing-facilities>
                </ng-template>
              </kendo-panelbar-item>
              <kendo-panelbar-item title="P&E Scope - Non-Standard/Outside Factory Projects" expanded="true">
                <ng-template kendoPanelBarContent>
                  <app-scope-non-standard-outside-factory-projects
                    (performanceBodUpdate)="onPerformanceBodUpdate($event)"
                    (saveBodCompleted)="onSaveBodCompleted($event)"
                  ></app-scope-non-standard-outside-factory-projects>
                </ng-template>
              </kendo-panelbar-item>
            </kendo-panelbar>
          </div>
        </ng-template>
      </kendo-tabstrip-tab>
      <kendo-tabstrip-tab [title]="'Water Scope'" [selected]="false">
        <ng-template kendoTabContent>
          <div class="panelbar-wrapper">
            <br />
            <br />
            <kendo-panelbar [keepItemContent]="true">
              <kendo-panelbar-item title="Drilling Supply" expanded="true" [selected]="true">
                <ng-template kendoPanelBarContent>
                  <app-water-scope-drilling-supply
                    (performanceBodUpdate)="onPerformanceBodUpdate($event)"
                    (saveBodCompleted)="onSaveBodCompleted($event)"
                  ></app-water-scope-drilling-supply>
                </ng-template>
              </kendo-panelbar-item>
            </kendo-panelbar>
            <kendo-panelbar [keepItemContent]="true">
              <kendo-panelbar-item title="Frac Supply" expanded="true">
                <ng-template kendoPanelBarContent>
                  <app-water-scope-frac-supply
                    (performanceBodUpdate)="onPerformanceBodUpdate($event)"
                    (saveBodCompleted)="onSaveBodCompleted($event)"
                  ></app-water-scope-frac-supply>
                </ng-template>
              </kendo-panelbar-item>
            </kendo-panelbar>
            <kendo-panelbar [keepItemContent]="true">
              <kendo-panelbar-item title="Water Disposal" expanded="true">
                <ng-template kendoPanelBarContent>
                  <app-water-scope-water-disposal
                    (performanceBodUpdate)="onPerformanceBodUpdate($event)"
                    (saveBodCompleted)="onSaveBodCompleted($event)"
                  ></app-water-scope-water-disposal>
                </ng-template>
              </kendo-panelbar-item>
            </kendo-panelbar>
          </div>
        </ng-template>
      </kendo-tabstrip-tab>
    </kendo-tabstrip>
  </div>
</div>
