import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { InlineEditService } from 'src/app/services/inline-edit.service';
import { PerformanceBodDataService } from 'src/app/services/performance-bod-data.service';
import { IEditableInline } from 'src/app/api/ieditable-inline';
import { PerformanceBodStoreService } from 'src/app/services/performance-bod-store.service';
import { IPerformanceBodCompressor } from 'src/app/api/performance-bod-compressor';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PerformanceBodPerformanceBodCompressorService extends InlineEditService implements IEditableInline {
  public data: IPerformanceBodCompressor[] = [];
  public originalData: IPerformanceBodCompressor[] = [];
  public createdItems: IPerformanceBodCompressor[] = [];
  public updatedItems: IPerformanceBodCompressor[] = [];
  public deletedItems: IPerformanceBodCompressor[] = [];
  public performanceBodId: string;

  constructor(
    private performanceBodDataService: PerformanceBodDataService
  ) {
    super();
  }

  public isNew(item: any): boolean {
    return !item.id;
  }

  public itemIndex(item: any, data: IPerformanceBodCompressor[]): number {
    for (let idx = 0; idx < data.length; idx++) {
      if (data[idx].id === item.id) {
        return idx;
      }
    }
    return -1;
  }

  public create(): Observable<any> {
    return this.performanceBodDataService.createPerformanceBodCompressor(this.createdItems);
  }

  public retrieve(): Observable<any> {
    return this.performanceBodDataService.getPerformanceBodCompressor(this.performanceBodId).pipe(map(data => {
      return data;
    }));
  }

  public update(): Observable<any> {
    return this.performanceBodDataService.updatePerformanceBodCompressor(this.updatedItems);
  }

  public delete(): Observable<any> {
    return this.performanceBodDataService.updatePerformanceBodCompressor(this.deletedItems);
  }

}
