import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable()
export class RedirectGuard implements CanActivate {

  constructor(private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    var extURL;
    if (route.data['externalUrl'] == 'pricetmap/') {
      extURL = environment.PricetWebURL.replace('pacer', '') + route.data['externalUrl'];
    }
    else {
      extURL = environment.PricetWebURL + route.data['externalUrl'];
    }
    window.location.href = extURL;
    return true;
  }
}
