import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanDeactivate } from '@angular/router';
import { DialogService } from '@progress/kendo-angular-dialog';
import { Observable,of } from 'rxjs';
import { BodToolComponent } from '../modules/infrastructure/bod-tool/bod-tool.component';

@Injectable({
  providedIn: 'root'
})
export class FitUnsavedChangesGuard implements CanDeactivate<BodToolComponent> {

  constructor(private dialogService: DialogService) {

  }
  canDeactivate(component: BodToolComponent): Observable<boolean> {
    if (component.notesComponent.notesEditService.hasChanges() ||
      (component.bodDetailsComponent.formNewBod.dirty &&
        !component.bodDetailsComponent.formNewBod.pristine) ||
      component.notesComponent.notesEditService.hasChanges() ||
      component.infrastructureBodFracPondsComponent.infrastructureBodFracPondsEditService.hasChanges() ||
      component.infrastructureBodFacilityPadsComponent.infrastructureBodFacilityPadsEditService.hasChanges() ||
      component.wellPadsComponent.wellPadsEditService.hasChanges() ||
      component.bodGasLiftComponent.pipelineGasLiftEditService.hasChanges() ||
      component.pipelineGatheringLineComponent.pipelineGatheringLineEditService.hasChanges() ||
      component.waterInjectionComponent.pipelineWaterInjectionEditService.hasChanges() ||
      component.bodFlowlinesComponent.pipelineFlowlinesEditService.hasChanges() ||
      component.summaryComponent.summaryEditService.hasChanges() ||
      (component.costSummaryComponent.formCostSummary.dirty &&
        !component.costSummaryComponent.formCostSummary.pristine)) {
      return component.canDeactivateConfirmDialog();
    }
    return of(true);
  }
}
