

declare global {
  interface Array<T> {
    distinct(propertyExpression: (item: T) => any): T[];
  }
}

Array.prototype.distinct = function(propertyExpression: (item: any) => any) {
  const result = [];
  const flags = [];

  for (let i = 0; i < (<Array<any>>this).length; i++) {
    if (flags[propertyExpression((<Array<any>>this)[i])]) {
      continue;
    }
    flags[propertyExpression((<Array<any>>this)[i])] = true;
    result.push((<Array<any>>this)[i]);
  }
  return result;
};

export {};
