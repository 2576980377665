import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IEditableInline } from 'src/app/api/ieditable-inline';
import { IPerformanceBodSummary } from 'src/app/api/performance-bod-summary';
import { InlineEditService } from 'src/app/services/inline-edit.service';
import { PerformanceBodDataService } from 'src/app/services/performance-bod-data.service';
import { PerformanceBodStoreService } from 'src/app/services/performance-bod-store.service';
import { PerformanceBodCalculationsService } from 'src/app/services/performance-bod-calculations.service';
import { IPerformanceBodCompressor } from 'src/app/api/performance-bod-compressor';
import { IPerformanceBodCentralTankBattery } from 'src/app/api/performance-bod-central-tank-battery';
import { IPerformanceBodSaltWaterDisposal } from 'src/app/api/performance-bod-salt-water-disposal';
import * as Helpers from 'src/app/_shared/helpers';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StandardFacilitiesSummaryService extends InlineEditService implements IEditableInline {
  public data: IPerformanceBodSummary[] = [];
  public originalData: IPerformanceBodSummary[] = [];
  public createdItems: IPerformanceBodSummary[] = [];
  public updatedItems: IPerformanceBodSummary[] = [];
  public deletedItems: IPerformanceBodSummary[] = [];

  public performanceBodId: string;

  constructor(
    private performanceBodDataService: PerformanceBodDataService,
    private performanceBodStoreService: PerformanceBodStoreService,
    private performanceBodCalculationsService: PerformanceBodCalculationsService
  ) {
    super();
  }

  public isNew(item: any): boolean {
    return !item.id;
  }

  public itemIndex(item: any, data: IPerformanceBodSummary[]): number {
    for (let idx = 0; idx < data.length; idx++) {
      if (data[idx].id === item.id) {
        return idx;
      }
    }
    return -1;
  }

  public create(): Observable<any> {
    return this.performanceBodDataService.createPerformanceBodSummary(this.createdItems);
  }

  public retrieve(): Observable<any> {
    return this.performanceBodDataService.getPerformanceBodSummary(this.performanceBodId).pipe(map(data => {
      data.forEach(element => {
        if (element.readyForServiceDate !== null && element.readyForServiceDate !== undefined) {
          element.readyForServiceDate = Helpers.convertUtcToLocalTime(element.readyForServiceDate);
        } else {
          element.readyForServiceDate = undefined;
        }

        if (element.bodLockdownDate !== null && element.bodLockdownDate !== undefined) {
          element.bodLockdownDate = Helpers.convertUtcToLocalTime(element.bodLockdownDate);
        } else {
          element.bodLockdownDate = undefined;
        }
      });
      return data;
    }));
  }

  public update(): Observable<any> {
    return this.performanceBodDataService.updatePerformanceBodSummary(this.updatedItems);
  }

  public delete(): Observable<any> {
    return this.performanceBodDataService.updatePerformanceBodSummary(this.deletedItems);
  }

  public retrieveByPerformanceBodStdFacilityId(performanceBodStdFacilityId: string): void {
    this.performanceBodStoreService.retrievePerformanceBodSummary(performanceBodStdFacilityId);
  }

  public applyLogicFromPads(compressors: IPerformanceBodCompressor[],
    centralTankBateries: IPerformanceBodCentralTankBattery[],
    saltWaterDisposals: IPerformanceBodSaltWaterDisposal[]): void {
    const standardFacilitiesChanges = this.performanceBodCalculationsService.standardFacilitiesCalculations(this.data, compressors,
      centralTankBateries, saltWaterDisposals);
    for(const standarFacility of standardFacilitiesChanges) {
      this.updateItem(standarFacility);
    }
  }

  protected setOrder(): void {
    if (this.createdItems.length > 0) {
      const obj = this.createdItems[0];
      if (!obj.hasOwnProperty('sortOrder')) {
        // empty
      }
    } else {
      // empty
    }
    let order = 0;
    for (const dataItem of this.createdItems) {
      dataItem.sortOrder = this.data.length;
      dataItem.updatedDate = new Date();
      dataItem.updatedBy = dataItem.createdBy;
      dataItem.updatedDate = dataItem.createdDate;
      // this.updateItem(dataItem);
      order++;
    }

    super.setOrder();
  }

  
}
