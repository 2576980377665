import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs';
import { HandleError, HttpErrorHandler } from '../services/http-error-handler.service';
import * as Helpers from '../_shared/helpers';
import { ISmartImportSequence } from '../api/smart-import-sequence';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root'
})
export class SmartImportSequenceService {
  public loading: EventEmitter<boolean>;
  private handleError: HandleError;
  private baseUrlSmartImportSequence: string = environment.PricetDomainURL + 'SmartImportSequence';

  constructor(private http: HttpClient, httpErrorHandler: HttpErrorHandler) {
    this.handleError = httpErrorHandler.createHandleError();
  }

  public getByDasSimpleId(dasSimpleId: string): Observable<ISmartImportSequence[]> {
    return this.http
      .get<ISmartImportSequence[]>(
        `${this.baseUrlSmartImportSequence}/ListByDasSimpleId/${dasSimpleId}`
      )
      .pipe(
        map((data: ISmartImportSequence[]) => {
          data.forEach(element => {
            if (element.popDate !== null && element.popDate !== undefined) {
              element.popDate = Helpers.convertUtcToLocalTime(element.popDate);
            } else {
              element.popDate = undefined;
            }            
            if (element.createdDate !== null && element.createdDate !== undefined) {
              element.createdDate = Helpers.convertUtcToLocalTime(element.createdDate);
            } else {
              element.createdDate = undefined;
            }

            if (element.updatedDate !== null && element.updatedDate !== undefined) {
              element.updatedDate = Helpers.convertUtcToLocalTime(element.updatedDate);
            } else {
              element.updatedDate = undefined;
            }
          });
          return data;
        }),
        catchError(error => {
          this.handleError('getByDasSimpleId');
          return throwError(error);
        })
      );
  }

  public createSmartImportSequence(data: ISmartImportSequence[]): Observable<any> {
    return this.http
      .post<ISmartImportSequence[]>(`${this.baseUrlSmartImportSequence}/Create`, data, httpOptions)
      .pipe(catchError(this.handleError('createSmartImportSequence')));
  }

  public updateSmartImportSequence(data: ISmartImportSequence[]): Observable<any> {
    return this.http
      .post<ISmartImportSequence[]>(`${this.baseUrlSmartImportSequence}/Update`, data, httpOptions)
      .pipe(catchError(this.handleError('updateSmartImportSequence')));
  }

}
