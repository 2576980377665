import {
  Component,
  OnInit,
  AfterViewChecked,
  ViewChild,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { padStatusOptions } from '../../../api/mock/mockData';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import {
  RowClassArgs,
  GridComponent,
  ColumnReorderEvent,
  DataStateChangeEvent,
  GridDataResult,
  PageChangeEvent,
} from '@progress/kendo-angular-grid';
import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';
import { ScenarioStoreService } from '../../../services/scenario-store.service';
import { InputFacilityAssignmentModel, IPadBracketFields } from './input-facility-assignment.model';
import { OverriddenFieldsService } from '../../../services/overridden-fields.service';
import { State, process } from '@progress/kendo-data-query';
import { PermissionsProvider } from '../../../services/permissions.provider';
import clonedeep from 'lodash.clonedeep';

@Component({
  selector: 'app-input-facility-assignment',
  templateUrl: './input-facility-assignment.component.html',
  styleUrls: ['./input-facility-assignment.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputFacilityAssignmentComponent implements OnInit, AfterViewChecked {
  public canEditCnd: boolean = false;
  public readonly Fields: IPadBracketFields;
  public readonly nonEditableFields: string[];
  public readonly editableOutputFields: string[];
  public readonly executionCategoryOptions: string[];
  public newScenario: boolean;
  public padConfigGridData: any[];
  public padBuilderData: any[];
  public padStatusOptions: string[] = padStatusOptions;
  public activeForOutlookOptions: string[];
  public partOfCo2DevelopmentOptions: string[];
  public developmentAreaOptions = [];
  public developmentAreaOptionsOnEdit = [];
  public sortedPadsByPopDate: any[];
  public facilityAssignemntForm: UntypedFormGroup;
  public contingencyValue: number;
  public formatOptions: any = {
    minimumFractionDigits: 1,
    maximumFractionDigits: 2,
    style: 'percent',
  };
  public isHeaderBtnExpandend = false;
  public isHeaderBtnVisible = false;
  public formGroup: UntypedFormGroup;
  public gridView: GridDataResult;
  public state: State = {};
  public skip = 0;

  @ViewChild('inputFacilityAssignmentGrid') public grid: GridComponent;
  @ViewChild('dropdownEditor')
  public dropDownEditor: DropDownListComponent;
  @Output() public openEditPads = new EventEmitter<any>();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private scenarioStoreService: ScenarioStoreService,
    private overriddenFieldsService: OverriddenFieldsService,
    private changeDetector: ChangeDetectorRef,
    private permissionsProvider: PermissionsProvider
  ) {
    this.Fields = InputFacilityAssignmentModel.IPadFields;
    this.nonEditableFields = [
      'fieldName',
      this.Fields.spudDate,
      this.Fields.popDate,
      this.Fields.wellCount,
      this.Fields.cumulativeWellCount,
      this.Fields.padOrder,
      this.Fields.wellTestLocation,
      this.Fields.padRigDownMoveOffDate,
    ];
    this.editableOutputFields = [
      this.Fields.cumulativeTestSeparators,
      this.Fields.cumulativeHeaderSlots,
      this.Fields.tragetSatelliteRfsd,
      this.Fields.satelliteExecutionCategory,
      this.Fields.tragetCtbRfsd,
      this.Fields.ctbExecutionCategory,
      this.Fields.compressionRfsd,
      this.Fields.compExecutionCategory,
      this.Fields.waterDisposalRfsd,
      this.Fields.swdExecutionCategory,
      this.Fields.fracPondRfsd,
      this.Fields.pondExecutionCategory,
      this.Fields.padContingency,
    ];
    this.executionCategoryOptions = ['Calculated', 'Greenfield', 'Brownfield'];
    this.activeForOutlookOptions = ['', 'Yes', 'No'];
    this.partOfCo2DevelopmentOptions = ['Yes', 'No'];
    this.createFormGroup = this.createFormGroup.bind(this);
  }

  public ngOnInit(): void {
    this.canEditCnd = this.permissionsProvider.canEditCnd;

    this.scenarioStoreService.newScenario$.subscribe(value => {
      this.newScenario = value;
    });

    this.scenarioStoreService.developmentAreaOptions$.subscribe(data => {
      data
        .filter(d => d.isDeleted === false)
        .forEach(element => {
          this.developmentAreaOptions.push(element.name);
        });
      this.developmentAreaOptionsOnEdit = clonedeep(this.developmentAreaOptions);
    });

    this.scenarioStoreService.padConfigData$.subscribe(
      data => {
        // filter by isVisible to use the expand and collapse button is related to InputFacilityAssignmentModel
        this.padConfigGridData = data.filter(pad => pad.fieldName.isVisible === true);
        this.isHeaderBtnVisible = data.length > 0;
        this.loadPads();
        this.changeDetector.markForCheck();
      },
      error => {
        this.isHeaderBtnVisible = false;
      }
    );
    this.scenarioStoreService.facilityPadBuilderDataPage$.subscribe(data => {
      this.padBuilderData = data;
      this.loadPads();
      this.changeDetector.markForCheck();
    });
  }

  public cellClickHandler(args) {
    if (args.column.field === 'fieldName' || this.isNonEditable(args.dataItem.fieldName.name)) {
      return;
    }
    if (!args.isEdited && this.canEditCnd) {
      //Row index 3 is Development area.
      if (args.rowIndex === 3) {
        this.fixIfIsOld(args.dataItem[args.column.field]);
      }
      args.sender.editCell(args.rowIndex, args.columnIndex, this.createFormGroup(args.dataItem, args.column.field));
      const param = this;
      setTimeout(() => {
        if (param.dropDownEditor) {
          param.dropDownEditor.toggle(true);
        }
      }, 0);
    }
  }

  public ngAfterViewChecked() {
    if (this.grid.activeCell) {
      const focusedCell = document.querySelector('app-input-facility-assignment td.k-state-focused');
      if (focusedCell) {
        const focusedCellTemplate = document.querySelector('app-input-facility-assignment td .focused-cell');
        if (focusedCellTemplate) {
          focusedCellTemplate.classList.remove('focused-cell');
        }
        focusedCell.children[0].classList.add('focused-cell');
      } else {
        const focusedCellTemplate = document.querySelector('app-input-facility-assignment td .focused-cell');
        if (focusedCellTemplate) {
          focusedCellTemplate.classList.remove('focused-cell');
        }
      }
    } else {
      const focusedCellTemplate = document.querySelector('app-input-facility-assignment td .focused-cell');
      if (focusedCellTemplate) {
        focusedCellTemplate.classList.remove('focused-cell');
      }
    }
  }

  public cellCloseHandler(args: any) {
    const { formGroup, dataItem } = args;
    if (!formGroup.valid) {
      args.preventDefault();
    } else if (formGroup.dirty) {
      this.scenarioStoreService.updatePadConfigField(
        args.column.field,
        dataItem.fieldName.name,
        formGroup.value[args.column.field]
      );
    }
  }

  public calculatePadFields(): void {
    this.scenarioStoreService.calculatePadFields();
  }

  public createFormGroup(dataItem: any, column: string, tab: boolean = false): UntypedFormGroup {
    const controlsConfig = {};
    if (tab) {
      const i = parseInt(column, null);
      if (!isNaN(i) && i >= 2) {
        const j = this.padBuilderData.slice(i - 2, i);
        column = j[0].padName;
      }
    }
    controlsConfig[column] = dataItem[column];
    return this.formBuilder.group(controlsConfig);
  }

  public isNonEditable(fieldName: string): boolean {
    return this.nonEditableFields.includes(fieldName);
  }

  public isEditableOutput(fieldName: string): boolean {
    return this.editableOutputFields.includes(fieldName);
  }

  public rowCallback() {
    return (rowArguments: RowClassArgs) => {
      if (this.isNonEditable(rowArguments.dataItem.fieldName.name)) {
        return 'non-editable-row';
      } else if (this.isEditableOutput(rowArguments.dataItem.fieldName.name)) {
        return 'editable-output-row';
      } else {
        return 'editable-row';
      }
    };
  }

  public isFieldOverridden(padName: string, fieldName: string): boolean {
    if (this.isEditableOutput(fieldName)) {
      return this.overriddenFieldsService.isPadConfigFieldOverridden(padName, fieldName);
    } else {
      return false;
    }
  }

  public cellClass(padName: string, fieldName: string, isForEdition: boolean = false): string {
    let cellClass = isForEdition ? '' : 'custom-cell-template ';
    if (
      this.isEditableOutput(fieldName) &&
      this.overriddenFieldsService.isPadConfigFieldOverridden(padName, fieldName)
    ) {
      cellClass += 'overridden-field ';
    }
    if (this.isNonEditable(fieldName)) {
      cellClass += 'non-editable-cell';
    } else if (this.isEditableOutput(fieldName)) {
      cellClass += 'editable-output-cell';
    } else {
      cellClass += 'editable-cell';
    }
    return cellClass;
  }

  // Behavior for the column reorder, drag and drop the column and reorder the two Grids (Scope Counts and Facility Assigment) are connected
  public onColumnReorder(columnReorderEvent: ColumnReorderEvent): void {
    if (columnReorderEvent.newIndex > -1) {
      this.scenarioStoreService.reorderPadbuilderPad(
        columnReorderEvent.column['field'],
        columnReorderEvent.oldIndex,
        columnReorderEvent.newIndex
      );
    } else {
      columnReorderEvent.preventDefault();
    }
  }

  public trackIndex(index: number): any {
    return index;
  }

  // Is used to return if the row is expanded or collapsed
  public isHiddenExpandCollapse(dataItem: any): boolean {
    if (dataItem !== undefined && dataItem !== null) {
      if (dataItem.fieldName.parentName !== undefined && dataItem.fieldName.parentName === '0') {
        return false;
      } else {
        return true;
      }
    }
  }

  public cellClassExpandCollapse(dataItem: any) {
    let vclass;
    if (!dataItem !== undefined) {
      if (dataItem.fieldName.isExpanded === false) {
        vclass = 'k-icon item-custom-icon-class-plus';
      } else {
        vclass = 'k-icon item-custom-icon-class-minus';
      }
      return vclass;
    }
  }

  public headerCellClassExpandCollapse() {
    if (this.isHeaderBtnExpandend === false) {
      return 'k-icon item-custom-icon-class-plus';
    } else {
      return 'k-icon item-custom-icon-class-minus';
    }
  }

  public expandCollapseHandler({ sender, rowIndex, dataItem }) {
    if (
      dataItem !== undefined &&
      dataItem !== null &&
      dataItem.fieldName.parentName !== undefined &&
      dataItem.fieldName.parentName !== null
    ) {
      this.scenarioStoreService.updatePadFieldIsVisible(dataItem.fieldName.name);
    }
  }

  public onPadClick(fieldName: string) {
    if (fieldName !== undefined && fieldName !== null) {
      this.isHeaderBtnExpandend = !this.isHeaderBtnExpandend;
      this.scenarioStoreService.updatePadFieldIsVisible(fieldName);
    }
  }

  public onPadHeaderClick() {
    this.isHeaderBtnExpandend = !this.isHeaderBtnExpandend;
    this.scenarioStoreService.updateAllPadFieldIsVisible(this.isHeaderBtnExpandend);
  }

  public onDataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.gridView = process(this.padConfigGridData, this.state);
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.loadPads();
  }

  private loadPads(): void {
    this.gridView = process(this.padConfigGridData, this.state);
  }

  private fixIfIsOld(column: string): void {
    const selectedColumnValue = column ? column : null;
    if (selectedColumnValue) {
      const findIt = this.developmentAreaOptionsOnEdit.find(d => d === selectedColumnValue);
      if (!findIt) {
        this.developmentAreaOptionsOnEdit.unshift(selectedColumnValue);
      }
    }
  }

  public onEditPadOpenClick(padIdSelected: string): void {
    this.openEditPads.next({ padId: padIdSelected, open: true });
  }
}
