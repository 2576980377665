import { Component, EventEmitter, OnInit, Output, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs';
import { GridDataResult, RowClassArgs } from '@progress/kendo-angular-grid';
import { State, process, CompositeFilterDescriptor, filterBy } from '@progress/kendo-data-query';
import { IPerformanceBodPackages } from 'src/app/api/performance-bod-package';
import { IPerformanceBodPads } from 'src/app/api/performance-bod-pads';
import { PerformanceBodStoreService } from 'src/app/services/performance-bod-store.service';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from '@progress/kendo-angular-notification';
import { SummaryPackageInformationEditService } from './summary-package-information-edit.service';
import { DialogService, DialogRef } from '@progress/kendo-angular-dialog';
import { PermissionsProvider } from 'src/app/services/permissions.provider';
import { UtilService } from 'src/app/services/util.service';
import { AuthenticationService } from 'src/app/services';
import { DragAndDropService } from 'src/app/_shared/drag-and-drop.service';
import { PerformanceBodCalculationsService } from 'src/app/services/performance-bod-calculations.service';

const DRAGGABLE_SELECTOR = '.package-information-draggable';

@Component({
  selector: 'app-summary-package-information',
  templateUrl: './summary-package-information.component.html',
  styleUrls: ['./summary-package-information.component.css'],
})
export class SummaryPackageInformationComponent implements OnInit {
  public view: Observable<GridDataResult>;
  public performanceBodId: string;
  public formGroup: UntypedFormGroup;
  public gridState: State = {
    sort: [],
    skip: 0,
  };
  public filter: CompositeFilterDescriptor;
  public filterable: boolean = true;
  public canEditPerformanceBod: boolean = false;
  public performanceBodPackages: IPerformanceBodPackages[];

  public changes: any = {};
  public duplicatesFound = '';
  public duplicateCheck: string = 'wellPadName';
  public blanksFound;
  public result;
  public columnsToCheckForBlanks = [
    { name: 'packageName', errorMessageName: 'Package Name' },
    { name: 'wellPadName', errorMessageName: 'Well Pad Name' },
    { name: 'spudDate', errorMessageName: 'SPUD Date' },
    { name: 'popDate', errorMessageName: 'Pop Date' },
    { name: 'numberOfWells', errorMessageName: '# of Wells' },
  ];

  public isEditing = false;
  @Output() public performanceBodUpdate = new EventEmitter<string>();
  @Output() public spudDateUpdate = new EventEmitter<any>();
  @Output() public popDateUpdate = new EventEmitter<any>();
  @Output() public numberOfWellsUpdate = new EventEmitter<any>();
  @Output() public packageInformationUpdate = new EventEmitter<boolean>();
  public summaryPackageInformationEditService: SummaryPackageInformationEditService;
  private dragAndDropService: DragAndDropService;

  constructor(
    private performanceBodStoreService: PerformanceBodStoreService,
    private formBuilder: UntypedFormBuilder,
    private spinner: NgxSpinnerService,
    private notificationService: NotificationService,    
    private dialogService: DialogService,
    private permissionsProvider: PermissionsProvider,
    private authenticationService: AuthenticationService,
    private utilService: UtilService,
    private performanceBodCalculationsService: PerformanceBodCalculationsService,
    @Inject(DragAndDropService) dragAndDropServiceFactory: any,
    @Inject(SummaryPackageInformationEditService) summaryPackageInformationEditServiceFactory: any
  ) {
    this.dragAndDropService = dragAndDropServiceFactory();
    this.summaryPackageInformationEditService = summaryPackageInformationEditServiceFactory();
  }

  public ngOnInit(): void {
    this.performanceBodStoreService.performanceBodSignatured$.subscribe((isSignature) => {
      this.canEditPerformanceBod = isSignature ? false : this.permissionsProvider.canEditPerformanceBod;
    });
    // Set the performance bod identification
    this.performanceBodStoreService.performanceBodId$.subscribe(id => {
      this.performanceBodId = id;
      this.summaryPackageInformationEditService.performanceBodId = id;
    });

    // get the pad names of store service
    this.performanceBodStoreService.performanceBodPackagesData$.subscribe(data => {
      this.performanceBodPackages = data;
    });

    this.view = this.summaryPackageInformationEditService.pipe(
      map(data => {
        this.dragAndDropService.dataSourceRefreshed(data);

        // Important to refresh grid whenever there's an update to the grid
        this.packageInformationUpdate.next(true);  
        return process(filterBy(data, this.filter), this.gridState);
      })
    );

    // Get async array
    this.summaryPackageInformationEditService.read();

    this.dragAndDropService.initialize(this.summaryPackageInformationEditService.data, DRAGGABLE_SELECTOR, () => {
      this.summaryPackageInformationEditService.markAsSetOrder(this.authenticationService.getCurrentUser());
    });
  }

  public ngAfterViewInit(): void {
    // To Do
  }

  public ngOnDestroy(): void {
    this.dragAndDropService.refreshDragAndDrop();
  }

  public createFormGroup(dataItem: any): UntypedFormGroup {
    return this.formBuilder.group({
      id: [dataItem.id],
      name: [dataItem.name],
      isDeleted: [dataItem.isDeleted],
      createdBy: [dataItem.createdBy],
      createdDate: [dataItem.createdDate],
      updatedBy: [dataItem.updatedBy],
      updatedDate: [dataItem.updatedDate],
      wellPadName: [dataItem.wellPadName, [Validators.minLength(1), Validators.maxLength(100)]],
      reservoirName: [dataItem.reservoirName, [Validators.minLength(1), Validators.maxLength(100)]],
      facilityName: [dataItem.facilityName],
      spudDate: [dataItem.spudDate],
      popDate: [dataItem.popDate],
      numberOfWells: [dataItem.numberOfWells, [Validators.min(0), Validators.max(50)]],
      comments: [dataItem.comments, Validators.maxLength(500)],
      performanceBodId: [dataItem.performanceBodId],
      performanceBodPackagesId: [dataItem.performanceBodPackagesId],
    });
  }

  public cellClickHandler({ sender, rowIndex, columnIndex, dataItem, isEdited }) {
    if (!isEdited && this.canEditPerformanceBod) {
      if (dataItem.spudDate !== undefined && columnIndex === 2) {
        if (dataItem.spudDate !== null) {
          const y = new Date(dataItem.spudDate.toString()).getFullYear();
          const m = new Date(dataItem.spudDate.toString()).getMonth();
          const d = new Date(dataItem.spudDate.toString()).getDate();
          dataItem.spudDate = new Date(y, m, d);
        }
      }

      if (dataItem.popDate !== undefined && columnIndex === 3) {
        if (dataItem.popDate !== null) {
          const y = new Date(dataItem.popDate.toString()).getFullYear();
          const m = new Date(dataItem.popDate.toString()).getMonth();
          const d = new Date(dataItem.popDate.toString()).getDate();
          dataItem.popDate = new Date(y, m, d);
        }
      }
      this.isEditing = true;
      sender.editCell(rowIndex, columnIndex, this.createFormGroup(dataItem));
      this.dragAndDropService.onEditingMode();
    }
  }

  public cellCloseHandler(args: any) {
    const { formGroup, dataItem } = args;

    if (!formGroup.valid) {
      // prevent closing the edited cell if there are invalid values.
      args.preventDefault();
    } else if (formGroup.dirty) {
      dataItem.updatedDate = new Date();
      dataItem.updatedBy = this.authenticationService.getCurrentUser();

      // Date is not working in Object.assign(), so need to be do it manually
      formGroup.value.updatedBy = dataItem.updatedBy;
      formGroup.value.updatedDate = dataItem.updatedDate;
      let updateSpudDate = false;
      let updatePopDate = false;
      let updateNumberOfWells = false;
      if (formGroup.value.spudDate) {
        updateSpudDate = formGroup.value.spudDate !== dataItem.spudDate;
      }
      if (formGroup.value.popDate) {
        updatePopDate = formGroup.value.popDate !== dataItem.popDate;
      }
      if (formGroup.value.numberOfWells) {
        updateNumberOfWells = formGroup.value.numberOfWells !== dataItem.numberOfWells;
      }

      this.summaryPackageInformationEditService.assignValues(dataItem, formGroup.value);
      if (updateSpudDate) {
        this.spudDateUpdate.next(dataItem);
      }
      if (updatePopDate) {
        this.popDateUpdate.next(dataItem);
      }
      if (updateNumberOfWells) {
        this.numberOfWellsUpdate.next(dataItem);
      }

      this.summaryPackageInformationEditService.update(dataItem);

      this.duplicatesFound = this.summaryPackageInformationEditService.duplicatesExist(this.duplicateCheck);
      this.blanksFound = this.summaryPackageInformationEditService.blanksExists(
        this.columnsToCheckForBlanks,
        this.performanceBodPackages
      );
    }
    this.isEditing = false;
    // calls this to add the attribute of current row
    this.dragAndDropService.onEditingClose();
    this.dragAndDropService.refreshDragAndDrop();
  }

  public addHandler({ sender }) {
    const item: IPerformanceBodPads = {
      id: Guid.create().toString(),
      name: '   ',
      isDeleted: false,
      createdBy: this.authenticationService.getCurrentUser(),
      createdDate: new Date(),
      updatedBy: this.authenticationService.getCurrentUser(),
      updatedDate: new Date(),
      wellPadName: '',
      reservoirName: '',
      facilityName: '',
      spudDate: null,
      popDate: null,
      numberOfWells: 0,
      comments: '',
      sortOrder: 0,
      performanceBodId: this.performanceBodId,
      performanceBodPackagesId: null,
    };

    item.createdDate = new Date();
    sender.addRow(this.createFormGroup(item));
    this.dragAndDropService.onEditingMode();
  }

  public cancelHandler({ sender, rowIndex }) {
    sender.closeRow(rowIndex);
    this.dragAndDropService.onEditingClose();
    this.dragAndDropService.refreshDragAndDrop();
  }

  public saveHandler({ sender, formGroup, rowIndex }) {
    if (formGroup.valid) {
      formGroup.patchValue({
        performanceBodId: this.performanceBodId,
      });
      this.summaryPackageInformationEditService.create(formGroup.value);
      this.duplicatesFound = this.summaryPackageInformationEditService.duplicatesExist(this.duplicateCheck);
      this.blanksFound = this.summaryPackageInformationEditService.blanksExists(
        this.columnsToCheckForBlanks,
        this.performanceBodPackages
      );
      sender.closeRow(rowIndex);
      this.dragAndDropService.onEditingClose();
      this.dragAndDropService.dataSourceRefreshed(this.summaryPackageInformationEditService.data);
      this.dragAndDropService.refreshDragAndDrop();
      this.summaryPackageInformationEditService.markAsSetOrder(this.permissionsProvider.cai);
    } else {
      this.utilService.markFormGroupAsTouched(formGroup);
    }
  }

  public removeHandler({ sender, dataItem }) {
    if (this.performanceBodStoreService.validateAllowRemovePerformanceBodPads(dataItem.id)) {
      // Additional Check with API for sections that have not been saved.
      this.summaryPackageInformationEditService.validateRemovePads(dataItem.id).subscribe(
        (data: any) => {
          if (data !== null && data !== undefined) {
            if (data) {
              this.summaryPackageInformationEditService.remove(dataItem);
              this.prepareOnDeletePad();
              this.performanceBodStoreService.removePeakProductionRate(dataItem.id);
              this.duplicatesFound = this.summaryPackageInformationEditService.duplicatesExist(this.duplicateCheck);
              this.blanksFound = this.summaryPackageInformationEditService.blanksExists(
                this.columnsToCheckForBlanks,
                this.performanceBodPackages
              );
              sender.cancelCell();
            } else {
              this.showRemoveValidation();
            }
          } else {
            return false;
          }
        },
        (err: any) => {
          this.showRemoveValidation();
        }
      );
    } else {
      this.showRemoveValidation();
    }
  }

  public saveChanges(grid: any): void {
    grid.closeCell();
    grid.cancelCell();

    this.spinner.show();
    // calls this to add the attribute of current row
    this.dragAndDropService.refreshDragAndDrop();
    this.summaryPackageInformationEditService.saveChanges();
    this.performanceBodUpdate.next(this.authenticationService.getCurrentUser());

    const subscription = this.summaryPackageInformationEditService.subscribe(() => {
      this.spinner.hide();
      this.notificationService.show({
        content: 'Performance BOD sucessfully saved',
        position: { horizontal: 'center', vertical: 'top' },
        animation: { type: 'fade', duration: 500 },
        type: { style: 'success', icon: true },
        hideAfter: 700,
      });  
      subscription.unsubscribe();    
    });

  }

  public cancelChanges(grid: any): void {
    grid.cancelCell();
    this.dragAndDropService.refreshDragAndDrop();
    this.summaryPackageInformationEditService.cancelChanges();
  }

  public handlerFilter(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    if (filter.filters) {
      if (filter.filters.length > 0) {
        this.dragAndDropService.onEditingMode();
      } else {
        this.dragAndDropService.onEditingClose();
        this.dragAndDropService.refreshDragAndDrop();
      }
    }
  }

  public rowCallback(context: RowClassArgs) {
    return {
      dragging: context.dataItem.dragging,
    };
  }

  public onStateChange(state: State) {
    this.gridState = state;
    this.summaryPackageInformationEditService.read();

    // const viewSubscription = this.view.subscribe(data => {
    //   this.dragAndDropService.dataSourceRefreshed(data.data);
    // });

    // viewSubscription.unsubscribe();
    // this.dragAndDropService.runZoneOnStable();
  }

  public getPerformanceBodPackages(id: string): any {
    if (this.performanceBodPackages === null || this.performanceBodPackages === undefined) {
      return '';
    }
    this.performanceBodPackages = this.performanceBodPackages.filter(x => x.id !== null && x.id !== undefined);
    return this.performanceBodPackages.find(x => x.id === id);
  }

  public showRemoveValidation() {
    const dialog: DialogRef = this.dialogService.open({
      title: 'Field Validations',
      content: 'Package Information - Well Pad Name is being referenced in sections of the Performance BOD.',
      actions: [{ text: 'OK', primary: true }],
      width: 450,
      height: 200,
      minWidth: 250,
    });

    dialog.result.subscribe(result => {
      this.result = JSON.stringify(result);
    });
  }

  public rowLoaded(): void {
    // to check until grid have been loeaded.
    this.dragAndDropService.checkIsOnEditMode();
    this.dragAndDropService.checkDraggableElementLoaded();
  }

  private prepareOnDeletePad(): void {
    const deletItems = this.summaryPackageInformationEditService.getDeletedItems();
    this.performanceBodCalculationsService.expectedPEakProductionUnspecifiedPackages(
      deletItems.map((item: IPerformanceBodPads) => item.performanceBodPackagesId)
    );
  }
}
