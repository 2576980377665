<div class="card-body">
  <kendo-grid
    [data]="view | async"
    [filter]="filter"
    (filterChange)="handlerFilter($event)"
    filterable="menu"
    (dataStateChange)="onDataStateChange($event)"
    [sortable]="{ allowUnsort: allowUnsort, mode: multiple ? 'multiple' : 'single' }"
    [sort]="sort"
    (sortChange)="sortChange($event)"
    style="height: auto; overflow-x: hidden; margin-bottom: 2vw;"
    [loading]="isLoading"
  >
    <kendo-grid-column field="name" title="DAS Project Name"></kendo-grid-column>
    <kendo-grid-column field="developmentAreasCode" title="Development Area"> </kendo-grid-column>
    <kendo-grid-column
      field="createdDate"
      title="Created Date"
      filter="date"
      format="{0:MM/dd/yyyy}"
    ></kendo-grid-column>
    <kendo-grid-column field="createdBy" title="Created By"></kendo-grid-column>
    <kendo-grid-column field="updatedDate" title="Last Updated Date" filter="date" format="{0:MM/dd/yyyy}">
    </kendo-grid-column>
    <kendo-grid-column field="updatedBy" title="Last Updated By"></kendo-grid-column>
    <kendo-grid-command-column title="" width="220">
      <ng-template kendoGridCellTemplate let-dataItem>
        <button kendoGridEditCommand primary="true" [routerLink]="['/' + dataItem.id]" (click)="openHandler(dataItem)">
          Open
        </button>
        <button kendoGridRemoveCommand [primary]="true" (click)="openDelete(dataItem)" [hidden]="!isAdmin()" style="background:red">
          Delete
        </button>
      </ng-template>
    </kendo-grid-command-column>
  </kendo-grid>
</div>
<div class="example-wrapper">
  <kendo-dialog title="Confirmation Deletion"
                *ngIf="openedDelete"
                (close)="closeDelete('cancel')"
                [minWidth]="250"
                [width]="450">
    <div class="card border-0">
      <div class="card-body">
        <div class="card-text">
          <p style="margin: 30px; text-align: center;">
            Are you sure you want to delete "{{ dasSimpleSelected.name }}" project?
          </p>
          <!--<kendo-dialog-actions>-->
        </div>
      </div>
      <div class="card-footer">
        <button kendoButton class="k-button col-5" (click)="removeHandler()">Yes</button>
        <button kendoButton class="k-button k-primary col-6 ms-3" (click)="closeDelete('cancel')">No</button>
      </div>
    </div>
    <!--</kendo-dialog-actions>-->
  </kendo-dialog>
</div>
