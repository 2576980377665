export const environment = {
  production: true,
  PricetDomainURL: 'https://pricet-dev.azure.chevron.com/pricetdomain/api/',
  SecurityDomainURL: 'https://pricet-dev.azure.chevron.com/securitydomain/api/',
  EnvironmentTag: 'Dev',
  PacerDomainURL: 'https://pricet-dev.azure.chevron.com/PerformanceCostExecution/api/',
  PricetWebURL: 'https://pricet-dev.azure.chevron.com/pacer',
  SmartDomainURL: 'https://smart-so-mcbu-api-dev.azure.chevron.com/api/v2/',
  PbsorDomainURL: 'https://pbsor-mcbu-api-dev.azure.chevron.com/api/',
  SmartAppURL: 'https://smart-mcbu-dev.azure.chevron.com/',
};
