import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs';
import { environment } from '../../environments/environment';
import { IUsrPermissions } from '../api/user-permission';
import { AppLogEntry } from '../api/logEntry';

export function initApp(authentication: AuthenticationService) {
  return () => authentication.load();
}

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
  withCredentials: true,
};

@Injectable({
  providedIn: 'root',
})



export class AuthenticationService {
  public isInGroupValue: boolean;
  public isAdminValue = false;
  public isAdmin$: Observable<boolean>;
  public currentUser$: Observable<string>;
  public isReadOnly$: Observable<boolean>;
  public isEditOnly$: Observable<boolean>;
  private getUserUrl: string = environment.SecurityDomainURL + 'Authentication/GetCAIAuthenticated';
  private isInGLAMGroupUrl: string = environment.SecurityDomainURL + 'Authentication/IsInGlamGroup/';
  private isAdminUrl: string = environment.SecurityDomainURL + 'Authentication/IsAdmin';
  private getUsersEmailsUrl: string = environment.SecurityDomainURL + 'Authentication/GetEmails?cais=';
  private getUserNameUrl: string = environment.SecurityDomainURL + 'Authentication/GetFullName?cai=';
  private getUserPermissionsByCaiUrl: string = environment.SecurityDomainURL + 'UserPermission/ListByName/';
  private membersInContactAdminGroupUrl: string = environment.SecurityDomainURL + 'Authentication/GetContactAdminMembers';
  private isInAppUserGroupUrl: string = environment.SecurityDomainURL + 'Authentication/IsInUserGroup?cai=';
  private isAuthenticatedUrl: string = environment.SecurityDomainURL + 'Authentication/IsAuthenticated';
  private OAuthUrl : string = environment.SecurityDomainURL + 'OAuth/SignIn';
  private createLogUrl: string = environment.SecurityDomainURL + 'AppLog/Create/';
  private cai = '';
  private isAdminValue$: BehaviorSubject<boolean>;
  private currentUserValue$: BehaviorSubject<string>;
  private isReadOnlyValue$: BehaviorSubject<boolean>;
  private isEditOnlyValue$: BehaviorSubject<boolean>;

  constructor(private http: HttpClient) {
    this.isAdminValue$ = new BehaviorSubject<boolean>(false);
    this.isAdmin$ = this.isAdminValue$.asObservable();
    this.currentUserValue$ = new BehaviorSubject<string>('');
    this.currentUser$ = this.currentUserValue$.asObservable(); 
    this.isReadOnlyValue$ = new BehaviorSubject<boolean>(false);
    this.isReadOnly$ = this.isReadOnlyValue$.asObservable();
    this.isEditOnlyValue$ = new BehaviorSubject<boolean>(false);
    this.isEditOnly$ = this.isEditOnlyValue$.asObservable();
  }

  public getUser(): Observable<string> {
    let Url: string;
    Url = this.getUserUrl;
    return this.http.get(Url, { responseType: 'text' }).pipe(
      map((rslt: string) => {
        const r = rslt
          .replace('"', '')
          .replace('"', '')
          .replace('CT\\\\', '');
        this.cai = r;
        return r;
      })
    );
  }

  public isInGroup(): Observable<boolean> {
    let Url: string;
    Url = this.isInGLAMGroupUrl;
    return this.http.get<boolean>(Url);
  }

  public isAuthenticated(): Observable<boolean> {
    let Url: string;
    Url = this.isAuthenticatedUrl;
    return this.http.get<boolean>(Url);
  }

  public getUserName(CAI: string): Observable<string | undefined> {
    let Url: string;
    Url = this.getUserNameUrl + CAI;

    return this.http.get<string>(Url);
  }

  public isAdmin(): Observable<boolean> {
    let Url: string;
    Url = this.isAdminUrl;

    return this.http.get<boolean>(Url);
  }

  public getUsersEmails(cais: string[]): Observable<string> {
    let Url: string;
    let data = '';
    data = cais.map(x => x).join(",");
    Url = this.getUsersEmailsUrl + data;

    //return this.http.get(`${Url}`, cais, httpOptions).map;
    return this.http.get<string>(Url);
  }

  public getUserPermissionsByCAI(cai: string): Observable<IUsrPermissions> {
    let url: string;
    url = this.getUserPermissionsByCaiUrl + cai;

    return this.http.get(url, httpOptions).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  public membersOfAdminGroup(): Observable<string> {
    let Url: string;
    Url = this.membersInContactAdminGroupUrl;

    return this.http.get<string>(Url);
  }

  public isInAppUserGroup(cai: string): Observable<boolean> {
    let Url: string;
    Url = this.isInAppUserGroupUrl + cai;
    return this.http.get<boolean>(Url);
  }

  public logErrorEntry(entry: AppLogEntry): Observable<number> {
    const entries: AppLogEntry[] = [];
    entry.createdBy = this.cai;
    entries.push(entry);
    return this.http.post<number>(`${this.createLogUrl}`, entries, httpOptions);
  }

  public load(): Promise<any> {
   return this.authenticate();
  }

  public setIsAdmin(val: boolean) {
    this.isAdminValue$.next(val);
  }
  
  public getIsAdmin(): boolean {
    return this.isAdminValue$.value;
  }

  public setCurrentUser(id: string) {
    this.currentUserValue$.next(id);
  }

  public getCurrentUser(): string {
    return this.currentUserValue$.value;
  }

  public setReadOnly(val: boolean) {
    this.isReadOnlyValue$.next(val);
  }
  public getIsReadOnly(): boolean {
    return this.isReadOnlyValue$.value;
  }

  public setCanEdit(val: boolean) {
    this.isEditOnlyValue$.next(val);
  }

  public getCanEdit(): boolean {
    return this.isEditOnlyValue$.value;
  }

  public authenticate(): Promise<any>{
    return new Promise((resolve, reject) => {
      this.isAuthenticated().subscribe(isAuthenticated => {
        if (isAuthenticated) {
          this.isInGroup().subscribe(isInGroup => {
            this.isInGroupValue = isInGroup;
            resolve(true);
          });
        }
        else{
          window.location.href = this.OAuthUrl;
        }
      });
    });
  }

}
