import { Component, OnInit, Inject, Input } from '@angular/core';
import { MyFacilitiesService } from '../_services/my-facilities.service';
import { FacilityBod, IFacilityBod } from '../_models/facility.bod.model';
import { FacilityTypeService } from '../../modules/admin/services/facilty.type.service';
import { IFacilityType } from '../../modules/admin/models/facility.type.model';
import { UnitRateAreaService } from '../../_shared/_services/unit.rate.area.service';
import { IUnitRateArea } from '../../_shared/_models/unit.rate.area';
import { FacilityStdDesignNoBehaviorSubjectService } from '../../_shared/_services/design.service';
import { IFacilityStdDesigns } from '../_models/facility.std.designs.model';
import { UntypedFormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { formatDate } from '@angular/common';
import { FacilityAFEService } from '../../modules/standard-facility/bod/_services/facility.afe.service';
import { IFacilityAfe, FacilityAfe } from '../../modules/standard-facility/bod/_models/facility.afe';
import { HttpClient } from '@angular/common/http';
import { IntlService } from '@progress/kendo-angular-intl';
import { IFacilityProjectCostEstimates } from '../_models/facility.project.cost.estimates';
import { map } from 'rxjs';
import { FacilityProjectCostSignaturesService } from '../../modules/standard-facility/bod/_services/facility.project.cost.signatures.service';
import { IFacilityProjectCostSignatures } from '../../modules/standard-facility/bod/_models/facility.project.cost.signatures';
import { IUsrPermissions } from '../_models/user-permissions.model';
import { AuthenticationService } from 'src/app/services';
import * as Helpers from '../../_shared/helpers';
import { PermissionsProvider } from 'src/app/services/permissions.provider';
import { UserPermissionsService } from 'src/app/services/user-permission.service';
import { FacilityProviderService } from '../../modules/standard-facility/_services/facility-provider.service';
import { Globals } from '../Globals';
import { IFacilityPrefBod } from '../_models/facility.preformance.bod.model';
import { IDevArea } from '../_models/devarea.interface';
import { IBasin } from '../_models/basin.interface';
import { IAppConfiguration } from 'src/app/api/app-configurations';
import { PerformanceConfigurationService } from 'src/app/services/performance-configuration-service';

@Component({
  selector: 'app-facility-details',
  templateUrl: './facility-details.component.html',
  styleUrls: ['./facility-details.component.css'],
})
export class FacilityDetailsComponent implements OnInit {
  public facilityId: string;
  public facilityForm: UntypedFormGroup;

  public facilitySelected: IFacilityBod;
  public parentFacilitySelected: IFacilityBod[] = [];
  public facilityTypeList: IFacilityType[] = [];
  public unitRateAreaList: IUnitRateArea[] = [];
  public designList: IFacilityStdDesigns[] = [];
  public facilityDefaultWbs: IFacilityAfe;

  public facilityService: MyFacilitiesService;
  public facilityTypeService: FacilityTypeService;
  public unitRateAreaService: UnitRateAreaService;
  public facilityStdDesignService: FacilityStdDesignNoBehaviorSubjectService;
  public facilityAFEService: FacilityAFEService;
  public revisionDateString: string;
  public requestedStartupDateString: string;
  public executionPmSignDateString: string;
  public executionPMName: string;
  public performancePmSignDateString: string;
  public performancePMName;
  public iePmSignDateString: string;
  public iePmName: string;
  public defaultWbsNumber: string;
  public defaultWbsAmount: number;

  public isLoading = true;
  public facilityProjectCostEstimatesByProjectId: IFacilityProjectCostEstimates;
  public facilityProjectCostEstimatesByProjectList: IFacilityProjectCostEstimates[] = [];
  private userPermissionsService: UserPermissionsService;
  private facilityPrefBod: IFacilityPrefBod;
  private developmentAreaDetails: IDevArea;
  private basinDetails: IBasin;
  public isPreformanceBodEdited : boolean = false;

  constructor(
    public permissionsProvider: PermissionsProvider,
    public globals: Globals,
    @Inject(MyFacilitiesService) facilityServiceFactory: any,
    @Inject(FacilityTypeService) facilityTypeServiceFactory: any,
    @Inject(UnitRateAreaService) unitRateAreaServiceFactory: any,
    @Inject(FacilityStdDesignNoBehaviorSubjectService) facilityStdDesignServiceFactory: any,
    @Inject(FacilityAFEService) facilityAFEServiceFactory: any,
    @Inject(UserPermissionsService) userPermissionsServiceFactory: any,
    public facilityProviderService: FacilityProviderService,
    public facilityProjectCostSignaturesService: FacilityProjectCostSignaturesService,
    public intl: IntlService,
    private performanceConfigurationService: PerformanceConfigurationService
  ) {
    this.facilityAFEService = facilityAFEServiceFactory();
    this.facilityService = facilityServiceFactory();
    this.facilityTypeService = facilityTypeServiceFactory();
    this.unitRateAreaService = unitRateAreaServiceFactory();
    this.facilityAFEService = facilityAFEServiceFactory();
    this.facilityStdDesignService = facilityStdDesignServiceFactory();
    this.userPermissionsService = userPermissionsServiceFactory();
    this.fillFacilityType();
    this.fillUnitRateArea();
    this.fillFacilityStdDesigns();
    this.fillParentFacility();
    this.facilityService.loader.subscribe(isLoaded => this.LoadedData(isLoaded));
  }

  public ngOnInit(): void {
    this.facilityProviderService.facilityBod$.subscribe((facilityBod: FacilityBod) => {
      if (facilityBod) {
        this.facilitySelected = facilityBod;
        this.facilityPrefBod = facilityBod.facilityPrefBod?.[0];  
              this.isPreformanceBodEdited = this.facilityPrefBod?.isPrefBodEdited;
              this.facilityService.getDevAreaById(facilityBod.developmentAreaId).subscribe((data) => {
                if (data) {
                  this.developmentAreaDetails = data;
                  this.facilityService.getBasinAreaById(data.geographicArea.basinId).subscribe((basinData) => {
                    if (basinData) {
                      this.basinDetails = basinData;
                    }
                  })
                }
              });
              this.refreshData();
        this.facilityProviderService.facilityProjectCostEstimates$.subscribe(
          (projectsEstimates: IFacilityProjectCostEstimates[]) => {
            if (projectsEstimates.length > 0) {
              this.facilityProjectCostEstimatesByProjectId = projectsEstimates[0];
              this.facilityProjectCostSignaturesService.initialize(this.facilityProjectCostEstimatesByProjectId.id);
              
            }
          }
        );
      }
    });
  }

  public LoadedData(isLoaded: boolean) {
    this.isLoading = isLoaded;
  }

  public getFacilityTypeById(facilityBod: IFacilityBod): any {
    if (
      facilityBod === undefined ||
      this.facilityTypeList === undefined ||
     // this.designList === undefined ||
      this.facilityProjectCostEstimatesByProjectId === undefined
    ) {
      return '';
    }


    return this.facilityTypeList.find(x => x.id === facilityBod.facilityTypeId);
  }

  public getUnitRateAreaById(id: string): any {
    if (id === undefined || this.unitRateAreaList === undefined) {
      return '';
    }
    return this.unitRateAreaList.find(x => x.id === id);
  }

  public getDesignsById(facilityBod: IFacilityBod): any {
    if (
      facilityBod === undefined ||
      this.designList === undefined ||
      this.facilityProjectCostEstimatesByProjectId === undefined
    ) {
      return '';
    }

    return this.designList.find(x => x.id === this.facilityProjectCostEstimatesByProjectId.facilityStdDesignId);
  }

  public getParentFacilityById(id: string): any {
    if (id === undefined || this.parentFacilitySelected === undefined) {
      return '-';
    }
    return this.parentFacilitySelected.find(x => x.id === id);
  }

  public fillFacilityType(): void {
    this.facilityTypeService.getFacilityTypes().subscribe(data => {
      this.facilityTypeList = data;
    });
  }

  public fillUnitRateArea(): void {
    this.unitRateAreaService.getUnitRateArea().subscribe(data => {
      this.unitRateAreaList = data;
    });
  }

  public fillFacilityStdDesigns(): void {
    this.facilityStdDesignService.getFacilityStdDesigns().subscribe(data => {
      this.designList = data;
      this.facilityStdDesignService.getDeletedFacilityStdDesigns().subscribe(deletedList => {
        this.designList = this.designList.concat(deletedList);
      });
    });
  }

  public fillParentFacility(): void {
    this.facilityService.getFacilities().subscribe(data => {
      this.parentFacilitySelected = data;
    });
  }

  public getShortDate(date: Date): string {
    return date.getMonth() + '/' + date.getDate() + '/' + date.getFullYear();
  }

  public getTimeFormat(date: any): string {
    if (date === undefined || date == null) {
      return '';
    }
    // new Date(new Date(date).toUTCString())
    return this.intl.formatDate(date, 'MM/dd/yyyy hh:mm:ss a');
  }

  public refreshData(): void {

    // get Wbs from Facility
    this.facilityAFEService.retrieveByFacilityBodId(this.facilitySelected.id).subscribe(wbs => {
      this.facilityDefaultWbs = wbs.find(w => w.isDefault);
      if (this.facilityDefaultWbs !== undefined) {
        this.defaultWbsNumber = this.facilityDefaultWbs.number;
        this.defaultWbsAmount = this.facilityDefaultWbs.amount;
      }
    });
  }

  public getTextInfoFromFacilityProjectCostEstimates(columnName: string): string {
    let textInfo = '';

    if (this.facilityProjectCostEstimatesByProjectId) {
      switch (columnName) {
        case 'longitude':
          textInfo = this.facilityProjectCostEstimatesByProjectId.longitude;
          break;
        case 'latitude':
          textInfo = this.facilityProjectCostEstimatesByProjectId.latitude;
          break;
        case 'revisionUserId':
          textInfo = this.facilityProjectCostEstimatesByProjectId.revisionUserId;
          break;
        case 'comments':
          textInfo = this.facilityProjectCostEstimatesByProjectId.comments;
          break;
        case 'trainCount':
          textInfo = this.facilityProjectCostEstimatesByProjectId.trainCount.toString();
          break;
        case 'standardProjectKey':
          textInfo = this.facilityProjectCostEstimatesByProjectId.standardProjectKey;
        default:
          break;
      }
    }

    return textInfo;
  }

  public convertToAfeFormat(params: any): any {
    return Helpers.convertToAfeFormat(params);
  }

  public convertUtcToLocalTime(params: any): any {
    return Helpers.convertUtcToLocalTime2(params);
  }

  public getInfoFromFacilityPrefBod(columnName: string) { 
    if (this.facilityPrefBod)
    {
    if(columnName == "newOrExisting")
      return this.facilityPrefBod?.[columnName].toString() == "1" ? "New" : "Existing";
      if(columnName == "noOfTrains")
      return this.facilityPrefBod?.[columnName].toString() == "0" ? "NA" : this.facilityPrefBod?.[columnName].toString();
    else
      return this.facilityPrefBod?.[columnName];
    }
    else
      return '';
    
  }

  public getInfoFromDevelopmentArea(columnName: string) {
    if (this.developmentAreaDetails)
      return this.developmentAreaDetails?.[columnName];
    else
      return '';
  }

  public getInfoFromGeographicArea(columnName: string) {
    if (this.developmentAreaDetails && this.developmentAreaDetails.geographicArea)
      return this.developmentAreaDetails.geographicArea?.[columnName];
    else
      return '';
  }

  public getInfoFromBasin(columnName: string) {
    if (this.basinDetails)
      return this.basinDetails?.[columnName];
    else
      return '';
  }

  public isVisible(...facilityTypes: string[]): boolean {
    if (this.facilitySelected === undefined) {
      return true;
    }

    const ids = this.performanceConfigurationService.appConfigs
      .filter(configs => facilityTypes.includes(configs.code))
      .map((configs: IAppConfiguration) => configs.attributeId);
    return !ids.includes(this.facilitySelected.facilityTypeId);//facilityProjectCostEstimates[0].facilityStdDesign.facilityTypeId);
  }
}
