import { AuthenticationService } from './authentication.service';
import { Inject, Injectable } from '@angular/core';
import { Promise } from 'q';
import { ScenarioStoreService } from './scenario-store.service';
import { PerformanceBodStoreService } from './performance-bod-store.service';
import { concat } from 'rxjs';
import { map, take } from 'rxjs';

export function initApp2(permissions: PermissionsProvider) {
  return () => permissions.load();
}

@Injectable()
export class PermissionsProvider {
  public cai: string  = '';
  public authBack = '';
  public canEditCnd: boolean = false;
  public canEditPerformanceBod: boolean = false;
  public canEditSft: boolean = false;
  public canEditFit: boolean = false;
  public canEditPAndE: boolean = false;
  public isAdmin: boolean = false;
  constructor(
    @Inject(AuthenticationService) private authenticationService: AuthenticationService,
    @Inject(PerformanceBodStoreService) public performanceBodStoreService: PerformanceBodStoreService
  ) {}

  public load(): Promise<any> {
    return Promise((resolve, reject) => {
      this.authenticationService.setIsAdmin(false);
      this.authenticationService.getUser().subscribe(r => {
        this.cai = r;
        this.authenticationService.setCurrentUser(this.cai);

        const getUserPermissions = this.authenticationService
        .getUserPermissionsByCAI(this.cai).pipe(map((value: any) => {
          if (value.length > 0) {
            this.canEditCnd = value[0].canEditCnd;
            this.canEditFit = value[0].canEditFit;
            this.canEditSft =  value[0].canEditSft;
            this.canEditPerformanceBod = value[0].canEditPerformance;
            this.canEditPAndE = value[0].canEditPAndE;
          }
        }), take(1));

        const getIsInGroup = this.authenticationService.isInGroup().pipe(map(i => {
          this.authenticationService.setReadOnly(i);
        }), take(1));

        const getIsAdmin = this.authenticationService.isAdmin().pipe(map(isAdmin => {
          this.isAdmin = isAdmin;
          if (this.isAdmin) {
            this.canEditCnd = true;
            this.canEditPerformanceBod = true;
            this.canEditPAndE = true;
            this.canEditSft = true;
          }
          this.authenticationService.setIsAdmin(isAdmin);
          this.performanceBodStoreService.setCanEditPerformance(isAdmin);
        }), take(1));

        concat(getUserPermissions, getIsInGroup, getIsAdmin)
          .toPromise()
          .then(() => {
            resolve(true);
          })
          .catch((reason: any) => {
            this.authBack = 'error';
            this.canEditCnd = false;
            this.canEditPerformanceBod = false;
            this.canEditPAndE = false;
            this.canEditSft = false;
            this.authenticationService.setReadOnly(false);
            this.authenticationService.setCanEdit(false);
            this.authenticationService.setIsAdmin(false);
            reject(reason);
          });
      });
    });
  }
}
