import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';

import { map } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ILog } from '../_models/logs-bod.model';


const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
  withCredentials: true,
};

@Injectable({
  providedIn: 'root',
})
export class LogsBodService extends BehaviorSubject<any[]> {

  public data: any[] = [];
  constructor(private http: HttpClient) {
    super([]);
  }

  public getLogsByBodId(id: string): Observable<ILog[]> {
    const serviceUrl: string = environment.PacerDomainURL + 'LogsBod/ListLogsByBodId/' + id;
    return this.http.get<ILog[]>(serviceUrl, httpOptions)
    .pipe(
      map((data: any[]) => {
        this.data = data;
        return this.data;
      })
    );
  }

  public read() {
    if (this.data !== undefined) {
      if (this.data.length) {
        return super.next(this.data);
      }
    }
  }

  public createLogs(logs: ILog[]): Observable<any> {
    const serviceUrl: string = environment.PacerDomainURL + 'LogsBod/Create';
    const toCreate = logs.map((item) => {
      return {
        projectType: item.projectType,
        bodId: item.bodId,
        operation: item.operation,
        fieldName: item.fieldName,
        previousValue: item.previousValue ? item.previousValue.toString() : item.previousValue,
        currentValue: item.currentValue ? item.currentValue.toString() : item.currentValue,
        createdBy: item.createdBy,
        createdDate: item.createdDate,
        updatedBy: item.updatedBy,
        updatedDate: item.updatedDate
      };
    });
    // const toCreate = designs.map((item) => {
    //   return {
    //     name: item.name,
    //     trainCount: item.trainCount === null || item.trainCount === undefined ? 0 : item.trainCount,
    //     isAddition: item.isAddition === null || item.isAddition === undefined ? false : item.isAddition,
    //     facilityTypeId: item.facilityTypeId,
    //     createdBy: item.createdBy,
    //     createdDate: item.createdDate,
    //     updatedBy: item.updatedBy,
    //     updatedDate: item.updatedDate,
    //     name: item.name, trainCount: item.trainCount, isExpansion: item.isAddition,
    //     isDeleted: item.isDeleted, facilityTypeId: item.facilityTypeId,
    //     facilityType: null, stdDesignEquipment: null,
    //     designExpansions: null, designIeScopes: null,
    //     stdDesignLaborEstimates: null, designUnitRates: null, facilities: null,
    //     createdBy: item.createdBy, createdDate: item.createdDate, updatedBy: item.updatedBy,
    //     updatedDate: item.updatedDate
    //   };
    // });

    return this.http.post(serviceUrl, JSON.stringify(toCreate), httpOptions).pipe(map(data => data as any[]));
  }
}
