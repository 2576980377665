import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, map } from 'rxjs';
import 'rxjs';
import { environment } from 'src/environments/environment';
import { IFacilityProjectCostSignatures } from '../_models/facility.project.cost.signatures';
import { HandleError, HttpErrorHandler } from 'src/app/services/http-error-handler.service';
import { SftBodStatusService } from '../../_services/sft-bod-status.service';
import { Globals } from 'src/app/_shared/Globals';
import * as Helpers from 'src/app/_shared/helpers';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class FacilityProjectCostSignaturesService {
  public facilityProjectCostSignatures$: Observable<IFacilityProjectCostSignatures[]>;
  public facilityBodId: string;
  private facilityProjectCostSignaturesValue$: BehaviorSubject<IFacilityProjectCostSignatures[]>;
  private handleError: HandleError;
  private facilityProjecCosttEstimateId: string;

  constructor(
    private http: HttpClient,
    private httpErrorHandler: HttpErrorHandler,
    private sftBodStatusService: SftBodStatusService,
    public globals: Globals
  ) {
    this.facilityProjectCostSignaturesValue$ = new BehaviorSubject<IFacilityProjectCostSignatures[]>([]);
    this.facilityProjectCostSignatures$ = this.facilityProjectCostSignaturesValue$.asObservable();
    this.handleError = httpErrorHandler.createHandleError();
  }

  public initialize(facilityProjecCosttEstimateId: string) {
    this.facilityProjecCosttEstimateId = facilityProjecCosttEstimateId;
    this.getSignatureByFacilityProjectCostEstimates(this.facilityProjecCosttEstimateId).subscribe(
      (facilityProjectCostSignatures: IFacilityProjectCostSignatures[]) => {
        facilityProjectCostSignatures.forEach((signature: IFacilityProjectCostSignatures) => {
          signature.createdDate = Helpers.convertUtcToLocalTime2(signature.createdDate);
          signature.updatedDate = Helpers.convertUtcToLocalTime2(signature.updatedDate);
          signature.signDate = Helpers.convertUtcToLocalTime2(signature.signDate);
        });
        this.facilityProjectCostSignaturesValue$.next(facilityProjectCostSignatures);
        this.sftBodStatusService.nextChangeStatus(true);
      },
      () => {
        this.facilityProjectCostSignaturesValue$.error('Error loading data: could not be able to load Sft bod status.');
      }
    );
  }

  public cleanObservables(): void {
    this.facilityProjectCostSignaturesValue$.next([]);
  }

  public next(): void {
    this.facilityProjectCostSignaturesValue$.next(this.facilityProjectCostSignaturesValue$.getValue());
  }

  public save(action: string, itemToSave: IFacilityProjectCostSignatures[]): Observable<any> {
    if (action === 'Create') {
      return this.create(itemToSave);
    } else {
      return this.delete();
    }
  }

  private create(createdItem: IFacilityProjectCostSignatures[]): Observable<any> {
    const serviceUrl: string = environment.PacerDomainURL + 'FacilityProjectCostSignatures/CreateWithReturn/';

    const toCreate = createdItem.map((item: IFacilityProjectCostSignatures) => {
      return {
        facilityProjectCostEstimatesId: item.facilityProjectCostEstimatesId,
        signer: item.signer,
        signDate: item.signDate,
        signerRole: item.signerRole,
        createdBy: item.createdBy,
        updatedBy: item.updatedBy,
        createdDate: item.createdDate,
        updatedDate: item.updatedDate,
      };
    });
    const requestBody = { entities: toCreate, facilityProjectCostEstimatesId: this.facilityProjecCosttEstimateId };
    return this.http.post(serviceUrl, JSON.stringify(requestBody), httpOptions).pipe(
      map((signatures: IFacilityProjectCostSignatures[]) => {
        signatures.forEach((signature: IFacilityProjectCostSignatures) => {
          signature.createdDate = Helpers.convertUtcToLocalTime2(signature.createdDate);
          signature.updatedDate = Helpers.convertUtcToLocalTime2(signature.updatedDate);
          signature.signDate = Helpers.convertUtcToLocalTime2(signature.signDate);
        });
        this.facilityProjectCostSignaturesValue$.next(signatures);
        this.sftBodStatusService.nextChangeStatus(true);

        return this.facilityProjectCostSignaturesValue$.value;
      }), catchError(error => {
        this.facilityProjectCostSignaturesValue$.error('Error loading data: could not be able to load Fit bod status.');
        return error;
      })
    );
  }

  private delete(): Observable<any> {
    const serviceUrl: string = environment.PacerDomainURL + 'FacilityProjectCostSignatures/DeleteWithReturn';
    const deletedItems = this.facilityProjectCostSignaturesValue$.getValue();
    deletedItems.forEach((item: IFacilityProjectCostSignatures) => {
      item.isDeleted = true;
    });
    const requestBody = { entities: deletedItems, facilityProjectCostEstimatesId: this.facilityProjecCosttEstimateId };
    return this.http.post(serviceUrl, JSON.stringify(requestBody), httpOptions).pipe(
      map((signatures: IFacilityProjectCostSignatures[]) => {
        signatures.forEach((signature: IFacilityProjectCostSignatures) => {
          signature.createdDate = Helpers.convertUtcToLocalTime2(signature.createdDate);
          signature.updatedDate = Helpers.convertUtcToLocalTime2(signature.updatedDate);
          signature.signDate = Helpers.convertUtcToLocalTime2(signature.signDate);
        });
        this.facilityProjectCostSignaturesValue$.next(signatures);
        this.sftBodStatusService.nextChangeStatus(true);

        return this.facilityProjectCostSignaturesValue$.value;
      }), catchError(error => {
        this.facilityProjectCostSignaturesValue$.error('Error loading data: could not be able to load Fit bod status.');
        return error;
      })
    );
  }

  private getSignatureByFacilityProjectCostEstimates(facilityProjecCosttEstimateId: string): Observable<any> {
    const serviceUrl: string =
      environment.PacerDomainURL +
      'FacilityProjectCostSignatures/ListByFacilityProjectCostEstimatesId/' +
      facilityProjecCosttEstimateId;

    return this.http.get(serviceUrl, { responseType: 'json' }).pipe(
      map((data: any[]) => {
        return data;
      })
    );
  }
}
