import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { PadbuilderImport } from '../../../../api/padbuilder-import';
import { PermissionsProvider } from '../../../../services/permissions.provider';
import { ScenarioStoreService } from '../../../../services/scenario-store.service';
import { DasSimpleService } from '../../../../services/das-simple.service';
import { Guid } from 'guid-typescript';
import { NotificationService } from '@progress/kendo-angular-notification';
import { AuthenticationService } from '../../../../services';
import { DataService } from '../../../../services/data.service';
import { IDevAreaCode } from '../../../../api/scenarioSequence';
import { ActivatedRoute, ParamMap } from '@angular/router';

@Component({
  selector: 'app-import-from-smart',
  templateUrl: './import-from-smart.component.html',
  styleUrls: ['./import-from-smart.component.css']
})
export class ImportFromSmartComponent implements OnInit {
  public showProgressBar: boolean;
  public formSmartImport: UntypedFormGroup;
  public selectedOptionIndex = 0;
  public selectedOptionIndexTab1 = true;
  public devAreaList: any[];
  public devAreaListFiltered: any[];
  public sequenceList: any[];
  public sequenceListFiltered: any[];
  public sequenceStatusList: any[] = [
    { text: 'Draft', value: 'Draft' },
    { text: 'Promoted', value: 'Promoted' },
    { text: 'Published', value: 'Published' },
  ];
  public sequenceSelectDisabled = true;
  public canEditCnd = false;
  public selectedOptionIndexTab2: boolean;
  public pbImport: PadbuilderImport;
  public formUpload: UntypedFormGroup;
  public uploadProgress: number;
  public importProgress: number;
  public submitted = false;
  public scenarioId: string;
  public developmentAreas: IDevAreaCode[];
  public isRefreshPads = false;
  
  constructor(private spinner: NgxSpinnerService,
    private dialog: DialogRef,
    private notificationService: NotificationService,
    private dasSimpleService: DasSimpleService,
    private permissionsProvider: PermissionsProvider,
    private dataService: DataService,    
    private scenarioStoreService: ScenarioStoreService,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.spinner.show();
    this.canEditCnd = this.permissionsProvider.canEditCnd;
    this.scenarioStoreService.scenarioID$.subscribe(data => {
      this.scenarioId = data;
    });

    this.dasSimpleService.getDevAreaCodes().subscribe(
      data => {
        if (data.result.length > 0) {
          this.developmentAreas = data.result;
          this.devAreaList = this.developmentAreas
            .map(item => ({ text: item.developmentAreaDescription, value: item.developmentAreaCode }))
            .sort((a, b) => (a.text > b.text ? 1 : a.text === b.text ? (a.text > b.text ? 1 : -1) : -1));
          this.devAreaListFiltered = this.devAreaList;
        }
        this.spinner.hide();
      },
      (error: any) => {
        alert('An error has ocurred trying to loading Development areas from Smart.');
        this.spinner.hide();
      }
    );    
    this.loadPbImport();
    // For Smart Dialog
    this.createNewFormGroup(null);

    if (this.isRefreshPads) {
      this.route.paramMap.subscribe((params: ParamMap) => {
        const scenarioId = params.get('id');
        this.scenarioId = scenarioId;
      });
    }
  }

  public loadPbImport() {
    if (this.formUpload) {
      this.formUpload.reset();
    }
    this.pbImport = this.initModel();
    this.uploadProgress = 0;
  }

  public initModel(): PadbuilderImport {
    return {
      fileName: '',
      fileNameLoaded: '',
      modificationDate: null,
      scenarioName: '',
      scenarioId: Guid.EMPTY,
      userName: '',
      forecastNotes: null,
      message: null,
      rigCount: null,
    };
  }

  public createNewFormGroup(data: any) {
    if (data === null) {
      this.formSmartImport = new UntypedFormGroup({
        devArea: new UntypedFormControl(null, [Validators.required]),
        sequenceStatus: new UntypedFormControl(null, [Validators.required]),
        sequence: new UntypedFormControl(null, [Validators.required]),
        smartLink: new UntypedFormControl(null),
      });
    }
  }

  public onTabSelect(event: any): void {
    this.selectedOptionIndex = event.index;

    switch (event.index) {
      case 0:
        // Search sequence
        this.formSmartImport.get('devArea').enable();
        this.formSmartImport.get('sequenceStatus').enable();
        this.formSmartImport.get('sequence').enable();
        this.formSmartImport.get('smartLink').disable();
        this.formSmartImport.clearValidators();
        break;
      case 1:
        // Provide link for smart
        this.formSmartImport.get('devArea').disable();
        this.formSmartImport.get('sequenceStatus').disable();
        this.formSmartImport.get('sequence').disable();
        this.formSmartImport.get('smartLink').enable();
        this.formSmartImport.clearValidators();
        break;
      default:
        // tslint:disable-next-line: no-console
        console.log('An error has ocurred at select tab.');
        break;
    }
  }

  public handleDevAreaChange(value: any, form: UntypedFormGroup) {
    if (value !== null && value !== undefined) {
      const sequenceStatus = form.controls['sequenceStatus'].value;
      if (sequenceStatus !== null && sequenceStatus !== undefined) {
        this.sequenceList = [];
        this.formSmartImport.get('sequence').setValue(null);
        this.dasSimpleService.getSequenceByDevAreaStatus(value.value, sequenceStatus.value).subscribe(data => {
          if (data.length > 0) {
            this.sequenceList = data
              .map(item => ({ text: item.name, value: item.id }))
              .sort((a, b) => (a.text > b.text ? 1 : a.text === b.text ? (a.text > b.text ? 1 : -1) : -1));
            this.sequenceListFiltered = this.sequenceList;
          }
        });
      }
    }
  }

  //Filter events for SMART
  public handleFilterSmartDevArea(value: any) {
    if (this.devAreaList)
    {
      this.devAreaListFiltered = this.devAreaList.filter(
        (s) => s.text.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    }    
  }

  public handleSequenceStatusChange(value: any, form: UntypedFormGroup) {
    if (value !== null && value !== undefined) {
      const devArea = form.controls['devArea'].value;
      if (devArea !== null && devArea !== undefined) {
        this.sequenceList = [];
        this.formSmartImport.get('sequence').setValue(null);
        this.dasSimpleService.getSequenceByDevAreaStatus(devArea.value, value.value).subscribe(data => {
          if (data.length > 0) {
            this.sequenceList = data
              .map(item => ({ text: item.name, value: item.id }))
              .sort((a, b) => (a.text > b.text ? 1 : a.text === b.text ? (a.text > b.text ? 1 : -1) : -1));
            this.sequenceListFiltered = this.sequenceList;
            this.sequenceSelectDisabled = false;
          }
        });
      }
    }
  }

  public handleFilterSmartSequence(value: any) {
    if (this.sequenceList)
    {
      this.sequenceListFiltered = this.sequenceList.filter(
        (s) => s.text.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    }    
  }

  public smartImport() {    
    this.scenarioStoreService.changeScenarioID(Guid.create().toString());
    let sequenceId: string;
    if (this.selectedOptionIndex === 0) {
      sequenceId = this.formSmartImport.controls['sequence'].value.value;
    } else if (this.selectedOptionIndex === 1) {
      const sequenceUrl = this.formSmartImport.controls['smartLink'].value;  
      let uriArray = sequenceUrl.split("/");    
      sequenceId = uriArray[uriArray.length - 1]; 
    }
    if (!this.pbImport) {
      return;
    }

    if (this.formSmartImport.dirty && this.formSmartImport.valid) {
      this.submitted = true;
      this.notificationService.show({
        content: 'Importing from SMART, this may take a few minutes...',
        position: { horizontal: 'center', vertical: 'top' },
        animation: { type: 'fade', duration: 700 },
        type: { style: 'info', icon: true },
      });

      this.pbImport.scenarioId = this.scenarioId;
      this.pbImport.userName = this.authenticationService.getCurrentUser();
      this.pbImport.sequenceId = sequenceId;
      this.scenarioStoreService.setSequenceId(sequenceId);

      this.dataService.smartSequenceImport(this.pbImport).subscribe(
        (res: PadbuilderImport) => {
          this.onSmartImportComplete(res);
        },
        (err: any) => {
          this.onImportError(err);
        }
      );
    }
  }

  public onSmartImportComplete(value: any) {
    this.scenarioStoreService.changeNewScenario(false);
    this.dialog.close({ openSelectPadDialog: true });
  }

  public onImportError(value: any) {
    this.showProgressBar = false;
    this.importProgress = 0;
    this.notificationService.show({
      content: 'Error on Importing PadBuilder file process',
      position: { horizontal: 'center', vertical: 'top' },
      animation: { type: 'fade', duration: 500 },
      type: { style: 'error', icon: true },
    });

    this.submitted = false;
    this.dialog.close({ openSelectPadDialog: false });
  }

  public onCancelAction(): void {
    this.dialog.close({ openSelectPadDialog: false });
  }

  public onClickOk(): void {
    this.smartImport();
  }
  public onClickRefreshPads(): void {
    this.RefreshPadFromSmartImport();
  }

  public RefreshPadFromSmartImport() {
    this.spinner.show();
    let sequenceId: string;
    if (this.selectedOptionIndex === 0) {
      sequenceId = this.formSmartImport.controls['sequence'].value.value;
    }
    if (!this.pbImport) {
      return;
    }

    if (this.formSmartImport.dirty && this.formSmartImport.valid) {
      this.spinner.show();
      this.pbImport.scenarioId = this.scenarioId;
      this.pbImport.userName = this.authenticationService.getCurrentUser();
      this.pbImport.sequenceId = sequenceId;
      this.scenarioStoreService.setSequenceId(sequenceId);
      this.dataService.refreshPadsSmartSequenceImport(this.pbImport).subscribe(
        (res: PadbuilderImport) => {
          this.scenarioStoreService.setCostLogicDetector(false);
          this.scenarioStoreService.padbuilderImportCompleted(this.scenarioId);
          this.spinner.hide();
          this.showInfo('The Pads refreshed successfully.', 'success');
          this.dialog.close();
        },
        (err: any) => {
          this.spinner.hide();
          this.onImportError(err);
        }
      );
    }
  }

  public showInfo(value: string, typeChange: string) {
    switch (typeChange) {
      case 'info': {
        this.notificationService.show({
          content: value,
          position: { horizontal: 'center', vertical: 'top' },
          animation: { type: 'fade', duration: 200 },
          type: { style: 'info', icon: true },
        });
        break;
      }
      case 'success': {
        this.notificationService.show({
          content: value,
          position: { horizontal: 'center', vertical: 'top' },
          animation: { type: 'fade', duration: 300 },
          type: { style: 'success', icon: true },
        });
        break;
      }
      default: {
        break;
      }
    }
  }

  
}
