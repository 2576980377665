import { Injectable } from '@angular/core';
import { Observable, concat, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject,tap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IViewPricetScenario } from '../api/viewPricetScenario';
import { HttpErrorHandler, HandleError } from './http-error-handler.service';
const baseUrlScenario = environment.PricetDomainURL + 'Scenario';
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Credentials': 'true',
    'Access-Control-Allow-Methods': 'GET, HEAD, OPTIONS, POST, PUT',
  }),
};

@Injectable({
  providedIn: 'root',
})
export class ScenarioDataService extends BehaviorSubject<any[]> {
  private data: any[] = [];
  private handleError: HandleError;
  public baseUrlScenario: string = environment.PricetDomainURL + 'Scenario';
  constructor(private http: HttpClient) {
    super([]);
    httpOptions.headers.append('Access-Control-Allow-Credentials', 'true');
    httpOptions.headers.append('Access-Control-Allow-Methods', 'GET, HEAD, OPTIONS, POST, PUT');
  }

  public read(devName: string, isActiveForOutlook: string) {
    if (this.data.length) {
      return super.next(this.data);
    }

    this.fetch(devName, isActiveForOutlook)
      .pipe(
        tap(data => {
          this.data = data;
        })
      )
      .subscribe(data => {
        super.next(data);
      });
  }

  public getScenarioExpansionsByDevName(
    devName: string,
    isActiveForOutlook: string
  ): Observable<IViewPricetScenario[]> {
    return this.http.get<IViewPricetScenario[]>(
      `${baseUrlScenario}/ListExpansionsByDevName/` + `${devName}/` + `${isActiveForOutlook}`
    );
  }

  private fetch(devName: string, isActiveForOutlook: string): Observable<any[]> {
    return this.http
      .get<any[]>(`${baseUrlScenario}/ListExpansionsByDevName/` + `${devName}/` + `${isActiveForOutlook}`)
      .pipe(map(res => res as any[]));
  }
}
