import { Directive, ElementRef, HostListener, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { PAndEProviderService } from '../modules/existing-facilities/_services/p-and-e-provider.service';
import { PermissionsProvider } from '../services/permissions.provider';


@Directive({
  selector: '[appPAndEEnterKey]'
})
export class PAndEEnterKeyDirective implements OnInit, OnDestroy  {
  @Input('appPAndEEnterKey')
  public currentFormGroup: (args: any) => UntypedFormGroup;
  private unsubKeydown: () => void;
  private hasPermissionToEdit = false;

  constructor(private el: ElementRef,
    private renderer: Renderer2,
    public pPAndEProviderService: PAndEProviderService,
    private permissionsProvider: PermissionsProvider) { }


  public ngOnInit(): void {
    this.unsubKeydown = this.renderer.listen(this.el.nativeElement, 'keydown', e => this.onKeydown(e));

    this.pPAndEProviderService.pAndEProjectBod$.subscribe((pAndEProjectBod: any) => {
      if (pAndEProjectBod) {
        this.pPAndEProviderService.isReadOnlyFacility$.subscribe((isReadOnlyFacility: boolean) => {
          if (isReadOnlyFacility) {
            this.hasPermissionToEdit = false;
          } else {
            if (this.permissionsProvider.isAdmin) {
              this.hasPermissionToEdit = true;
            } else {
              this.hasPermissionToEdit = this.permissionsProvider.canEditPAndE;
            }
          }
        });
      } else {
        if (this.permissionsProvider.canEditPAndE) {
          this.hasPermissionToEdit = true;
        }     
      }
    });

  }

  public ngOnDestroy(): void {
    this.unsubKeydown();
  }


  public onKeydown(e: KeyboardEvent): void {

    if (e.key !== 'Enter') {
      // Handle just tabs
      return;
    }

    if (!this.hasPermissionToEdit) {
      // Prevent the focus from moving to the next element
      e.preventDefault();
    }

  }

  @HostListener('window:keydown', ['$event']) 
  public onKeyDownHandler(e: KeyboardEvent): void {
    if (e.key !== 'Enter') {
      // Handle just tabs
      return;
    }

    if (!this.hasPermissionToEdit) {
      // Prevent the focus from moving to the next element
      e.preventDefault();
    }

  }
}
