import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IEditableInline } from 'src/app/api/ieditable-inline';
import { InfrastructureRegulatoryPermitting } from 'src/app/api/infrastructure-regulatory-permitting';
import { InlineEditService } from 'src/app/services/inline-edit.service';
import { PerformanceBodDataService } from 'src/app/services/performance-bod-data.service';
import { PerformanceBodCalculationsService } from 'src/app/services/performance-bod-calculations.service';
import * as Helpers from 'src/app/_shared/helpers';
import { map } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class InfrastructureRegulatoryPermittingService extends InlineEditService implements IEditableInline {
  public data: InfrastructureRegulatoryPermitting[] = [];
  public originalData: InfrastructureRegulatoryPermitting[] = [];
  public createdItems: InfrastructureRegulatoryPermitting[] = [];
  public updatedItems: InfrastructureRegulatoryPermitting[] = [];
  public deletedItems: InfrastructureRegulatoryPermitting[] = [];
  public performanceBodId: string;

  constructor(
    private performanceBodDataService: PerformanceBodDataService,
    private performanceBodCalculationsService: PerformanceBodCalculationsService
  ) {
    super();
  }

  public isNew(item: any): boolean {
    return !item.id;
  }

  public itemIndex(item: any, data: InfrastructureRegulatoryPermitting[]): number {
    for (let idx = 0; idx < data.length; idx++) {
      if (data[idx].id === item.id) {
        return idx;
      }
    }
    return -1;
  }

  public create(): Observable<any> {
    return this.performanceBodDataService.createPerformanceBodRegulatoryPermitting(this.createdItems);
  }

  public retrieve(): Observable<InfrastructureRegulatoryPermitting[]> {
    return this.performanceBodDataService.getPerformanceBodRegulatoryPermitting(this.performanceBodId).pipe(map(data => {
      data.forEach(element => {
        if (element.requestedConstructionStartDate !== null && element.requestedConstructionStartDate !== undefined) {
          element.requestedConstructionStartDate = Helpers.convertUtcToLocalTime(
            element.requestedConstructionStartDate
          );
        } else {
          element.requestedConstructionStartDate = undefined;
        }
      });
      return data;
    }));
  }

  public update(): Observable<any> {
    return this.performanceBodDataService.updatePerformanceBodRegulatoryPermitting(this.updatedItems);
  }

  public delete(): Observable<any> {
    return this.performanceBodDataService.updatePerformanceBodRegulatoryPermitting(this.deletedItems);
  }

  public applyLogicFromPads(): void {
    const regulatoryPermitingChanges = this.performanceBodCalculationsService.regulatoryPermittingCalculations(this.data);
    for(const regulatoryPermitting of regulatoryPermitingChanges) {
      this.updateItem(regulatoryPermitting);
    }
  }

  public applyLogicFromWellPads(): void {
    const regulatoryPermitingChanges = this.performanceBodCalculationsService.
    regulatoryPermittingPermitsRequiredByCalculations(this.data);
    for(const regulatoryPermitting of regulatoryPermitingChanges) {
      this.updateItem(regulatoryPermitting);
    }
  }  
}
