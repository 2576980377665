import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs';
import { IEditableInline } from 'src/app/api/ieditable-inline';
import { InfrastructureSourceWater } from 'src/app/api/infrastructure-source-water';
import { InlineEditService } from 'src/app/services/inline-edit.service';
import { PerformanceBodDataService } from 'src/app/services/performance-bod-data.service';
import { PerformanceBodStoreService } from 'src/app/services/performance-bod-store.service';
import * as Helpers from 'src/app/_shared/helpers';

@Injectable({
  providedIn: 'root',
})
export class InfrastructureSourceWaterService extends InlineEditService implements IEditableInline {
  public data: InfrastructureSourceWater[] = [];
  public originalData: InfrastructureSourceWater[] = [];
  public createdItems: InfrastructureSourceWater[] = [];
  public updatedItems: InfrastructureSourceWater[] = [];
  public deletedItems: InfrastructureSourceWater[] = [];
  public performanceBodId: string;

  constructor(
    private performanceBodDataService: PerformanceBodDataService
  ) {
    super();
  }

  public isNew(item: any): boolean {
    return !item.id;
  }

  public itemIndex(item: any, data: InfrastructureSourceWater[]): number {
    for (let idx = 0; idx < data.length; idx++) {
      if (data[idx].id === item.id) {
        return idx;
      }
    }
    return -1;
  }

  public create(): Observable<any> {
    return this.performanceBodDataService.createInfrastructureSourceWater(this.createdItems);
  }

  public retrieve(): Observable<InfrastructureSourceWater[]> {
    return this.performanceBodDataService.getInfrastructureSourceWater(this.performanceBodId).pipe(map(data => {
      data.forEach(element => {
        if (element.readyForServiceDate !== null && element.readyForServiceDate !== undefined) {
          element.readyForServiceDate = Helpers.convertUtcToLocalTime(element.readyForServiceDate);
        } else {
          element.readyForServiceDate = undefined;
        }
      });
      return data;
    }));
  }

  public update(): Observable<any> {
    return this.performanceBodDataService.updateInfrastructureSourceWater(this.updatedItems);
  }

  public delete(): Observable<any> {
    return this.performanceBodDataService.updateInfrastructureSourceWater(this.deletedItems);
  }

}
