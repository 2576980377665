<kendo-dialog title="Warning" *ngIf="openDialog" [minWidth]="250" [width]="450" (close)="closeKendoPopUp()">
  <p>{{ warningMessage }}</p>
</kendo-dialog>

<kendo-grid
  #infrastructureCommercial
  [data]="view | async"
  (cellClick)="cellClickHandler($event)"
  (cellClose)="cellCloseHandler($event)"
  (cancel)="cancelHandler($event)"
  (save)="saveHandler($event)"
  (remove)="removeHandler($event)"
  (add)="addHandler($event)"
  [navigable]="true"
  [resizable]="true"
  [sort]="gridState.sort"
  [sortable]="{ mode: 'multiple' }"
  (dataStateChange)="onStateChange($event)"
  filterable="menu"
  [filter]="filter"
  (filterChange)="handlerFilter($event)"
  class="infrastructure-commercial-draggable"
  [rowClass]="rowCallback"
>
  <ng-template kendoGridToolbarTemplate>
    <button
      kendoButton
      class="k-button"
      [disabled]="!infrastructureCommercialService.hasChanges()"
      (click)="cancelChanges(infrastructureCommercial)"
      [primary]="true"
    >
      Cancel
    </button>
    <button [icon]="'plus'" kendoGridAddCommand [primary]="true" [disabled]="!canEditPerformanceBod"></button>
  </ng-template>
  <kendo-grid-column title="" field="" [hidden]="true" [width]="1">
    {{ rowLoaded() }}
  </kendo-grid-column>
  <kendo-grid-column field="performanceBodPadsId" title="Well Pad Name" [width]="120" [editable]="true">
    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
      <multicheck-filter
        [isPrimitive]="false"
        [field]="column.field"
        textField="wellPadName"
        valueField="id"
        [filterService]="filterService"
        [currentFilter]="filter"
        [data]="performanceBodPadsList"
      >
      </multicheck-filter>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ getPerformanceBodPads(dataItem.performanceBodPadsId)?.wellPadName }}
    </ng-template>
    <ng-template
      kendoGridEditTemplate
      let-dataItem="dataItem"
      let-column="column"
      let-formGroup="formGroup"
      let-isNew="isNew"
    >
      <kendo-dropdownlist
        [data]="performanceBodPadsList"
        textField="wellPadName"
        valueField="id"
        [valuePrimitive]="true"
        [formControl]="formGroup.get('performanceBodPadsId')"
        (valueChange)="changePackage($event, dataItem, formGroup)"
      >
        <ng-template kendoDropDownListValueTemplate let-dataItem>
          {{ getPerformanceBodPads(dataItem?.id)?.wellPadName }}
        </ng-template>
        <ng-template kendoDropDownListItemTemplate let-dataItem>
          {{ getPerformanceBodPads(dataItem?.id)?.wellPadName }}
        </ng-template>
      </kendo-dropdownlist>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="performanceBodPackagesId" title="Package Name" [editable]="false" [width]="120">
    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
      <multicheck-filter
        [isPrimitive]="false"
        [field]="column.field"
        textField="name"
        valueField="id"
        [filterService]="filterService"
        [currentFilter]="filter"
        [data]="performanceBodPackagesList"
      >
      </multicheck-filter>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ getPerformanceBodPackages(dataItem.performanceBodPackagesId)?.name }}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="description" title="Commercial Description" [width]="120" [editable]="true">
    <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-column="column" let-formGroup="formGroup">
      <input #input elementFocus type="text" [formControl]="formGroup.get(column.field)" class="k-textbox" />
      <kendo-popup [anchor]="input" [hidden]="messageDuplicate" popupClass="k-widget k-tooltip k-tooltip-validation">
        The Commercial Description already exist.
      </kendo-popup>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="readyForServiceDate"
    title="Commercial RFSD"
    [width]="120"
    [editable]="true"
    editor="date"
    format="MM/dd/yyyy"
    [filterable]="filterable"
    filter="date"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
      <span>{{ dataItem.readyForServiceDate | date: 'MM/dd/yyyy' }}</span>
    </ng-template>
    <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-column="column" let-formGroup="formGroup">
      <kendo-datepicker
        [value]="dataItem.readyForServiceDate"
        [format]="'MM/dd/yyyy'"
        [formControl]="formGroup.get('readyForServiceDate')"
      ></kendo-datepicker>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="capacity"
    title="Capacity (Contract Agreement-MMSCFD,BPD,KW)"
    [width]="120"
    [editable]="true"
    editor="numeric"
    filter="numeric"
  ></kendo-grid-column>
  <kendo-grid-column
    field="newPlusExistingPeakProduction"
    title="New + Existing Peak Production"
    [width]="120"
    [editable]="true"
    editor="numeric"
    filter="numeric"
  ></kendo-grid-column>
  <kendo-grid-column
    field="maxPressurePsig"
    title="Max Pressure (PSIG)"
    [width]="120"
    [editable]="true"
    editor="numeric"
    filter="numeric"
  ></kendo-grid-column>
  <kendo-grid-column
    field="operatingPressurePsig"
    title="Operating Pressure (PSIG)"
    [width]="120"
    [editable]="true"
    editor="numeric"
    filter="numeric"
  ></kendo-grid-column>
  <kendo-grid-column
    field="otherComposition"
    title="Other (Composition)"
    [width]="120"
    [editable]="true"
  ></kendo-grid-column>
  <kendo-grid-column field="tieInLocation" title="Tie-In Location" [width]="120" [editable]="true"></kendo-grid-column>
  <kendo-grid-column
    field="comments"
    title="Comments"
    [width]="150"
    [editable]="true"
    [hidden]="false"
  ></kendo-grid-column>
  <kendo-grid-command-column title="" [width]="150">
    <ng-template kendoGridCellTemplate let-isNew="isNew">
      <button kendoGridRemoveCommand [primary]="true" [icon]="'minus'" [disabled]="!canEditPerformanceBod"></button>
      <button kendoGridSaveCommand [primary]="true">Add</button>
      <button kendoGridCancelCommand [primary]="true">Cancel</button>
    </ng-template>
  </kendo-grid-command-column>
</kendo-grid>
