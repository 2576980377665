import { Component, EventEmitter, OnInit, Output, ViewChild, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs';
import { GridDataResult, RowClassArgs } from '@progress/kendo-angular-grid';
import { State, process, filterBy, CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { IPerformanceBodPackages } from 'src/app/api/performance-bod-package';
import { IPerformanceBodPads } from 'src/app/api/performance-bod-pads';
import { PerformanceBodStoreService } from 'src/app/services/performance-bod-store.service';
import { Guid } from 'guid-typescript';
import { ScopeExistingFacilitiesEditService } from './scope-existing-facilities-edit.service';
import { IPerformanceBodExistingFacilities } from 'src/app/api/performance-bod-existing-facilities';
import { UtilService } from 'src/app/services/util.service';
import { PermissionsProvider } from 'src/app/services/permissions.provider';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DragAndDropService } from 'src/app/_shared/drag-and-drop.service';

const DRAGGABLE_SELECTOR = '.scope-existing-facilities-draggable';

@Component({
  selector: 'app-scope-existing-facilities',
  templateUrl: './scope-existing-facilities.component.html',

})
export class ScopeExistingFacilitiesComponent implements OnInit {
  public filter: CompositeFilterDescriptor;
  public filterable: boolean = true;
  public warningMessage: string =
    'Please provide a Well Pad Name on the Package Information Section (Performance BOD Summary Tab) before adding a new record';
  public countWellPadName = 0;
  public openDialog = false;
  public wellPadName = 'Pending To Add Well Pad Name field';
  public view: Observable<GridDataResult>;
  public performanceBodPackagesList: IPerformanceBodPackages[];
  public performanceBodPadsList: IPerformanceBodPads[];
  public designYearList: Array<{ text: string; value: string }> = [];
  public performanceBodId: string;
  public messageDuplicate = true;
  public formGroup: UntypedFormGroup;
  public gridState: State = {
    sort: [],
    skip: 0,
  };
  public canEditPerformanceBod: boolean = false;
  @Output() public performanceBodUpdate = new EventEmitter<string>();
  @Output() public existinFacilityCreate = new EventEmitter<any>();
  @Output() public saveBodCompleted = new EventEmitter<boolean>();
  public scopeExistingFacilitiesEditService: ScopeExistingFacilitiesEditService;
  @ViewChild('performanceBodExistingFacilitiesGrid')
  private performanceBodExistingFacilitiesGrid: any;
  private dragAndDropService: DragAndDropService;

  constructor(
    private performanceBodStoreService: PerformanceBodStoreService,
    private formBuilder: UntypedFormBuilder,    
    private utilService: UtilService,
    private permissionsProvider: PermissionsProvider,
    private authenticationService: AuthenticationService,
    @Inject(DragAndDropService) dragAndDropServiceFactory: any,
    @Inject(ScopeExistingFacilitiesEditService) scopeExistingFacilitiesEditServiceFactory: any
  ) {
    this.dragAndDropService = dragAndDropServiceFactory();
    this.scopeExistingFacilitiesEditService = scopeExistingFacilitiesEditServiceFactory();
  }

  public ngOnInit(): void {
    this.performanceBodStoreService.performanceBodSignatured$.subscribe((isSignature) => {
      this.canEditPerformanceBod = isSignature ? false : this.permissionsProvider.canEditPerformanceBod;
    });

    // Set the performance bod identification
    this.performanceBodStoreService.performanceBodId$.subscribe(id => {
      this.performanceBodId = id;
      this.scopeExistingFacilitiesEditService.performanceBodId = id;
      // Get async array
      this.scopeExistingFacilitiesEditService.read();      
    });

    // get the pad names of store service
    this.performanceBodStoreService.performanceBodPackagesData$.subscribe(data => {
      this.performanceBodPackagesList = data;
    });

    // get pads of store service
    this.performanceBodStoreService.performanceBodPadsData$.subscribe(data => {
      this.performanceBodPadsList = data.filter(row => row.wellPadName !== '');
    });

    for (let i = 2016; i <= 2050; i++) {
      const designYear = {} as { text: string; value: string };
      designYear.text = i.toString();
      designYear.value = i.toString();

      this.designYearList.push(designYear);
    }

    this.view = this.scopeExistingFacilitiesEditService.pipe(
      map(data => {
        this.dragAndDropService.dataSourceRefreshed(data);
        return process(filterBy(data, this.filter), this.gridState);
      })
    );



    this.dragAndDropService.initialize(this.scopeExistingFacilitiesEditService.data, DRAGGABLE_SELECTOR, () => {
      this.scopeExistingFacilitiesEditService.markAsSetOrder(this.authenticationService.getCurrentUser());
    });
  }

  public ngAfterViewInit(): void {
    // To Do
  }

  public ngOnDestroy(): void {
    this.dragAndDropService.refreshDragAndDrop();
  }

  public createFormGroup(dataItem: any): UntypedFormGroup {
    return this.formBuilder.group({
      id: [dataItem.id],
      name: [dataItem.name, [Validators.required, Validators.minLength(1), Validators.maxLength(100)]],
      isDeleted: [dataItem.isDeleted],
      createdBy: [dataItem.createdBy],
      createdDate: [dataItem.createdDate],
      updatedBy: [dataItem.updatedBy],
      updatedDate: [dataItem.updatedDate],
      scopeDefinition: [dataItem.scopeDefinition, [Validators.maxLength(500)]],
      readyForServiceDate: [dataItem.readyForServiceDate, [Validators.required]],
      sftDesignYear: [dataItem.sftDesignYear, [Validators.min(2016), Validators.max(2050)]],
      comments: [dataItem.comments, [Validators.maxLength(500)]],
      performanceBodPadsId: [dataItem.performanceBodPadsId, [Validators.required]],
      performanceBodPackagesId: [dataItem.performanceBodPackagesId],
      afeNumber: [dataItem.afeNumber, [
        Validators.maxLength(13),
        Validators.pattern('^[a-zA-Z0-9]{0,13}$'),
      ]],  
      afeAmount: [dataItem.afeAmount, [
        Validators.maxLength(8),
        Validators.pattern('^[a-zA-Z0-9]{0,8}$'),
      ]],  
      chargeCode : [dataItem.chargeCode, [
        Validators.maxLength(13),
        Validators.pattern('^[a-zA-Z0-9]{0,13}$'),
      ]],
      cndHOEstimate: [dataItem.cndHOEstimate, [Validators.min(0), Validators.max(999999999999)]],
    });
  }

  public cellClickHandler({ sender, rowIndex, columnIndex, dataItem, isEdited }) {
    if (!isEdited && this.canEditPerformanceBod) {
      if (dataItem.readyForServiceDate !== undefined && columnIndex === 4) {
        if (dataItem.readyForServiceDate !== null) {
          const y = new Date(dataItem.readyForServiceDate.toString()).getFullYear();
          const m = new Date(dataItem.readyForServiceDate.toString()).getMonth();
          const d = new Date(dataItem.readyForServiceDate.toString()).getDate();
          dataItem.readyForServiceDate = new Date(y, m, d);
        }
      }

      sender.editCell(rowIndex, columnIndex, this.createFormGroup(dataItem));
      this.dragAndDropService.onEditingMode();
    }
  }

  public cellCloseHandler(args: any) {
    const { formGroup, dataItem, column } = args;

    let columnField = '';

    if (column.field === 'performanceBodPadsName') {
      columnField = 'performanceBodPadsId';
    } else {
      columnField = column.field;
    }

    const checkColumn = formGroup.controls[columnField];

    if (!checkColumn.valid) {
      args.preventDefault();
    } else if (checkColumn.dirty) {
      if (columnField === 'performanceBodPadsId') {
        dataItem.performanceBodPadsName = this.getPerformanceBodPads(formGroup.controls[columnField].value);
      }
    }

    if (!formGroup.valid) {
      // prevent closing the edited cell if there are invalid values.
      args.preventDefault();
    } else if (formGroup.dirty) {
      if (formGroup.valid && args.sender.data.total > 0) {
        for (let i = 0; i <= args.sender.data.total - 1; i++) {
          if (
            args.sender.data.data[i].name === formGroup.value.name.trim() &&
            args.sender.data.data[i].performanceBodPackagesId === formGroup.value.performanceBodPackagesId &&
            args.sender.data.data[i].id !== formGroup.value.id
          ) {
            this.messageDuplicate = false;
            args.preventDefault();
            return;
          } else {
            this.messageDuplicate = true;
          }
        }
      }

      if (this.messageDuplicate) {
        dataItem.updatedDate = new Date();
        dataItem.updatedBy = this.authenticationService.getCurrentUser();

        // Date is not working in Object.assign(), so need to be do it manually
        formGroup.value.updatedBy = dataItem.updatedBy;
        formGroup.value.updatedDate = dataItem.updatedDate;

        this.scopeExistingFacilitiesEditService.assignValues(dataItem, formGroup.value);
        this.scopeExistingFacilitiesEditService.update(dataItem);
      }
    }
    // calls this to add the attribute of current row
    this.dragAndDropService.onEditingClose();
    this.dragAndDropService.refreshDragAndDrop();
  }

  public addHandler({ sender, rowIndex }) {
    const item: IPerformanceBodExistingFacilities = {
      id: null,
      name: '',
      isDeleted: false,
      createdBy: this.authenticationService.getCurrentUser(),
      createdDate: new Date(),
      updatedBy: this.authenticationService.getCurrentUser(),
      updatedDate: new Date(),
      scopeDefinition: '',
      readyForServiceDate: null,
      sftDesignYear: null,
      comments: '',
      performanceBodPadsId: '',
      performanceBodPackagesId: '',
      sortOrder: 0,
      afeNumber: '',
      afeAmount: 0,
      chargeCode : null,
      cndHOEstimate : 0
    };

    if (this.performanceBodPadsList) {
      for (const pad of this.performanceBodPadsList) {
        if (pad.wellPadName === '') {
          this.countWellPadName = this.countWellPadName + 1;
        }
      }
      if (this.countWellPadName === this.performanceBodPadsList.length) {
        this.openDialog = true;
        this.cancelHandler({ sender, rowIndex });
        this.countWellPadName = 0;
        return;
      } else {
        item.createdBy = this.authenticationService.getCurrentUser();
        item.createdDate = new Date();
        sender.addRow(this.createFormGroup(item));
        this.dragAndDropService.onEditingMode();
      }
    }
  }

  public cancelHandler({ sender, rowIndex }) {
    this.messageDuplicate = true;
    sender.closeRow(rowIndex);
    this.dragAndDropService.onEditingClose();
    this.dragAndDropService.refreshDragAndDrop();
  }

  public saveHandler({ sender, formGroup, rowIndex }) {
    if (formGroup.valid && sender.data.total > 0) {
      for (let x = 0; x <= sender.data.total - 1; x++) {
        if (
          sender.data.data[x].name === formGroup.value.name.trim() &&
          sender.data.data[x].performanceBodPackagesId === formGroup.value.performanceBodPackagesId &&
          sender.data.data[x].id !== formGroup.value.id
        ) {
          this.messageDuplicate = false;
          return;
        } else {
          this.messageDuplicate = true;
        }
      }
    }

    if (this.messageDuplicate) {
      if (formGroup.valid) {
        formGroup.patchValue({
          performanceBodId: this.performanceBodId,
        });

        if (this.performanceBodPadsList) {
          for (const pad of this.performanceBodPadsList) {
            if (pad.wellPadName === '') {
              this.countWellPadName = this.countWellPadName + 1;
            }
          }
          if (this.countWellPadName === this.performanceBodPadsList.length) {
            this.openDialog = true;
            this.countWellPadName = 0;
            return;
          } else {
            formGroup.value.id = Guid.create().toString();
            this.scopeExistingFacilitiesEditService.create(formGroup.value);
            sender.closeRow(rowIndex);
            this.dragAndDropService.onEditingClose();
            this.dragAndDropService.dataSourceRefreshed(this.scopeExistingFacilitiesEditService.data);
            this.dragAndDropService.refreshDragAndDrop();
            this.scopeExistingFacilitiesEditService.markAsSetOrder(this.permissionsProvider.cai);
          }
        }
      } else {
        // required fields
        this.utilService.markFormGroupAsTouched(formGroup);
      }
    }
  }

  public removeHandler({ sender, dataItem }) {
    dataItem.isDeleted = true;
    this.scopeExistingFacilitiesEditService.remove(dataItem);

    sender.cancelCell();
  }

  public saveChanges(grid: any): void {
    grid.closeCell();
    grid.cancelCell();

    // calls this to add the attribute of current row
    this.dragAndDropService.refreshDragAndDrop();
    this.scopeExistingFacilitiesEditService.saveChanges();
  }

  public saveFromBOD(): void {
    this.performanceBodExistingFacilitiesGrid.closeCell();
    this.performanceBodExistingFacilitiesGrid.cancelCell();

    if (this.scopeExistingFacilitiesEditService.hasChanges()) {
      // calls this to add the attribute of current row
      this.dragAndDropService.refreshDragAndDrop();
      this.scopeExistingFacilitiesEditService.saveChanges();

      const subscription = this.scopeExistingFacilitiesEditService.subscribe(() => {
        this.saveBodCompleted.next(true);
      });

      subscription.unsubscribe();
    }
  }

  public cancelChanges(grid: any): void {
    this.messageDuplicate = true;
    grid.cancelCell();
    this.dragAndDropService.refreshDragAndDrop();
    this.scopeExistingFacilitiesEditService.cancelChanges();
  }

  public onStateChange(state: State) {
    this.gridState = state;
    this.scopeExistingFacilitiesEditService.read();


  }

  public changePackage(value, dataItem: IPerformanceBodExistingFacilities, formGroup: UntypedFormGroup) {
    const performanceBodPad = this.performanceBodPadsList.find(s => s.id === value);
    let performanceBodPackagesId: string;
    dataItem.performanceBodPackagesId = performanceBodPad ? performanceBodPad.performanceBodPackagesId : null;
    performanceBodPackagesId = dataItem.performanceBodPackagesId;
    formGroup.patchValue({
      performanceBodPackagesId,
    });

    if (!dataItem.id) {
      dataItem.performanceBodPadsId = performanceBodPad ? performanceBodPad.id : null;
      this.existinFacilityCreate.next(dataItem);
      formGroup.patchValue({
        readyForServiceDate: dataItem.readyForServiceDate,
      });
    }
  }

  public getPerformanceBodPackages(performanceBodPadsId: string): any {
    if (
      this.performanceBodPackagesList === null ||
      (this.performanceBodPackagesList === undefined && this.performanceBodPadsList === null) ||
      this.performanceBodPadsList === undefined
    ) {
      return '';
    } else {
      const vpad = this.performanceBodPadsList.find(pad => pad.id === performanceBodPadsId);
      if (vpad !== null && vpad !== undefined) {
        return this.performanceBodPackagesList.find(pkg => pkg.id === vpad.performanceBodPackagesId);
      } else {
        return '';
      }
    }
  }

  public getPerformanceBodPads(id: string): any {
    if (this.performanceBodPadsList === null || this.performanceBodPadsList === undefined) {
      return '';
    } else {
      return this.performanceBodPadsList.find(x => x.id === id);
    }
  }

  public closeKendoPopUp() {
    this.openDialog = false;
  }

  public handlerFilter(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    if (filter.filters) {
      if (filter.filters.length > 0) {
        this.dragAndDropService.onEditingMode();
      } else {
        this.dragAndDropService.onEditingClose();
        this.dragAndDropService.refreshDragAndDrop();
      }
    }
  }

  public rowCallback(context: RowClassArgs) {
    return {
      dragging: context.dataItem.dragging,
    };
  }

  public rowLoaded(): void {
    // to check until grid have been loeaded.
    this.dragAndDropService.checkIsOnEditMode();
    this.dragAndDropService.checkDraggableElementLoaded();
  }
}
