import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import 'rxjs';
import { catchError, map, tap } from 'rxjs';
import { environment } from '../../environments/environment';
import { InfrastructureCommercial } from '../api/infrastructure-commercial';
import { InfrastructureRecycle } from '../api/infrastructure-recycle';
import { InfrastructureSourceWater } from '../api/infrastructure-source-water';
import { IPerformanceBod } from '../api/performance-bod';
import { IPerformanceBodCentralTankBattery } from '../api/performance-bod-central-tank-battery';
import { IPerformanceBodCompressor } from '../api/performance-bod-compressor';
import { IPerformanceBodEdsIe } from '../api/performance-bod-eds-ie';
import { IPerformanceBodFacilityPad } from '../api/performance-bod-facility-pad';
import { IPerformanceBodFracPond } from '../api/performance-bod-frac-pond';
import { IPerformanceBodPackages } from '../api/performance-bod-package';
import { IPerformanceBodPads } from '../api/performance-bod-pads';
import { IPerformanceBodPipelines } from '../api/performance-bod-pipelines';
import { IPerformanceBodSaltWaterDisposal } from '../api/performance-bod-salt-water-disposal';
import { IPerformanceBodSummary } from '../api/performance-bod-summary';
import { IPerformanceBodWaterDisposal } from '../api/performance-bod-water-disposal';
import { IPerformanceBodWellPad } from '../api/performance-bod-well-pad';
import { IPerformanceExpectedPeakProductionRates } from '../api/performance-expected-peak-production-rates';
import { HandleError, HttpErrorHandler } from './http-error-handler.service';
import { InfrastructureRegulatoryPermitting } from '../api/infrastructure-regulatory-permitting';
import { IPerformanceBodWellArtificialLift } from '../api/performance-bod-well-artificial-lift';
import { IScopeNonStandardOutsideFactoryProjects } from '../api/scope-non-standard-outside-factory-projects';
import { IPerformanceBodExistingFacilities } from '../api/performance-bod-existing-facilities';
import { IPerformanceBodSignatures } from '../api/performance-bod-signatures';

import * as Helpers from '../_shared/helpers';
import { IPerformanceBodNotes } from '../api/performance-bod-notes';
import { IPerformanceBodNotesAttachment } from '../api/performance-bod-notes-attachment';
import { IFileInfo } from '../api/fileInfo';
import { IPerformanceBodInput } from '../api/performance-bod-input';
import { IPerformanceBodStatus } from '../api/performance-bod-status';
import { IStatusIndicator } from '../api/status-indicator';
import { IPerformanceBodWSDrillingSupply } from '../api/performance-bod-ws-drilling-supply';
import { IPerformanceBodWSFracSupply } from '../api/performance-bod-ws-frac-supply';
import { IPerformanceBodWSWaterDisposal } from '../api/performance-bod-ws-water-disposal';
import { IPerformanceBodRoadMaintenance } from '../api/performance-bod-road-maintenance';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

const httpOptionsDownload = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  responseType: 'blob' as 'json',
};

@Injectable({
  providedIn: 'root',
})
export class PerformanceBodDataService {
  private handleError: HandleError;
  private baseUrlPerformanceBod: string = environment.PacerDomainURL + 'PerformanceBod';
  private baseUrlPerformanceBodSignatures: string = environment.PacerDomainURL + 'PerformanceBodSignatures';
  private baseUrlPerformanceBodPackage: string = environment.PacerDomainURL + 'PerformanceBodPackages';
  private baseUrlPerformanceBodCentralTankBattery: string = environment.PacerDomainURL + 'PerformanceBodCtb';
  private baseUrlPerformanceExpectedPeakProductionRates: string =
    environment.PacerDomainURL + 'PerformanceExpectedPeakProductionRates';
  private baseUrlPerformanceBodCompressor: string = environment.PacerDomainURL + 'PerformanceBodCompressor';
  private baseUrlPerformanceBodSaltWaterDisposal: string = environment.PacerDomainURL + 'PerformanceBodSwd';
  private baseUrlPerformanceBodFracPond: string = environment.PacerDomainURL + 'PerformanceBodFracPonds';
  private baseURLPerformanceBodSourceWater: string = environment.PacerDomainURL + 'PerformanceBodSourceWater';
  private baseURLPerformanceBodRecycle: string = environment.PacerDomainURL + 'PerformanceBodRecycle';
  private baseURLPerformanceBodSummary: string = environment.PacerDomainURL + 'PerformanceStdFacilities';

  private baseUrlPerformanceBodEdsIe: string = environment.PacerDomainURL + 'PerformanceBodEdsIe';
  private baseUrlPerformanceBodWellPad: string = environment.PacerDomainURL + 'PerformanceBodWellPad';
  private baseUrlPerformanceBodFacilityPad: string = environment.PacerDomainURL + 'PerformanceBodFacilityPad';
  private baseUrlPerformanceWaterDisposal: string = environment.PacerDomainURL + 'PerformanceBodWaterDisposal';
  private baseUrlPerformanceBodPipelines: string = environment.PacerDomainURL + 'PerformanceBodPipelines';
  private baseUrlPerformanceBodCommercial: string = environment.PacerDomainURL + 'PerformanceBodCommercial';
  private baseUrlWaterSourceDrillingSupply: string = environment.PacerDomainURL + 'PerformanceBodWsDrillingSupply';
  private baseUrlWaterSourceFracSupply: string = environment.PacerDomainURL + 'PerformanceBodWsFracSupply';
  private baseUrlWaterSourceWaterDisposal: string = environment.PacerDomainURL + 'PerformanceBodWsWaterDisposal';
  private baseUrlPerformanceBodRegulatoryPermitting: string =
    environment.PacerDomainURL + 'PerformanceBodRegulatoryPermitting';
  private baseUrlPerformanceBodWellArtificialLift: string =
    environment.PacerDomainURL + 'PerformanceBodWellArtificialLift';
  private baseUrlPerformanceBodRoadMaintenance: string = environment.PacerDomainURL + 'PerfBodRoadMaintenance';
  private baseUrlPerformanceBodPads: string = environment.PacerDomainURL + 'PerformanceBodPads';
  private baseUrlScopeNonStandard: string = environment.PacerDomainURL + 'PerformanceBodOutsideFactoryProjects';
  private baseUrlPerformanceBodExistingFacilities: string =
    environment.PacerDomainURL + 'PerformanceBodExistingFacilities';
  private baseUrlPerformanceBodNotes: string = environment.PacerDomainURL + 'PerformanceBodNotes';
  private baseUrlPerformanceBodNotesAttachment: string = environment.PacerDomainURL + 'PerformanceBodNotesAttachment';
  private baseUrlPerformanceBodNotesAttachmentUpload: string = environment.PacerDomainURL + 'Upload';
  private baseUrlPerformanceBodStatus: string = environment.PacerDomainURL + 'PerformanceBodStatus';
  private baseUrlStatusIndicator: string = environment.PacerDomainURL + 'StatusIndicator';

  constructor(private http: HttpClient, httpErrorHandler: HttpErrorHandler) {
    this.handleError = httpErrorHandler.createHandleError();
  }

  public getPerformanceBOD(): Observable<IPerformanceBod[]> {
    return this.http
      .get<IPerformanceBod[]>(this.baseUrlPerformanceBod + '/List')
      .pipe(catchError(this.handleError('getPerformanceBODs')));
  }

  public deletePerformanceBOD(id: string): Observable<IPerformanceBod> {
    return this.http
      .get<string>(`${this.baseUrlPerformanceBod}/DeleteByKey/${id}`)
      .pipe(catchError(this.handleError('deleteByKey')));
  }

  public getByIdPerformanceBod(id: string): Observable<IPerformanceBod> {
    return this.http.get<IPerformanceBod>(`${this.baseUrlPerformanceBod}/GetById/${id}`).pipe(
      map((data: IPerformanceBod) => {
        const row: IPerformanceBod = {
          id: data.id,
          name: data.name,
          isTemp: data.isTemp,
          isDeleted: data.isDeleted,
          createdBy: data.createdBy,
          createdDate: data.createdDate,
          updatedBy: data.updatedBy,
          updatedDate: data.updatedDate,
          batch: data.batch,
          performanceProjectManagerSignature: data.performanceProjectManagerSignature,
          performanceProjectManagerCai: data.performanceProjectManagerCai,
          performanceTeamLeadSignature: data.performanceTeamLeadSignature,
          performanceTeamLeadCai: data.performanceTeamLeadCai,
          performanceReservoirEngineerSignature: data.performanceReservoirEngineerSignature,
          performanceReservoirEngineerCai: data.performanceReservoirEngineerCai,
          performanceWaterEngineerSignature: data.performanceWaterEngineerSignature,
          performanceWaterEngineerCai: data.performanceWaterEngineerCai,
          performanceProjectManagerDate:
            data.performanceProjectManagerDate !== null && data.performanceProjectManagerDate !== undefined
              ? Helpers.convertUtcToLocalTime(data.performanceProjectManagerDate)
              : undefined,
          performanceTeamLeadDate:
            data.performanceTeamLeadDate !== null && data.performanceTeamLeadDate !== undefined
              ? Helpers.convertUtcToLocalTime(data.performanceTeamLeadDate)
              : undefined,
          performanceReservoirEngineerDate:
            data.performanceReservoirEngineerDate !== null && data.performanceReservoirEngineerDate !== undefined
              ? Helpers.convertUtcToLocalTime(data.performanceReservoirEngineerDate)
              : undefined,
          performanceWaterEngineerDate:
            data.performanceWaterEngineerDate !== null && data.performanceWaterEngineerDate !== undefined
              ? Helpers.convertUtcToLocalTime(data.performanceWaterEngineerDate)
              : undefined,
          numberOfPackages: data.numberOfPackages,
          projectNumber: data.projectNumber,
          generalComments: data.generalComments,
          basinId: data.basinId,
          geographicAreaId: data.geographicAreaId,
          developmentAreaId: data.developmentAreaId,
          unspecifiedPads: false,
          performanceBodAfes: data.performanceBodAfes
        };
        return row;
      }),
      catchError(error => {
        this.handleError('getByIdPerformanceBod');
        return throwError(error);
      })
    );
  }

  public createPerformanceBOD(data: IPerformanceBod): Observable<any> {
    return this.http
      .post<IPerformanceBodPackages>(`${this.baseUrlPerformanceBod}/Create`, [data], httpOptions)
      .pipe(catchError(this.handleError('createPerformanceBod')));
  }

  public updatePerformanceBOD(data: IPerformanceBod): Observable<any> {
    return this.http.post<IPerformanceBodPackages>(`${this.baseUrlPerformanceBod}/Update`, [data], httpOptions).pipe(
      catchError(error => {
        this.handleError('updatePerformanceBOD');
        return throwError(error);
      })
    );
  }

  public deletePerformanceBODSignatures(id: string): Observable<IPerformanceBodSignatures> {
    return this.http
      .get<string>(`${this.baseUrlPerformanceBodSignatures}/DeleteByKey/${id}`)
      .pipe(catchError(this.handleError('deleteByKey')));
  }

  public getByIdPerformanceBodSignatures(id: string): Observable<any> {
    return this.http
      .get<IPerformanceBodSignatures>(`${this.baseUrlPerformanceBodSignatures}/ListByPerformanceBodId/${id}`)
      .pipe(
        catchError(error => {
          this.handleError('getByIdPerformanceBodSignatures');
          return throwError(error);
        })
      );
  }

  public createPerformanceBODSignatures(data: IPerformanceBodSignatures): Observable<any> {
    return this.http
      .post<IPerformanceBodSignatures>(`${this.baseUrlPerformanceBodSignatures}/Create`, [data], httpOptions)
      .pipe(catchError(this.handleError('createPerformanceBodSignatures')));
  }

  public updatePerformanceBODSignatures(data: IPerformanceBodSignatures[]): Observable<any> {
    return this.http
      .post<IPerformanceBodSignatures>(`${this.baseUrlPerformanceBodSignatures}/Update`, data, httpOptions)
      .pipe(
        catchError(error => {
          this.handleError('updatePerformanceBODSignatures');
          return throwError(error);
        })
      );
  }

  public deletePerformanceBODSignature(data: IPerformanceBodSignatures[]): Observable<any> {
    return this.http
      .post<IPerformanceBodSignatures>(`${this.baseUrlPerformanceBodSignatures}/Delete`, data, httpOptions)
      .pipe(
        catchError(error => {
          this.handleError('DeletePerformanceBODSignatures');
          return throwError(error);
        })
      );
  }

  public createPerformanceBodPackage(data: IPerformanceBodPackages[]): Observable<any> {
    return this.http.post<IPerformanceBodPackages>(`${this.baseUrlPerformanceBodPackage}/Create`, data, httpOptions);
    // .pipe(catchError(this.handleError('createPerformanceBodPackage')));
  }

  public addPerformanceBodPackage(data: any[]): Observable<any> {
    return this.http.post<any[]>(`${this.baseUrlPerformanceBodPackage}/Create`, data, httpOptions).pipe(
      catchError(error => {
        this.handleError('addPerformanceBodPackage');
        return throwError(error);
      })
    );
  }

  public getPerformanceBodPackage(id: string): Observable<IPerformanceBodPackages[]> {
    const rows: IPerformanceBodPackages[] = [];
    return this.http
      .get<IPerformanceBodPackages[]>(`${this.baseUrlPerformanceBodPackage}/ListByPerformanceBodId/${id}`)
      .pipe(
        map(
          (data: IPerformanceBodPackages[]) => {
            data.forEach(dataRow => {
              const row: IPerformanceBodPackages = {
                id: dataRow.id,
                name: dataRow.name,
                isDeleted: dataRow.isDeleted,
                createdBy: dataRow.createdBy,
                createdDate: dataRow.createdDate,
                sortOrder: dataRow.sortOrder,
                updatedBy: dataRow.updatedBy,
                updatedDate: dataRow.updatedDate,
                performanceBodId: dataRow.performanceBodId,
                wellPads: dataRow.wellPads,
                facilityPads: dataRow.facilityPads,
                flowLines: dataRow.flowLines,
                glLines: dataRow.glLines,
                gathLines: dataRow.gathLines,
                injLines: dataRow.injLines,
                ponds: dataRow.ponds,
                eds: dataRow.eds,
                infrastructureComments: dataRow.infrastructureComments,
              };
              rows.push(row);
            });
            return rows;
          },
          catchError(error => {
            this.handleError('getPerformanceBodPackage');
            return throwError(error);
          })
        )
      );
  }

  public updatePerformanceBodPackage(data: IPerformanceBodPackages[]): Observable<any> {
    return this.http
      .post<IPerformanceBodPackages>(`${this.baseUrlPerformanceBodPackage}/Update`, data, httpOptions)
      .pipe(
        catchError(error => {
          this.handleError('updatePerformanceBodPackage');
          return throwError(error);
        })
      );
  }

  public createPerformanceBodCentralTankBattery(data: IPerformanceBodCentralTankBattery[]): Observable<any> {
    return this.http.post<IPerformanceBodCentralTankBattery>(
      `${this.baseUrlPerformanceBodCentralTankBattery}/Create`,
      data,
      httpOptions
    );
  }

  public getPerformanceBodCentralTankBattery(id: string): Observable<IPerformanceBodCentralTankBattery[]> {
    return this.http
      .get<IPerformanceBodCentralTankBattery[]>(
        `${this.baseUrlPerformanceBodCentralTankBattery}/ListByPerformanceBodId/${id}`
      )
      .pipe(catchError(this.handleError('getPerformanceBodCentralTankBattery')));
  }

  public updatePerformanceBodCentralTankBattery(data: IPerformanceBodCentralTankBattery[]): Observable<any> {
    return this.http.post<IPerformanceBodCentralTankBattery>(
      `${this.baseUrlPerformanceBodCentralTankBattery}/Update`,
      data,
      httpOptions
    );
  }

  public createPerformanceBodCompressor(data: IPerformanceBodCompressor[]): Observable<any> {
    return this.http
      .post<IPerformanceBodCompressor>(`${this.baseUrlPerformanceBodCompressor}/Create`, data, httpOptions)
      .pipe(catchError(this.handleError('createPerformanceBodCompressor')));
  }

  public getPerformanceBodCompressor(id: string): Observable<IPerformanceBodCompressor[]> {
    return this.http
      .get<IPerformanceBodCompressor[]>(`${this.baseUrlPerformanceBodCompressor}/ListByPerformanceBodId/${id}`)
      .pipe(catchError(this.handleError('getPerformanceBodCompressor')));
  }

  public updatePerformanceBodCompressor(data: IPerformanceBodCompressor[]): Observable<any> {
    return this.http
      .post<IPerformanceBodCompressor>(`${this.baseUrlPerformanceBodCompressor}/Update`, data, httpOptions)
      .pipe(catchError(this.handleError('updatePerformanceBodCompressor')));
  }

  public createPerformanceBodSaltWaterDisposal(data: IPerformanceBodSaltWaterDisposal[]): Observable<any> {
    return this.http
      .post<IPerformanceBodSaltWaterDisposal>(
        `${this.baseUrlPerformanceBodSaltWaterDisposal}/Create`,
        data,
        httpOptions
      )
      .pipe(catchError(this.handleError('createPerformanceBodSaltWaterDisposal')));
  }

  public getPerformanceBodSaltWaterDisposal(id: string): Observable<IPerformanceBodSaltWaterDisposal[]> {
    return this.http
      .get<IPerformanceBodSaltWaterDisposal[]>(
        `${this.baseUrlPerformanceBodSaltWaterDisposal}/ListByPerformanceBodId/${id}`
      )
      .pipe(catchError(this.handleError('getPerformanceBodSaltWaterDisposal')));
  }

  public updatePerformanceBodSaltWaterDisposal(data: IPerformanceBodSaltWaterDisposal[]): Observable<any> {
    return this.http
      .post<IPerformanceBodSaltWaterDisposal>(
        `${this.baseUrlPerformanceBodSaltWaterDisposal}/Update`,
        data,
        httpOptions
      )
      .pipe(catchError(this.handleError('updatePerformanceBodSaltWaterDisposal')));
  }

  //#region "ExpectedPeakProductionRates"

  // ExpectedPeakProductionRates
  public createPerformanceExpectedPeakProductionRates(
    data: IPerformanceExpectedPeakProductionRates[]
  ): Observable<any> {
    return this.http.post<IPerformanceExpectedPeakProductionRates>(
      `${this.baseUrlPerformanceExpectedPeakProductionRates}/Create`,
      data,
      httpOptions
    );
  }

  public getPerformanceExpectedPeakProductionRates(
    performanceBodId: string
  ): Observable<IPerformanceExpectedPeakProductionRates[]> {
    const rows: IPerformanceExpectedPeakProductionRates[] = [];
    return this.http
      .get<IPerformanceExpectedPeakProductionRates[]>(
        `${this.baseUrlPerformanceExpectedPeakProductionRates}/ListByPerformanceBodId/${performanceBodId}`
      )
      .pipe(
        map((data: IPerformanceExpectedPeakProductionRates[]) => {
          data.forEach(dataRow => {
            const row: IPerformanceExpectedPeakProductionRates = {
              id: dataRow.id,
              isDeleted: dataRow.isDeleted,
              createdBy: dataRow.createdBy,
              createdDate: dataRow.createdDate,
              updatedBy: dataRow.updatedBy,
              updatedDate: dataRow.updatedDate,
              oilBopd: dataRow.oilBopd,
              oilBopdOverridable: null,
              waterBwpd: dataRow.waterBwpd,
              waterBwpdOverridable: null,
              lpGasMmscfd: dataRow.lpGasMmscfd,
              lpGasMmscfdOverridable: null,
              hpGasMmscfd: dataRow.hpGasMmscfd,
              hpGasMmscfdOverridable: null,
              totalGasMmscfd: dataRow.totalGasMmscfd,
              gasLiftMmscfd: dataRow.gasLiftMmscfd,
              gasLiftMmscfdOverridable: null,
              totalCompressionMmscfd: dataRow.totalCompressionMmscfd,
              comments: dataRow.comments,
              performanceBodPadsId: dataRow.performanceBodPadsId,
              sortOrder: dataRow.sortOrder,
              packageName: dataRow.packageName,
              wellPadName: dataRow.wellPadName,
              isWellPadNameDisabled: dataRow.isWellPadNameDisabled,
            };
            rows.push(row);
          });
          return rows;
        })
      );
  }

  public updatePerformanceExpectedPeakProductionRates(
    data: IPerformanceExpectedPeakProductionRates[]
  ): Observable<any> {
    return this.http.post<IPerformanceExpectedPeakProductionRates>(
      `${this.baseUrlPerformanceExpectedPeakProductionRates}/Update`,
      data,
      httpOptions
    );
  }

  //#endregion "ExpectedPeakProductionRates"

  // Well Pad
  public createPerformanceBodWellPad(data: IPerformanceBodWellPad[]): Observable<any> {
    return this.http.post<IPerformanceBodWellPad>(`${this.baseUrlPerformanceBodWellPad}/Create`, data, httpOptions);
  }

  public getPerformanceBodWellPad(id: string): Observable<IPerformanceBodWellPad[]> {
    return this.http.get<IPerformanceBodWellPad[]>(`${this.baseUrlPerformanceBodWellPad}/ListByPerformanceBodId/${id}`);
  }

  public updatePerformanceBodWellPad(data: IPerformanceBodWellPad[]): Observable<any> {
    return this.http.post<IPerformanceBodWellPad>(`${this.baseUrlPerformanceBodWellPad}/Update`, data, httpOptions);
  }

  // Facility Pad
  public createPerformanceBodFacilityPad(data: IPerformanceBodFacilityPad[]): Observable<any> {
    return this.http.post<IPerformanceBodFacilityPad>(
      `${this.baseUrlPerformanceBodFacilityPad}/Create`,
      data,
      httpOptions
    );
  }

  public getPerformanceBodFacilityPad(id: string): Observable<IPerformanceBodFacilityPad[]> {
    const rows: IPerformanceBodFacilityPad[] = [];
    return this.http
      .get<IPerformanceBodFacilityPad[]>(`${this.baseUrlPerformanceBodFacilityPad}/ListByPerformanceBodId/${id}`)
      .pipe(
        map((data: IPerformanceBodFacilityPad[]) => {
          data.forEach(dataRow => {
            const row: IPerformanceBodFacilityPad = {
              id: dataRow.id,
              // performanceBodId: dataRow.performanceBodId,
              performanceBodPackagesId: dataRow.performanceBodPackagesId,
              name: dataRow.name,
              padRfsd:
                dataRow.padRfsd !== null && dataRow.padRfsd !== undefined
                  ? Helpers.convertUtcToLocalTime(dataRow.padRfsd)
                  : undefined,
              padDimensions: dataRow.padDimensions,
              permitsRequiredBy:
                dataRow.permitsRequiredBy !== null && dataRow.permitsRequiredBy !== undefined
                  ? Helpers.convertUtcToLocalTime(dataRow.permitsRequiredBy)
                  : undefined,
              padOrientation: dataRow.padOrientation,
              comments: dataRow.comments,
              isDeleted: dataRow.isDeleted,
              createdBy: dataRow.createdBy,
              createdDate: dataRow.createdDate,
              updatedBy: dataRow.updatedBy,
              updatedDate: dataRow.updatedDate,
              sortOrder: dataRow.sortOrder,
              afeNumber:dataRow.afeNumber,
            };
            rows.push(row);
          });
          return rows;
        })
      );
  }

  public getPerformanceBodFacilityPadsForGrid(id: string): Observable<IPerformanceBodFacilityPad[]> {
    const rows: IPerformanceBodFacilityPad[] = [];
    return this.http.get<IPerformanceBodFacilityPad[]>(
      `${this.baseUrlPerformanceBodFacilityPad}/ListByPerformanceBodId/${id}`
    );
  }

  public updatePerformanceBodFacilityPad(data: IPerformanceBodFacilityPad[]): Observable<any> {
    return this.http.post<IPerformanceBodFacilityPad>(
      `${this.baseUrlPerformanceBodFacilityPad}/Update`,
      data,
      httpOptions
    );
  }

  public createInfrastructureSourceWater(data: InfrastructureSourceWater[]): Observable<any> {
    return this.http.post<InfrastructureSourceWater>(
      `${this.baseURLPerformanceBodSourceWater}/Create`,
      data,
      httpOptions
    );
    // .pipe(catchError(this.handleError('createInfracstructureSourceWater')));
  }

  public getInfrastructureSourceWater(id: string): Observable<InfrastructureSourceWater[]> {
    return this.http.get<InfrastructureSourceWater[]>(
      `${this.baseURLPerformanceBodSourceWater}/ListByPerformanceBodId/${id}`
    );
    // .pipe(catchError(this.handleError('getInfracstructureSourceWater')));
  }

  public updateInfrastructureSourceWater(data: InfrastructureSourceWater[]): Observable<any> {
    return this.http.post<InfrastructureSourceWater>(
      `${this.baseURLPerformanceBodSourceWater}/Update`,
      data,
      httpOptions
    );
    // .pipe(catchError(this.handleError('updateInfracstructureSourceWater')));
  }

  public createInfrastructureRecycle(data: InfrastructureRecycle[]): Observable<any> {
    return this.http.post<InfrastructureRecycle>(`${this.baseURLPerformanceBodRecycle}/Create`, data, httpOptions);
    // .pipe(catchError(this.handleError('createInfrastructureRecycle')));
  }

  public getInfrastructureRecycle(id: string): Observable<InfrastructureRecycle[]> {
    return this.http.get<InfrastructureRecycle[]>(`${this.baseURLPerformanceBodRecycle}/ListByPerformanceBodId/${id}`);
    // .pipe(catchError(this.handleError('getInfrastructureRecycle')));
  }

  public updateInfrastructureRecycle(data: InfrastructureRecycle[]): Observable<any> {
    return this.http.post<InfrastructureRecycle>(`${this.baseURLPerformanceBodRecycle}/Update`, data, httpOptions);
    // .pipe(catchError(this.handleError('updateInfrastructureRecycle')));
  }

  public createPerformanceBodFracPond(data: IPerformanceBodFracPond[]): Observable<any> {
    return this.http
      .post<IPerformanceBodFracPond>(`${this.baseUrlPerformanceBodFracPond}/Create`, data, httpOptions)
      .pipe(catchError(this.handleError('createPerformanceBodFracPond')));
  }

  public getPerformanceBodFracPond(id: string): Observable<IPerformanceBodFracPond[]> {
    return this.http
      .get<IPerformanceBodFracPond[]>(`${this.baseUrlPerformanceBodFracPond}/ListByPerformanceBodId/${id}`)
      .pipe(catchError(this.handleError('getPerformanceBodFracPond')));
  }

  public updatePerformanceBodFracPond(data: IPerformanceBodFracPond[]): Observable<any> {
    return this.http
      .post<IPerformanceBodFracPond>(`${this.baseUrlPerformanceBodFracPond}/Update`, data, httpOptions)
      .pipe(catchError(this.handleError('updatePerformanceBodFracPond')));
  }

  public getPerformanceBodEdsIe(id: string): Observable<IPerformanceBodEdsIe[]> {
    return this.http
      .get<IPerformanceBodEdsIe[]>(`${this.baseUrlPerformanceBodEdsIe}/ListByPerformanceBodId/${id}`)
      .pipe(catchError(this.handleError('getPerformanceBodEdsIe')));
  }

  public updatePerformanceBodEdsIe(data: IPerformanceBodEdsIe[]): Observable<any> {
    return this.http
      .post<IPerformanceBodEdsIe>(`${this.baseUrlPerformanceBodEdsIe}/Update`, data, httpOptions)
      .pipe(catchError(this.handleError('updatePerformanceBodEdsIe')));
  }

  public createPerformanceBodEdsIe(data: IPerformanceBodEdsIe[]): Observable<any> {
    return this.http
      .post<IPerformanceBodEdsIe>(`${this.baseUrlPerformanceBodEdsIe}/Create`, data, httpOptions)
      .pipe(catchError(this.handleError('createPerformanceBodEdsIe')));
  }

  public getPerformanceBodWaterDisposal(id: string): Observable<IPerformanceBodWaterDisposal[]> {
    return this.http
      .get<IPerformanceBodWaterDisposal[]>(`${this.baseUrlPerformanceWaterDisposal}/ListByPerformanceBodId/${id}`)
      .pipe(catchError(this.handleError('getPerformanceBodWaterDisposal')));
  }

  public updatePerformanceBodWaterDisposal(data: IPerformanceBodWaterDisposal[]): Observable<any> {
    return this.http
      .post<IPerformanceBodWaterDisposal>(`${this.baseUrlPerformanceWaterDisposal}/Update`, data, httpOptions)
      .pipe(catchError(this.handleError('updatePerformanceBodWaterDisposal')));
  }

  public createPerformanceBodWaterDisposal(data: IPerformanceBodWaterDisposal[]): Observable<any> {
    return this.http
      .post<IPerformanceBodWaterDisposal>(`${this.baseUrlPerformanceWaterDisposal}/Create`, data, httpOptions)
      .pipe(catchError(this.handleError('createPerformanceBodWaterDisposal')));
  }

  public getPerformanceBodWSDrillingSupply(id: string): Observable<IPerformanceBodWSDrillingSupply[]> {
    return this.http
      .get<IPerformanceBodWSDrillingSupply[]>(`${this.baseUrlWaterSourceDrillingSupply}/ListByPerformanceBodId/${id}`)
      .pipe(catchError(this.handleError('getPerformanceBodWSDrillingSupply')));
  }

  public updatePerformanceBodWSDrillingSupply(data: IPerformanceBodWSDrillingSupply[]): Observable<any> {
    return this.http
      .post<IPerformanceBodWSDrillingSupply>(`${this.baseUrlWaterSourceDrillingSupply}/Update`, data, httpOptions)
      .pipe(catchError(this.handleError('updatePerformanceBodWSDrillingSupply')));
  }

  public createPerformanceBodWSDrillingSupply(data: IPerformanceBodWSDrillingSupply[]): Observable<any> {
    return this.http
      .post<IPerformanceBodWSDrillingSupply>(`${this.baseUrlWaterSourceDrillingSupply}/Create`, data, httpOptions)
      .pipe(catchError(this.handleError('createPerformanceBodWSDrillingSupply')));
  }

  public getPerformanceBodWSFracSupply(id: string): Observable<IPerformanceBodWSFracSupply[]> {
    return this.http
      .get<IPerformanceBodWSFracSupply[]>(`${this.baseUrlWaterSourceFracSupply}/ListByPerformanceBodId/${id}`)
      .pipe(catchError(this.handleError('getPerformanceBodWSFracSupply')));
  }

  public updatePerformanceBodWSFracSupply(data: IPerformanceBodWSFracSupply[]): Observable<any> {
    return this.http
      .post<IPerformanceBodWSFracSupply>(`${this.baseUrlWaterSourceFracSupply}/Update`, data, httpOptions)
      .pipe(catchError(this.handleError('updatePerformanceBodWSFracSupply')));
  }

  public createPerformanceBodWSFracSupply(data: IPerformanceBodWSFracSupply[]): Observable<any> {
    return this.http
      .post<IPerformanceBodWSFracSupply>(`${this.baseUrlWaterSourceFracSupply}/Create`, data, httpOptions)
      .pipe(catchError(this.handleError('createPerformanceBodWSFracSupply')));
  }

  public getPerformanceBodWSWaterDisposal(id: string): Observable<IPerformanceBodWSWaterDisposal[]> {
    return this.http
      .get<IPerformanceBodWSWaterDisposal[]>(`${this.baseUrlWaterSourceWaterDisposal}/ListByPerformanceBodId/${id}`)
      .pipe(catchError(this.handleError('getPerformanceBodWSWaterDisposal')));
  }

  public updatePerformanceBodWSWaterDisposal(data: IPerformanceBodWSWaterDisposal[]): Observable<any> {
    return this.http
      .post<IPerformanceBodWSWaterDisposal>(`${this.baseUrlWaterSourceWaterDisposal}/Update`, data, httpOptions)
      .pipe(catchError(this.handleError('updatePerformanceBodWSWaterDisposal')));
  }

  public createPerformanceBodWSWaterDisposal(data: IPerformanceBodWSWaterDisposal[]): Observable<any> {
    return this.http
      .post<IPerformanceBodWSWaterDisposal>(`${this.baseUrlWaterSourceWaterDisposal}/Create`, data, httpOptions)
      .pipe(catchError(this.handleError('createPerformanceBodWSWaterDisposal')));
  }

  // Pipelines
  public createPerformanceBodPipelines(data: IPerformanceBodPipelines[]): Observable<any> {
    return this.http.post<IPerformanceBodPipelines>(`${this.baseUrlPerformanceBodPipelines}/Create`, data, httpOptions);
  }

  public getPerformanceBodPipelines(id: string): Observable<IPerformanceBodPipelines[]> {
    return this.http
      .get<IPerformanceBodPipelines[]>(`${this.baseUrlPerformanceBodPipelines}/ListByPerformanceBodId/${id}`)
      .pipe(catchError(this.handleError('getPerformanceBodPipelines')));
  }

  public updatePerformanceBodPipelines(data: IPerformanceBodPipelines[]): Observable<any> {
    return this.http.post<IPerformanceBodPipelines>(`${this.baseUrlPerformanceBodPipelines}/Update`, data, httpOptions);
  }

  // Bod Summary
  public createPerformanceBodSummary(data: IPerformanceBodSummary[]): Observable<any> {
    return this.http.post<IPerformanceBodSummary>(`${this.baseURLPerformanceBodSummary}/Create`, data, httpOptions);
  }

  public getPerformanceBodSummary(id: string): Observable<IPerformanceBodSummary[]> {
    return this.http
      .get<IPerformanceBodSummary[]>(`${this.baseURLPerformanceBodSummary}/ListByPerformanceBodId/${id}`)
      .pipe(catchError(this.handleError('getPerformanceBodFracPond')));
  }

  public updatePerformanceBodSummary(data: any[]): Observable<any> {
    return this.http.post<IPerformanceBodSummary>(`${this.baseURLPerformanceBodSummary}/Update`, data, httpOptions);
  }

  // public getPerformanceBodSummaryByStdFacilityId(id: string): Observable<IPerformanceBodSummary> {
  //  return this.http
  //    .get<IPerformanceBodSummary>(`${this.baseURLPerformanceBodSummary}/GetByPerformanceBodStdFacilityId/${id}`)
  //    .pipe(catchError(this.handleError('getPerformanceBodSummaryByStdFacilityId')));
  // }

  //#region "Pads"

  // Pads
  public createPerformanceBodPads(data: IPerformanceBodPads[]): Observable<any> {
    return this.http.post<IPerformanceBodPads[]>(`${this.baseUrlPerformanceBodPads}/Create`, data, httpOptions);
  }

  public updatePerformanceBodPads(data: IPerformanceBodPads[]): Observable<any> {
    return this.http
      .post<IPerformanceBodPads>(`${this.baseUrlPerformanceBodPads}/Update`, data, httpOptions)
      .pipe(catchError(this.handleError('updatePerformanceBodPads')));
  }

  public createPerformanceBodPadsWithPeakProduction(data: IPerformanceBodPads[]): Observable<any> {
    return this.http.post<IPerformanceBodPads[]>(
      `${this.baseUrlPerformanceBodPads}/CreateWithPeakProduction`,
      data,
      httpOptions
    );
  }

  public updatePerformanceBodPadsWithPeakProduction(data: IPerformanceBodPads[]): Observable<any> {
    return this.http
      .post<IPerformanceBodPads>(`${this.baseUrlPerformanceBodPads}/UpdateWithPeakProduction`, data, httpOptions)
      .pipe(catchError(this.handleError('updatePerformanceBodPads')));
  }

  public getPerformanceBodPads(id: string): Observable<IPerformanceBodPads[]> {
    const rows: IPerformanceBodPads[] = [];
    return this.http.get<IPerformanceBodPads[]>(`${this.baseUrlPerformanceBodPads}/ListByPerformanceBodId/${id}`).pipe(
      map((data: IPerformanceBodPads[]) => {
        data.forEach(dataRow => {
          const row: IPerformanceBodPads = {
            id: dataRow.id,
            name: dataRow.name,
            isDeleted: dataRow.isDeleted,
            createdBy: dataRow.createdBy,
            createdDate: dataRow.createdDate,
            updatedBy: dataRow.updatedBy,
            updatedDate: dataRow.updatedDate,
            wellPadName: dataRow.wellPadName,
            reservoirName: dataRow.reservoirName,
            facilityName: dataRow.facilityName,
            spudDate:
              dataRow.spudDate !== null && dataRow.spudDate !== undefined
                ? Helpers.convertUtcToLocalTime(dataRow.spudDate)
                : undefined,
            popDate:
              dataRow.popDate !== null && dataRow.popDate !== undefined
                ? Helpers.convertUtcToLocalTime(dataRow.popDate)
                : undefined,
            numberOfWells: dataRow.numberOfWells,
            comments: dataRow.comments,
            performanceBodId: dataRow.performanceBodId,
            performanceBodPackagesId: dataRow.performanceBodPackagesId,
            sortOrder: dataRow.sortOrder,
          };
          rows.push(row);
        });
        return rows;
      })
    );
  }

  public validDeletePerformanceBodPads(id: string): Observable<boolean> {
    let valid: boolean = false;
    return this.http.get<boolean>(`${this.baseUrlPerformanceBodPads}/ValidDelete/${id}`).pipe(
      map((data: boolean) => {
        valid = data;
        return valid;
      }),
      catchError(this.handleError('validDeletePerformanceBodPads'))
    );
  }

  public createPerformanceBodCommercial(data: InfrastructureCommercial[]): Observable<any> {
    return this.http.post<InfrastructureCommercial>(
      `${this.baseUrlPerformanceBodCommercial}/Create`,
      data,
      httpOptions
    );
    // .pipe(catchError(this.handleError('createPerformanceBodCommercial')));
  }

  public getPerformanceBodCommercial(id: string): Observable<InfrastructureCommercial[]> {
    return this.http
      .get<InfrastructureCommercial[]>(`${this.baseUrlPerformanceBodCommercial}/ListByPerformanceBodId/${id}`)
      .pipe(catchError(this.handleError('getPerformanceBodCommercial')));
  }

  public updatePerformanceBodCommercial(data: InfrastructureCommercial[]): Observable<any> {
    return this.http.post<InfrastructureCommercial>(
      `${this.baseUrlPerformanceBodCommercial}/Update`,
      data,
      httpOptions
    );
    // .pipe(catchError(this.handleError('updatePerformanceBodCommercial')));
  }

  public createPerformanceBodRegulatoryPermitting(data: InfrastructureRegulatoryPermitting[]): Observable<any> {
    return this.http.post<InfrastructureRegulatoryPermitting>(
      `${this.baseUrlPerformanceBodRegulatoryPermitting}/Create`,
      data,
      httpOptions
    );
    // .pipe(catchError(this.handleError('createPerformanceBodRegulatoryPermitting')));
  }

  public getPerformanceBodRegulatoryPermitting(id: string): Observable<InfrastructureRegulatoryPermitting[]> {
    return this.http
      .get<InfrastructureRegulatoryPermitting[]>(
        `${this.baseUrlPerformanceBodRegulatoryPermitting}/ListByPerformanceBodId/${id}`
      )
      .pipe(catchError(this.handleError('getPerformanceBodRegulatoryPermitting')));
  }

  public updatePerformanceBodRegulatoryPermitting(data: InfrastructureRegulatoryPermitting[]): Observable<any> {
    return this.http.post<InfrastructureRegulatoryPermitting>(
      `${this.baseUrlPerformanceBodRegulatoryPermitting}/Update`,
      data,
      httpOptions
    );
    // .pipe(catchError(this.handleError('updatePerformanceBodRegulatoryPermitting')));
  }

  public createPerformanceBODWithPackages(data: IPerformanceBod): Observable<any> {
    return this.http
      .post<IPerformanceBod>(`${this.baseUrlPerformanceBod}/CreateWithPackages`, data, httpOptions)
      .pipe(catchError(this.handleErrorPBOD));
  }

  // PerformanceBod Well Artificial Lift
  public createPerformanceBodWellArtificialLift(data: any[]): Observable<any> {
    return this.http.post<IPerformanceBodWellArtificialLift>(
      `${this.baseUrlPerformanceBodWellArtificialLift}/Create`,
      data,
      httpOptions
    );
  }

  public getPerformanceBodWellArtificialLift(id: string): Observable<IPerformanceBodWellArtificialLift[]> {
    return this.http
      .get<IPerformanceBodWellArtificialLift[]>(
        `${this.baseUrlPerformanceBodWellArtificialLift}/ListByPerformanceBodId/${id}`
      )
      .pipe(catchError(this.handleError('getPerformanceBodWellArtificialLift')));
  }

  public updatePerformanceBodWellArtificialLift(data: IPerformanceBodWellArtificialLift[]): Observable<any> {
    return this.http.post<IPerformanceBodWellArtificialLift>(
      `${this.baseUrlPerformanceBodWellArtificialLift}/Update`,
      data,
      httpOptions
    );
  }

  // Existing Facilities
  public createPerformanceBodExistingFacilities(data: IPerformanceBodExistingFacilities[]): Observable<any> {
    return this.http.post<IPerformanceBodExistingFacilities>(
      `${this.baseUrlPerformanceBodExistingFacilities}/Create`,
      data,
      httpOptions
    );
  }

  public getPerformanceBodExistingFacilities(id: string): Observable<IPerformanceBodExistingFacilities[]> {
    const rows: IPerformanceBodExistingFacilities[] = [];
    return this.http
      .get<IPerformanceBodExistingFacilities[]>(
        `${this.baseUrlPerformanceBodExistingFacilities}/ListByPerformanceBodId/${id}`
      )
      .pipe(
        map((data: IPerformanceBodExistingFacilities[]) => {
          data.forEach(dataRow => {
            const row: IPerformanceBodExistingFacilities = {
              id: dataRow.id,
              name: dataRow.name,
              isDeleted: dataRow.isDeleted,
              createdBy: dataRow.createdBy,
              createdDate: dataRow.createdDate,
              updatedBy: dataRow.updatedBy,
              updatedDate: dataRow.updatedDate,
              scopeDefinition: dataRow.scopeDefinition,
              readyForServiceDate:
                dataRow.readyForServiceDate !== null && dataRow.readyForServiceDate !== undefined
                  ? Helpers.convertUtcToLocalTime(dataRow.readyForServiceDate)
                  : undefined,
              sftDesignYear: dataRow.sftDesignYear,
              comments: dataRow.comments,
              performanceBodPadsId: dataRow.performanceBodPadsId,
              performanceBodPackagesId: dataRow.performanceBodPackagesId,
              sortOrder: dataRow.sortOrder,
              afeNumber: dataRow.afeNumber,
              afeAmount: dataRow.afeAmount,
              chargeCode: dataRow.chargeCode,
              cndHOEstimate : dataRow.cndHOEstimate
            };
            rows.push(row);
          });
          return rows;
        })
      );
  }

  public updatePerformanceBodExistingFacilities(data: IPerformanceBodExistingFacilities[]): Observable<any> {
    return this.http.post<IPerformanceBodExistingFacilities>(
      `${this.baseUrlPerformanceBodExistingFacilities}/Update`,
      data,
      httpOptions
    );
  }

  // P&E Scope Non Standard Outside Factory Projects
  public createScopeNonStandardOutsideFactoryProjects(data: any[]): Observable<any> {
    return this.http.post<IScopeNonStandardOutsideFactoryProjects>(
      `${this.baseUrlScopeNonStandard}/Create`,
      data,
      httpOptions
    );
  }

  public getScopeNonStandardOutsideFactoryProjects(id: string): Observable<IScopeNonStandardOutsideFactoryProjects[]> {
    return this.http
      .get<IScopeNonStandardOutsideFactoryProjects[]>(`${this.baseUrlScopeNonStandard}/ListByPerformanceBodId/${id}`)
      .pipe(catchError(this.handleError('getScopeNonStandardOutsideFactoryProjects')));
  }

  public updateScopeNonStandardOutsideFactoryProjects(
    data: IScopeNonStandardOutsideFactoryProjects[]
  ): Observable<any> {
    return this.http.post<IScopeNonStandardOutsideFactoryProjects>(
      `${this.baseUrlScopeNonStandard}/Update`,
      data,
      httpOptions
    );
  }

  public getPerformanceBodNotes(performanceBodId: string): Observable<IPerformanceBodNotes[]> {
    return this.http
      .get<IPerformanceBodNotes[]>(`${this.baseUrlPerformanceBodNotes}/GetGraphByPerformanceBodId/${performanceBodId}`)
      .pipe(
        catchError(error => {
          this.handleError('getPerformanceBodNotes');
          return throwError(error);
        })
      );
  }

  public createPerformanceBodNotes(data: any[]): Observable<any> {
    return this.http.post<any>(`${this.baseUrlPerformanceBodNotes}/Create`, data, httpOptions);
  }

  public updatePerformanceBodNotes(data: IPerformanceBodNotes[]): Observable<any> {
    return this.http.post<IPerformanceBodNotes>(`${this.baseUrlPerformanceBodNotes}/Update`, data, httpOptions);
  }

  public downloadFile(folder: string, subFolder: string, file: string): Observable<any> {
    const body: any = {
      fileName: file,
      folderName: folder,
      subFolderName: subFolder,
    };

    return this.http
      .post(`${this.baseUrlPerformanceBodNotesAttachmentUpload}/Attachments/`, JSON.stringify(body), {
        headers: new HttpHeaders().set('Access-Control-Allow-Credential', 'true'),
      })
      .pipe(
        catchError(error => {
          this.handleError('downloadFile');
          return throwError(error);
        })
      );
  }

  public getPerformanceBodNotesAttachment(performanceBodNotesId: string): Observable<IPerformanceBodNotesAttachment[]> {
    return this.http
      .get<IPerformanceBodNotesAttachment[]>(
        `${this.baseUrlPerformanceBodNotesAttachment}/ListByPerformanceBodNotesId/${performanceBodNotesId}`
      )
      .pipe();
  }

  public createPerformanceBodNotesAttachment(data: any[]): Observable<any> {
    return this.http.post<any>(`${this.baseUrlPerformanceBodNotesAttachment}/Create`, data, httpOptions);
  }

  public updatePerformanceBodNotesAttachment(data: IPerformanceBodNotesAttachment[]): Observable<any> {
    return this.http.post<IPerformanceBodNotesAttachment>(
      `${this.baseUrlPerformanceBodNotesAttachment}/Update`,
      data,
      httpOptions
    );
  }

  public deletePerformanceBodNotesAttachment(data: IPerformanceBodNotesAttachment[]): Observable<any> {
    return this.http.post<IPerformanceBodNotesAttachment>(
      `${this.baseUrlPerformanceBodNotesAttachment}/Delete`,
      data,
      httpOptions
    );
  }

  public createWithAttachments(notes: any[]): Observable<any> {
    return this.http
      .post<any>(`${this.baseUrlPerformanceBodNotes}/CreateWithReturn`, notes, httpOptions)
      .pipe(map(data => data as IPerformanceBodNotes[]));
  }

  public removeAttachments(fileInfo: IFileInfo[]): Observable<any> {
    return this.http
      .post(
        `${this.baseUrlPerformanceBodNotesAttachmentUpload}/Attachments/Remove/`,
        JSON.stringify(fileInfo),
        httpOptions
      )
      .pipe(map(data => data as any[]));
  }

  public validPerformanceBOD(data: IPerformanceBod): Observable<any> {
    return this.http
      .post<IPerformanceBod>(`${this.baseUrlPerformanceBod}/valid`, data, httpOptions)
      .pipe(catchError(this.handleErrorPBOD));
  }

  public createPerformanceBodStatus(data: any[]): Observable<any> {
    return this.http.post<any>(`${this.baseUrlPerformanceBodStatus}/Create`, data, httpOptions);
  }

  public getPerformanceBodStatus(performanceBodId: string): Observable<IPerformanceBodStatus[]> {
    return this.http
      .get<IPerformanceBodStatus[]>(`${this.baseUrlPerformanceBodStatus}/ListByPerformanceBodId/${performanceBodId}`)
      .pipe();
  }

  public updatePerformanceBodStatus(data: any[]): Observable<any> {
    return this.http.post<any>(`${this.baseUrlPerformanceBodStatus}/Update`, data, httpOptions);
  }

  public getStatusIndicator(): Observable<IStatusIndicator[]> {
    return this.http.get<IStatusIndicator[]>(`${this.baseUrlStatusIndicator}/List`).pipe();
  }

  public downloadPerformanceBodExcelFile(input: IPerformanceBodInput): Observable<Blob> {
    return this.http
      .post<Blob>(`${this.baseUrlPerformanceBod}/Download/`, input, httpOptionsDownload)
      .pipe(catchError(this.handleError('downloadPerformanceBodFile')));
  }

  //#region Performance Bod: Infrastructure - Road Maintenance table
  public getPerformanceBodRoadMaintenance(id: string): Observable<IPerformanceBodRoadMaintenance[]> {
    return this.http
      .get<IPerformanceBodRoadMaintenance[]>(
        `${this.baseUrlPerformanceBodRoadMaintenance}/ListByPerformanceBodId/${id}`
      )
      .pipe(catchError(this.handleError('getPerformanceBodRoadMaintenance')));
  }

  public updatePerformanceBodRoadMaintenance(data: IPerformanceBodRoadMaintenance[]): Observable<any> {
    return this.http.post<IPerformanceBodRoadMaintenance>(
      `${this.baseUrlPerformanceBodRoadMaintenance}/Update`,
      data,
      httpOptions
    );
  }

  public createPerformanceBodRoadMaintenance(data: IPerformanceBodRoadMaintenance[]): Observable<any> {
    return this.http.post<IPerformanceBodRoadMaintenance>(
      `${this.baseUrlPerformanceBodRoadMaintenance}/Create`,
      data,
      httpOptions
    );
  }

  //#endregion

  private handleErrorPBOD(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // tslint:disable-next-line: no-console
      console.error('An error occurred:', error.error.message);
    }
    return throwError(error.error);
  }
}
