import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IPerformanceAFE, PerformanceAFE } from 'src/app/api/performance-afe';
import { PerformanceAFEService } from '../performance-afe/performance-afe.service';
import { TabStripComponent } from '@progress/kendo-angular-layout';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { PermissionsProvider } from 'src/app/services/permissions.provider';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { PerformanceBodStoreService } from 'src/app/services/performance-bod-store.service';
import { HttpClient } from '@angular/common/http';
import * as Helpers from 'src/app/_shared/helpers';
import { IPerformanceBod } from 'src/app/api/performance-bod';
import { requiredValidatorLogic } from 'src/app/_directives/required.directive';

@Component({
  selector: 'app-perf-afe-remove-popup',
  templateUrl: './perf-afe-remove-popup.component.html',
  
})
export class PerfAfeRemovePopupComponent implements OnInit {
  @ViewChild('tabStripAfe') tabStripAfe: TabStripComponent;
  @Input() afe: IPerformanceAFE;
  @Input() perfAfeService: PerformanceAFEService;

  public afeForm: UntypedFormGroup;
  public afeList: IPerformanceAFE[] = [];
  public isSubmitted = false;
  public defaultAfe: { number: string; value: string } = {
    number: 'Select item...',
    value: null,
  };

  public selectedBAfeDLL: { number: string; id: string };
  public defaulAfeValue = '0000000000000';
  public afeMask = 'AAAAA-AAAAA-AAA';
  public selectedOptionIndexTab1: boolean;
  public selectedOptionIndexTab2: boolean;
  public selectedOptionIndex: number;
  public isDisabled: boolean;
  public hasPermissionToEdit = false;
  public isLoading = true;
  public currentBod: any;

  constructor(
    private permissionsProvider: PermissionsProvider,
    private authenticationService: AuthenticationService,
    http: HttpClient,
    private dialog: DialogRef,
    private spinnerService: NgxSpinnerService,
    private formBuilder: UntypedFormBuilder,
    public performanceBodStoreService: PerformanceBodStoreService
  ) {}

  ngOnInit() {
    this.createFormGroup();

    this.afeList = this.perfAfeService.data
      .filter(d => d.id && d.id !== this.afe.id && !d.isDeleted)
      .map(item => {
        item.number = Helpers.convertToAfeFormat(item.number);
        return item;
      });

    this.initialState();

    this.performanceBodStoreService.performanceBod$.subscribe((currentPerf: IPerformanceBod) => {
      this.currentBod = currentPerf;
    });
  }

  public createFormGroup(): void {
    this.afeForm = this.formBuilder.group({
      afeDDL: new UntypedFormControl({}, requiredValidatorLogic),
      name: [null, [Validators.required]],
      number: [this.defaulAfeValue, [Validators.required]],
      amount: [null],
    });
  }

  public initialState(): void {
    if (this.afeList[0] === undefined) {
      // By default second tab controls are disabled
      this.afeForm.get('afeDDL').disable();
      this.isDisabled = true;
      this.selectedOptionIndexTab1 = false;
      this.selectedOptionIndexTab2 = true;
      this.selectedOptionIndex = 1;
    } else {
      // By default second tab controls are disabled
      this.afeForm.get('name').disable();
      this.afeForm.get('number').disable();
      this.afeForm.get('amount').disable();
      this.isDisabled = false;
      this.selectedOptionIndexTab1 = true;
      this.selectedOptionIndexTab2 = false;
      this.selectedOptionIndex = 0;
    }
  }

  public onTabSelect(event: any): void {
    this.selectedOptionIndex = event.index;

    switch (event.index) {
      case -0:
        this.afeForm.get('name').disable();
        this.afeForm.get('number').disable();
        this.afeForm.get('amount').disable();
        // Enable
        this.afeForm.get('afeDDL').enable();
        this.afeForm.get('afeDDL').clearValidators();
        break;
      case 1:
        this.afeForm.get('afeDDL').disable();
        // Enable
        this.afeForm.get('name').enable();
        this.afeForm.get('number').enable();
        this.afeForm.get('amount').enable();

        this.afeForm.get('name').clearValidators();
        this.afeForm.get('number').clearValidators();
        this.afeForm.get('amount').clearValidators();
        break;
      default:
        console.log('An error has ocurred at select tab.');
        break;
    }
  }

  public onCancelAction(): void {
    this.dialog.close();
  }

  public afeDDLChange(value: any) {
    this.selectedBAfeDLL = value;
  }

  public onConfirmAction(value: any, valid: boolean): void {
    this.isSubmitted = true;

    if (valid) {
      this.spinnerService.show();
      switch (this.selectedOptionIndex) {
        case 0:
          const updated: IPerformanceAFE = this.afeForm.get('afeDDL').value;
          updated.isDefault = updated.isDefault ? updated.isDefault : this.afe.isDefault;
          updated.updatedBy = this.authenticationService.getCurrentUser();
          updated.updatedDate = new Date();
          this.afe.isDeleted = true;
          this.afe.isDefault = false;

          this.perfAfeService.updateTheReplace([updated]).subscribe(
            data => {
              this.spinnerService.hide();
              this.dialog.close({
                hasChanges: true,
                deletedItem: this.afe,
              });
              return data;
            },
            error => console.log(error)
          );

          break;
        case 1:
          const newAfe = new PerformanceAFE();
          newAfe.name = this.afeForm.get('name').value;
          newAfe.amount = this.afeForm.get('amount').value == null ? 0 : this.afeForm.get('amount').value;
          newAfe.number = this.afeForm.get('number').value;
          newAfe.isDefault = this.afe.isDefault;
          newAfe.createdBy = this.authenticationService.getCurrentUser();
          newAfe.performanceBodId = this.currentBod.id;
          newAfe.createdDate = new Date();
          newAfe.updatedBy = newAfe.createdBy;
          newAfe.updatedDate = newAfe.createdDate;

          this.afe.isDeleted = true;
          this.afe.isDefault = false;

          this.perfAfeService.createTheReplace([newAfe]).subscribe(
            data => {
              const createdItem: IPerformanceAFE =
                data.entity !== undefined || data.entity != null ? data.entity[0] : null;

              this.spinnerService.hide();
              this.dialog.close({
                hasChanges: true,
                deletedItem: this.afe,
              });
              return data;
            },
            error => console.log(error)
          );

          break;
        default:
          this.spinnerService.hide();
          console.log('An error has ocurred at select tab.');
          break;
      }
    }
  }
}
