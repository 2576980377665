import { IScopeAssumptions } from './../api/IScopeAssumptions';
import { environment } from '../../environments/environment';
import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs';
import { HandleError, HttpErrorHandler } from '../services/http-error-handler.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class ScopeAssumptionsService {
  public scopeAssumptions$: Observable<IScopeAssumptions[]>;
  private handleError: HandleError;
  private baseUrlScopeAssumptions: string = environment.PricetDomainURL + 'dasSimpleScopeAssumption';
  private scopeAssumptionsValue$: BehaviorSubject<IScopeAssumptions[]>;
  constructor(private http: HttpClient, httpErrorHandler: HttpErrorHandler) {
    this.scopeAssumptionsValue$ = new BehaviorSubject<IScopeAssumptions[]>([]);
    this.scopeAssumptions$ = this.scopeAssumptionsValue$.asObservable();
    this.handleError = httpErrorHandler.createHandleError();
  }

  public cleanObservables(): void {
    this.scopeAssumptionsValue$.next(null);
  }

  public getByDasSimpleId(dasSimpleId: string): Observable<IScopeAssumptions[]> {
    return this.http.get<IScopeAssumptions[]>(`${this.baseUrlScopeAssumptions}/ListByDasSimpleId/${dasSimpleId}`).pipe(
      map((data: IScopeAssumptions[]) => {
        this.scopeAssumptionsValue$.next(data);
        return data;
      }),
      catchError(error => {
        this.handleError('getByDasSimpleId');
        return throwError(error);
      })
    );
  }

  public createScopeAssumptions(data: IScopeAssumptions[]): Observable<any> {
    return this.http.post<IScopeAssumptions[]>(`${this.baseUrlScopeAssumptions}/Create`, data, httpOptions).pipe(
      catchError(
        catchError(error => {
          this.handleError('updateScopeAssumptions');
          return throwError(error);
        })
      )
    );
  }

  public updateScopeAssumptions(data: IScopeAssumptions[]): Observable<any> {
    return this.http.post<IScopeAssumptions[]>(`${this.baseUrlScopeAssumptions}/Update`, data, httpOptions).pipe(
      catchError(
        catchError(error => {
          this.handleError('updateScopeAssumptions');
          return throwError(error);
        })
      )
    );
  }

  public setValue(value: any) {
    this.scopeAssumptionsValue$.next([value]);
  }

  public fire(): void {
    const valueS = this.scopeAssumptionsValue$.value;
    this.scopeAssumptionsValue$.next(valueS);
  }
}
