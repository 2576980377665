import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';

import { map } from 'rxjs';
import { environment } from '../../../environments/environment';

import { IUnitRateArea } from '../../_shared/_models/unit.rate.area';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
  withCredentials: true,
};

@Injectable({
  providedIn: 'root',
})
export class UnitRateAreaService extends BehaviorSubject<any[]> {
  constructor(private http: HttpClient) {
    super([]);
  }

  public getUnitRateArea(): Observable<IUnitRateArea[]> {
    const serviceUrl: string = environment.PacerDomainURL + 'UnitRateArea/List';
    return this.http.get<IUnitRateArea[]>(serviceUrl, httpOptions).pipe(
      map((data: any[]) => {
        return data;
      })
    );
  }

  public getUnitRateAreaById(pcrUnitRateAreaId: string): Observable<IUnitRateArea> {
    const serviceUrl: string = environment.PacerDomainURL + 'UnitRateArea/GetByKey' + pcrUnitRateAreaId;
    return this.http.get(serviceUrl, httpOptions).pipe(
      map((data: any) => {
        return data;
      })
    );
  }
}
