import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, NgForm } from '@angular/forms';
import { ScenarioStoreService } from 'src/app/services/scenario-store.service';
import { IDevelopmentArea } from 'src/app/api/devArea';
import { Basin } from 'src/app/api/basin';
import { GeographicArea } from 'src/app/api/geographicArea';
import { UserPermissionsService } from 'src/app/services/user-permission.service';
import { HttpClient } from '@angular/common/http';
import { IUsrPermissions } from 'src/app/api/user-permission';
import { IPerformanceBod } from 'src/app/api/performance-bod';
import { IPerformanceBodPackages } from 'src/app/api/performance-bod-package';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from '@progress/kendo-angular-notification';
import { Location } from '@angular/common';
import { DialogService, DialogRef, DialogCloseResult } from '@progress/kendo-angular-dialog';
import { PerformanceBodStoreService } from 'src/app/services/performance-bod-store.service';
import { Guid } from 'guid-typescript';
import { Router } from '@angular/router';
import { PerformanceBodDataService } from 'src/app/services/performance-bod-data.service';
import { PermissionsProvider } from 'src/app/services/permissions.provider';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { IPerformanceAFE, PerformanceAFE } from 'src/app/api/performance-afe';

interface Item {
  id: number;
  name: string;
}

interface IUser {
  cai: string;
  fullname: string;
}

@Component({
  selector: 'app-perfomance-bod-create-new',
  templateUrl: './perfomance-bod-create-new.component.html',
  styleUrls: ['./perfomance-bod-create-new.component.css'],
})
export class PerfomanceBodCreateNewComponent implements OnInit {
  public canEditCnd: boolean = false;
  public perfomanceBODData: IPerformanceBod;
  public perfomanceBODPackages: IPerformanceBodPackages[] = [];
  public performanceBODPackage: IPerformanceBodPackages;
  public usersCnD: IUsrPermissions[];
  public users: IUser[] = [];
  public data: string[];
  public systemCAIs: string[] = [];
  public developmentAreaOptions: IDevelopmentArea[];
  public developmentAreaOptionsFiltered: IDevelopmentArea[];
  public basinOptions: Basin[];
  public isDisabledGeographic = true;
  public isDisabledDevelopment = true;
  public geographicAreaOptions: GeographicArea[];
  public geographicAreaOptionsFiltered: GeographicArea[];
  public numPackages = [
    { id: 1, name: '1' },
    { id: 2, name: '2' },
    { id: 3, name: '3' },
    { id: 4, name: '4' },
    { id: 5, name: '5' },
  ];
  public yesNoList: any[] = [{ text: 'No', value: false }, { text: 'Yes', value: true }];
  public canEditPerformanceBod: boolean = false;
  public selectedItem: Item = this.numPackages[0];
  public developmentArea: UntypedFormControl = new UntypedFormControl({ value: null, disabled: true }, Validators.required);
  public geographicArea: UntypedFormControl = new UntypedFormControl({ value: null, disabled: true }, Validators.required);
  public basinArea: UntypedFormControl = new UntypedFormControl(null, Validators.required);
  public unspecifiedPads: UntypedFormControl = new UntypedFormControl(true, Validators.required);
  public formNewBOD: UntypedFormGroup;
  public selectedUnspecifiedPads: Item = this.yesNoList[0];
  private userPermissionsService: UserPermissionsService;
  public afeMask = 'AAAAA-AAAAA-AAA';
  public defaultAfeNo = '0000000000000';
  public perfDefaultAfe: IPerformanceAFE = new PerformanceAFE();

  constructor(
    private scenarioStoreService: ScenarioStoreService,
    @Inject(UserPermissionsService) userPermissionsServiceFactory: any,
    private notificationService: NotificationService,
    private dialogService: DialogService,
    public performanceBodStoreService: PerformanceBodStoreService,
    private router: Router,
    private performanceBodDataService: PerformanceBodDataService,
    private permissionsProvider: PermissionsProvider,
    private authenticationService: AuthenticationService
  ) {
    this.userPermissionsService = userPermissionsServiceFactory();
  }

  public ngOnInit(): void {
    this.canEditPerformanceBod = this.permissionsProvider.canEditPerformanceBod;

    this.CreateNewFormGroup(null);
    this.userPermissionsService.getAllUsers().subscribe(data => {
      this.usersCnD = data;
      for (const entry of this.usersCnD) {
        this.systemCAIs.push(entry.fullName);
        this.users.push({
          cai: entry.name,
          fullname: entry.fullName,
        });
      }
      this.systemCAIs = this.systemCAIs.filter((el, i, a) => i === a.indexOf(el));
      this.systemCAIs.sort();
      this.data = this.systemCAIs.slice();
    });

    this.scenarioStoreService.developmentAreaOptions$.subscribe(data => {
      this.developmentAreaOptions = data.filter(d => d.isDeleted === false);
      this.developmentAreaOptionsFiltered = this.developmentAreaOptions;
    });
    this.scenarioStoreService.basinOptions$.subscribe(data => {
      this.basinOptions = data;
    });
    this.scenarioStoreService.geographicArea$.subscribe(data => {
      this.geographicAreaOptions = data.filter(d => d.isDeleted === false);
      this.geographicAreaOptionsFiltered = this.geographicAreaOptions;
    });

    this.bindToFormControls();

    this.perfomanceBODData = {
      id: null,
      basinId: '',
      geographicAreaId: '',
      developmentAreaId: '',
      batch: '',
      numberOfPackages: null,
      name: '',
      projectNumber: null,
      generalComments: '',
      performanceProjectManagerSignature: '',
      performanceProjectManagerCai: '',
      performanceProjectManagerDate: null,
      performanceTeamLeadSignature: '',
      performanceTeamLeadCai: '',
      performanceTeamLeadDate: null,
      performanceReservoirEngineerSignature: '',
      performanceReservoirEngineerCai: '',
      performanceReservoirEngineerDate: null,
      performanceWaterEngineerCai: '',
      performanceWaterEngineerSignature: '',
      performanceWaterEngineerDate: null,
      createdBy: '',
      createdDate: null,
      updatedBy: '',
      updatedDate: null,
      isTemp: false,
      isDeleted: false,
      unspecifiedPads: false,
      performanceBodAfes: [this.perfDefaultAfe],
    };

    this.performanceBODPackage = {
      id: null,
      name: '',
      sortOrder: null,
      performanceBodId: null,
      isDeleted: false,
      createdBy: null,
      createdDate: null,
      updatedBy: null,
      updatedDate: null,
      wellPads: 0,
      facilityPads: 0,
      flowLines: 0,
      glLines: 0,
      gathLines: 0,
      injLines: 0,
      ponds: 0,
      eds: 0,
      infrastructureComments: null,
    };
  }

  public bindToFormControls(): void {
    // this.developmentArea.valueChanges.subscribe(() => this.updateScenarioName());
  }

  public filterChange(filter: any): void {
    this.data = this.systemCAIs.filter(s => s.toLowerCase().indexOf(filter.toLowerCase()) !== -1);
  }

  public valueChange(value: any, input: string): void {
    this.data = this.systemCAIs.filter(s => s.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    if (this.users.filter(user => user.fullname === value)) {
      const i = this.users.find(j => j.fullname === value);
      if (i !== undefined && this.data !== undefined) {
        this.formNewBOD.controls[input].setValue(i.fullname);
        switch (input) {
          case 'performancePMSignature':
            this.perfomanceBODData.performanceProjectManagerCai = i.cai;
            break;
          case 'performanceTLSignature':
            this.perfomanceBODData.performanceTeamLeadCai = i.cai;
            break;
          case 'reservoirEngineerSignature':
            this.perfomanceBODData.performanceReservoirEngineerCai = i.cai;
            break;
          case 'waterEngineerSignature':
            this.perfomanceBODData.performanceWaterEngineerCai = i.cai;
            break;
          default:
            break;
        }
      } else {
        this.formNewBOD.controls[input].setValue('');
        switch (input) {
          case 'performancePMSignature':
            this.perfomanceBODData.performanceProjectManagerCai = null;
            break;
          case 'performanceTLSignature':
            this.perfomanceBODData.performanceTeamLeadCai = null;
            break;
          case 'reservoirEngineerSignature':
            this.perfomanceBODData.performanceReservoirEngineerCai = null;
            break;
          case 'waterEngineerSignature':
            this.perfomanceBODData.performanceWaterEngineerCai = null;
            break;
          default:
            break;
        }
      }
    } else {
      this.formNewBOD.controls[input].setValue('');
      switch (input) {
        case 'performancePMSignature':
          this.perfomanceBODData.performanceProjectManagerCai = null;
          break;
        case 'performanceTLSignature':
          this.perfomanceBODData.performanceTeamLeadCai = null;
          break;
        case 'reservoirEngineerSignature':
          this.perfomanceBODData.performanceReservoirEngineerCai = null;
          break;
        case 'waterEngineerSignature':
          this.perfomanceBODData.performanceWaterEngineerCai = null;
          break;
        default:
          break;
      }
    }
  }

  public CreateNewFormGroup(data: any) {
    if (data === null) {
      // Create new facility
      this.formNewBOD = new UntypedFormGroup({
        basinId: new UntypedFormControl({ id: null, disabled: false }, Validators.required),
        geographicAreaId: new UntypedFormControl({ id: null }, Validators.required),
        developmentAreaId: new UntypedFormControl({ id: null }, Validators.required),
        batch: new UntypedFormControl('', [Validators.minLength(1), Validators.maxLength(50), Validators.required]),
        packageId: new UntypedFormControl({ id: null }),
        package1Name: new UntypedFormControl('', [Validators.required]),
        package2Name: new UntypedFormControl(''),
        package3Name: new UntypedFormControl(''),
        package4Name: new UntypedFormControl(''),
        package5Name: new UntypedFormControl(''),
        performanceBODName: new UntypedFormControl('', [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(50),
        ]),
        projectNumber: new UntypedFormControl('', [Validators.max(20000), Validators.min(0), Validators.maxLength(5)]),
        performancePMSignature: new UntypedFormControl(''),
        performanceTLSignature: new UntypedFormControl(''),
        reservoirEngineerSignature: new UntypedFormControl(''),
        waterEngineerSignature: new UntypedFormControl(''),
        generalComments: new UntypedFormControl('', Validators.maxLength(500)),
        unspecifiedPads: new UntypedFormControl(true, Validators.required),
        defaultAfeNumber: new UntypedFormControl(this.defaultAfeNo, { updateOn: 'change', validators: [] }),
        defaultAfeAmount: new UntypedFormControl(null, { updateOn: 'change', validators: [] }),
      });
    }
  }

  public goBack() {
    this.showConfirmation();
  }

  public createNewPerfBOD(value: any, valid: boolean) {
    if (this.validateNewBOD(value)) {
      if (valid && value.developmentAreaId.id != null && value.geographicAreaId.id && value.basinId.id) {
        this.perfomanceBODData.id = Guid.create().toString();
        this.perfomanceBODData.basinId = value.basinId.id;
        this.perfomanceBODData.geographicAreaId = value.geographicAreaId.id;
        this.perfomanceBODData.developmentAreaId = value.developmentAreaId.id;
        this.perfomanceBODData.batch = value.batch;
        this.perfomanceBODData.numberOfPackages = value.packageId.id;
        this.perfomanceBODData.name = value.performanceBODName;
        this.perfomanceBODData.projectNumber = value.projectNumber;

        if (value.performancePMSignature != null || undefined) {
          this.perfomanceBODData.performanceProjectManagerSignature = value.performancePMSignature;
          this.perfomanceBODData.performanceProjectManagerDate = null;
        }
        if (value.performanceTLSignature != null || undefined) {
          this.perfomanceBODData.performanceTeamLeadSignature = value.performanceTLSignature;
          this.perfomanceBODData.performanceTeamLeadDate = null;
        }
        if (value.reservoirEngineerSignature != null || undefined) {
          this.perfomanceBODData.performanceReservoirEngineerSignature = value.reservoirEngineerSignature;
          this.perfomanceBODData.performanceReservoirEngineerDate = null;
        }
        if (value.waterEngineerSignature != null || undefined) {
          this.perfomanceBODData.performanceWaterEngineerSignature = value.waterEngineerSignature;
          this.perfomanceBODData.performanceWaterEngineerDate = null;
        }
        this.perfomanceBODData.generalComments = value.generalComments;
        this.perfomanceBODData.createdDate = new Date();
        this.perfomanceBODData.createdBy = this.authenticationService.getCurrentUser().toUpperCase();
        this.perfomanceBODData.updatedDate = null;
        this.perfomanceBODData.updatedBy = this.authenticationService.getCurrentUser().toUpperCase();
        this.perfomanceBODData.isDeleted = false;
        this.perfomanceBODData.unspecifiedPads = value.unspecifiedPads.value;

        this.perfomanceBODPackages = [];
        for (let x = 1; x <= this.perfomanceBODData.numberOfPackages; x++) {
          this.clearData();
          this.performanceBODPackage.id = Guid.create().toString();
          this.performanceBODPackage.performanceBodId = this.perfomanceBODData.id;
          switch (x) {
            case 1:
              this.performanceBODPackage.name = value.package1Name;
              this.performanceBODPackage.sortOrder = 1;
              break;
            case 2:
              this.performanceBODPackage.name = value.package2Name;
              this.performanceBODPackage.sortOrder = 2;
              break;
            case 3:
              this.performanceBODPackage.name = value.package3Name;
              this.performanceBODPackage.sortOrder = 3;
              break;
            case 4:
              this.performanceBODPackage.name = value.package4Name;
              this.performanceBODPackage.sortOrder = 4;
              break;
            case 5:
              this.performanceBODPackage.name = value.package5Name;
              this.performanceBODPackage.sortOrder = 5;
              break;
            default:
              break;
          }
          this.performanceBODPackage.createdBy = this.authenticationService.getCurrentUser().toUpperCase();
          this.performanceBODPackage.createdDate = new Date();
          this.performanceBODPackage.updatedBy = null;
          this.performanceBODPackage.updatedDate = null;
          this.perfomanceBODPackages.push(this.performanceBODPackage);
        }

        this.perfomanceBODData.performanceBodPackages = this.perfomanceBODPackages;

        //filling AFE info
        this.perfomanceBODData.performanceBodAfes[0].amount = value.defaultAfeAmount;
        this.perfomanceBODData.performanceBodAfes[0].number = value.defaultAfeNumber;
        this.perfomanceBODData.performanceBodAfes[0].isDefault = true;

        this.performanceBodStoreService.setStartCreate();
        this.performanceBodDataService.createPerformanceBODWithPackages(this.perfomanceBODData).subscribe(
          (data: any) => {
            this.performanceBodStoreService.setCompleteCreate();
            this.onCreateComplete(data);
          },
          (err: any) => {
            this.onCreateError(err);
          }
        );
      }
    } else {
      return;
    }
  }

  public clearData() {
    this.performanceBODPackage = {
      id: null,
      name: '',
      performanceBodId: null,
      sortOrder: null,
      isDeleted: false,
      createdBy: null,
      createdDate: null,
      updatedBy: null,
      updatedDate: null,
      wellPads: 0,
      facilityPads: 0,
      flowLines: 0,
      glLines: 0,
      gathLines: 0,
      injLines: 0,
      ponds: 0,
      eds: 0,
      infrastructureComments: null,
    };
  }

  public validateNewBOD(entity: any): boolean {
    let isValid = true;
    // packages names
    if (entity === null || entity === undefined) {
      this.showWarning('The information of the new Performance BOD is required.');
      isValid = false;
    } else {
      if (
        entity.developmentAreaId === null ||
        entity.developmentAreaId === undefined ||
        entity.developmentAreaId.id === null ||
        entity.developmentAreaId.id === Guid.EMPTY ||
        entity.developmentAreaId.id === undefined
      ) {
        this.showWarning('The Development Area is required.');
        isValid = false;
      }
      if (entity.performanceBODName === null || entity.performanceBODName === '') {
        this.showWarning('The Perfomance BOD name is required.');
        isValid = false;
      } else {
        if (entity.performanceBODName.length < 5) {
          this.showWarning('The Perfomance BOD name requires minimum 5 characters.');
          isValid = false;
        }

        if (entity.performanceBODName.length > 50) {
          this.showWarning('The Perfomance BOD name requires requires maximum 50 characters.');
          isValid = false;
        }
      }

      if (
        entity.geographicAreaId === null ||
        entity.geographicAreaId === undefined ||
        entity.geographicAreaId.id === null ||
        entity.geographicAreaId.id === undefined
      ) {
        this.showWarning('The Geographic Area is required.');
        isValid = false;
      }

      if (
        entity.basinId === null ||
        entity.basinId === undefined ||
        entity.basinId.id === null ||
        entity.basinId.id === undefined
      ) {
        this.showWarning('The Basin is required.');
        isValid = false;
      }

      // if (!entity.defaultAfeAmount) {
      //   this.showWarning('Default AFE Amount is required.');
      //   isValid = false;
      // }

      // if (entity.defaultAfeNumber == '0000000000000' || !entity.defaultAfeNumber) {
      //   this.showWarning('Default AFE Number is required.');
      //   isValid = false;
      // }

      if (entity.batch === null || entity.batch === '') {
        this.showWarning('The Batch is required.');
        isValid = false;
      } else {
        if (entity.batch.length < 1) {
          this.showWarning('The Batch requires minimum 1 character.');
          isValid = false;
        }

        if (entity.batch.length > 50) {
          this.showWarning('The Batch requires maximum 50 characters.');
          isValid = false;
        }
      }

      if (entity.packageId === null) {
        this.showWarning('The Number of Packages is required.');
        isValid = false;
      }

      if (entity.packageId.id != null || entity.packageId.id !== undefined) {
        for (let j = 1; j <= entity.packageId.id; j++) {
          switch (j) {
            case 1:
              if (entity.package1Name === '' || !entity.package1Name) {
                this.showWarning('The Package Name 1 is required.');
                this.formNewBOD.controls['package1Name'].markAsTouched();
                isValid = false;
              }
              break;
            case 2:
              if (entity.package2Name === '' || !entity.package2Name) {
                this.showWarning('The Package Name 2 is required.');
                this.formNewBOD.controls['package2Name'].markAsTouched();
                isValid = false;
              } else if (isValid && entity.package1Name === entity.package2Name) {
                this.showWarning('Package Names must be unique.');
                this.formNewBOD.controls['package2Name'].markAsTouched();
                isValid = false;
              }
              break;
            case 3:
              if (entity.package3Name === '' || !entity.package3Name) {
                this.showWarning('The Package Name 3 is required.');
                this.formNewBOD.controls['package3Name'].markAsTouched();
                isValid = false;
              } else if (
                isValid &&
                (entity.package3Name === entity.package1Name || entity.package3Name === entity.package2Name)
              ) {
                this.showWarning('Package Names must be unique.');
                this.formNewBOD.controls['package3Name'].markAsTouched();
                isValid = false;
              }
              break;
            case 4:
              if (entity.package4Name === '' || !entity.package4Name) {
                this.showWarning('The Package Name 4 is required.');
                this.formNewBOD.controls['package4Name'].markAsTouched();
                isValid = false;
              } else if (
                isValid &&
                (entity.package4Name === entity.package1Name ||
                  entity.package4Name === entity.package2Name ||
                  entity.package4Name === entity.package3Name)
              ) {
                this.showWarning('Package Names must be unique.');
                this.formNewBOD.controls['package4Name'].markAsTouched();
                isValid = false;
              }
              break;
            case 5:
              if (entity.package5Name === '' || !entity.package5Name) {
                this.showWarning('The Package Name 5 is required.');
                this.formNewBOD.controls['package5Name'].markAsTouched();
                isValid = false;
              } else if (
                isValid &&
                (entity.package5Name === entity.package1Name ||
                  entity.package5Name === entity.package2Name ||
                  entity.package5Name === entity.package3Name ||
                  entity.package5Name === entity.package4Name)
              ) {
                this.showWarning('Package Names must be unique.');
                this.formNewBOD.controls['package5Name'].markAsTouched();
                isValid = false;
              }
              break;
            default:
              isValid = false;
              break;
          }
        }
      }

      if (!this.formNewBOD.valid && isValid) {
        this.showWarning('Please check the required fields.');
        isValid = false;
      }
    }

    return isValid;
  }

  public showWarning(value: string) {
    this.notificationService.show({
      content: value,
      position: { horizontal: 'center', vertical: 'top' },
      animation: { type: 'fade', duration: 400 },
      type: { style: 'warning', icon: true },
      hideAfter: 1400,
    });
  }

  public showInfo(value: string) {
    this.notificationService.show({
      content: value,
      position: { horizontal: 'center', vertical: 'top' },
      animation: { type: 'fade', duration: 400 },
      type: { style: 'info', icon: true },
      hideAfter: 1400,
    });
  }

  public showConfirmation() {
    const dialog: DialogRef = this.dialogService.open({
      title: 'Please confirm',
      content: 'Are you certain that you want to navigate away from this page? Any unsaved changes will be lost.',
      actions: [{ text: 'Cancel', primary: true }, { text: 'Ok' }],
      width: 450,
      height: 200,
      minWidth: 250,
    });

    dialog.result.subscribe(result => {
      if (result instanceof DialogCloseResult) {
        // ToDo
      } else {
        if (result.text === 'Ok') {
          this.router.navigate(['']);
        } else {
          // ToDo
        }
      }
    });
  }

  public basinSelectionChange(value: any): void {
    this.geographicAreaOptionsFiltered = [];
    this.developmentAreaOptionsFiltered = [];
    this.geographicAreaOptionsFiltered = this.geographicAreaOptions.filter(g => g.basinId === value.id);
    this.formNewBOD.controls['geographicAreaId'].reset();
    this.formNewBOD.controls['developmentAreaId'].reset();
    this.isDisabledGeographic = false;
  }

  public geographicAreaSelectionChange(value: any): void {
    this.developmentAreaOptionsFiltered = [];
    this.developmentAreaOptionsFiltered = this.developmentAreaOptions.filter(d => d.geographicAreaId === value.id);
    this.formNewBOD.controls['developmentAreaId'].reset();
    this.isDisabledDevelopment = false;
  }

  public handlePackagesChange(value: Item, form: UntypedFormGroup) {
    this.setNumOfPackgesValidators(value, form);
    this.selectedItem = value;
  }

  public setNumOfPackgesValidators(value: Item, form: UntypedFormGroup) {
    for (let i = 1; i <= this.numPackages.length; i++) {
      if (value.id >= i) {
        form.controls['package' + i + 'Name'].setValidators(Validators.required);
      } else {
        form.controls['package' + i + 'Name'].clearValidators();
        form.controls['package' + i + 'Name'].setValue(null);
      }
    }
  }

  private onCreateComplete(value: any) {
    if (value !== null && value !== undefined && value === '') {
      sessionStorage.setItem('PERFORMANCE_BOD_ID', this.perfomanceBODData.id);
      this.router.navigate(['/performanceBOD', this.perfomanceBODData.id]);
    } else {
      this.showWarning(value);
      this.performanceBodStoreService.errorCreatePerformanceBod.hasError = true;
    }
  }

  private onCreateError(value: any) {
    if (value !== null && value !== undefined && value !== '') {
      this.showWarning(value);
      this.performanceBodStoreService.errorCreatePerformanceBod.hasError = true;
    }
  }
}
