import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IEditableInline } from 'src/app/api/ieditable-inline';
import { InfrastructureCommercial } from 'src/app/api/infrastructure-commercial';
import { InlineEditService } from 'src/app/services/inline-edit.service';
import { PerformanceBodDataService } from 'src/app/services/performance-bod-data.service';
import { PerformanceBodCalculationsService } from 'src/app/services/performance-bod-calculations.service';
import * as Helpers from 'src/app/_shared/helpers';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InfrastructureCommercialService extends InlineEditService implements IEditableInline {
  public data: InfrastructureCommercial[] = [];
  public originalData: InfrastructureCommercial[] = [];
  public createdItems: InfrastructureCommercial[] = [];
  public updatedItems: InfrastructureCommercial[] = [];
  public deletedItems: InfrastructureCommercial[] = [];
  public performanceBodId: string;

  constructor(
    private performanceBodDataService: PerformanceBodDataService,
    private performanceBodCalculationsService: PerformanceBodCalculationsService
  ) {
    super();
  }

  public isNew(item: any): boolean {
    return !item.id;
  }

  public itemIndex(item: any, data: InfrastructureCommercial[]): number {
    for (let idx = 0; idx < data.length; idx++) {
      if (data[idx].id === item.id) {
        return idx;
      }
    }
    return -1;
  }

  public create(): Observable<any> {
    return this.performanceBodDataService.createPerformanceBodCommercial(this.createdItems);
  }

  public retrieve(): Observable<InfrastructureCommercial[]> {
    return this.performanceBodDataService.getPerformanceBodCommercial(this.performanceBodId).pipe(map(data => {
      data.forEach(element => {
        if (element.readyForServiceDate !== null && element.readyForServiceDate !== undefined) {
          element.readyForServiceDate = Helpers.convertUtcToLocalTime(element.readyForServiceDate);
        } else {
          element.readyForServiceDate = undefined;
        }
      });
      return data;
    }));
  }

  public update(): Observable<any> {
    return this.performanceBodDataService.updatePerformanceBodCommercial(this.updatedItems);
  }

  public delete(): Observable<any> {
    return this.performanceBodDataService.updatePerformanceBodCommercial(this.deletedItems);
  }

  public applyLogicFromPads(): void {
    const commercialChanges = this.performanceBodCalculationsService.commercialCalculations(this.data);
    for(const commercial of commercialChanges) {
      this.updateItem(commercial);
    }
  }    
}
