import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { InlineEditService } from 'src/app/services/inline-edit.service';
import { PerformanceBodDataService } from 'src/app/services/performance-bod-data.service';
import { IEditableInline } from 'src/app/api/ieditable-inline';
import { PerformanceBodStoreService } from 'src/app/services/performance-bod-store.service';
import { IPerformanceBodSaltWaterDisposal } from 'src/app/api/performance-bod-salt-water-disposal';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PerformanceBodSaltWaterDisposalService extends InlineEditService implements IEditableInline {
  public data: IPerformanceBodSaltWaterDisposal[] = [];
  public originalData: IPerformanceBodSaltWaterDisposal[] = [];
  public createdItems: IPerformanceBodSaltWaterDisposal[] = [];
  public updatedItems: IPerformanceBodSaltWaterDisposal[] = [];
  public deletedItems: IPerformanceBodSaltWaterDisposal[] = [];
  public performanceBodId: string;

  constructor(
    private performanceBodDataService: PerformanceBodDataService
  ) {
    super();
  }

  public isNew(item: any): boolean {
    return !item.id;
  }

  public itemIndex(item: any, data: IPerformanceBodSaltWaterDisposal[]): number {
    for (let idx = 0; idx < data.length; idx++) {
      if (data[idx].id === item.id) {
        return idx;
      }
    }
    return -1;
  }

  public create(): Observable<any> {
    return this.performanceBodDataService.createPerformanceBodSaltWaterDisposal(this.createdItems);
  }

  public retrieve(): Observable<any> {
    return this.performanceBodDataService.getPerformanceBodSaltWaterDisposal(this.performanceBodId).pipe(map(data => {
      return data;
    }));
  }

  public update(): Observable<any> {
    return this.performanceBodDataService.updatePerformanceBodSaltWaterDisposal(this.updatedItems);
  }

  public delete(): Observable<any> {
    return this.performanceBodDataService.updatePerformanceBodSaltWaterDisposal(this.deletedItems);
  }

}
