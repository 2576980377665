import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { config, Observable } from 'rxjs';
import { concat } from 'rxjs';
import { map, take, catchError } from 'rxjs';
import { environment } from '../../environments/environment';
import { IPerformanceConfiguration } from '../api/performance-configuration';
import { ICndCostMappingSft } from '../api/cnd-cost-mapping-sft';
import { HandleError, HttpErrorHandler } from './http-error-handler.service';
import { IAppConfiguration } from '../api/app-configurations';
import { Globals } from '../_shared/Globals';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

export function initConfiguration(configuration: PerformanceConfigurationService) {
  return () => configuration.load();
}

@Injectable({
  providedIn: 'root',
})
export class PerformanceConfigurationService {
  public cndCostMappingSft: ICndCostMappingSft[] = [];
  public appConfigs: IAppConfiguration[] = [];
  private baseUrlFacilityItem: string = environment.PacerDomainURL + 'FacilityItem';
  private baseUrlAppConfig: string = environment.PacerDomainURL + 'AppConfig';
  private handleError: HandleError;
  constructor(private http: HttpClient, httpErrorHandler: HttpErrorHandler,
    private globals: Globals) { 
    this.handleError = httpErrorHandler.createHandleError();
  }

  public load(): Promise<any> {
    return new Promise((resolve, reject) => {

      const getFirtConfigurations = this.getConfigurations().pipe(map((configuration: IPerformanceConfiguration) => {
        this.cndCostMappingSft = configuration.cndCostMappingSft;
        resolve(true);
      }), take(1));

      const getIsInGroup = this.getAppConfig().pipe(map((configs: IAppConfiguration[]) => {
        this.appConfigs = configs;

      /*NOSONAR*/  configs.filter(c => c.value != null).map((item: IAppConfiguration) => {
          this.globals.APP_CONFIG[item.code] = item.value;
        });

      }), take(1));

      concat(getFirtConfigurations, getIsInGroup)
      .toPromise()
      .then(() => {
        resolve(true);
      })
      .catch((reason: any) => {
        reject(reason);
      });      
    });
  }

  private getConfigurations(): Observable<IPerformanceConfiguration> {
    return this.http
      .get<IPerformanceConfiguration>(`${this.baseUrlFacilityItem}/GetConfigurations/`, httpOptions)
      .pipe(catchError(this.handleError('getConfigurations')));
  }

  private getAppConfig(): Observable<IAppConfiguration[]> {
    return this.http
      .get<IAppConfiguration[]>(`${this.baseUrlAppConfig}/List`, httpOptions)
      .pipe(catchError(this.handleError('getAppConfig')));
  }

}
