import { Component, EventEmitter, OnInit, Output, ViewChild, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { GridDataResult, RowClassArgs } from '@progress/kendo-angular-grid';
import { NotificationService } from '@progress/kendo-angular-notification';
import { State, process, filterBy, CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { map } from 'rxjs';
import { IPerformanceBodPads } from 'src/app/api/performance-bod-pads';
import { IPerformanceBodEdsIe } from 'src/app/api/performance-bod-eds-ie';
import { IPerformanceBodPackages } from 'src/app/api/performance-bod-package';
import { PerformanceBodStoreService } from 'src/app/services/performance-bod-store.service';
import { PerformanceBodEdsIeService } from './infrastructure-eds-ie.service';
import { UtilService } from 'src/app/services/util.service';
import { PermissionsProvider } from 'src/app/services/permissions.provider';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DragAndDropService } from 'src/app/_shared/drag-and-drop.service';

const DRAGGABLE_SELECTOR = '.infrastructure-eds-draggable';

@Component({
  selector: 'app-infrastructure-eds-ie',
  
  templateUrl: './infrastructure-eds-ie.component.html',
})
export class InfrastructureEdsIeComponent implements OnInit {
  public filter: CompositeFilterDescriptor;
  public filterable: boolean = true;
  public warningMessage: string =
    'Please provide a Well Pad Name on the Package Information Section (Performance BOD Summary Tab) before adding a new record';
  public countWellPadName: number = 0;
  public openDialog: boolean = false;
  public messageDuplicate: boolean = true;
  public view: Observable<GridDataResult>;
  public performanceBodPackagesList: IPerformanceBodPackages[];
  public performanceBodPadsList: IPerformanceBodPads[];
  public performanceBodPadsFilter: IPerformanceBodPads[] = [];
  public performanceBodId: string;
  public formGroup: UntypedFormGroup;
  public gridState: State = {
    sort: [],
    skip: 0,
  };
  public canEditPerformanceBod: boolean = false;
  public performanceBodEdsIeService: PerformanceBodEdsIeService;
  @Output() public performanceBodUpdate = new EventEmitter<string>();
  @Output() public saveBodCompleted = new EventEmitter<boolean>();

  @ViewChild('edsIe')
  public infrastructureEdsIeGrid: any;
  private dragAndDropService: DragAndDropService;

  constructor(
    private performanceBodStoreService: PerformanceBodStoreService,    
    private formBuilder: UntypedFormBuilder,
    private spinner: NgxSpinnerService,
    private notificationService: NotificationService,
    private utilService: UtilService,
    private permissionsProvider: PermissionsProvider,
    private authenticationService: AuthenticationService,
    @Inject(DragAndDropService) dragAndDropServiceFactory: any,
    @Inject(PerformanceBodEdsIeService) performanceBodEdsIeServiceFactory: any
  ) {
    this.dragAndDropService = dragAndDropServiceFactory();
    this.performanceBodEdsIeService = performanceBodEdsIeServiceFactory();
  }

  public ngOnInit(): void {
    this.canEditPerformanceBod = this.permissionsProvider.canEditPerformanceBod;

    this.performanceBodStoreService.performanceBodId$.subscribe(id => {
      this.performanceBodId = id;
      this.performanceBodEdsIeService.performanceBodId = id;
      this.performanceBodEdsIeService.read();
    });
    this.performanceBodStoreService.performanceBodPackagesData$.subscribe(data => {
      this.performanceBodPackagesList = data;
    });
    this.performanceBodStoreService.performanceBodPadsData$.subscribe(data => {
      this.performanceBodPadsList = data.filter(row => row.wellPadName !== '');
    });

    this.view = this.performanceBodEdsIeService.pipe(
      map(data => {
        this.dragAndDropService.dataSourceRefreshed(data);
        return process(filterBy(data, this.filter), this.gridState);
      })
    );    

    this.dragAndDropService.initialize(this.performanceBodEdsIeService.data, DRAGGABLE_SELECTOR, () => {
      this.performanceBodEdsIeService.markAsSetOrder(this.authenticationService.getCurrentUser());
    });
  }

  public ngAfterViewInit(): void {
    // To Do
  }

  public ngOnDestroy(): void {
    this.dragAndDropService.refreshDragAndDrop();
  }

  public createFormGroup(dataItem: any): UntypedFormGroup {
    return this.formBuilder.group({
      id: new UntypedFormControl(dataItem.id),
      isDeleted: new UntypedFormControl(dataItem.isDeleted, [Validators.required]),
      performanceBodId: new UntypedFormControl(dataItem.performanceBodId),
      performanceBodPadsId: new UntypedFormControl(dataItem.performanceBodPadsId, [Validators.required]),
      performanceBodPackagesId: new UntypedFormControl(dataItem.performanceBodPackagesId),
      readyForServiceDate: new UntypedFormControl(dataItem.readyForServiceDate, [Validators.required]),
      permitsRequiredBy: [dataItem.permitsRequiredBy],
      powerSupplySource: new UntypedFormControl(dataItem.powerSupplySource, [
        Validators.minLength(0),
        Validators.maxLength(250),
      ]),
      from: new UntypedFormControl(dataItem.from, [Validators.minLength(0), Validators.maxLength(250)]),
      to: new UntypedFormControl(dataItem.to, [Validators.minLength(0), Validators.maxLength(250)]),
      comments: new UntypedFormControl(dataItem.comments, [Validators.minLength(0), Validators.maxLength(500)]),
      createdBy: [dataItem.createdBy],
      createdDate: [dataItem.createdDate],
      updatedBy: [dataItem.updatedBy],
      updatedDate: [dataItem.updatedDate],
      name: new UntypedFormControl(dataItem.name),
    });
  }

  public cellClickHandler({ sender, rowIndex, columnIndex, dataItem, isEdited }) {
    if (!isEdited && this.canEditPerformanceBod) {
      if (dataItem.readyForServiceDate !== undefined && columnIndex === 3) {
        if (dataItem.readyForServiceDate !== null) {
          const y = new Date(dataItem.readyForServiceDate.toString()).getFullYear();
          const m = new Date(dataItem.readyForServiceDate.toString()).getMonth();
          const d = new Date(dataItem.readyForServiceDate.toString()).getDate();
          dataItem.readyForServiceDate = new Date(y, m, d);
        }
      }

      if (dataItem.permitsRequiredBy !== undefined && columnIndex === 4) {
        if (dataItem.permitsRequiredBy !== null) {
          const y = new Date(dataItem.permitsRequiredBy.toString()).getFullYear();
          const m = new Date(dataItem.permitsRequiredBy.toString()).getMonth();
          const d = new Date(dataItem.permitsRequiredBy.toString()).getDate();
          dataItem.permitsRequiredBy = new Date(y, m, d);
        }
      }
      sender.editCell(rowIndex, columnIndex, this.createFormGroup(dataItem));
      this.dragAndDropService.onEditingMode();
    }
  }

  public cellCloseHandler(args: any) {
    const { formGroup, dataItem, column } = args;

    let columnField = '';

    if (column.field === 'performanceBodPackagesName') {
      columnField = 'performanceBodPackagesId';
    } else {
      columnField = column.field;
    }

    const checkColumn = formGroup.controls[columnField];

    if (!checkColumn.valid) {
      args.preventDefault();
    } else if (checkColumn.dirty) {
      if (columnField === 'performanceBodPackagesId') {
        dataItem.performanceBodPackagesName = this.getPerformanceBodPackages(formGroup.controls[columnField].value);
      }
    }

    if (!formGroup.valid) {
      // prevent closing the edited cell if there are invalid values.
      args.preventDefault();
    } else if (formGroup.dirty) {
      if (formGroup.valid && args.sender.data.total > 0) {
        for (let i = 0; i <= args.sender.data.total - 1; i++) {
          if (
            args.sender.data.data[i].performanceBodPadsId === formGroup.value.performanceBodPadsId &&
            args.sender.data.data[i].id !== formGroup.value.id
          ) {
            this.messageDuplicate = false;
            args.preventDefault();
            return;
          } else {
            this.messageDuplicate = true;
          }
        }
      }

      if (this.messageDuplicate) {
        dataItem.updatedDate = new Date();
        dataItem.updatedBy = this.authenticationService.getCurrentUser();

        // Date is not working in Object.assign(), so need to be do it manually
        formGroup.value.updatedBy = dataItem.updatedBy;
        formGroup.value.updatedDate = dataItem.updatedDate;

        this.performanceBodEdsIeService.assignValues(dataItem, formGroup.value);
        this.performanceBodEdsIeService.updateItem(dataItem);
      }
    }
    // calls this to add the attribute of current row
    this.dragAndDropService.onEditingClose();
    this.dragAndDropService.refreshDragAndDrop();
  }

  public addHandler({ sender, rowIndex }) {
    const item: IPerformanceBodEdsIe = {
      id: Guid.create().toString(),
      performanceBodId: this.performanceBodId,
      performanceBodPadsId: '',
      performanceBodPackagesId: '',
      readyForServiceDate: null,
      permitsRequiredBy: null,
      powerSupplySource: '',
      from: '',
      to: '',
      comments: '',
      isDeleted: false,
      createdBy: this.authenticationService.getCurrentUser(),
      createdDate: new Date(),
      updatedBy: null,
      updatedDate: null,
      name: '',
      sortOrder: 0
    };

    if (this.performanceBodPadsList) {
      for (const pad of this.performanceBodPadsList) {
        if (pad.wellPadName === '') {
          this.countWellPadName = this.countWellPadName + 1;
        }
      }
      if (this.countWellPadName === this.performanceBodPadsList.length) {
        this.openDialog = true;
        this.cancelHandler({ sender, rowIndex });
        this.countWellPadName = 0;
        return;
      } else {
        item.createdBy = this.authenticationService.getCurrentUser();
        item.createdDate = new Date();
        sender.addRow(this.createFormGroup(item));
        this.dragAndDropService.onEditingMode();
      }
    }
  }

  public cancelHandler({ sender, rowIndex }) {
    this.messageDuplicate = true;
    sender.closeRow(rowIndex);
    this.dragAndDropService.onEditingClose();
    this.dragAndDropService.refreshDragAndDrop();
  }

  public saveHandler({ sender, formGroup, rowIndex }) {
    if (formGroup.valid && sender.data.total > 0) {
      for (let x = 0; x <= sender.data.total - 1; x++) {
        if (sender.data.data[x].performanceBodPadsId === formGroup.value.performanceBodPadsId) {
          this.messageDuplicate = false;
          return;
        } else {
          this.messageDuplicate = true;
        }
      }
    }

    if (this.messageDuplicate) {
      if (formGroup.valid) {
        formGroup.patchValue({
          performanceBodId: this.performanceBodId,
        });

        if (this.performanceBodPadsList) {
          for (const pad of this.performanceBodPadsList) {
            if (pad.wellPadName === '') {
              this.countWellPadName = this.countWellPadName + 1;
            }
          }
          if (this.countWellPadName === this.performanceBodPadsList.length) {
            this.openDialog = true;
            this.countWellPadName = 0;
            return;
          } else {
            this.performanceBodEdsIeService.createItem(formGroup.value);
            sender.closeRow(rowIndex);
            this.dragAndDropService.onEditingClose();
            this.dragAndDropService.dataSourceRefreshed(this.performanceBodEdsIeService.data);
            this.dragAndDropService.refreshDragAndDrop();
          }
        }
      } else {
        this.utilService.markFormGroupAsTouched(formGroup);
      }
    }
  }

  public removeHandler({ sender, dataItem }) {
    dataItem.isDeleted = true;
    this.performanceBodEdsIeService.removeItem(dataItem);

    sender.cancelCell();
  }

  public saveChanges(grid: any): void {
    grid.closeCell();
    grid.cancelCell();
    this.spinner.show();

    // calls this to add the attribute of current row
    this.dragAndDropService.refreshDragAndDrop();
    this.performanceBodEdsIeService.saveChanges();
    this.performanceBodUpdate.next(this.authenticationService.getCurrentUser());

    setTimeout(() => {
      this.spinner.hide();
      this.notificationService.show({
        content: 'Infrastructure - EDS / I&E Information saved',
        position: { horizontal: 'center', vertical: 'top' },
        animation: { type: 'fade', duration: 500 },
        type: { style: 'success', icon: true },
        hideAfter: 700,
      });
    }, 3000);
  }

  public saveFromBOD(): void {
    this.infrastructureEdsIeGrid.closeCell();
    this.infrastructureEdsIeGrid.cancelCell();
    this.spinner.show();

    if (this.performanceBodEdsIeService.hasChanges()) {
      // calls this to add the attribute of current row
      this.dragAndDropService.refreshDragAndDrop();
      this.performanceBodEdsIeService.saveChanges();
      this.performanceBodUpdate.next(this.authenticationService.getCurrentUser());
      const subscription = this.performanceBodEdsIeService.subscribe(() => {
        this.saveBodCompleted.next(true);
      });

      subscription.unsubscribe();
      
    }
  }

  public cancelChanges(grid: any): void {
    this.messageDuplicate = true;
    grid.cancelCell();
    this.dragAndDropService.refreshDragAndDrop();
    this.performanceBodEdsIeService.cancelChanges();
  }

  public onStateChange(state: State) {
    this.gridState = state;
    this.performanceBodEdsIeService.read();


  }

  public getPerformanceBodPackages(id: string): any {
    if (this.performanceBodPackagesList === null || this.performanceBodPackagesList === undefined) {
      return '';
    }
    return this.performanceBodPackagesList.find(x => x.id === id);
  }

  public changePackage(value, dataItem, formGroup: UntypedFormGroup) {
    let performanceBodPad: IPerformanceBodPads[];
    let performanceBodPackagesId: string;
    performanceBodPad = this.performanceBodPadsList.filter(s => s.id === value);
    performanceBodPad.forEach(element => {
      performanceBodPackagesId = element.performanceBodPackagesId;
    });

    dataItem.performanceBodPackagesId = performanceBodPackagesId;
    formGroup.patchValue({
      performanceBodPackagesId,
    });
  }

  public getPerformanceBodPads(id: string): any {
    if (this.performanceBodPadsList === null || this.performanceBodPadsList === undefined) {
      return '';
    } else {
      return this.performanceBodPadsList.find(x => x.id === id);
    }
  }

  public closeKendoPopUp() {
    this.openDialog = false;
  }

  public handlerFilter(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    if (filter.filters) {
      if (filter.filters.length > 0) {
        this.dragAndDropService.onEditingMode();
      } else {
        this.dragAndDropService.onEditingClose();
        this.dragAndDropService.refreshDragAndDrop();
      }
    }
  }

  public rowCallback(context: RowClassArgs) {
    return {
      dragging: context.dataItem.dragging,
    };
  }

  public rowLoaded(): void {
    // to check until grid have been loeaded.
    this.dragAndDropService.checkIsOnEditMode();
    this.dragAndDropService.checkDraggableElementLoaded();
  }
}
