import { EventEmitter, Injectable } from '@angular/core';
import { IEditableInline } from 'src/app/api/ieditable-inline';
import { IPerformanceAFE } from 'src/app/api/performance-afe';
import { InlineEditService } from 'src/app/services/inline-edit.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { InlineActions } from 'src/app/api/inline-actions.enum';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable()
export class PerformanceAFEService extends InlineEditService implements IEditableInline {
  public loader = new EventEmitter<any>();
  public wasModified = new EventEmitter<any>();
  public data: IPerformanceAFE[] = [];
  public originalData: IPerformanceAFE[] = [];
  public createdItems: IPerformanceAFE[] = [];
  public updatedItems: IPerformanceAFE[] = [];
  public deletedItems: IPerformanceAFE[] = [];

  public performanceBodId?: string = null;

  constructor(private http: HttpClient) {
    super();
    super.fetch(InlineActions.Retrieve, this.data);
  }

  public itemIndex(item: any, data: IPerformanceAFE[]): number {
    for (let idx = 0; idx < data.length; idx++) {
      if (data[idx].id === item.id) {
        return idx;
      }
    }
    return -1;
  }

  public create(): Observable<any> {
    const serviceUrl: string = environment.PacerDomainURL + 'PerformanceAfe/Create/';
    const toCreate = this.createdItems.map(item => {
      return {
        performanceBodId: item.performanceBodId,
        name: item.name,
        number: item.number,
        amount: item.amount,
        isDefault: item.isDefault,
        isDeleted: item.isDeleted,
        createdBy: item.createdBy,
        createdDate: item.createdDate,
        updatedBy: item.updatedBy,
        updatedDate: item.updatedDate,
        chargeCode: item.chargeCode,
      };
    });

    return this.http.post(serviceUrl, JSON.stringify(toCreate), httpOptions).pipe(
      map((data: any) => {
        // this.wasModified.emit(true);
        return data;
      })
    );
  }

  public createTheReplace(createdItems: IPerformanceAFE[]): Observable<any> {
    const serviceUrl: string = environment.PacerDomainURL + 'PerformanceAfe/Create/';
    const toCreate = createdItems.map(item => {
      return {
        performanceBodId: item.performanceBodId,
        name: item.name,
        number: item.number,
        amount: item.amount,
        isDefault: item.isDefault,
        isDeleted: item.isDeleted,
        createdBy: item.createdBy,
        createdDate: item.createdDate,
        updatedBy: item.updatedBy,
        updatedDate: item.updatedDate,
        chargeCode: item.chargeCode,
      };
    });

    return this.http.post(serviceUrl, JSON.stringify(toCreate), httpOptions).pipe(
      map((data: any) => {
        // this.wasModified.emit(true);
        return data;
      })
    );
  }

  public retrieve(): Observable<any> {
    if (!this.performanceBodId) {
      return of([]);
    }
    this.loader.emit(true);
    const serviceUrl: string =
      environment.PacerDomainURL + 'PerformanceAfe/ListByPerformanceBODID/' + this.performanceBodId;

    return this.http.get(serviceUrl, { responseType: 'json' }).pipe(
      map((data: any[]) => {
        this.loader.emit(false);
        return data;
      })
    );
  }

  public retrieveByPerformanceBodId(performanceId: string): Observable<any> {
    const serviceUrl: string = environment.PacerDomainURL + 'PerformanceAfe/ListByPerformanceBODID/' + performanceId;

    return this.http.get(serviceUrl, { responseType: 'json' }).pipe(
      map((data: any[]) => {
        return data;
      })
    );
  }

  public update(): Observable<any> {
    const serviceUrl: string = environment.PacerDomainURL + 'PerformanceAfe/Update/';

    return this.http.post(serviceUrl, JSON.stringify(this.updatedItems), httpOptions).pipe(
      map((data: any) => {
        // this.wasModified.emit(true);
        return data;
      })
    );
  }

  public updateTheReplace(updatedItems: IPerformanceAFE[]): Observable<any> {
    const serviceUrl: string = environment.PacerDomainURL + 'PerformanceAfe/Update/';

    return this.http.post(serviceUrl, JSON.stringify(updatedItems), httpOptions).pipe(
      map((data: any) => {
        // this.wasModified.emit(true);
        return data;
      })
    );
  }

  public delete(): Observable<any> {
    this.loader.emit(true);
    const serviceUrl: string = environment.PacerDomainURL + 'PerformanceAfe/Update/';

    const wbsIds = this.deletedItems.map(wbs => wbs.id);
    //this.removeReferences(wbsIds).subscribe();

    return this.http.post(serviceUrl, JSON.stringify(this.deletedItems), httpOptions).pipe(
      map((data: any) => {
        // this.wasModified.emit(true);
        this.loader.emit(false);
        return data;
      })
    );
  }

  public isNew(item: any): boolean {
    return !item.id;
  }
}
